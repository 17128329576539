import React, { memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import commonCss from '@/css/common.less';
import { CardProps } from './type';
import css from './style.less';

export const Card = memo((props: PropsWithChildren<CardProps>) => {
  const { firstHeading = false, title, titleTop, children, className } = props;

  const renderTitle = () => (
    <div className={css.tableHeader}>
      <div className={css.tableName}>{title}</div>
    </div>
  );

  return (
    <div className={classNames(commonCss.content, className)}>
      {firstHeading ? renderTitle() : null}

      {titleTop}

      {!firstHeading ? renderTitle() : null}

      {children}
    </div>
  );
});
