import React, { memo, useState } from 'react';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import { Button } from '@/components/widgets/button';
import { message } from '@/components/widgets/message';
import { warnError } from '@/components/infos';
import ExportCenter from '@/components/works/exportCenter';
import { ExportButtonProps } from './type';

export const ExportButton = memo((props: ExportButtonProps) => {
  const {
    onExport,
    emptyText = '导出明细为空',
    type = 'primary',
    ghost = true,
    label = '导出',
    ...restProps
  } = props;

  const [loading, setLoading] = useState(false);
  const [openDialog] = useDialogMethods();

  const handleExport = async () => {
    setLoading(true);
    try {
      const ok = await onExport();
      if (!ok) {
        message.warning(emptyText);
        return;
      }
      message.success('任务创建成功');
      openDialog(ExportCenter);
    } catch (error) {
      warnError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      type={type}
      ghost={ghost}
      onClick={handleExport}
      loading={loading}
      {...restProps}
    >
      {label}
    </Button>
  );
});
