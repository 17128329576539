import { RemindContentDtoLike } from './type';

class RemindContentService {
  processContent<T extends RemindContentDtoLike>(dto: T): T {
    const { contents } = dto;

    if (!contents) {
      return {
        ...dto,
        content: undefined,
        contents: []
      };
    }

    return {
      ...dto,
      content: undefined,
      contents: contents.reduce((prev, curr) => {
        const trimed = curr.trim();
        return trimed.length ? prev.concat(trimed) : prev;
      }, [] as string[])
    };
  }

  processContents<T extends RemindContentDtoLike>(dtos: T[]): T[] {
    return dtos.map(this.processContent);
  }

  isValidContent<T extends RemindContentDtoLike>(dto: T): boolean {
    const res = this.processContent(dto);
    return !!(res.contents?.length || res.imageId);
  }

  isValidContents<T extends RemindContentDtoLike>(dtos: T[]): boolean {
    return dtos.every(dto => this.isValidContent(dto));
  }
}

export default new RemindContentService();
