import React, { memo } from 'react';
import { Icon } from '@/components/widgets/icon';
import { Tooltip } from '@/components/widgets/tooltip';
import css from './style.less';
import classNames from 'classnames';
import { TooltipWithIconProps } from './type';

export const TooltipWithIcon = memo((props: TooltipWithIconProps) => {
  const {
    label,
    tooltip,
    showColon = false,
    icon = 'bangzhushuoming',
    tooltipClassNames,
    iconClassName
  } = props;

  return (
    <>
      {label ?? null}
      <Tooltip
        title={tooltip}
        className={classNames(css.customTooltip, tooltipClassNames)}
      >
        {tooltip ? (
          <Icon type={icon} className={classNames(css.icon, iconClassName)} />
        ) : null}
      </Tooltip>
      {showColon ? '：' : null}
    </>
  );
});
