// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_input_LY0tJ {\n  position: relative;\n}\n.style_input_LY0tJ .style_count_bCPSr {\n  position: absolute;\n  right: 10px;\n  padding: 6px;\n  color: #999999;\n}\n.ant-input-textarea-show-count > textarea {\n  margin-bottom: -25px;\n}\n.ant-input-textarea-show-count:after {\n  position: relative;\n  left: -16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/input/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,kBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AACJ;AAGA;EACE,oBAAA;AADF;AAIA;EACE,kBAAA;EACA,WAAA;AAFF","sourcesContent":[".input {\n  position: relative;\n\n  .count {\n    position: absolute;\n    right: 10px;\n    padding: 6px;\n    color: #999999;\n  }\n}\n\n:global(.ant-input-textarea-show-count) > textarea {\n  margin-bottom: -25px;\n}\n\n:global(.ant-input-textarea-show-count:after) {\n  position: relative;\n  left: -16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "style_input_LY0tJ",
	"count": "style_count_bCPSr"
};
export default ___CSS_LOADER_EXPORT___;
