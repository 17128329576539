import React, { ReactNode, memo } from 'react';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import { Button } from '@/components/widgets/button';
import { ValidReplyDialog } from '@/pages/generalSetting/validReplyDialog';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import userConfigRequest from '@/requests/userConfig';
import useRequest from '@/hooks/useRequest';
import { warnError } from '@/components/infos';
import { unitTextMap } from '@/constants/unitTime';

type ReplyNumTitleProps = {
  label?: ReactNode;
};

export const ReplyNumTitle = memo(({ label = '' }: ReplyNumTitleProps) => {
  const [openDialog] = useDialogMethods();

  const { data, run } = useRequest(() => userConfigRequest.validReplyConfig(), {
    onError: warnError
  });
  const { value, unit = 'DAYS' } = data || {};

  const openValidReplyDialog = async e => {
    const confirm = await openDialog(ValidReplyDialog, {});

    if (confirm) run();
  };

  return (
    <TooltipWithIcon
      label={label}
      tooltip={
        <div onClick={e => e.stopPropagation()}>
          消息发出后，买家在{value}
          {unitTextMap[unit]}内有回复即会被统计
          <Button type="link" onClick={openValidReplyDialog}>
            调整统计天数
          </Button>
        </div>
      }
    />
  );
});
