import React, { memo, useEffect, useRef, useState } from 'react';
import {
  shallowEqual,
  useAgentMethods,
  useAgentReducer,
  useAgentSelector
} from 'use-agent-reducer';
import { pick } from '@/polyfill/object';
import { getEncode64 } from '@/utils/str';
import { advertisementRef } from '@/components/works/advertisement/modal';
import css from './style.less';
import classNames from 'classnames';
import { AthenaCampaign } from '@/requests/clientApi/type';
import { warnError } from '@/components/infos';
import {
  ListenersType,
  PositionCodes
} from '@/components/works/advertisement/type';
import { stack } from '@/entry/popUpController/modal';
import { advertisementRequestService } from './service';
import { cacheRef } from '@/modules/cache';

const articleCode = 'hykfzs';
const CAMPAIGN_ACTION_TYPE = 'data-campaign-action-type';
const CAMPAIGN_ACTION_PARAM = 'data-campaign-action-param';

// 按照交互支持说明，配置`data-campaign-action-type="campaign-click-track"`, 记录点击数据以做效果跟踪
// 注意！！！️，配置给小程序使用的广告️，标签不可添加clickStats标记(attribute), 此标志会修改当前标签的href属性，导致通过athena.topchitu.com重定向，而小程序无法访问此域名
const CAMPAIGN_CLICK_TRACK_ACTION = 'campaign-click-track';
// 不感兴趣
const NOT_INTERESTING_PAUSE_ACTION = 'not-interesting-pause';

const closeListeners: ListenersType = [];
const openListeners: ListenersType = [];
const pauseListeners: ListenersType = [];

window.openBoard = (newPositionCode: PositionCodes) => {
  closeListeners.forEach(ls => ls(newPositionCode));
  openListeners.forEach(ls => ls(newPositionCode));
};

window.closeBoard = (closePositionCode: PositionCodes) => {
  closeListeners.forEach(ls => ls(closePositionCode));
};

window.pauseBoard = (pausePositionCode: PositionCodes) => {
  pauseListeners.forEach(ls => ls(pausePositionCode));
};

const subscribeClose = (listener: (arg) => void): (() => void) => {
  closeListeners.push(listener);
  return () => {
    const index = closeListeners.findIndex(l => l === listener);
    closeListeners.splice(index, 1);
  };
};

const subscribeOpen = (listener: (arg) => void): (() => void) => {
  openListeners.push(listener);
  return () => {
    const index = openListeners.findIndex(l => l === listener);
    openListeners.splice(index, 1);
  };
};

const subscribePause = (listener: (arg) => void): (() => void) => {
  pauseListeners.push(listener);
  return () => {
    const index = pauseListeners.findIndex(l => l === listener);
    pauseListeners.splice(index, 1);
  };
};

const useGetAd = (
  positionCode: PositionCodes
): [AthenaCampaign, (...arg) => void] => {
  const { sellerName, subName } = useAgentSelector(
    cacheRef.current,
    s => pick(s, 'sellerName', 'subName'),
    shallowEqual
  );

  const { state, addLogClick } = useAgentReducer(advertisementRef.current);
  const advertisementInfo =
    state.campaignsDatas.filter(ele => ele.positionCode === positionCode)[0] ||
    {};

  function getTagHref(e, campaignId) {
    const element = e.target as HTMLDivElement;
    const campaignActionType = element.getAttribute(CAMPAIGN_ACTION_TYPE);
    if (!campaignActionType) {
      return;
    }
    if (campaignActionType !== CAMPAIGN_CLICK_TRACK_ACTION) {
      return;
    }
    const href = element.getAttribute('href' || '');
    if (!href) {
      return;
    }
    const encodeLink = encodeURIComponent(href);
    try {
      addLogClick({
        campaignId,
        encodeLink,
        sellerNick: sellerName as string,
        subNick: subName as string
      });
    } catch (err) {
      warnError(err);
    }
  }

  return [advertisementInfo, getTagHref];
};

const reloadScript = (sc: HTMLScriptElement) => {
  const createScript = ({ innerHTML, src }: HTMLScriptElement) => {
    const newScript = document.createElement('script');
    newScript.type = 'text/javascript';
    newScript.innerHTML = innerHTML;
    if (src) {
      newScript.src = src;
    }
    return newScript;
  };

  const parent = sc.parentNode!;
  parent.removeChild(sc);
  parent.appendChild(createScript(sc));
};

const HTMLRender = memo(
  ({
    html,
    onClickAdContent,
    ...other
  }: {
    html: string;
    onClickAdContent: (arg) => void;
  } & React.HTMLAttributes<HTMLDivElement>) => {
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const { current } = divRef;
      if (!current) {
        return;
      }
      const scripts = current.getElementsByTagName('script');
      Array.from(scripts).forEach(ele => reloadScript(ele));
    }, [html]);
    return (
      <div
        ref={divRef}
        {...other}
        onClick={e => onClickAdContent(e)}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
);

export const AdvertisementPop = memo(
  ({ positionCode }: { positionCode: PositionCodes }) => {
    const [adInfo, getTagHref] = useGetAd(positionCode);
    const [content, setContent] = useState<string | null>(adInfo.content);
    const { campaignId } = adInfo;
    useEffect(() => {
      setContent(adInfo.content);
    }, [adInfo]);

    useEffect(
      () =>
        subscribeClose(closePositionCode => {
          if (closePositionCode !== positionCode) {
            return;
          }
          setContent(null);
        }),
      []
    );

    return content ? (
      <div className={classNames(css.adPopWrapper)}>
        <div className={css.adPopPosition}>
          <HTMLRender
            onClickAdContent={e => getTagHref(e, campaignId)}
            html={content}
          />
        </div>
      </div>
    ) : null;
  }
);

export const AdvertisementTop = memo(
  ({ positionCode }: { positionCode: PositionCodes }) => {
    const [adInfo, getTagHref] = useGetAd(positionCode);
    const [content, setContent] = useState<string | null>(adInfo.content);
    const { campaignId } = adInfo;
    const [newPositionCode, setNewPositionCode] = useState<PositionCodes | ''>(
      ''
    );
    const { subName } = cacheRef.current.state;

    useEffect(() => {
      setContent(adInfo.content);
    }, [adInfo]);

    useEffect(
      () =>
        subscribeClose(closePositionCode => {
          if (closePositionCode === newPositionCode) {
            setNewPositionCode('');
            return;
          }
          if (closePositionCode !== positionCode) {
            return;
          }
          setContent(null);
        }),
      [newPositionCode]
    );

    useEffect(
      () =>
        subscribePause(pausePositionCode => {
          advertisementRequestService.campaignPause({
            articleCode: getEncode64(articleCode),
            campaignId: getEncode64(`${campaignId}`),
            subNick: getEncode64(`${subName}`)
          });
          if (pausePositionCode === newPositionCode) {
            setNewPositionCode('');
            return;
          }
          if (pausePositionCode !== positionCode) {
            return;
          }
          setContent(null);
        }),
      [newPositionCode]
    );

    useEffect(
      () =>
        subscribeOpen(openPositionCode => {
          setNewPositionCode(openPositionCode);
        }),
      []
    );

    if (content && newPositionCode) {
      return (
        <div>
          <HTMLRender
            style={{ maxHeight: 120, overflowY: 'hidden' }}
            onClickAdContent={e => getTagHref(e, campaignId)}
            html={content}
          />
          <AdvertisementPop positionCode={newPositionCode} />
        </div>
      );
    }
    return content ? (
      <HTMLRender
        style={{ maxHeight: 120, overflowY: 'hidden' }}
        onClickAdContent={e => getTagHref(e, campaignId)}
        html={content}
      />
    ) : null;
  }
);

const AdvertisementWrap = () => (
  <>
    <AdvertisementTop positionCode="hykfzs_top_banner_ad" />
    <AdvertisementPop positionCode="hykfzs_pop_global_ad" />
  </>
);

export const Advertisement = () => {
  const { getCampaign } = useAgentMethods(advertisementRef.current);
  const show = useAgentSelector(stack.current, s => s.current === 'ad');
  const { setDialogVisible } = useAgentMethods(stack.current);
  const before = useAgentSelector(stack.current, s => {
    const { values } = s;
    const index = values.findIndex(({ name }) => name === 'ad');
    return values.slice(0, index);
  });

  const fetch = async () => {
    const { sellerName, subName } = cacheRef.current.state;

    const { campaignsDatas } = await getCampaign(
      (sellerName as string) || '',
      subName as string
    );
    const positionCodes = campaignsDatas.reduce(
      (total, cur) => [...total, cur.positionCode],
      [] as string[]
    );
    setDialogVisible('ad', !!campaignsDatas.length, positionCodes);
  };

  const fetchedRef = useRef(false);

  useEffect(() => {
    if (fetchedRef.current) {
      return;
    }
    fetchedRef.current = true;
    fetch();
  }, [before]);
  return show ? <AdvertisementWrap /> : null;
};
