import React, { memo, useEffect, useState } from 'react';
import { Dialog, useDialogMethods } from '@/components/widgets/modal';
import { Table, Column } from '@/components/widgets/table';
import { Button } from '@/components/widgets/button';
import css from './index.less';
import { Input } from '@/components/widgets/input';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { RangePicker } from '@/components/widgets/datePicker';
import classNames from 'classnames';
import { useAgentReducer } from 'use-agent-reducer';
import { presaleActivityRef } from './model';
import { useLoading } from '@/hooks/useLoading';
import { warnError } from '@/components/infos';
import { message } from '@/components/widgets/message';
import { Spin } from '@/components/widgets/spin';
import moment, { Moment } from 'moment';
import { Form } from '@/components/widgets/form';
import { Space } from '@/components/widgets/space';
import { PresaleActivityWebDto } from '@/requests/presaleActivity/type';
import { Tooltip } from '@/components/widgets/tooltip';
import { RemindTimesConfigWebDto } from '@/requests/remindTimesConfig/type';

const activityStatusMap = {
  VALID: '生效中',
  INVALID: '已失效'
};

const timeMap = {
  HOURS: '小时',
  MINUTES: '分钟'
};

type AddActivityModalType = {
  isEdit: boolean;
  id: number;
  timeConfig: RemindTimesConfigWebDto[];
};

const filterTimeConfig = (timeConfig: RemindTimesConfigWebDto[]) =>
  timeConfig.filter(item => item.remindTimesType === 'SECOND_REMIND')[0];

const AddTipModal = (
  props: DialogDeployProps<{ timeConfig: RemindTimesConfigWebDto[] }>
) => {
  const {
    closeDialog,
    params: { timeConfig }
  } = props;
  const value = filterTimeConfig(timeConfig)?.remindTime.value;
  const unit = filterTimeConfig(timeConfig)?.remindTime.unit;

  return (
    <Dialog
      title="提示"
      width={410}
      onCancel={closeDialog}
      footer={
        <div>
          <Button type="primary" onClick={closeDialog}>
            关闭
          </Button>
        </div>
      }
    >
      <div style={{ color: '#666' }}>
        尾款支付结束时间 - 尾款支付开始时间 需大于
        <span className={css.originTime}>
          {value}
          {timeMap[unit]}
        </span>
      </div>
      <div style={{ color: '#666' }}>
        （<span>当前配置</span>：付尾款开始
        <span className={css.originTime}>
          {value}
          {timeMap[unit]}
        </span>
        后，催尾款）
      </div>
    </Dialog>
  );
};

const AddActivityModal = (props: DialogDeployProps<AddActivityModalType>) => {
  const [openDialog] = useDialogMethods();
  const {
    closeDialog,
    params: { isEdit, id }
  } = props;
  const {
    state,
    findPresaleActivityById,
    modifiedPresaleActivity,
    getPresaleActivityList
  } = useAgentReducer(presaleActivityRef.current);
  const { editDataSource, timeConfigList: timeConfig } = state;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    if (isEdit) {
      findPresaleActivityById(id);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        activityName: editDataSource.activityName,
        range: [editDataSource.finalPayStart, editDataSource.finalPayEnd]
      });
    }

    setLoading(false);
  }, [editDataSource]);

  const showAddTipModal = (arg1: Moment, arg2: Moment): boolean => {
    const time = filterTimeConfig(timeConfig).remindTime;
    const payTime = Math.abs(
      Number(moment(arg2).format('x')) - Number(moment(arg1).format('x'))
    );

    if (time.unit === 'HOURS') {
      if (payTime - time?.value * 60 * 60 * 1000 < 0) {
        return true;
      }
    } else if (time.unit === 'MINUTES') {
      if (payTime - time?.value * 60 * 1000 < 0) {
        return true;
      }
    }
    return false;
  };

  const handleFinish = async value => {
    const reverceTime =
      new Date(value.range[0]).getTime() - new Date(value.range[1]).getTime() >
      0;
    const params = {
      activityName: value.activityName.trim(),
      finalPayStart: reverceTime ? value.range[1] : value.range[0],
      finalPayEnd: reverceTime ? value.range[0] : value.range[1],
      id
    };

    if (timeConfig) {
      const res = showAddTipModal(value.range[0], value.range[1]);
      if (res) {
        openDialog(AddTipModal, { timeConfig });
        return;
      }
    }

    try {
      await modifiedPresaleActivity(params);
      message.success(isEdit ? '编辑成功' : '保存成功');

      getPresaleActivityList();
    } catch (error) {
      warnError(error);
    }
    closeDialog(true);
  };

  return (
    <Dialog
      title={isEdit ? '编辑活动' : '新建活动'}
      onOk={() => form.submit()}
      onCancel={closeDialog}
    >
      <Spin spinning={loading}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={form}
          onFinish={handleFinish}
          autoComplete="off"
        >
          <Form.Item
            label="活动名称"
            name="activityName"
            required
            rules={[{ required: true, whitespace: true }]}
          >
            <Input
              placeholder="请输入活动名称"
              showCount
              maxLength={30}
              className={css.activityName}
            />
          </Form.Item>
          <Form.Item
            label="尾款支付时间"
            name="range"
            required
            rules={[{ required: true }]}
          >
            <RangePicker showTime allowClear={false} />
          </Form.Item>
        </Form>
      </Spin>
    </Dialog>
  );
};

const DeleteActivityModal = (props: DialogDeployProps<any>) => {
  const { state, deletePresaleActivityById } = useAgentReducer(
    presaleActivityRef.current
  );
  const { deleteMsg } = state;
  const {
    closeDialog,
    params: { id }
  } = props;

  const handleOk = async () => {
    try {
      await deletePresaleActivityById(id);
      message.success('删除成功');
    } catch (error) {
      warnError(error);
    }

    closeDialog(true);
  };

  return (
    <Dialog
      title="提示"
      okText="确认删除"
      onOk={handleOk}
      onCancel={closeDialog}
    >
      <div className={css.deleteContent}>
        {deleteMsg.errorTaskNames ? (
          <>
            <div className={css.deleteMsg}>
              以下任务指定此活动，删除活动将一同删除对应的任务
            </div>
            <div className={css.deleteMsgBox}>
              {deleteMsg.errorTaskNames?.map(item => (
                <div>
                  <span className={css.taskTitle}>任务名称：</span>
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div>是否确认删除？</div>
        )}
      </div>
    </Dialog>
  );
};

export const ActivityManageModal = memo((props: DialogDeployProps<any>) => {
  const { closeDialog } = props;
  const {
    state,
    getPresaleActivityList,
    handleChange,
    getTimeConfigList,
    handleTableChange,
    initSort
  } = useAgentReducer(presaleActivityRef.current);
  const {
    dataSource,
    page,
    activityNameSort,
    activityStatusSort,
    rangeTimeSort
  } = state;
  const [openDialog] = useDialogMethods<PresaleActivityWebDto>();
  const loading = useLoading();

  useEffect(() => {
    getPresaleActivityList();
    getTimeConfigList();
  }, []);

  const handleEdit = async (id: number) => {
    await openDialog(AddActivityModal, { isEdit: true, id });
  };

  const handleDelete = async (id: number) => {
    openDialog(DeleteActivityModal, { id });
  };

  const addActivity = async () => {
    const res = await openDialog(AddActivityModal, {
      isEdit: false
    });
    if (res) {
      handleChange(1);
      initSort();
      getPresaleActivityList();
    }
  };

  return (
    <Dialog
      title="活动管理"
      onCancel={closeDialog}
      width={880}
      okText=""
      cancelText="关闭"
    >
      <div className={css.action}>
        {dataSource.length < 50 ? (
          <Button type="primary" onClick={addActivity}>
            新建活动
          </Button>
        ) : (
          <Tooltip title="最多创建50个活动">
            <Button disabled className={css.disabledBtn}>
              新建活动
            </Button>
          </Tooltip>
        )}
      </div>
      <Table
        dataSource={dataSource}
        loading={loading}
        rowKey="id"
        scroll={{ y: '300px' }}
        pagination={{
          pageSize: 10,
          position: ['bottomCenter'],
          onChange: handleChange,
          current: page,
          showSizeChanger: false
        }}
        bordered
        className={css.table}
        onChange={handleTableChange}
      >
        <Column
          title="活动名称"
          dataIndex="activityName"
          width="200px"
          sorter
          sortOrder={activityNameSort}
        />
        <Column
          title="状态"
          dataIndex="activityStatus"
          sorter
          sortOrder={activityStatusSort}
          width="120px"
          render={value => (
            <Space>
              <div
                className={classNames(
                  value === 'VALID' ? css.validDot : css.invalidDot
                )}
              />
              {activityStatusMap[value]}
            </Space>
          )}
        />
        <Column
          title="尾款支付时间"
          dataIndex="rangeTime"
          sorter
          sortOrder={rangeTimeSort}
          render={(value, record: PresaleActivityWebDto) => (
            <div>
              {record.finalPayStart} 到 {record.finalPayEnd}
            </div>
          )}
        />
        <Column
          title="操作"
          dataIndex="id"
          width="120px"
          render={id => (
            <Space>
              <Button type="link" onClick={() => handleEdit(id)}>
                编辑
              </Button>
              <Button type="link" onClick={() => handleDelete(id)}>
                删除
              </Button>
            </Space>
          )}
        />
      </Table>
    </Dialog>
  );
});
