import { AxiosRequestConfig } from 'axios';
import { UploadFileOptions, UploadFileRes } from './type';
import { AuthorizeConfig } from '@/requests/staffAccount/type';

abstract class Api {
  abstract uploadFile(v: UploadFileOptions): Promise<UploadFileRes | undefined>;

  abstract uploadImg(v: File): Promise<number>;

  abstract downloadImg(v: number): Promise<Blob | undefined>;

  abstract http(params: {
    path: string;
    config?: AxiosRequestConfig;
  }): Promise<any>;

  abstract authorize(v?: string): Promise<AuthorizeConfig | any>;
}

export default Api;
