import React, { memo, useRef } from 'react';
import { Paragraph } from '@/components/widgets/typography';
import MiniImage from '@/components/works/miniImage';
import { approveStatusMap } from '../../goodsSelectModal/constants';
import { SkuWebDto } from '@/types/esItem';
import css from './style.less';
import { combineSkuProperties } from '../../goodsSelectModal/service';

type SkuToolTipProps = {
  itemSkus: SkuWebDto[];
};

export const SkuToolTip = memo((props: SkuToolTipProps) => {
  const { itemSkus } = props;

  // 让重复校验弹窗出现的时候tooltip不会自动关掉
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={divRef} className={css.skuToolTip}>
      {itemSkus.map(sku => (
        <div key={sku.taobaoSkuId} className={css.sku}>
          <div className={css.imgBox}>
            <div style={{ position: 'relative' }}>
              <MiniImage
                src={sku.picUrl}
                size={50}
                style={{ border: '1px solid #85aeff' }}
                lazyLoad
              />
              <div className={css.approveStatusMask}>
                {approveStatusMap[sku.approveStatus]}
              </div>
            </div>
          </div>
          <Paragraph
            style={{ marginBottom: '4px', height: '41px' }}
            ellipsis={{
              rows: 2,
              tooltip: combineSkuProperties(sku.skuProperties)
            }}
          >
            {sku.title}
          </Paragraph>
          <Paragraph ellipsis={{ rows: 1, tooltip: sku.price }}>
            <span style={{ color: '#818181' }}>SKU价格：</span>
            {sku.price}
          </Paragraph>
        </div>
      ))}
    </div>
  );
});
