import { useCallback, useState } from 'react';

/**
 * 强制组件重新渲染的 hook
 * https://ahooks.js.org/zh-CN/hooks/life-cycle/use-update
 */
const useUpdate = () => {
  const [, setState] = useState({});

  return useCallback(() => setState({}), []);
};

export default useUpdate;
