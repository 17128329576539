import { Model, strict, act, flow, Flows } from 'agent-reducer';
import remindBuyStatsRequest from '@/requests/remindBuyStats';
import {
  StatsListResult,
  RecordDetailDto,
  RemindBuyStrategyStatsWebDto
} from '@/requests/remindBuyStats/type';
import { uiPrompt } from '@/avatars';
import {
  formatDayFromNow,
  checkRange,
  formatNow,
  transToValidDateRange,
  isValidDateRange
} from '@/utils/date';
import type { PageDetail } from '@/types/pageDetail';
import exportCenterRequest from '@/requests/exportCenter';
import type { RemindBuyState } from './type';
import { Range } from '@/types/date';

const initStatsData: StatsListResult = {
  valueList: [],
  avgMap: {},
  sumMap: {}
};

@strict()
export class RemindBuyStatModel implements Model<RemindBuyState> {
  state: RemindBuyState = {
    statsLoading: false,
    statsTableLoading: false,
    detailLoading: false,
    dateRange: [formatDayFromNow(-6), formatDayFromNow(0)],
    statsTabKey: 'time',
    stats: initStatsData,
    strategyStatsList: initStatsData,
    statsPage: 1,
    page: 1,
    pageSize: 10,
    total: 0,
    detailRecordList: [],
    recordSearchParams: {
      from: formatDayFromNow(-6),
      to: formatDayFromNow(0)
    }
  };

  @act()
  private statsLoad(): RemindBuyState {
    return { ...this.state, statsLoading: true };
  }

  @act()
  private statsUnload(): RemindBuyState {
    return { ...this.state, statsLoading: false };
  }

  @act()
  private detailLoad(): RemindBuyState {
    return { ...this.state, detailLoading: true };
  }

  @act()
  private statsTableLoad(): RemindBuyState {
    return { ...this.state, statsTableLoading: true };
  }

  @act()
  private statsTableUnload(): RemindBuyState {
    return { ...this.state, statsTableLoading: false };
  }

  @act()
  private deatilUnload(): RemindBuyState {
    return { ...this.state, detailLoading: false };
  }

  @act()
  private changeState(state: Partial<RemindBuyState>): RemindBuyState {
    return { ...this.state, ...state };
  }

  @act()
  changeStatsTabKey(statsTabKey: string): RemindBuyState {
    return { ...this.state, statsTabKey };
  }

  @act()
  changeStatsPage(statsPage: number): RemindBuyState {
    return { ...this.state, statsPage };
  }

  private transformDetailList(dataList: RecordDetailDto[]): RecordDetailDto[] {
    return dataList.flatMap(item => {
      const { relatedTradeWebDtos, ...rest } = item;
      if (relatedTradeWebDtos.length) {
        return relatedTradeWebDtos.map((i, index) => ({
          rowSpan: index === 0 ? relatedTradeWebDtos.length || 1 : 0,
          ...i,
          ...rest
        })) as unknown as RecordDetailDto[];
      }
      return item;
    });
  }

  private fetchDetailRecord(params): Promise<PageDetail<RecordDetailDto>> {
    const { recordSearchParams, pageSize, page } = this.state;

    return remindBuyStatsRequest.recordDetailList({
      page,
      pageSize,
      ...recordSearchParams,
      ...params
    });
  }

  private fetchStats(): Promise<StatsListResult> {
    const [from, to] = this.state.dateRange;
    return remindBuyStatsRequest.statsList({ from, to });
  }

  private fetchStrategyStatsList(): Promise<RemindBuyStrategyStatsWebDto> {
    const {
      dateRange: [from, to],
      subStrategyId
    } = this.state;
    return remindBuyStatsRequest.strategyStatsList({
      from,
      to,
      subStrategyId,
      taskType: 'REMIND_BUY'
    });
  }

  @flow(Flows.latest())
  async initStats(): Promise<void> {
    this.statsLoad();
    try {
      const stats = await this.fetchStats();

      this.changeState({ stats, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.statsUnload();
    }
  }

  @flow(Flows.latest())
  async initStrategyStatsList(): Promise<void> {
    this.statsTableLoad();
    try {
      const strategyStatsList = await this.fetchStrategyStatsList();

      this.changeState({ strategyStatsList, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.statsTableUnload();
    }
  }

  @flow(Flows.latest())
  async initRecord(): Promise<void> {
    this.detailLoad();
    try {
      const detailRecord = await this.fetchDetailRecord({ page: 1 });
      const { dataList, totalCount } = detailRecord;
      this.changeState({
        detailRecordList: this.transformDetailList(dataList),
        total: totalCount,
        page: 1
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  validateDateRange = (
    dateRange: [string | undefined, string | undefined]
  ): Range | undefined => {
    if (!isValidDateRange(dateRange)) {
      return;
    }

    const validDateRange = transToValidDateRange(dateRange);

    if (!checkRange([validDateRange[0], formatNow()], 180)) {
      uiPrompt.current.error('仅可查看最近180天的发送明细');
      return;
    }
    if (!checkRange(validDateRange, 31)) {
      uiPrompt.current.error('一次最多选择31天');
      return;
    }

    return validDateRange;
  };

  @flow()
  handleDateRangeChange(
    dateRange: [string | undefined, string | undefined]
  ): void {
    const validDateRange = this.validateDateRange(dateRange);
    if (!validDateRange) {
      return;
    }

    this.changeState({ dateRange: validDateRange });

    Promise.all([this.initStats(), this.initStrategyStatsList()]);
  }

  @flow(Flows.latest())
  async handleStrategyStatsListSearch(subStrategyId?: number): Promise<void> {
    this.changeState({ subStrategyId, statsPage: 1 });

    const { dateRange } = this.state;
    const validDateRange = this.validateDateRange(dateRange);

    if (!validDateRange) {
      return;
    }

    this.initStrategyStatsList();
  }

  @flow()
  async handleDetailSearch(value): Promise<void> {
    this.detailLoad();

    const { range, ...rest } = value;
    this.changeState({
      page: 1,
      recordSearchParams: { from: range[0], to: range[1], ...rest }
    });

    try {
      const { dataList, totalCount } = await this.fetchDetailRecord({
        page: 1
      });
      this.changeState({
        detailRecordList: this.transformDetailList(dataList),
        total: totalCount
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  @flow(Flows.latest())
  async handleDetailPageChange(page: number): Promise<void> {
    this.detailLoad();
    try {
      const { dataList, totalCount } = await this.fetchDetailRecord({ page });
      this.changeState({
        detailRecordList: this.transformDetailList(dataList),
        total: totalCount,
        page
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  async handleExportDetail(): Promise<boolean> {
    const { recordSearchParams, total } = this.state;

    if (!total) {
      return false;
    }
    await exportCenterRequest.saveRecordDetailExportTask({
      taskType: 'REMIND_BUY',
      ...recordSearchParams
    });
    return true;
  }

  async handleExportStats(): Promise<boolean> {
    const { stats, statsTabKey, strategyStatsList, dateRange, subStrategyId } =
      this.state;
    const [from, to] = dateRange;
    if (
      (!stats.valueList.length && statsTabKey === 'time') ||
      (!strategyStatsList.valueList.length && statsTabKey === 'task')
    ) {
      return false;
    }

    const params = { from, to };

    if (statsTabKey === 'time') {
      await exportCenterRequest.createDailySendStatsExportTask({
        remindType: 'REMIND_BUY',
        ...params
      });
    } else {
      await exportCenterRequest.createStrategySendStatsExportTask({
        taskType: 'REMIND_BUY',
        subStrategyId,
        ...params
      });
    }

    return true;
  }
}
