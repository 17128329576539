export type CacheKey =
  | 'date'
  | 'pageSize'
  | 'account'
  | 'token'
  | 'shopName'
  | 'accountType'
  | 'shopId'
  | 'pageAuthority'
  | 'subName'
  | 'permissions'
  | 'fromShop' // 是否来源于店铺
  | 'sellerName';


export type CacheState = {
  [key in CacheKey]?: unknown;
};

export enum Permission {
  REPLY = 'REPLY',
  REMIND_BUY = 'REMIND_BUY',
  REMIND_PAY = 'REMIND_PAY',
  PAYMENT_PUSH = 'PAYMENT_PUSH',
  CONSIGN_PUSH = 'CONSIGN_PUSH',
  DELIVERY_PUSH = 'DELIVERY_PUSH',
  SIGNED = 'SIGNED',
  RECEIVED = 'RECEIVED',
  REFUND_PUSH = 'REFUND_PUSH',
  ITEM_RECOMMEND = 'ITEM_RECOMMEND',
  MEMBER_MARKETING_PUSH = "MEMBER_MARKETING_PUSH",
  INVITE_RATE = "INVITE_RATE",
  TRADE_BATCH_PUSH = "TRADE_BATCH_PUSH",
  LOGISTIC_STAGNATE_PUSH = 'LOGISTIC_STAGNATE_PUSH',
  REPLY_TIMEOUT_PUSH = 'REPLY_TIMEOUT_PUSH',
  TRANSFER_WORD_PUSH = 'TRANSFER_WORD_PUSH',
  EXCHANGE_PUSH = 'EXCHANGE_PUSH',
  MEMBER_MARKETING = 'MEMBER_MARKETING',
  TRADE_NOPAID_CLOSED_PUSH = 'TRADE_NOPAID_CLOSED_PUSH'
}
