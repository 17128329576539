import { connect, MiddleWare, StateProcess } from 'agent-reducer';
import { UIState } from '@/modules/ui/type';
import { uiRef } from '@/modules/ui';

export function takeUiCache<S>(
  uiMapper: (state: S) => Partial<UIState>
): MiddleWare {
  return function uiCacheMiddleWare() {
    return function nextProcess(next: StateProcess) {
      return function stateProcess(result: any) {
        const uiState = uiMapper(result);
        return connect(uiRef.current).run((agent) => {
           agent.update(uiState);
           return next(result);
        });
      };
    };
  };
}

export function takeLoading(): MiddleWare {
  return function loadingMiddleWare() {
     connect(
      uiRef.current
    ).run((agent) => {
       agent.setPageLoading(true);
    });
    return function nextProcess(next: StateProcess) {
      return function stateProcess(result: any) {
        Promise.resolve(result).finally(() => {
         connect(
            uiRef.current
          ).run((agent) => {
            agent.setPageLoading(false);
         });
        });
        return next(result);
      };
    };
  };
}
