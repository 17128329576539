/* eslint-disable react/jsx-boolean-value */
import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { Input, InputNumber } from '@/components/widgets/input';
import { Select } from '@/components/widgets/select';
import { FormLike, IOS } from '@/components/widgets/formLike';
import css from './style.less';
import { SearchConditionUnit } from '@/components/works/components/conditionUnit';
import { Button } from '@/components/widgets/button';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { staffService } from '../service';
import { DefaultOptionType } from 'antd/lib/select';
import { warnError } from '@/components/infos';
import {
  AddNoPhoneStaffDailogProps,
  ShopPermissionConfiguratorPorps
} from './type';
import { randomPassword } from '@/utils/randomPassword';
import { message } from '@/components/widgets/message';
import { PermissionShopType } from '@/requests/staff/type';
import { Tooltip } from '@/components/widgets/tooltip';
import { platformTextMap } from '@/types/platform';

const errorMsgMap = {
  invalidName: "姓名不支持输入'主号'",
  notSelectShops: '请选择可配置店铺',
  needName: '请输入姓名',
  needAccount: '请输入账号',
  validAccount: '请输入3~16位的账号',
  needPassword: '请输入密码'
};

const defaultFormValue = {
  staffName: '',
  accountName: '',
  phoneNumber: '',
  password: '',
  configurePermission: {
    permissionShopType: 'NONE' as PermissionShopType,
    shopIds: [] as number[]
  },
  enterpriseManager: false
};

const ShopPermissionConfigurator = (props: ShopPermissionConfiguratorPorps) => {
  const {
    value = {
      permissionShopType: 'NONE',
      shopIds: []
    },
    onChange
  } = props;
  const { permissionShopType = 'NONE', shopIds = [] } = value;
  const [configurableShops, setConfigurableShops] = useState<
    DefaultOptionType[]
  >([]);

  const getConfigurableShopsList = async () => {
    try {
      const result = await staffService.listConfigurableShops();
      setConfigurableShops(
        result.map(i => ({ value: i.shopId, label: `（${platformTextMap[i.platform]}）${i.shopName}` }))
      );
    } catch (e) {
      warnError(e);
    }
  };

  useEffect(() => {
    getConfigurableShopsList();
  }, []);

  const handlePermissionChange = e => {
    onChange?.({
      ...value,
      permissionShopType: e.target.value
    });
  };

  const handleShopChange = v => {
    onChange?.({
      ...value,
      shopIds: v
    });
  };

  return (
    <div className={css.shopPermissionConfigurator}>
      <RadioGroup value={permissionShopType} onChange={handlePermissionChange}>
        <Radio value="NONE">无</Radio>
        <Radio value="ALL">全部店铺</Radio>
        <Radio value="POINTED">指定店铺</Radio>
      </RadioGroup>
      {permissionShopType === 'POINTED' ? (
        <div className={classNames(css.permissionShop)}>
          <Select
            mode="multiple"
            style={{ width: 350, marginTop: 8 }}
            allowClear
            placeholder="请选择店铺"
            options={configurableShops}
            value={shopIds}
            onChange={handleShopChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export const AddNoPhoneStaffDailog = memo(
  (props: DialogDeployProps<AddNoPhoneStaffDailogProps>) => {
    const { closeDialog, params } = props;
    const [formValue, setFormValue] = useState(defaultFormValue);

    const {
      id,
      staffName,
      phoneNumber,
      accountName,
      permissionShopType,
      permissionShops,
      password = '',
      enterpriseManager
    } = params;

    useEffect(() => {
      if (id) {
        setFormValue({
          staffName,
          accountName,
          phoneNumber,
          password,
          configurePermission: {
            permissionShopType: permissionShopType || 'NONE',
            shopIds: permissionShops.map(i => i.shopId)
          },
          enterpriseManager
        });
      }
    }, []);

    const handleFormChange = value => {
      setFormValue(value);
    };

    const validateError = () => {
      const {
        accountName,
        staffName,
        password,
        configurePermission: { permissionShopType, shopIds }
      } = formValue;
      const trimAccountName = accountName.trim();
      const trimStaffName = staffName.trim();
      const trimPassword = password.trim();
      const needSelectShop =
        permissionShopType === 'POINTED' && !shopIds.length;
      const notAllowedName = trimStaffName.includes('主号');
      const validAccount =
        trimAccountName.length <= 16 && trimAccountName.length >= 3;

      if (!trimStaffName) {
        return errorMsgMap.needName;
      }

      if (!trimAccountName) {
        return errorMsgMap.needAccount;
      }

      if (!validAccount) {
        return errorMsgMap.validAccount;
      }

      if (notAllowedName) {
        return errorMsgMap.invalidName;
      }

      if (!trimPassword && !id) {
        return errorMsgMap.needPassword;
      }

      if (needSelectShop) {
        return errorMsgMap.notSelectShops;
      }
    };

    const handleOk = async () => {
      const {
        accountName,
        phoneNumber,
        staffName,
        password,
        configurePermission: { permissionShopType, shopIds },
        enterpriseManager
      } = formValue;

      const res = validateError();
      if (res) {
        message.warning(res);
        return;
      }

      const params = {
        id: id || undefined,
        staffName,
        accountName,
        phoneNumber: phoneNumber || undefined,
        password: id ? undefined : password,
        permissionShopType,
        shopIds,
        enterpriseManager
      };
      try {
        if (id) {
          await staffService.updateStaff(params);
          message.success('编辑成功');
        } else {
          await staffService.saveStaff(params);
          message.success('保存成功');
        }
        closeDialog(true);
      } catch (error) {
        warnError(error);
      }
    };

    const randomGeneratePassword = () => {
      setFormValue({
        ...formValue,
        password: randomPassword()
      });
    };

    return (
      <Dialog
        title={`${id ? '编辑' : '添加'}员工`}
        onCancel={closeDialog}
        onOk={handleOk}
      >
        <FormLike value={formValue} onChange={handleFormChange}>
          <SearchConditionUnit
            className={css.unit}
            labelClassName={css.label}
            label="员工姓名"
            name="staffName"
            required
          >
            <Input
              className={css.inputWidth}
              placeholder="请输入员工姓名"
              trim
              maxLength={10}
              showCount
            />
          </SearchConditionUnit>
          <SearchConditionUnit
            className={css.unit}
            labelClassName={css.label}
            label="登录账号"
            name="accountName"
            required
          >
            <Input
              className={css.inputWidth}
              placeholder="请输入登录账号，建议手机号"
              trim
              disabled={!!id}
            />
          </SearchConditionUnit>
          {id ? null : (
            <SearchConditionUnit
              className={css.unit}
              labelClassName={css.label}
              label="登录密码"
              name="password"
              required
            >
              <Input
                className={css.inputWidth}
                placeholder="请输入登录密码"
                trim
                addonAfter={
                  <Button
                    type="ghost"
                    className={css.btnReset}
                    onClick={randomGeneratePassword}
                  >
                    随机生成
                  </Button>
                }
              />
            </SearchConditionUnit>
          )}
          <SearchConditionUnit
            className={css.unit}
            labelClassName={css.label}
            label="手机号"
            name="phoneNumber"
          >
            <InputNumber
              className={css.inputWidth}
              stringMode
              placeholder="请输入手机号"
            />
          </SearchConditionUnit>
          <SearchConditionUnit
            className={css.unit}
            labelClassName={css.label}
            label="使用权限"
            name="usePermission"
            io={IOS.valueIO}
          >
            <RadioGroup>
              <Radio checked>有</Radio>
            </RadioGroup>
          </SearchConditionUnit>
          <SearchConditionUnit
            className={css.unit}
            labelClassName={css.label}
            label="配置权限"
            name="configurePermission"
          >
            <ShopPermissionConfigurator />
          </SearchConditionUnit>
          <SearchConditionUnit
            className={css.unit}
            labelClassName={css.label}
            label="企业管理权限"
            name="enterpriseManager"
            io={IOS.valueIO}
          >
            <RadioGroup>
              <Radio value={false}>无</Radio>
              <Radio value={true}>
                <span>有</span>
                <Tooltip title="可添加、编辑、删除员工等">
                  <InfoCircleOutlined
                    style={{ verticalAlign: 'middle', marginLeft: '4px' }}
                  />
                </Tooltip>
              </Radio>
            </RadioGroup>
          </SearchConditionUnit>
        </FormLike>
      </Dialog>
    );
  }
);
