import React from 'react';
import { ColumnType } from 'antd/es/table/interface';
import { DelayData } from '@/types/delayData';
import { ItemWithDelayIcon } from '@/components/works/components/delayIcon';
import { ColumnBuildCmd } from './type';

function compare<T extends string | number>(a: T, b: T) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  return (a as number) - (b as number);
}

export function sortByKey<T extends Record<string, any>>(
  keyOrCallback: keyof T | ((obj: T) => string | number)
): (a: T, b: T) => number {
  if (typeof keyOrCallback === 'function') {
    return (a, b) => compare(keyOrCallback(a), keyOrCallback(b));
  }
  return (a, b) => compare(a[keyOrCallback], b[keyOrCallback]);
}

export function buildColumnsWithSorter<
  T extends Record<string, number | string | DelayData<any>>
>(columns: ColumnBuildCmd<T>[]): ColumnType<T>[] {
  return columns.map(column => {
    if (column.isDelayData) {
      return {
        sorter: sortByKey(v => (v[column.dataIndex] as DelayData<any>).data),
        render: v => <ItemWithDelayIcon value={v} />,
        ...(column as ColumnType<T>)
      };
    }
    return {
      sorter: sortByKey(column.dataIndex),
      ...(column as ColumnType<T>)
    };
  });
}
