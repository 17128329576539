import React, { memo, useRef } from 'react';
import { Icon } from '@/components/widgets/icon';
import { Emoji } from '@/components/widgets/emoji';
import { TextArea } from '@/components/widgets/input';
import { EmojiTextareaProps, AddTextareaInfoProps } from './type';
import css from './style.less';
import { Alert } from '@/components/widgets/alert';
import { Tag } from '@/components/widgets/tag';
import { Tooltip } from '@/components/widgets/tooltip';
import UploadPic from '../../uploadPic';

export const CheckStatusTags = memo(
  ({ type, checkFailReason }: { type?: string; checkFailReason?: string }) => {
    switch (type) {
      case 'WAIT_CHECK':
        return (
          <Tooltip title="保存任务自动提交审核">
            <Tag color="#FA8C16" className={css.textAreaTag}>
              内容待审核
            </Tag>
          </Tooltip>
        );
      case 'CHECKING':
        return (
          <Tooltip title="客服审核时间 09:00 至 22:59">
            <Tag color="#3D7EFF" className={css.textAreaTag}>
              内容审核中
            </Tag>
          </Tooltip>
        );
      case 'CHECK_SUCCESS':
        return (
          <Tooltip title="内容审核通过，可正常生效，用于回复">
            <Tag color="#1DC11D" className={css.textAreaTag}>
              审核通过
            </Tag>
          </Tooltip>
        );
      case 'CHECK_FAIL':
        return (
          <Tooltip
            title={
              <>
                审核不通过原因：
                <br />
                {checkFailReason}
              </>
            }
          >
            <Tag color="#FF5E64" className={css.textAreaTag}>
              审核不通过
              <Icon type="bangzhushuoming" className={css.icon} />
            </Tag>
          </Tooltip>
        );

      default:
        return null;
    }
  }
);

export const EmojiTextarea = memo((props: EmojiTextareaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const {
    width,
    index,
    length,
    value,
    onChange,
    onDelete,
    extraVariables,
    maxLength,
    placeholder = '',
    type = 'WAIT_CHECK',
    checkFailReason = '',
    showUpload = true,
    showInfo = true
  } = props;
  const maxFontNumer = maxLength || 500;

  const handleEmojiSelect = (key: string) => {
    if (textAreaRef && textAreaRef.current) {
      const current = textAreaRef.current as HTMLTextAreaElement & {
        resizableTextArea: { textArea: HTMLTextAreaElement };
      };
      const { textArea } = current.resizableTextArea!;
      if (textArea) {
        const startPos = textArea.selectionStart; // 所选中的字段第一个字符串的index
        const endPos = textArea.selectionEnd; // 所选中的字段最后一个字符串index+1
        if (key.length + textArea.value.length - endPos + startPos > 500) {
          return;
        }
        const restoreTop = textArea.scrollTop;
        const start = textArea.value.substring(0, startPos);
        const end = textArea.value.substring(endPos, textArea.value.length);
        textArea.value = start + key + end;

        if (restoreTop > 0) {
          textArea.scrollTop = restoreTop;
        }
        textArea.focus();
        textArea.selectionStart = startPos + key.length;
        textArea.selectionEnd = startPos + key.length;
        onChange({ content: textArea.value.slice(0, 500) });
      }
    }
  };

  const handleVariable = s => handleEmojiSelect(s);

  const handleTextAreaChange = (content: string) => {
    onChange({ ...value, content });
  };

  const handleImageChange = (imageId: number) => {
    onChange({ ...value, imageId });
  };
  const handleImageRemove = () => {
    onChange({ ...value, imageId: undefined });
  };

  const onImageSendTypeChange = () => {
    onChange({
      ...value,
      replyOrder:
        value.replyOrder === 'IMAGE_BEFORE_TEXT'
          ? 'TEXT_BEFORE_IMAGE'
          : 'IMAGE_BEFORE_TEXT'
    });
  };

  return (
    <div
      className={index !== length - 1 ? css.textAreaMain : ''}
      style={{ width }}
    >
      {length > 1 ? (
        <div className={css.topTextMargin}>
          <span className={css.topText}>{`随机推送${index + 1}`}</span>
          <Icon
            className={css.icon}
            type="shanchu"
            onClick={() => onDelete && onDelete()}
          />
        </div>
      ) : null}
      <div className={css.emojiInfo} style={{ width }}>
        <div className={css.emojiInfoLeft}>
          <div className={css.emoji}>
            <Emoji onSelect={handleEmojiSelect} disabled={false} />
          </div>
          {extraVariables?.map(v => (
            <span
              key={`extra_${v}`}
              className={css.variableList}
              onClick={() => handleVariable(`{${v}}`)}
            >{`{${v}}`}</span>
          ))}
        </div>
        <CheckStatusTags type={type} checkFailReason={checkFailReason} />
      </div>
      <div className={css.textAreaBox}>
        <div className={css.textAreaWrap}>
          <TextArea
            maxLength={500}
            ref={textAreaRef}
            className={css.textArea}
            value={value.content}
            onChange={handleTextAreaChange}
            rows={5}
            placeholder={placeholder}
          />
          <div className={css.fontNumberBox}>
            <span>
              {(value?.content || '').length}/{maxFontNumer}
            </span>
          </div>
        </div>

        <div className={css.imageWrap}>
          {showUpload ? (
            <UploadPic
              imageId={value.imageId}
              changeImage={handleImageChange}
              removeImage={handleImageRemove}
              size="small"
            />
          ) : null}
          {value.imageId ? (
            <Tooltip
              placement="right"
              title={
                <div>
                  {value.replyOrder === 'TEXT_BEFORE_IMAGE'
                    ? '先发文字，再发图片，点击交换顺序'
                    : '先发图片，再发文字，点击交换顺序'}
                </div>
              }
            >
              <Icon
                className={css.sendOrder}
                type={
                  value.replyOrder === 'TEXT_BEFORE_IMAGE'
                    ? 'xianwenhoutu'
                    : 'xiantuhouwen'
                }
                onClick={() => onImageSendTypeChange()}
              />
            </Tooltip>
          ) : null}
        </div>
        {showInfo ? (
          <Alert
            className={css.textAreaMsg}
            message="请不要输入平台禁止的敏感内容，以免买家拒收或投诉"
            type="info"
            showIcon
          />
        ) : null}
      </div>
    </div>
  );
});

export const AddTextareaInfo = memo(
  ({
    prefix,
    name = '推送内容',
    info = '多个推送内容随机发送，更加真实。',
    limit,
    onChange
  }: AddTextareaInfoProps) => (
    <div>
      {!limit ? (
        <span className={css.addLink} onClick={onChange}>
          {prefix}增加{name}
        </span>
      ) : null}
      <span className={css.info}>
        {limit ? `最多${limit}个${name}，` : ''}
        {info}
      </span>
    </div>
  )
);
