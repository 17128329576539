import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/widgets/button';
import { message } from '@/components/widgets/message';
import { Table, Column } from '@/components/widgets/table';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import css from './style.less';
import { useAgentReducer } from 'use-agent-reducer';
import { templateModalRef } from './model';
import { warnError } from '@/components/infos';
import { chunk } from '@/polyfill/array';
import { Pager } from '@/components/layouts/pager';
import { RemindTimesType } from '@/requests/shop/type';

const textMap = {
  REMIND_PAY: '催付',
  REMIND_BUY: '催拍',
  PAYMENT_PUSH: '付款关怀',
  SIGN_PUSH: '签收提醒',
  RECEIVED_PUSH: '确认收货关怀',
  APPLY_REFUND_PUSH: '退款关怀',
  URGE_RETURN_GOODS_PUSH: '催买家退货',
  REFUND_SUCCESS_PUSH: '退款成功',
  CONSIGN_PUSH: '发货提醒',
  LOGISTIC_STAGNATE_PUSH: '物流停滞提醒',
  DELIVERY_PUSH: '派送提醒',
  REMIND_FINAL_PAY: {
    FIRST_REMIND: '付尾款开始前',
    SECOND_REMIND: '付尾款开始后',
    THIRD_REMIND: '付尾款结束前'
  },
  INVITE_RATE: '服务邀评',
  APPLY_EXCHANGE_PUSH: '换货关怀'
};

export default memo(
  (
    props: DialogDeployProps<{
      type: string;
      num: number;
      remindTimesType?: RemindTimesType;
    }>
  ) => {
    const { closeDialog, params } = props;
    const { type, num, remindTimesType } = params;
    const text =
      type === 'REMIND_FINAL_PAY'
        ? textMap[type][remindTimesType || 'FIRST_REMIND']
        : textMap[type];
    const {
      state: { rowKeys, dataSource },
      initial,
      setRowKeys
    } = useAgentReducer(templateModalRef.current);

    const onSelectChange = useCallback(
      (record, selected) => {
        if (num > 0 && rowKeys.length + num > 9 && selected) {
          message.warning(`最多支持添加10个推送内容（已添加${num}个）`);
          return;
        }
        if (num <= 0 && rowKeys.length > 9 && selected) {
          message.warning(`最多支持添加10个推送内容`);
          return;
        }
        if (selected) {
          setRowKeys([...rowKeys, record.id]);
        } else {
          setRowKeys([...rowKeys.filter(id => id !== record.id)]);
        }
      },
      [rowKeys]
    );

    const onSelectAll = (selected, selectedRows, changeRows) => {
      if (selected) {
        if ([...rowKeys, ...changeRows.map(v => v.id)].length + num > 10) {
          message.warning(
            `最多支持添加10个推送内容${num ? `（已添加${num}个）` : ''}`
          );
          return;
        }
        setRowKeys([...rowKeys, ...changeRows.map(v => v.id)]);
      } else {
        const arr = rowKeys.filter(
          id => !changeRows.some(val => val.id === id)
        );
        setRowKeys(arr);
      }
    };

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
      (async () => {
        try {
          await initial(type, remindTimesType);
        } catch (error) {
          warnError(error);
        }
      })();
    }, []);

    const rowSelection = {
      selectedRowKeys: rowKeys,
      onSelect: onSelectChange,
      onSelectAll
    };

    const handleCancel = () => closeDialog([]);

    const handleOk = useCallback(async () => {
      const rowKeySet = new Set<number>(rowKeys);
      closeDialog([
        dataSource.filter(({ id }) => rowKeySet.has(id!)),
        dataSource
      ]);
    }, [dataSource, rowKeys]);

    const handlePageChange = (newPage, size) => {
      setPage(newPage);
      setPageSize(size);
    };

    const footer = () => (
      <div>
        <div className={css.info}>已经选择 {rowKeys.length} 个话术</div>
        <Button type="primary" disabled={!rowKeys.length} onClick={handleOk}>
          添加
        </Button>
        <Button onClick={handleCancel}>取消</Button>
      </div>
    );

    return (
      <Dialog
        title={`${text}话术模板`}
        width={900}
        onCancel={handleCancel}
        footer={footer()}
      >
        <div className={css.main}>
          <div>
            <Table
              bordered
              size="small"
              rowKey="id"
              rowSelection={rowSelection}
              dataSource={chunk(dataSource, pageSize)[page - 1]}
              scroll={{ y: 360 }}
              pagination={false}
            >
              <Column title="话术主题" dataIndex="contentTheme" width={200} />
              <Column title={`${text}话术`} dataIndex="content" />
            </Table>
          </div>
          <div>
            <Pager
              page={page}
              pageSize={pageSize}
              onChange={handlePageChange}
              total={dataSource.length}
            />
          </div>
        </div>
      </Dialog>
    );
  }
);
