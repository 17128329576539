import React, { memo } from 'react';
import { useDialogMethods } from '@/components/widgets/modal';
import { AddressDialog } from '@/components/works/addressDialog';
import { useProvinceNum } from '@/modules/address';
import { Icon } from '@/components/widgets/icon';
import { ReceiverAddressFilterConfig } from '@/requests/remindStrategy/types';
import css from './style.less';

export type AddressesSelectorProps = {
  value?: ReceiverAddressFilterConfig;
  onChange?: (value: ReceiverAddressFilterConfig) => void;
};

/**
 * 买家收货地址
 */
export const AddressesSelector = memo((props: AddressesSelectorProps) => {
  const { value = { type: 'ALL', areaIds: [] }, onChange } = props;
  const { type, areaIds = [] } = value;

  const provinceNum = useProvinceNum(areaIds);

  const [openDialog] = useDialogMethods();

  const openAddressModal = async () => {
    const result = (await openDialog(AddressDialog, {
      cityList: areaIds
    })) as number[];

    if (!result) {
      return;
    }

    onChange?.({
      type: result.length ? 'POINT' : 'ALL',
      areaIds: result
    });
  };

  return (
    <span>
      {type === 'POINT'
        ? `已选择${provinceNum}个省${areaIds.length}个市`
        : '不限'}
      <Icon className={css.icon} type="bianji" onClick={openAddressModal} />
    </span>
  );
});
