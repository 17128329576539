/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import { HelpTooltip } from '@/components/works/helpTooltip';
import { usePlatform } from '@/hooks/usePlatform';
import { ItemListMode } from '../../type';

export const ItemHelpTooltip = memo(({ mode }: { mode: ItemListMode }) => {
  const { isJD } = usePlatform();

  const keyLabel = isJD
    ? mode === 'SPU'
      ? '商品编码、商品货号'
      : '商品SKU编号、商家SKU'
    : '商品ID、商家编码';
  return (
    <HelpTooltip
      tooltip={
        <>
          <div>1.多个关键词可使用空格连接，表示要求“商品标题”中同时出现</div>
          <div>
            {`2.支持批量搜索${keyLabel}，最多支持同时搜索50个商品，多个请使用换行分隔`}
          </div>
        </>
      }
    />
  );
});
