import { Model, strict, act } from 'agent-reducer';
import { uiPrompt } from '@/avatars';
import { FatigueConfigWebUpdateCmd } from '@/requests/userConfig/type';
import { Params, State } from './type';

@strict()
export class FatigueDialogModel implements Model<State> {
  state: State = {
    saveLoading: false,
    fatigueConfig: {
      active: false,
      timeLimit: {
        unit: 'DAYS',
        value: 1
      },
      maxTimes: 3,
      excludeTypes: []
    },
    remindTypes: []
  };

  constructor(params: Params) {
    this.state.fatigueConfig = { ...params.fatigueConfig };
    this.state.remindTypes = [...params.remindTypes];
  }

  @act()
  load(): State {
    return { ...this.state, saveLoading: true };
  }

  @act()
  unload(): State {
    return { ...this.state, saveLoading: false };
  }

  @act()
  changeFatigueConfig(fatigueConfig: State['fatigueConfig']): State {
    return { ...this.state, fatigueConfig };
  }

  confirm(): FatigueConfigWebUpdateCmd | null {
    const { fatigueConfig } = this.state;

    const { timeLimit, maxTimes } = fatigueConfig;

    if (
      timeLimit.value < 1 ||
      timeLimit.value > 30 ||
      !Number.isInteger(timeLimit.value)
    ) {
      uiPrompt.current.warning('天数请填写1~30的整数');
      return null;
    }

    if (maxTimes < 1 || maxTimes > 10 || !Number.isInteger(maxTimes)) {
      uiPrompt.current.warning('推送次数请填写1~10的整数');
      return null;
    }

    return fatigueConfig;
  }
}
