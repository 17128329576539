import { chunk } from '@/polyfill/array';

/**
 * 假分页
 * @param dataSource
 * @param pageSize
 * @param pageNo
 */
export const safePaging = <T>(
  dataSource: T[],
  pageSize: number,
  pageNo: number
): { data: T[]; page: number } => {
  const dataChunk = chunk(dataSource, pageSize);
  const newPage = pageNo > dataChunk.length ? dataChunk.length : pageNo;
  const result = dataChunk[newPage - 1] || [];
  return { data: result, page: newPage };
};

/**
 * 真分页列表最后一页最后一条删除后前进一页
 * @param page number
 * @param pageSize number
 * @param total number
 * @returns number
 */
export const adjustPageAfterDelete = (
  page: number,
  pageSize: number,
  total: number
): number => {
  if (Math.ceil((total - 1) / pageSize) < page && page > 1) {
    return page - 1;
  }

  return page;
};
