import { strict, act, flow, Model, sharing } from 'agent-reducer';
import shopRequest from '@/requests/shop';
import { uiPrompt } from '@/avatars';
import {
  BaseInfoDto,
  SubAccountTrackOrderLimitDto
} from '@/requests/shop/type';
import moment from 'moment';
import { push } from '@/libs/history';
import routeConfig from '@/routeConfig';
import { differTime, duration } from '@/utils/date';

type State = {
  baseInfo?: BaseInfoDto;
  subAccountTrackOrderInfo?: SubAccountTrackOrderLimitDto;
};

function getDaysDiff(startDate: string, endDate: string): number {
  const start = moment(startDate);
  const end = moment(endDate);

  return end.diff(start, 'days');
}

@strict()
class ShopModel implements Model<State> {
  state: State = {};

  interce;

  @act()
  changeState(state: Partial<State>) {
    return { ...this.state, ...state };
  }

  @flow()
  async fetch() {
    try {
      const [baseInfo, subAccountTrackOrderInfo] = await Promise.all([
        shopRequest.baseInfo(),
        shopRequest.subAccountTrackOrderInfo()
      ]);

      this.changeState({
        baseInfo,
        subAccountTrackOrderInfo
      });
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow()
  async initial(): Promise<void> {
    try {
      await this.fetch();

      const { subAccountTrackOrderInfo } = this.state;

      if (
        !subAccountTrackOrderInfo ||
        !subAccountTrackOrderInfo?.nextValidLimitInfo
      ) {
        return;
      }

      const {
        openTrackOrderNum,
        nextValidLimitInfo: { startDay, limitNum }
      } = subAccountTrackOrderInfo;

      const daysUntilExpiry = getDaysDiff(
        moment().format('YYYY-MM-DD'),
        startDay
      );

      if (limitNum === -1) {
        return;
      }

      if (
        daysUntilExpiry <= 3 &&
        daysUntilExpiry >= 0 &&
        openTrackOrderNum > limitNum &&
        startDay &&
        !window.location.pathname.includes(routeConfig.guide.path)
      ) {
        uiPrompt.current.expireModalConfirm({
          title: '温馨提示',
          content: `跟单账号上限将于${startDay}降为${limitNum}个，届时若开启跟单账号数量超过上限，`,
          subContent: '系统将自动选择近3日跟单任务发送条数较少的账号并关闭跟单'
        });
      }
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }
}

export const shopModelRef = sharing(() => new ShopModel());
