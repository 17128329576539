import React, { HTMLAttributes, memo } from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import iconfont from '@/css/iconfont.js';

const CustomIcon = createFromIconfontCN({
  scriptUrl: iconfont
});

export const Icon = memo(
  ({
    type,
    className,
    ...others
  }: HTMLAttributes<HTMLSpanElement> & { readonly type: string }) => (
    <span {...others}>
      <CustomIcon type={`icon-${type}`} className={className} />
    </span>
  )
);
