import { Http, http } from '@/libs/http';
import { RemindType } from '@/types/remindType';
import {
  CheckForModifyTypeWebDto,
  RemindTimesConfigCreateAndDeleteWebCmd,
  RemindTimesConfigDeleteCmd,
  RemindTimesConfigModifyCmd,
  RemindTimesConfigWebDto,
  StrategyNumDto
} from './type';
import { RemindTimesType } from '@/types/remindTimesType';

export class RemindTimesRequest {
  private http: Http = http('/api/shop/remind-times-config');

  // 增加第二次推送
  addSecondTimesConfig(remindType: RemindType): Promise<boolean> {
    return this.http
      .path('add-second-times-config')
      .setRequestBody({ remindType })
      .post();
  }

  //  新增推送次数
  createRemindTimesConfig(
    params: RemindTimesConfigCreateAndDeleteWebCmd
  ): Promise<boolean> {
    return this.http.path('create').setRequestBody(params).post();
  }

  // 删除推送次数
  deleteRemindTimesConfig(
    params: RemindTimesConfigCreateAndDeleteWebCmd
  ): Promise<boolean> {
    return this.http.path('delete').setRequestBody(params).post();
  }

  //  允许的推送次数列表
  enableRemindTimesTypes(remindType: RemindType): Promise<RemindTimesType[]> {
    return this.http
      .path('remind-times-types')
      .setRequestBody({ remindType })
      .post();
  }

  // 修改推送次数前校验-只有催尾款需要
  checkRemindTimesConfig(
    params: RemindTimesConfigModifyCmd
  ): Promise<CheckForModifyTypeWebDto> {
    return this.http.path('check').setRequestBody(params).post();
  }

  // 催付次数配置列表查询接口
  getRemindTimesConfigList(
    remindType: RemindType
  ): Promise<RemindTimesConfigWebDto[]> {
    return this.http.path('list').setRequestParams({ remindType }).get();
  }

  // 删除第二次推送
  removeRemindTimesConfig(
    params: RemindTimesConfigDeleteCmd
  ): Promise<boolean> {
    return this.http.path('remove-times-config').setRequestBody(params).post();
  }

  // 推送次数关联策略数量接口
  getStrategyNum(remindTimesConfigId: number): Promise<StrategyNumDto> {
    return this.http
      .path('strategy-num')
      .setRequestParams({ remindTimesConfigId })
      .get();
  }

  // 用户催付次数配置修改接口
  updateRemindTimesConfig(
    params: RemindTimesConfigModifyCmd
  ): Promise<boolean> {
    return this.http.path('update').setRequestBody(params).post();
  }
}

export const remindTimesRequest = new RemindTimesRequest();
