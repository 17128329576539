import React, { Fragment, memo } from 'react';
import classNames from 'classnames';
import { IOS } from '@/components/widgets/formLike';
import { ConditionUnit } from '@/components/works/components/conditionUnit';
import { Divider } from '@/components/widgets/divider';
import { Input, InputNumber } from '@/components/widgets/input';
import { Radio, RadioGroup } from '@/components/widgets/radio';

import {
  CommonProps,
  TitleProps,
  SignTypeRadioProps,
  FormErrorRendererProps,
  TransferWordTypeProps,
  TimeoutSelectorProps
} from './type';
import css from './style.less';

export const Title = memo(({ name }: TitleProps) => (
  <Divider orientation="left" orientationMargin="-68px">
    {name}
  </Divider>
));

export const FormErrorRender = memo((props: FormErrorRendererProps) => {
  const { error } = props;

  if (!error) {
    return null;
  }

  return <div className={css.errorText}>{error}</div>;
});

/**
 * 任务名称
 */
export const TaskNameInput = memo((props: CommonProps) => {
  const { field, label } = props;

  return (
    <Fragment key={field}>
      <ConditionUnit className={css.inputItem} label={label} name={field}>
        <Input
          className={classNames(css.input)}
          showCount
          blurTrim
          style={{ width: 400 }}
          maxLength={20}
        />
      </ConditionUnit>
    </Fragment>
  );
});

/**
 * 开启状态
 */
export const ActiveRadio = memo((props: CommonProps) => {
  const { field, label } = props;
  return (
    <ConditionUnit
      key={field}
      className={css.item}
      label={label}
      name={field}
      io={IOS.valueIO}
    >
      <RadioGroup>
        <Radio value>开启</Radio>
        <Radio value={false}>关闭</Radio>
      </RadioGroup>
    </ConditionUnit>
  );
});

/**
 * 物流状态
 */
export const SignTypeRadio = memo((props: SignTypeRadioProps) => {
  const { value, onChange } = props;

  const handleChange = e => {
    onChange?.(e.target.value);
  };

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <Radio value="ALL">不限</Radio>
      <Radio value="UNSIGNED">未签收</Radio>
      <Radio value="SIGNED">已签收</Radio>
    </RadioGroup>
  );
});

/**
 * 转接话术-买家消息
 */
export const TransferWordTypeRadio = memo((props: TransferWordTypeProps) => {
  const { value = 'ALL', onChange } = props;

  const handleChange = e => {
    onChange?.(e.target.value);
  };

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <Radio value="ALL">不限</Radio>
      <Radio value="ANY_BUYER_MSG">转接前有买家消息</Radio>
      <Radio value="NO_BUYER_MSG">转接前无买家消息</Radio>
    </RadioGroup>
  );
});

/**
 * 超时时间
 */
export const TimeoutSelector = memo((props: TimeoutSelectorProps) => {
  const {
    value = {
      value: 30,
      unit: 'SECONDS'
    },
    onChange
  } = props;

  const handleChange = v => {
    onChange?.({ ...value, value: v });
  };

  return (
    <div className={css.item}>
      买家问题
      <InputNumber
        className={css.remindTimeInput}
        value={value?.value}
        onChange={handleChange}
      />
      秒没有人工/机器人回复时，进行回复
    </div>
  );
});
