import React, { memo } from 'react';
import { Table, Summary } from '@/components/widgets/table';
import { TableProps } from 'antd/lib/table';
import { uniqueId } from 'lodash';

type StrategyStatsTableProps = {
  summaryColumns?: string[];
  currentPage: number;
  sumMap?: Record<string, any>;
  avgMap?: Record<string, any>;
  onPageChange: (page: number) => void;
};

const StrategyStatsTable = memo(
  (props: TableProps<any> & StrategyStatsTableProps) => {
    const {
      dataSource,
      loading,
      columns,
      summaryColumns,
      currentPage,
      sumMap = {},
      avgMap = {},
      onPageChange,
      ...restProps
    } = props;

    const showSummary = summaryColumns && dataSource?.length;

    return (
      <Table
        rowKey={uniqueId}
        bordered
        size="small"
        dataSource={dataSource}
        columns={columns}
        showSorterTooltip={false}
        loading={loading}
        summary={_ =>
          showSummary ? (
            <Summary fixed>
              <Summary.Row>
                <Summary.Cell index={0}>汇总</Summary.Cell>
                {summaryColumns?.map((v, i) => (
                  <Summary.Cell key={i} index={i + 1}>
                    {sumMap[v]?.data ?? sumMap[v] ?? '-'}
                  </Summary.Cell>
                ))}
              </Summary.Row>
              <Summary.Row>
                <Summary.Cell index={0}>均值</Summary.Cell>
                {summaryColumns?.map((v, i) => (
                  <Summary.Cell key={i} index={i + 1}>
                    {avgMap[v]?.data ?? avgMap[v] ?? '-'}
                  </Summary.Cell>
                ))}
              </Summary.Row>
            </Summary>
          ) : null
        }
        pagination={{
          size: 'default',
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          showQuickJumper: true,
          showSizeChanger: false,
          current: currentPage,
          onChange: onPageChange
        }}
        {...restProps}
      />
    );
  }
);

export default StrategyStatsTable;
