import React from 'react';
import { Select, SelectProps } from '@/components/widgets/select';
import { message } from '@/components/widgets/message';
import { uiPrompt } from '@/avatars';

export function TagSelect(props: SelectProps): JSX.Element {
  const { onChange, onSelect, mode = 'tags', maxLength, ...rest } = props;

  const { value = [] } = rest;

  const handleChange: SelectProps['onChange'] = (tags: string[], options) => {
    onChange?.(
      tags?.map(item => item.trim()).filter(item => item.length),
      options
    );
  };

  const handleSelect: SelectProps['onSelect'] = (v: string, options) => {
    if(value.includes(v)){
      uiPrompt.current.warning('请勿重复输入')
    }
    onSelect?.(value, options);
  };

  return (
    <Select
      mode={mode}
      open={false}
      maxLength={maxLength}
      onChange={handleChange}
      onSelect={handleSelect}
      {...rest}
    />
  );
}
