import { TradeCloseReason, TradeStatus } from '@/requests/remindStrategy/types';
import { Unit } from '@/types/unitTime';

export const tradeStatusList: TradeStatus[] = [
  'WAIT_ORDER',
  'WAIT_PAY',
  'WAIT_SEND',
  'SELLER_CONSIGNED_PART',
  'SENT',
  'SUCCESS',
  'CLOSE'
];

export const tradeStatusMap: Partial<Record<TradeStatus, string>> = {
  WAIT_ORDER: '未下单',
  WAIT_PAY: '等待买家付款',
  WAIT_SEND: '等待卖家发货',
  SELLER_CONSIGNED_PART: '卖家部分发货',
  SENT: '卖家已发货',
  SUCCESS: '交易成功',
  CLOSE: '交易关闭'
};

export const timeoutPrefixMap: Partial<Record<TradeStatus, string>> = {
  WAIT_SEND: '等待',
  SELLER_CONSIGNED_PART: '部分发货',
  SENT: '已发货'
};

export const timeoutUnitMap: Partial<Record<Unit, string>> = {
  HOURS: '小时',
  DAYS: '天'
};

export const closeReasonMap: Record<TradeCloseReason, string> = {
  REFUND_SUCCESS: '退换货成功导致',
  TIMEOUT_NO_PAY: '超时未付款导致'
};

export const closeReasonList: TradeCloseReason[] = [
  'REFUND_SUCCESS',
  'TIMEOUT_NO_PAY'
];
