import React from 'react';
import {
  checkRange,
  formatNow,
  isValidDateRange,
  transToValidDateRange
} from '@/utils/date';
import { Range } from '@/types/date';
import { message } from '@/components/widgets/message';

export function validateDateRange(
  dateRange: [string | undefined, string | undefined]
): Range | undefined {
  if (!isValidDateRange(dateRange)) {
    return;
  }
  const validDateRange = transToValidDateRange(dateRange);

  if (!checkRange([validDateRange[0], formatNow()], 180)) {
    message.error('仅可查看最近180天的发送明细');
    return;
  }
  if (!checkRange(validDateRange, 31)) {
    message.error('一次最多选择31天');
    return;
  }

  return validDateRange;
}
