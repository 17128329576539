import { Pagination } from '@/components/widgets/pagination';
import React, { memo, useCallback } from 'react';
import css from './style.less';
import { PagerProps } from './type';

export const Pager = memo(
  ({ page, pageSize, total, onChange, ...restProps }: PagerProps) => {
    const handleChange = useCallback(
      (current: number, size?: number) => {
        onChange(current, size || pageSize);
      },
      [onChange]
    );

    return total ? (
      <div className={css.pagination}>
        <Pagination
          hideOnSinglePage
          showQuickJumper
          current={page}
          onChange={handleChange}
          pageSize={pageSize}
          total={total}
          showSizeChanger={false}
          {...restProps}
        />
      </div>
    ) : null;
  }
);
