import React, { memo } from 'react';
import { Popover } from '@/components/widgets/popover';
import css from './style.less';
import { PresaleActivityWebDto } from '@/requests/presaleActivity/type';
import classNames from 'classnames';

type GoodsPopProps = {
  presaleItem: PresaleActivityWebDto;
  className?: string;
};

export const activityStatusMap = {
  VALID: '生效中',
  INVALID: '已失效'
};

export const PresaleTooltipTitle = memo(
  ({ presaleDto }: { presaleDto: PresaleActivityWebDto }) => {
    const { activityName, finalPayStart, finalPayEnd } = presaleDto;
    return (
      <div className={css.presaleTooltipTitle}>
        <div className={css.presaleTooltipTitleLabel}>活动名称</div>
        <div style={{ color: '#333', maxWidth: 400 }}>{activityName}</div>
        <div className={css.presaleTooltipTitleLabel}>尾款支付时间</div>
        <div style={{ color: '#333' }}>
          {finalPayStart}至{finalPayEnd}
        </div>
      </div>
    );
  }
);

export default memo((props: GoodsPopProps) => {
  const { presaleItem, className } = props;
  const { activityName, activityStatus } = presaleItem;

  return (
    <Popover
      placement="left"
      destroyTooltipOnHide
      overlayClassName={css.pop}
      content={<PresaleTooltipTitle presaleDto={presaleItem} />}
    >
      <div
        className={classNames(
          className,
          css.label,
          activityStatus === 'INVALID' ? css.default : null
        )}
      >
        <div className={css.activityName}>{activityName}</div>
        <div  className={css.activityStatus}>
          |&ensp;{activityStatusMap[activityStatus]}
        </div>
      </div>
    </Popover>
  );
});
