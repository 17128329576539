import React from 'react';
import routes from '@/routeConfig';
import DunningBuyStrategy from './dunningBuyStrategy';
import DunningPayStrategy from './dunningPayStrategy';
import PaymentPushStrategy from './paymentPushStrategy';
import GeneralSetting from './generalSetting';
import StaffManage from './staffManage';
import OperationLog from './operationLog';
import DunningPayStatistics from './remindStatistics/dunningPayStatistics';
import DunningBuyStatistics from './remindStatistics/dunningBuyStatistics';
import PaymentPushStatistics from './remindStatistics/paymentPushStatistics';
import ConsignPushStrategy from './consignPushStrategy';
import SignPushStatistics from './remindStatistics/signPushStatistics';
import SignPushStrategy from './signPushStrategy';
import ReceivedPushStrategy from './receivedPushStrategy';
import RefundPushStrategy from './refundPushStrategy';
import RefundPushStatistics from './remindStatistics/refundPushStatistics';
import ReceivedPushStatistics from './remindStatistics/receivedPushStatistics';
import MemberMarketing from './memberMarketing';
import ItemMatch from './itemMatch';
import ItemConfig from './itemConfig';
import ItemStatistics from './itemStatistics';
import InviteRateStrategy from './inviteRate/inviteRateStrategy';
import InviteRateStatistics from './inviteRate/inviteRateStatistics';
import RealTimeLogin from './realTimeLogin';
import TardeBatchPush from './tardeBatchPush';
import ConsignPushStatistics from './remindStatistics/consignPushStatistics';
import DeliveryPushStrategy from './deliveryPushStrategy';
import DeliveryPushStatistics from './remindStatistics/deliveryPushStatistics';
import LogisticStagnatePushStrategy from './logisticStagnatePushStrategy';
import LogisticsPushStatistic from './remindStatistics/logisticsPushStatistics';
import ReplyTimeoutPush from './replyTimeoutPush';
import ExchangePushStrategy from './exchangePushStrategy';
import TransferWordConfig from './transferWordConfig';
import BlacklistManage from './blacklistManage';
import ExchangePushStatistics from './remindStatistics/exchangePushStatistics';
import BuyerGroupManage from './buyerGroupManage';
import BuyerGroupMarket from './buyerMemberMarket';
import CusTeamManange from './cusTeamManange';
import BuyerMemberMarketStats from './buyerMemberMarketStats';
import TradeNopaidClosedPushStrategy from './tradeNopaidClosedPushStrategy';
import SubAccountPermissions from './subAccountPermissions';
import TradeNopaidClosedPushStatistics from './remindStatistics/tradeNopaidClosedPushStatistics';
import SubAccountOnlineStatus from './subAccountOnlineStatus';
import ItemGroup from './itemGroup';
import SubAccounOperationsLog from './subAccountOperationsLog';
import Login from './login';
import FindPassword from './findPassword';
import Guide from './guide';

export const shopChildNodes = [
  <DunningPayStatistics
    path={routes.dunningPayStatistics.path}
    key={routes.dunningPayStatistics.path}
  />,
  <DunningBuyStatistics
    path={routes.dunningBuyStatistics.path}
    key={routes.dunningBuyStatistics.path}
  />,
  <PaymentPushStatistics
    path={routes.paymentPushStatistics.path}
    key={routes.paymentPushStatistics.path}
  />,
  <ConsignPushStatistics
    path={routes.consignPushStatistics.path}
    key={routes.consignPushStatistics.path}
  />,
  <DeliveryPushStatistics
    path={routes.deliveryPushStatistics.path}
    key={routes.deliveryPushStatistics.path}
  />,
  <SignPushStatistics
    path={routes.signPushStatistics.path}
    key={routes.signPushStatistics.path}
  />,
  <ReceivedPushStatistics
    path={routes.receivedPushStatistics.path}
    key={routes.receivedPushStatistics.path}
  />,
  <RefundPushStatistics
    path={routes.refundPushStatistics.path}
    key={routes.refundPushStatistics.path}
  />,
  <DunningBuyStrategy
    path={routes.dunningBuyStrategy.path}
    key={routes.dunningBuyStrategy.path}
  />,

  <DunningPayStrategy
    path={routes.dunningPayStrategy.path}
    key={routes.dunningPayStrategy.path}
  />,
  <RefundPushStrategy
    path={routes.refundPushStrategy.path}
    key={routes.refundPushStrategy.path}
  />,
  <GeneralSetting
    path={routes.generalSetting.path}
    key={routes.generalSetting.path}
  />,
  <PaymentPushStrategy
    path={routes.paymentPushStrategy.path}
    key={routes.paymentPushStrategy.path}
  />,
  <ConsignPushStrategy
    path={routes.consignPushStrategy.path}
    key={routes.consignPushStrategy.path}
  />,
  <LogisticStagnatePushStrategy
    path={routes.logisticStagnatePushStrategy.path}
    key={routes.logisticStagnatePushStrategy.path}
  />,
  <DeliveryPushStrategy
    path={routes.deliveryPushStrategy.path}
    key={routes.deliveryPushStrategy.path}
  />,
  <InviteRateStrategy
    path={routes.inviteRatestrategy.path}
    key={routes.inviteRatestrategy.path}
  />,
  <InviteRateStatistics
    path={routes.inviteRateStatistic.path}
    key={routes.inviteRateStatistic.path}
  />,
  <MemberMarketing
    path={routes.memberMarketing.path}
    key={routes.memberMarketing.path}
  />,
  <TardeBatchPush
    path={routes.tradeBatchPush.path}
    key={routes.tradeBatchPush.path}
  />,
  <BuyerGroupManage
    path={routes.buyerGroupManage.path}
    key={routes.buyerGroupManage.path}
  />,
  <BuyerGroupMarket
    path={routes.buyerMemberMarket.path}
    key={routes.buyerMemberMarket.path}
  />,
  <BuyerMemberMarketStats
    path={routes.buyerMemberMarketStats.path}
    key={routes.buyerMemberMarketStats.path}
  />,
  <ItemMatch path={routes.itemMatch.path} key={routes.itemMatch.path} />,
  <ItemConfig path={routes.itemConfig.path} key={routes.itemConfig.path} />,
  <ItemStatistics
    path={routes.itemStatistics.path}
    key={routes.itemStatistics.path}
  />,
  <OperationLog
    path={routes.operationLog.path}
    key={routes.operationLog.path}
  />,
  <BlacklistManage
    path={routes.blacklistManage.path}
    key={routes.blacklistManage.path}
  />,
  <SubAccountPermissions
    path={routes.subAccountPermissions.path}
    key={routes.subAccountPermissions.path}
  />,
  <SubAccountOnlineStatus
    path={routes.subAccountOnlineStatus.path}
    key={routes.subAccountOnlineStatus.path}
  />,
  <SignPushStrategy
    path={routes.signPushStrategy.path}
    key={routes.signPushStrategy.path}
  />,
  <ReceivedPushStrategy
    path={routes.receivedPushStrategy.path}
    key={routes.receivedPushStrategy.path}
  />,
  <LogisticsPushStatistic
    path={routes.logisticStagnatePushStatistic.path}
    key={routes.logisticStagnatePushStatistic.path}
  />,
  <ReplyTimeoutPush
    path={routes.replyTimeoutPush.path}
    key={routes.replyTimeoutPush.path}
  />,
  <TransferWordConfig
    path={routes.transferWordConfig.path}
    key={routes.transferWordConfig.path}
  />,
  <ExchangePushStrategy
    path={routes.exchangePushStrategy.path}
    key={routes.exchangePushStrategy.path}
  />,
  <ExchangePushStatistics
    path={routes.exchangePushStatistics.path}
    key={routes.exchangePushStatistics.path}
  />,
  <TradeNopaidClosedPushStrategy
    path={routes.tradeNopaidClosedPushStrategy.path}
    key={routes.tradeNopaidClosedPushStrategy.path}
  />,
  <CusTeamManange
    path={routes.cusTeamManage.path}
    key={routes.cusTeamManage.path}
  />,
  <TradeNopaidClosedPushStatistics
    path={routes.tradeNopaidClosedPushStatistics.path}
    key={routes.tradeNopaidClosedPushStatistics.path}
  />,
  <ItemGroup
    path={routes.itemGroupManage.path}
    key={routes.itemGroupManage.path}
  />,
  <Login path={routes.login.path} key={routes.login.path} />,
  <FindPassword
    path={routes.findPassword.path}
    key={routes.findPassword.path}
  />,
  <Guide path={routes.guide.path} key={routes.guide.path} />
];

// 主账号企业管理菜单
export const enterpriseChildNodes = [
  <StaffManage path={routes.staffManage.path} key={routes.staffManage.path} />,
  <RealTimeLogin
    path={routes.realTimeLogin.path}
    key={routes.realTimeLogin.path}
  />,

  <SubAccounOperationsLog
    path={routes.subAccounOperationsLog.path}
    key={routes.subAccounOperationsLog.path}
  />,
  <Login path={routes.login.path} key={routes.login.path} />,
  <FindPassword
    path={routes.findPassword.path}
    key={routes.findPassword.path}
  />,
  <Guide path={routes.guide.path} key={routes.guide.path} />
];
