export const pick = <T extends Record<string, unknown>, K extends keyof T>(
  object: T,
  ...keys: K[]
): Pick<T, K> =>
  keys.reduce(
    (r: Pick<T, K>, key: K) => ({ ...r, [key]: object[key] }),
    {} as Pick<T, K>
  );

export const omit = <T extends Record<string, unknown>, K extends keyof T>(
  object: T,
  ...keys: K[]
): Omit<T, K> =>
  keys.reduce((r: any, key: K) => {
    const { [key]: value, ...rest } = r;
    return rest;
  }, object);
