import { ContentTemplateRequest } from '@/requests/contentTemplate';
import { contentTemplateDto } from '@/requests/contentTemplate/type';
import { RemindTimesType } from '@/types/remindTimesType';
import { RemindType } from '@/types/remindType';

export class TemplateModalService {
  private contentTemplateRequest = new ContentTemplateRequest();

  getTemplateList(
    remindType: RemindType,
    remindTimesType?: RemindTimesType
  ): Promise<contentTemplateDto[]> {
    return this.contentTemplateRequest.contentTemplate({
      remindType,
      remindTimesType
    });
  }
}

export const templateModalService = new TemplateModalService();
