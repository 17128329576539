import React, { ReactNode, memo } from 'react';
import statsIcon1 from '@/images/stats-icon-1.png';
import statsIcon1_1 from '@/images/stats-icon-1-1.png';
import statsIcon2 from '@/images/stats-icon-2.png';
import statsIcon2_1 from '@/images/stats-icon-2-1.png';
import statsIcon3 from '@/images/stats-icon-3.png';
import statsIcon3_1 from '@/images/stats-icon-3-1.png';
import css from './style.less';
import classNames from 'classnames';
import { Statistic } from '@/components/widgets/statistic';
import { MarketingStatsSummaryWebDto } from '@/requests/buyerMembermarketStats/type';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';

type StatsCardProps = {
  icon: string;
  image: string;
  title: string | ReactNode;
  value: number | string;
  valueColor: string;
  backgroundColor?: string;
  unit: ReactNode;
  paymentIncrement?: number;
};

export const StatsCard = memo((props: StatsCardProps) => {
  const {
    title,
    value,
    unit,
    valueColor,
    backgroundColor,
    paymentIncrement,
    icon,
    image
  } = props;

  return (
    <div className={classNames(css.statsCardWrapper, backgroundColor)}>
      <div>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img src={icon} style={{ width: '20px', marginRight: '4px' }} />
          {title}
        </span>
        <div className={css.num}>
          <Statistic
            valueStyle={{
              color: `${valueColor}`,
              fontWeight: 'bold',
              fontSize: '18px',
              fontFamily: 'sans-serif'
            }}
            suffix={
              <span style={{ color: '#333', fontSize: '14px' }}>{unit}</span>
            }
            value={value}
          />
          {typeof paymentIncrement === 'number' ? (
            <span style={{ marginLeft: '24px', color: '#999999' }}>
              对比昨日上升：
              <span className={css.paymentUnit} style={{ color: '#FF4D4F' }}>
                {paymentIncrement}元
              </span>
            </span>
          ) : null}
        </div>
      </div>
      <img src={image} style={{ width: '48px' }} />
    </div>
  );
});

export const SummaryStats = memo(
  (props: { summaryStats: MarketingStatsSummaryWebDto }) => {
    const { summaryStats } = props;
    const {
      payment,
      paymentIncrement,
      sendNum,
      tradeCreatedNum,
      tradePaidNum,
      conversionRate
    } = summaryStats;

    return (
      <div>
        <StatsCard
          title="总收益"
          value={payment}
          valueColor="#FF8600"
          backgroundColor={css.origin}
          unit="元"
          paymentIncrement={paymentIncrement}
          icon={statsIcon1_1}
          image={statsIcon1}
        />
        <div className={css.summaryStatsItem}>
          <StatsCard
            title="总发送人次"
            value={sendNum}
            valueColor="#3D7EFF"
            backgroundColor={css.blue}
            unit="人"
            icon={statsIcon2_1}
            image={statsIcon2}
          />
          <StatsCard
            title="总下单人次"
            value={tradeCreatedNum}
            valueColor="#3D7EFF"
            backgroundColor={css.blue}
            unit="人"
            icon={statsIcon2_1}
            image={statsIcon2}
          />
        </div>
        <div className={css.summaryStatsItem}>
          <StatsCard
            title="总付款人次"
            value={tradePaidNum}
            valueColor="#3D7EFF"
            backgroundColor={css.blue}
            unit="人"
            icon={statsIcon2_1}
            image={statsIcon2}
          />
          <StatsCard
            title={
              <div>
                总转化率{' '}
                <TooltipWithIcon tooltip="总下单人次/总发送人次" label="" />
              </div>
            }
            value={(conversionRate * 100).toFixed(2)}
            valueColor="#1DC11D"
            backgroundColor={css.green}
            unit="%"
            icon={statsIcon3_1}
            image={statsIcon3}
          />
        </div>
      </div>
    );
  }
);
