import { useRef } from 'react';

export type DebounceOptions = {
  wait?: number;
};

/**
 * 用来处理防抖函数(立即执行)的 Hook。
 * @param fn
 * @param ms
 */
const useLeadingDebounceFn = <T extends (...args: any[]) => void>(
  fn: T,
  ms = 500
): T => {
  const fnRef = useRef<{
    version: any | undefined;
    fn: T;
    runner: (...args: any[]) => void;
  }>({
    version: undefined,
    fn,
    runner(...args: any[]) {
      const call = this.fn;
      const callNow = !this.version;
      if (this.version) {
        clearTimeout(this.version);
      }
      this.version = setTimeout(async () => {
        this.version = null;
      }, ms);
      if (callNow) {
        call(...args);
      }
    }
  });
  fnRef.current!.fn = fn;
  return fnRef.current!.runner.bind(fnRef.current!) as T;
};

export default useLeadingDebounceFn;
