// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_marginText_TGGDS {\n  margin: 0 8px;\n}\n.style_item_ljrYz {\n  display: flex;\n  padding: 8px 0 0 0;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/validDateTime/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;AAAF","sourcesContent":[".marginText {\n  margin: 0 8px;\n}\n\n.item {\n  display: flex;\n  padding: 8px 0 0 0;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginText": "style_marginText_TGGDS",
	"item": "style_item_ljrYz"
};
export default ___CSS_LOADER_EXPORT___;
