import React, { createElement, memo, ReactNode, Suspense } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Router,
  Switch
} from 'react-router';
import NoMatchedRoute from './NoMatched';
import { ErrorBoundary } from '@/entry/errorBoundery';

export type RedirectTo = string | { pathname: string; search?: string };

export const RouteNode = memo(
  ({ path, to, children, ...props }: RouteProps & { to?: RedirectTo }) =>
    to ? (
      <>
        <Route path={path} exact>
          <Redirect to={to} />
        </Route>
        <Route path={path} {...props}>
          {children}
        </Route>
      </>
    ) : (
      <Route path={path} {...props}>
        {children}
      </Route>
    )
);

export const SwitchWith404 = memo(({ children }: { children: ReactNode }) => (
  <Switch>
    {children}
    <NoMatchedRoute />
  </Switch>
));

export const withRouteNode = (
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>,
  to?: RedirectTo
) =>
  function RouteNodeComp({ path }: { path: string }): JSX.Element {
    return (
      <ErrorBoundary>
        <RouteNode to={to} path={path} component={component} />
      </ErrorBoundary>
    );
  };

export const withPages = (
  childNodes: Array<ReactNode>
): (({ to }: { to: RedirectTo }) => JSX.Element) =>
  function Pages({ to }: { to: RedirectTo }): JSX.Element {
    return (
      <SwitchWith404>
        <Route exact path="/">
          <Redirect to={to} />
        </Route>
        {childNodes}
      </SwitchWith404>
    );
  };

export const withRootRouter = (
  Comp: React.ComponentType<{ to?: RedirectTo }>,
  to?: RedirectTo
) =>
  function RootRouter(): JSX.Element {
    return createElement(Comp, { to });
  };

export const BlankSuspense = memo(({ children }: { children: ReactNode }) => (
  <Suspense fallback={<div />}>{children}</Suspense>
));

export const withAsyncRouteNode = (
  asyncRouteGetter: () => Promise<{ default: React.ComponentType<any> }>,
  to?: RedirectTo
) => {
  const Layout = React.lazy(asyncRouteGetter);
  const Comp = props => (
    <BlankSuspense>
      <Layout {...props} />
    </BlankSuspense>
  );
  return withRouteNode(Comp, to);
};
