import React, {
  FunctionComponent,
  memo,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import _uniqueId from 'lodash/uniqueId';
import _get from 'lodash/get';
import _isFunction from 'lodash/isFunction';
import { appKeyModelRef } from '@/modules/appkey';
import { IWangWangProps } from './type';
import { Icon } from '@/components/widgets/icon';
import { usePlatform } from '@/hooks/usePlatform';
import { DongDong } from '@/components/works/dongdong';

const webwwDependencies = {
  kissy: 'https://assets.alicdn.com/s/kissy/1.2.0/kissy-min.js',
  webww: 'https://market.m.taobao.com/app/aliww/bc-ww/webww'
};

const asyncTasks: { [id: string]: Promise<any> } = {};

export async function loadScript(url: string) {
  if (await asyncTasks[url]) {
    return asyncTasks[url];
  }

  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', url);
  script.setAttribute('charset', 'utf-8');
  const task = new Promise(resolve => {
    script.onload = resolve;
    script.onerror = resolve;
  });
  document.body.appendChild(script);
  asyncTasks[url] = task;
  return task;
}

async function light(id: string) {
  function initWW() {
    const Light = window['Light'];
    if (_isFunction(Light?.light)) {
      Light.light(id);
      return true;
    }
    return false;
  }

  if (!_isFunction(_get(window, 'KISSY.config'))) {
    await loadScript(webwwDependencies.kissy);
  }
  if (!initWW()) {
    await loadScript(webwwDependencies.webww);
    initWW();
  }
}

const WangWang: FunctionComponent<IWangWangProps> = memo(
  (props: IWangWangProps) => {
    const { nick, disabled, openUid, ...others } = props;
    const [id] = useState(_uniqueId('webww'));
    const {
      state: { appKey }
    } = useAgentReducer(appKeyModelRef.current);
    const ref = useRef(document.getElementById(id));

    const { isJD } = usePlatform();

    if (isJD) {
      return <DongDong nick={nick} buyerOpenUid={openUid} />;
    }

    // useEffect(() => {
    //   try {
    //     light(id);
    //   } catch (e) {}
    // }, []);

    const handleClick = () => {
      try {
        light(id);
      } catch (e) {}

      setTimeout(() => {
        ref.current = document.getElementById(id);
        const ele = ref.current?.children[0] as HTMLElement;
        ele.click();
      }, 200);
    };

    return (
      <>
        <span style={{ marginRight: '12px' }}>{nick}</span>
        <span onClick={handleClick}>
          <Icon
            type="wangwang"
            style={{ color: '#1e9eff', fontSize: '20px', cursor: 'pointer' }}
          />
        </span>
        <span
          id={id}
          style={{ display: 'none' }}
          className="J_WangWang"
          data-display="inline"
          data-nick={openUid}
          data-icon="small"
          data-encryptuid={openUid}
          data-bizdomain="taobao"
          data-openuid={openUid}
          data-appkey={appKey}
          data-biztype={1}
          data-sceneparam={{ toRole: 'buyer', bizRef: '' }}
        />
      </>
    );
  }
);
export default WangWang;
