import React, { memo, useEffect, useRef } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { Table, Column } from '@/components/widgets/table';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import { Pager } from '@/components/layouts/pager';
import { buildQuickDateRangeFromToday } from '@/utils/date';
import { useTitleRegister } from '@/hooks/titleRender';
import { buildColumnsWithSorter } from '@/services/sort';
import commonCss from '@/css/common.less';
import { DeliveryPushStatsModel } from './model';
import css from './style.less';
import WangWang from '@/components/works/wangwang';
import { LogisticsPushDetailRecordWebDto } from '@/requests/logisticsPushStats/type';
import { ExportButton } from '@/components/works/exportButton';
import {
  Card,
  DetailSearch,
  Statistic,
  StatisticTabs,
  StrategyStatsTable,
  ReplyNumTitle
} from '@/components/works/statisticComponents';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import {
  buildStatistics,
  numberToPercentage,
  formatPercentage
} from '../service';

const statisticItems = [
  { key: 'remindTradeNum', name: '派送提醒订单数' },
  { key: 'remindBuyerNum', name: '派送提醒人数' }
];

const timeStatsColumnsConfig = buildColumnsWithSorter([
  { title: '日期', dataIndex: 'businessDay', width: '200px' },
  { title: '派送提醒订单数', dataIndex: 'remindTradeNum' },
  { title: '派送提醒人数', dataIndex: 'remindBuyerNum' },
  { title: <ReplyNumTitle label="回复人数" />, dataIndex: 'replyBuyerNum' },
  {
    title: (
      <TooltipWithIcon label="回复率" tooltip="回复率=回复人数/派送提醒人数" />
    ),
    dataIndex: 'replyRate',
    render: value => formatPercentage(value)
  }
]);

const taskStatsColumnsConfig = buildColumnsWithSorter([
  { title: '任务名称', dataIndex: 'strategyName', width: '200px' },
  {
    title: '派送提醒订单数',
    dataIndex: 'remindTradeNum',
    defaultSortOrder: 'descend'
  },
  { title: '派送提醒人数', dataIndex: 'remindBuyerNum' },
  { title: <ReplyNumTitle label="回复人数" />, dataIndex: 'replyBuyerNum' },
  {
    title: (
      <TooltipWithIcon label="回复率" tooltip="回复率=回复人数/派送提醒人数" />
    ),
    dataIndex: 'replyRate',
    render: value => formatPercentage(value)
  }
]);

export default memo(() => {
  useTitleRegister(() => <>派送提醒数据</>);

  const deliveryPushStatsModelRef = useRef(new DeliveryPushStatsModel());

  const {
    state,
    initStats,
    initStrategyStatsList,
    initRecord,
    changeStatsPage,
    changeStatsTabKey,
    handleStrategyStatsListSearch,
    handleDetailSearch,
    handleDateRangeChange,
    handleRecordPageChange,
    handleExportStats,
    handleExportDetail
  } = useAgentReducer(deliveryPushStatsModelRef.current);
  const {
    dateRange,
    statsLoading,
    statsTableLoading,
    recordLoading,
    stats,
    statsTabKey,
    strategyStatsList,
    recordList,
    statsPage,
    recordPage,
    recordPageSize,
    recordTotal
  } = state;

  useEffect(() => {
    initStats();
    initStrategyStatsList();
    initRecord();
  }, []);

  const strategyStatsTableConfig =
    statsTabKey === 'time'
      ? {
          dataSource: stats.valueList,
          loading: statsLoading,
          columns: timeStatsColumnsConfig,
          sumMap: stats.sumMap,
          avgMap: numberToPercentage(stats.avgMap, ['replyRate'])
        }
      : {
          dataSource: strategyStatsList.valueList,
          loading: statsTableLoading,
          columns: taskStatsColumnsConfig,
          sumMap: strategyStatsList.sumMap,
          avgMap: numberToPercentage(strategyStatsList.avgMap, ['replyRate'])
        };

  const summaryColumns = strategyStatsTableConfig.columns
    .slice(1)
    .map(val => val.dataIndex);

  const statistics = buildStatistics(statisticItems, stats);

  return (
    <div className={commonCss.main}>
      <Card
        titleTop={
          <>
            <div className={commonCss.search}>
              <div className={commonCss.item}>
                <CustomRangePicker
                  options={buildQuickDateRangeFromToday()}
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  onSubmit={handleDateRangeChange}
                />
              </div>
            </div>
            <Statistic
              statisticItems={statisticItems}
              statistics={statistics}
              loading={statsLoading}
            />
          </>
        }
        title={
          <>
            派送提醒效果统计
            <ExportButton
              onExport={handleExportStats}
              emptyText="导出统计为空"
            />
          </>
        }
      >
        <StatisticTabs
          activeKey={statsTabKey}
          remindType="DELIVERY_PUSH"
          onChange={changeStatsTabKey}
          onSearch={handleStrategyStatsListSearch}
        />
        <StrategyStatsTable
          key={statsTabKey}
          currentPage={statsPage}
          summaryColumns={summaryColumns as string[]}
          onPageChange={changeStatsPage}
          {...strategyStatsTableConfig}
        />
      </Card>

      <Card
        className={css.content}
        titleTop={
          <DetailSearch
            remindType="DELIVERY_PUSH"
            onSearch={handleDetailSearch}
          />
        }
        firstHeading
        title={
          <>
            派送提醒明细
            <ExportButton onExport={handleExportDetail} />
          </>
        }
      >
        <Table
          rowKey={record => `${record.id}-${record.taobaoTid}`}
          bordered
          size="small"
          dataSource={recordList}
          pagination={false}
          showSorterTooltip={false}
          loading={recordLoading}
        >
          <Column title="派送提醒时间" dataIndex="remindTime" width="170px" />
          <Column title="任务名称" dataIndex="strategyName" width="200px" />
          <Column
            title="派送提醒客服"
            dataIndex="subAccountNick"
            width="170px"
          />
          <Column
            title="买家账号"
            dataIndex="buyerNick"
            width="170px"
            render={(
              value,
              record: LogisticsPushDetailRecordWebDto & { rowSpan: number }
            ) => <WangWang openUid={record.buyerOpenUid} nick={value} />}
          />
          <Column title="订单号" dataIndex="taobaoTid" width="170px" />
          <Column
            title="买家是否回复"
            dataIndex="buyerReplied"
            width="170px"
            render={v => (v ? '是' : '否')}
          />
        </Table>
        <Pager
          page={recordPage}
          pageSize={recordPageSize}
          total={recordTotal}
          onChange={handleRecordPageChange}
        />
      </Card>
    </div>
  );
});
