// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_unit_t7-5T {\n  margin-bottom: 12px;\n}\n.style_item_eDgD5 {\n  display: flex;\n  margin: 8px 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/subAccountPermissions/batchDialog/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,aAAA;EACA,eAAA;AAAF","sourcesContent":[".unit {\n  margin-bottom: 12px;\n}\n\n.item {\n  display: flex;\n  margin: 8px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit": "style_unit_t7-5T",
	"item": "style_item_eDgD5"
};
export default ___CSS_LOADER_EXPORT___;
