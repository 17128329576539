import React, { memo, ReactNode } from 'react';
import { Icon } from '@/components/widgets/icon';
import css from './style.less';

export const Alert = memo(
  ({
    visible = true,
    icon = 'Warning-Circle-Fill',
    className = '',
    children,
    style
  }: {
    visible?: boolean;
    icon?: string;
    className?: string;
    children: ReactNode;
    style?: React.CSSProperties;
  }) =>
    visible ? (
      <div className={`${css.main} ${className}`} style={style}>
        <Icon type={icon} className={css.icon} />
        <div className={css.text}>{children}</div>
      </div>
    ) : null
);
