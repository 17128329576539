import {
  middleWare,
  MiddleWarePresets,
  MiddleWares,
  OriginAgent,
  sharing
} from 'agent-reducer';
import {
  AdvertisementState,
  CampaignParams,
  ClientType,
  PositionCodes,
  CampaignPauseParams
} from '@/components/works/advertisement/type';
import { advertisementRequestService } from '@/components/works/advertisement/service';
import { CampaignLogClickParams } from '@/requests/clientApi/type';

export class Advertisement implements OriginAgent<AdvertisementState> {
  state: AdvertisementState = { campaignsDatas: [] };

  private readonly articleCode = 'hykfzs';

  private readonly clientType = 'PC' as ClientType;
 
  private readonly positionCodes = ['hykfzs_pop_global_ad', 'hykfzs_top_banner_ad'];

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async getCampaign(
    sellerNick: string,
    subNick: string
  ): Promise<AdvertisementState> {
    const params: CampaignParams = {
      clientType: this.clientType,
      articleCode: this.articleCode,
      sellerNick,
      subNick,
      positionCodes: this.positionCodes.join(',') as PositionCodes
    };
    const campaignsDatas = await advertisementRequestService.getCampaign(
      params
    );
    return { ...this.state, campaignsDatas };
  }

  @middleWare(MiddleWares.takeNone())
  campaignPause(params: CampaignPauseParams): Promise<void> {
    return advertisementRequestService.campaignPause(params);
  }

  @middleWare(MiddleWares.takeNone())
  addLogClick(params: CampaignLogClickParams): Promise<void> {
    return advertisementRequestService.addLogClick(params);
  }
}

export const advertisementRef = sharing(() => Advertisement);
