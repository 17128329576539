import { RemindType } from '@/types/remindType';

type ConditionsType =
  | 'taskName'
  | 'sendNum'
  | 'account'
  | 'buyerReplied'
  | 'order'
  | 'refundClosed';

export const conditionsMap: Partial<Record<RemindType, Array<ConditionsType>>> =
  {
    REMIND_BUY: ['sendNum', 'taskName', 'account', 'order', 'buyerReplied'],
    REMIND_PAY: ['sendNum', 'taskName', 'account', 'order', 'buyerReplied'],
    REMIND_FINAL_PAY: [
      'sendNum',
      'taskName',
      'account',
      'order',
      'buyerReplied'
    ],
    PAYMENT_PUSH: ['sendNum', 'taskName', 'account', 'order', 'buyerReplied'],
    FRONT_PAID_PAYMENT_PUSH: ['sendNum', 'taskName', 'account', 'order', 'buyerReplied'],
    FINAL_PAID_PAYMENT_PUSH: ['sendNum', 'taskName', 'account', 'order', 'buyerReplied'],
    
    CONSIGN_PUSH: ['taskName', 'account', 'order', 'buyerReplied'],
    LOGISTIC_STAGNATE_PUSH: ['taskName', 'account', 'order', 'buyerReplied'],
    DELIVERY_PUSH: ['taskName', 'account', 'order', 'buyerReplied'],
    SIGN_PUSH: ['sendNum', 'taskName', 'account', 'order', 'buyerReplied'],
    RECEIVED_PUSH: ['sendNum', 'taskName', 'account', 'order', 'buyerReplied'],
    APPLY_REFUND_PUSH: ['taskName', 'account', 'refundClosed', 'buyerReplied'],
    GUIDE_RETURN_GOODS: ['taskName', 'account', 'buyerReplied'],
    URGE_RETURN_GOODS_PUSH: ['taskName', 'account', 'buyerReplied'],
    REFUND_SUCCESS_PUSH: ['taskName', 'account', 'buyerReplied'],
    APPLY_EXCHANGE_PUSH: ['taskName', 'account', 'buyerReplied'],
    TRADE_NOPAID_CLOSED_PUSH: ['taskName', 'account', 'order', 'buyerReplied']
  };
