import { Http, http } from '@/libs/http';
import {
  DailySummaryParams,
  MarketingFlowUsedRateWebDto,
  MarketingStatsDailySummaryWebDto,
  MarketingStatsSummaryWebDto
} from './type';

export class MemberMarketingStatsRequest {
  private http: Http = http('/api/shop/member-marketing-stats');

  dailySummary(
    params: DailySummaryParams
  ): Promise<MarketingStatsDailySummaryWebDto> {
    return this.http.path('/daily-summary-v2').setRequestBody(params).post();
  }

  flowUsedRate(): Promise<MarketingFlowUsedRateWebDto> {
    return this.http.path('/flow-used-rate').get();
  }

  summary(): Promise<MarketingStatsSummaryWebDto> {
    return this.http.path('/summary').get();
  }
}

export const memberMarketingStatsRequest = new MemberMarketingStatsRequest();
