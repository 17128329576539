// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_item_Iqibj {\n  display: flex;\n  line-height: 32px;\n  margin-bottom: 12px;\n}\n.style_item_Iqibj .style_labelText_Rj4fi {\n  width: 70px;\n  text-align: right;\n}\n.style_item_Iqibj .style_title_REgw\\+ {\n  background: #f7f8fa;\n  color: #999999;\n  line-height: 24px;\n  padding: 6px 12px;\n  margin-bottom: 12px;\n}\n.style_item_Iqibj .style_roundsLimitTip_mNGJe {\n  color: #999;\n  vertical-align: middle;\n  line-height: 19px;\n}\n.style_customTooltip_qH\\+71 {\n  max-width: 400px !important;\n}\n.style_selectWidth_dJFKN {\n  width: 72px;\n  margin: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindMainStrategyDialog/components/remindLimitComp/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;AACF;AAJA;EAMI,WAAA;EACA,iBAAA;AACJ;AARA;EAWI,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAfA;EAmBI,WAAA;EACA,sBAAA;EACA,iBAAA;AADJ;AAKA;EACE,2BAAA;AAHF;AAMA;EACE,WAAA;EACA,aAAA;AAJF","sourcesContent":[".item {\n  display: flex;\n  line-height: 32px;\n  margin-bottom: 12px;\n\n  .labelText {\n    width: 70px;\n    text-align: right;\n  }\n\n  .title {\n    background: #f7f8fa;\n    color: #999999;\n    line-height: 24px;\n    padding: 6px 12px;\n    margin-bottom: 12px;\n  }\n\n  .roundsLimitTip {\n    color: #999;\n    vertical-align: middle;\n    line-height: 19px;\n  }\n}\n\n.customTooltip {\n  max-width: 400px !important;\n}\n\n.selectWidth {\n  width: 72px;\n  margin: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "style_item_Iqibj",
	"labelText": "style_labelText_Rj4fi",
	"title": "style_title_REgw+",
	"roundsLimitTip": "style_roundsLimitTip_mNGJe",
	"customTooltip": "style_customTooltip_qH+71",
	"selectWidth": "style_selectWidth_dJFKN"
};
export default ___CSS_LOADER_EXPORT___;
