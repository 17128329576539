// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_item_Bl-HY {\n  display: flex;\n  flex-direction: column;\n  padding-top: 8px;\n}\n.style_multipleSelect_Ws-iF {\n  width: 408px;\n  margin: 7px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/accountRadioSelector/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AAEA;EACE,YAAA;EACA,aAAA;AAAF","sourcesContent":[".item {\n  display: flex;\n  flex-direction: column;\n  padding-top: 8px;\n}\n\n.multipleSelect {\n  width: 408px;\n  margin: 7px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "style_item_Bl-HY",
	"multipleSelect": "style_multipleSelect_Ws-iF"
};
export default ___CSS_LOADER_EXPORT___;
