import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog } from '@/components/widgets/modal';
import { Checkbox, CheckboxGroup } from '@/components/widgets/checkbox';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import css from './style.less';
import { Button } from '@/components/widgets/button';
import { message } from '@/components/widgets/message';
import { CaretDownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { AddressDialogProps, Node } from './type';
import { warnError } from '@/components/infos';
import { AddressDialogModel } from './model';
import { useAgentReducer, useAgentSelector, useModel, useModelProvider } from 'use-agent-reducer';
import { areaMap, getAreaProvincesMap } from './const';
import { addressRef } from '@/modules/address';
import { RadioGroup, Radio } from '@/components/widgets/radio';

const ParentNode = memo(
  ({
    label,
    cities,
    filterCheck,
    className
  }: {
    label: string;
    cities: Node[];
    filterCheck: (list: Node[]) => number[];
    className?: string;
  }) => {
    const addressDialogModel = useModel(AddressDialogModel);
    const {
      state: { checkedList },
      onChangeSelect
    } = useAgentReducer(addressDialogModel);

    const indeterminate = useCallback(
      (list: Node[]): boolean =>
        filterCheck(list).length > 0 && filterCheck(list).length < list.length,
      [checkedList]
    );

    const isCheckedAll = useCallback(
      (list: Node[]): boolean => filterCheck(list).length >= list.length,
      [checkedList]
    );

    const onCheckedAll = useCallback(
      (list: Node[]) => {
        if (isCheckedAll(list)) {
          onChangeSelect([], list);
          return;
        }
        onChangeSelect(
          list.map(l => l.areaId),
          list
        );
      },
      [checkedList]
    );

    return (
      <Checkbox
        indeterminate={indeterminate(cities)}
        onChange={() => {
          onCheckedAll(cities);
        }}
        checked={isCheckedAll(cities)}
        className={className}
      >
        {label}
      </Checkbox>
    );
  }
);

export const AddressDialog = memo(
  (props: DialogDeployProps<AddressDialogProps>) => {
    const { closeDialog, params } = props;
    const { cityList = [], require = false } = params;
    const [receiverAddressType, setReceiverAddressType] = useState((cityList.length || require) ? 'POINT' : 'ALL')
    const addressDialogRef = useRef(new AddressDialogModel());
    const SharingProvider = useModelProvider(addressDialogRef.current);
    const {
      state: { nodeTree, nodeMap, checkedList, expendProvince, allCities },
      init,
      onChangeSelect,
      selectAll,
      setExpendProvince
    } = useAgentReducer(addressDialogRef.current);
    const area = useAgentSelector(addressRef.current ,s => s.area);
    const allProvinces = useMemo(() => nodeTree, [nodeTree]);

    const areaProvincesMap  = getAreaProvincesMap()

    useEffect(() => {
      (async () => {
        try {
          await init(cityList, area);
        } catch (e) {
          warnError(e);
        }
      })();
    }, []);

    const handleOk = useCallback(() => {
      if ((require || receiverAddressType === 'POINT') && !checkedList.length) {
        message.warning('请选择收货地址')
        return 
      }

      closeDialog(receiverAddressType === 'ALL' ? [] :checkedList);
    }, [checkedList, nodeMap, receiverAddressType]);

    const filterCheck = useCallback(
      (list: Node[]): number[] =>
        list
          .filter(({ areaId }) => checkedList.includes(areaId))
          .map(c => c.areaId),
      [checkedList]
    );

    const footer = useMemo(
      () => (
        <div className={receiverAddressType === 'POINT' ? css.footer : ''}>
          {receiverAddressType === 'POINT' ? <Checkbox
            indeterminate={
              checkedList.length > 0 && checkedList.length < allCities.length
            }
            checked={checkedList.length === allCities.length}
            onChange={selectAll}
          >
            全选
          </Checkbox> : null}
          <div>
            <Button type="primary" onClick={handleOk}>
              确认
            </Button>
            <Button
              onClick={() => {
                closeDialog();
              }}
            >
              取消
            </Button>
          </div>
        </div>
      ),
      [checkedList, allCities, receiverAddressType]
    );

    const areaProvinces = useCallback(
      (areaId: string) =>
        allProvinces.filter(ap =>
          areaProvincesMap[areaId].includes(ap.areaName)
        ),
      [allProvinces]
    );

    const areaCities = useCallback(
      (areaId: string) =>
        allCities.filter(ac =>
          areaProvincesMap[areaId].includes(
            nodeMap.get(ac.parentAreaId)!.areaName
          )
        ),
      [allCities, nodeMap]
    );


    const provinceCities = useCallback(
      (provinceId: number) =>
        allProvinces.find(ap => ap.areaId === provinceId)!.children!,
      [allProvinces]
    );

    return (
      <Dialog
        title="选择买家收货地址"
        footer={footer}
        width={850}
        onCancel={() => {
          closeDialog();
        }}
      >
        {require ? null : (
          <div className={css.radioWrap}>
            买家收货地址：
            <RadioGroup
              value={receiverAddressType}
              onChange={e => setReceiverAddressType(e.target.value)}
            >
              <Radio value="ALL">不限</Radio>
              <Radio value="POINT">指定收货地址</Radio>
            </RadioGroup>
          </div>
        )}
        
        {receiverAddressType === 'ALL' ? (
          <div className={css.noLimitedWrap}>
            <div>
              已选择“不限”
              <br />
              关联全部地址
            </div>
          </div>
        ): (
          <SharingProvider>
            <div className={css.main}>
             {[...areaMap.keys()].map(area => (
               <div className={css.item} key={area}>
                 <div className={css.area}>
                   <ParentNode
                     label={areaMap.get(area)!}
                     cities={areaCities(area)}
                     filterCheck={filterCheck}
                     className={css.areaLabel}
                   />
                 </div>
                 <div className={css.areaCtn}>
                   <div className={css.province}>
                     {areaProvinces(area).map(p => (
                       <div
                         className={classNames(
                           css.normalProvince,
                           p.areaId === expendProvince ? css.expendProvince : ''
                         )}
                         key={p.areaId}
                       >
                         <ParentNode
                           label={p.areaName}
                           cities={provinceCities(p.areaId)}
                           filterCheck={filterCheck}
                         />
                         <CaretDownOutlined
                           className={classNames(
                             css.normalArrow,
                             p.areaId === expendProvince ? css.expendArrow : ''
                           )}
                           onClick={() =>
                             setExpendProvince(
                               p.areaId === expendProvince ? -1 : p.areaId
                             )
                           }
                         />
                       </div>
                     ))}
                   </div>
                 </div>
                 {areaProvinces(area).some(p => p.areaId === expendProvince) ? (
                   <div className={css.cities}>
                     <CheckboxGroup
                       style={{ width: '100%' }}
                       value={filterCheck(provinceCities(expendProvince))}
                       onChange={list => {
                         onChangeSelect(
                           list as number[],
                           provinceCities(expendProvince)
                         );
                       }}
                     >
                       {provinceCities(expendProvince).map(c => (
                         <div className={css.cityItem} key={c.areaId}>
                           <Checkbox value={c.areaId}>{c.areaName}</Checkbox>
                         </div>
                       ))}
                     </CheckboxGroup>
                   </div>
                 ) : null}
               </div>
             ))}
           </div>
          </SharingProvider>
        )}
      </Dialog>
    );
  }
);
