import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useColumnDropdown() {
  const [inMenu, setInMenu] = useState(false);
  const [inTrigger, setInTrigger] = useState(false);

  const visible = inMenu || inTrigger;

  const close = () => {
    setInMenu(false);
    setInTrigger(false);
  };

  const menuProps = {
    onMouseEnter: () => setInMenu(true),
    onMouseLeave: () => {
      setTimeout(() => setInMenu(false), 200);
    }
  };

  const triggerProps = {
    onMouseEnter: () => setInTrigger(true),
    onMouseLeave: () => {
      setTimeout(() => setInTrigger(false), 200);
    }
  };

  return {
    visible,
    menuProps,
    triggerProps,
    close
  };
}
