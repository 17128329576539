import { http } from '@/libs/http';
import type { PageDetail } from '@/types/pageDetail';
import type {
  LogisticsPushDetailRecordWebDto,
  LogisticsPushRecordParams,
  LogisticsPushStatsParams,
  LogisticsPushStatsWebDto,
  LogisticsPushStrategyStatsWebDto
} from './type';
import { RemindStrategyStatsWebQuery } from '../strategyStats/type';

export class LogisticsPushStatsRequest {
  private http = http('/api/shop/logistics-push');

  // 查询明细接口
  recordList(
    params: LogisticsPushRecordParams
  ): Promise<PageDetail<LogisticsPushDetailRecordWebDto>> {
    return this.http.path('record-list').setRequestParams(params).get();
  }

  // 查询统计数据接口
  statsList(
    params: LogisticsPushStatsParams
  ): Promise<LogisticsPushStatsWebDto> {
    return this.http.path('stats-list-v2').setRequestBody(params).post();
  }

  // 按任务查询统计数据接口
  strategyStatsList(
    params: RemindStrategyStatsWebQuery
  ): Promise<LogisticsPushStrategyStatsWebDto> {
    return this.http
      .path('strategy-stats-list-v2')
      .setRequestBody(params)
      .post();
  }
}

export default new LogisticsPushStatsRequest();
