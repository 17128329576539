import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  formatDayFromNow,
  checkRange,
  formatNow,
  buildQuickDateRangeFromToday
} from '@/utils/date';
import { FormLike } from '@/components/widgets/formLike';
import ConditionUnit from '@/components/layouts/conditionUnit';
import commonCss from '@/css/common.less';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import { warnError } from '@/components/infos';
import moment from 'moment';

type Range = [string, string];

export type StatisticsSearchState = {
  range: Range;
};

type StatisticSearchProps = {
  onChange: (value: { range: Range }) => Promise<void>;
  defaultRange?: Range;
  remindType?: string;
};

const range: Range = [formatDayFromNow(-6), formatDayFromNow(0)];

const StatisticSearch = memo((props: StatisticSearchProps) => {
  const { onChange, remindType, defaultRange = range } = props;
  const [config, setConfig] = useState({ range: defaultRange });

  useEffect(() => {
    setConfig({ range: defaultRange });
  }, [remindType]);

  const handleSubmit = useCallback(
    async (value?: { range: Range }) => {
      const newConfig = { ...config, ...value };

      const {
        range: [start, end]
      } = newConfig;

      const newStart = moment(start).isBefore(moment(end)) ? start : end;

      if (!checkRange([newStart, formatNow()], 180)) {
        warnError('仅可查看最近180天的发送明细');
        return;
      }

      await onChange(newConfig);
    },
    [config]
  );

  const handleChange = async value => {
    setConfig(value);
    handleSubmit(value);
  };

  return (
    <FormLike value={config} onChange={handleChange} onSubmit={handleSubmit}>
      <div className={commonCss.search}>
        <ConditionUnit name="range" className={commonCss.item} label="">
          <CustomRangePicker options={buildQuickDateRangeFromToday()} />
        </ConditionUnit>
      </div>
    </FormLike>
  );
});

export default StatisticSearch;
