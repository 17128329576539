import React, { memo, ReactNode } from 'react';
import { Drawer as SourceDrawer, DrawerProps } from 'antd';

export const Drawer = memo(
  (
    props: DrawerProps & {
      children?: ReactNode;
      contentWrapperStyle?: React.CSSProperties;
    }
  ) => {
    const p = props as DrawerProps;
    return <SourceDrawer {...p} />;
  }
);
