import {
  AthenaCampaignDtos,
  CampaignFetchParams,
  CampaignLogClickParams
} from '@/requests/clientApi/type';
import { CampaignPauseParams } from '@/components/works/advertisement/type';
import axios from 'axios';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://athena.topchitu.com'
    : undefined;

export class ClientApiRequest {
  // 获取广告
  async getCampaign(params: CampaignFetchParams): Promise<AthenaCampaignDtos> {
    const result = await axios({
      url: '/client-api/campaign/get-campaigns',
      baseURL,
      method: 'get',
      params
    });
    return result.data;
  }

  // 不感兴趣
  async campaignPause({
    articleCode,
    campaignId,
    subNick
  }: CampaignPauseParams): Promise<void> {
    const result = await axios({
      url: `/client-api/campaign/campaign-pause/${articleCode}/${campaignId}/${subNick}`,
      baseURL,
      method: 'get'
    });
    return result.data;
  }

  // 记录点击
  async addLogClick(params: CampaignLogClickParams): Promise<void> {
    const result = await axios({
      url: `/client-api/campaign/log-clic`,
      baseURL,
      method: 'post',
      params
    });
    return result.data;
  }
}

export const clientApiRequest = new ClientApiRequest();
