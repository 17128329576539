import { isNumber } from '@/hooks/utils';
export const formatPercentage = (num?: number) => {
  return isNumber(num) ? `${(num * 100).toFixed(2)}%` : '-';
};

export const numberToPercentage = (target, fields: string[] = []) => {
  const newTarget = { ...target };

  fields.forEach(field => {
    newTarget[field] = formatPercentage(newTarget[field]);
  });

  return newTarget;
};

export const buildStatistics = (statisticItems, stats) => {
  const statistics = statisticItems.map(({ key }) => {
    if (
      ['conversionRate', 'rateSuccessRate', 'refundClosedRatio'].includes(key)
    ) {
      return [key, { data: stats.avgMap[key] ?? 0 }];
    }

    return [key, { data: stats.sumMap[key]?.data ?? stats.sumMap[key] ?? 0 }];
  });

  return Object.fromEntries(statistics);
};
