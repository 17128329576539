import React, { memo, useRef, useState } from 'react';
import { Input } from '@/components/widgets/input';
import { Button } from '@/components/widgets/button';
import { Form } from '@/components/widgets/form';
import css from './style.less';
import { push } from '@/libs/history';
import routeConfig from '@/routeConfig';
import { FormInstance } from 'antd/lib/form';
import staffAccountRequest from '@/requests/staffAccount';
import Countdown from 'antd/lib/statistic/Countdown';
import { warnError } from '@/components/infos';
import { message } from '@/components/widgets/message';

import { verifyFormValue } from './service';
import {
  SendSmsCodeParams,
  SmsModifyPasswordParams
} from '@/requests/staffAccount/type';
import { Space } from '@/components/widgets/space';

const loginType = 'BROWSER';
const type = 'FORGOT';

export default memo(() => {
  document.title = '重置密码 - 智能跟单助手';

  const [disabled, setDisabled] = useState(false);
  const formRef = React.createRef<FormInstance<SmsModifyPasswordParams>>();

  const goToLogin = () => {
    push(routeConfig.login.path);
  };

  const restPassword = async () => {
    const values = formRef.current?.getFieldsValue();

    const err = verifyFormValue(values);

    if (err) {
      message.warning(err);
      return;
    }

    try {
      await staffAccountRequest.smsModifyPassword({
        ...values,
        type,
        loginType
      } as SmsModifyPasswordParams);
      message.success('密码重置成功');
      goToLogin();
    } catch (error) {
      warnError(error);
    }
  };

  const sendSmsCode = async () => {
    const values = formRef.current?.getFieldsValue();

    const { accountName, phoneNumber } = values || {};

    const err = verifyFormValue(values);

    if (err) {
      message.warning(err);
      return;
    }

    try {
      await staffAccountRequest.sendSmsCode({
        accountName,
        phoneNumber,
        type,
        loginType
      } as SendSmsCodeParams);
      setDisabled(true);
      message.success('验证码发送成功');
    } catch (error) {
      warnError(error);
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <h1 style={{ textAlign: 'center' }}>重置密码</h1>
        <Form ref={formRef} wrapperCol={{ span: 20 }}>
          <Form.Item className={css.item} label="账号" name="accountName">
            <Input size="large" placeholder="请输入账号" />
          </Form.Item>
          <Form.Item className={css.item} label="手机号" name="phoneNumber">
            <Input size="large" placeholder="请输入手机号" />
          </Form.Item>
          <Form.Item
            className={css.item}
            label="验证码"
            wrapperCol={{ span: 20 }}
          >
            <Space style={{ justifyContent: 'space-between', width: '100%' }}>
              <Form.Item name="smsCode" noStyle>
                <Input size="large" placeholder="请输入短信验证码" />
              </Form.Item>
              <Button
                size="large"
                style={{ margin: '0 0 0 8px', display: 'flex' }}
                onClick={sendSmsCode}
                disabled={disabled}
              >
                {disabled ? (
                  <Countdown
                    valueStyle={{ fontSize: '16px', color: 'rgba(0,0,0,.25)' }}
                    value={Date.now() + 1000 * 60}
                    format="s秒后重"
                    onFinish={() => setDisabled(false)}
                  />
                ) : null}
                发送验证码
              </Button>
            </Space>
          </Form.Item>
          <Form.Item className={css.item} label="新密码" name="password">
            <Input
              size="large"
              placeholder="请输入新密码"
              type="password"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            className={css.item}
            noStyle
            label=""
            wrapperCol={{ span: 24 }}
          >
            <Button
              size="large"
              type="primary"
              className={css.resetPasswordBtn}
              onClick={restPassword}
            >
              重置密码
            </Button>
            <Button
              style={{ margin: 0, padding: 0 }}
              type="link"
              onClick={goToLogin}
            >
              前往登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
});
