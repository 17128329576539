// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_customTooltip_JtDYe {\n  max-width: 400px !important;\n}\n.style_icon_huRuY {\n  cursor: pointer;\n  margin: 0 2px 0 2px;\n  font-size: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/tooltipWithIcon/style.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;AAEA;EACE,eAAA;EACA,mBAAA;EACA,eAAA;AAAF","sourcesContent":[".customTooltip {\n  max-width: 400px !important;\n}\n\n.icon {\n  cursor: pointer;\n  margin: 0 2px 0 2px;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customTooltip": "style_customTooltip_JtDYe",
	"icon": "style_icon_huRuY"
};
export default ___CSS_LOADER_EXPORT___;
