import React, { memo, useEffect, useState } from 'react';
import { Checkbox } from '@/components/widgets/checkbox';
import { Icon } from '@/components/widgets/icon';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import { TradeStatusEditor } from '@/components/works/tradeStatusEditor';
import { Params, Result } from '@/components/works/tradeStatusEditor/type';
import userConfigRequest from '@/requests/userConfig';
import { ShopAfterSaleConfigWebDto } from '@/types/afterSale';
import { configsToRules, rulesToConfigs } from './util';
import { AfterSaleRuleProps } from './type';
import css from './style.less';

export const defaultAfterSaleConfig: ShopAfterSaleConfigWebDto = {
  afterSaleDay: 30,
  statusConfigs: [
    { tradeStatus: 'WAIT_SEND' },
    { tradeStatus: 'SELLER_CONSIGNED_PART' },
    { tradeStatus: 'SENT' },
    { tradeStatus: 'SUCCESS' }
  ]
};

export default memo((props: AfterSaleRuleProps) => {
  const { checkable = false, checked, onCheckedChange } = props;
  const [saleConfig, setSaleConfig] = useState<ShopAfterSaleConfigWebDto>(
    defaultAfterSaleConfig
  );

  const [openDialog] = useDialogMethods<Result | undefined>();

  useEffect(() => {
    async function init() {
      const afterSaleConfig = await userConfigRequest.afterSaleConfig();
      setSaleConfig(afterSaleConfig);
    }
    init();
  }, []);

  const handleOk = async (res: Result) => {
    await userConfigRequest.modifyAfterSaleConfig({
      afterSaleDay: res.tradeDay,
      statusConfigs: rulesToConfigs(res.tradeStatusRules)
    });
    const newConfig = await userConfigRequest.afterSaleConfig();
    setSaleConfig(newConfig);
  };

  const openTradeStatusEditor = () => {
    const { afterSaleDay, statusConfigs } = saleConfig;
    const params: Params = {
      tradeDay: afterSaleDay,
      tradeStatusRules: configsToRules(statusConfigs),
      timeoutTradeStatus: [],
      omitTradeStatus: ['WAIT_ORDER'],
      title: '设置售后期',
      showCheckAll: false,
      tradeDaySelectText: '，定义为售后期买家',
      onOK: handleOk
    };
    openDialog(TradeStatusEditor, params);
  };

  return (
    <div style={{ marginBottom: '4px' }}>
      {checkable ? (
        <Checkbox
          checked={checked}
          className={css.checkbox}
          onChange={e => onCheckedChange?.(e.target.checked)}
        />
      ) : (
        <Checkbox checked className={css.checkbox} disabled />
      )}
      <span>售后期买家</span>
      <Icon
        type="bianji"
        onClick={openTradeStatusEditor}
        className={css.editIcon}
      />
    </div>
  );
});
