import React, { memo, useEffect, useState } from 'react';
import { Image } from 'antd';
import { imageBlobToBase64 } from '@/utils/image';
import { warnError } from '@/components/infos';
import { invokeApi } from '@/libs/api';

export const ImageLogContent = memo(
  ({ imageId, className }: { imageId: number; className?: string }) => {
    const [imageBase64, setImageBase64] = useState('');

    useEffect(() => {
      (async () => {
        try {
          const blob = await invokeApi('downloadImg', imageId);

          if (blob) {
            const base64 = await imageBlobToBase64(blob);
            setImageBase64(base64);
          }
        } catch (e) {
          warnError(e);
        }
      })();
    }, [imageId]);

    return (
      <Image src={imageBase64} width={50} height={50} className={className} />
    );
  }
);
