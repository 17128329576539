import React, { memo } from 'react';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { SubAccountFilterConfig } from '@/requests/transferWord/type';
import CusTeamSelector from '@/components/works/cusTeamSelector';
import { AccountSelect } from '@/components/works/accountSelector';
import css from './style.less';
import classNames from 'classnames';

export type AccountRadioSelectorPorps = {
  status?: '' | 'success' | 'warning' | 'error' | 'validating';
  value?: SubAccountFilterConfig;
  onChange?: (value: SubAccountFilterConfig) => void;
  cancelFormError?: () => void;
};

export const AccountRadioSelector = memo((props: AccountRadioSelectorPorps) => {
  const {
    status = '',
    value = {
      type: 'ALL',
      subAccountIds: [],
      wangwangGroupIds: []
    },
    onChange,
    cancelFormError
  } = props;

  const { type, subAccountIds, wangwangGroupIds } = value;

  const handleTypeChange = e => {
    cancelFormError?.();
    onChange?.({
      ...value,
      type: e.target.value
    });
  };

  const accountValueChange = v => {
    cancelFormError?.();
    onChange?.({
      ...value,
      subAccountIds: v
    });
  };

  const cusTeamValueChange = v => {
    cancelFormError?.();
    onChange?.({
      ...value,
      wangwangGroupIds: v
    });
  };

  return (
    <div>
      <RadioGroup value={type} onChange={handleTypeChange}>
        <Radio value="ALL">全部客服</Radio>
        <Radio value="POINTED">指定客服</Radio>
        <Radio value="WANGWANG_GROUP">指定客服组</Radio>
      </RadioGroup>
      {type === 'POINTED' ? (
        <div className={css.item}>
          <AccountSelect
            status={status}
            className={classNames(css.multipleSelect)}
            value={subAccountIds}
            onChange={accountValueChange}
          />
        </div>
      ) : null}
      {type === 'WANGWANG_GROUP' ? (
        <div className={css.item}>
          <CusTeamSelector
            status={status}
            className={classNames(css.multipleSelect)}
            value={wangwangGroupIds}
            onChange={cusTeamValueChange}
          />
        </div>
      ) : null}
    </div>
  );
});

export const validateSubAccountFilterConfig = (
  v: SubAccountFilterConfig
): string => {
  const { type, subAccountIds, wangwangGroupIds } = v;
  if (type === 'POINTED' && !subAccountIds?.length) {
    return '请选择接待客服';
  }

  if (type === 'WANGWANG_GROUP' && !wangwangGroupIds?.length) {
    return '请选择接待客服组';
  }

  return '';
};
