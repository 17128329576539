import { ClientApiRequest } from '@/requests/clientApi';
import {
  AthenaCampaignDtos,
  CampaignLogClickParams
} from '@/requests/clientApi/type';
import { CampaignParams, CampaignPauseParams } from '@/components/works/advertisement/type';

export class AdvertisementRequestService {
  private advertisementRequest = new ClientApiRequest();

  getCampaign(params: CampaignParams): Promise<AthenaCampaignDtos> {
    return this.advertisementRequest.getCampaign(params);
  }

  campaignPause(params: CampaignPauseParams): Promise<void> {
    return this.advertisementRequest.campaignPause(params);
  }

  addLogClick(params: CampaignLogClickParams): Promise<void> {
    return this.advertisementRequest.addLogClick(params);
  }
}

export const advertisementRequestService = new AdvertisementRequestService();
