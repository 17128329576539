import React, { memo } from 'react';
import { Select as SourceSelect, SelectProps as SourceSelectProps } from 'antd';
import css from './style.less';

export const Select = memo(
  (
    props: SourceSelectProps<any> & {
      showCount?: boolean;
      maxLength?: number;
    }
  ) => {
    const { value, mode, maxLength, showCount, ...rest } = props;

    return (
      <span className={css.select}>
        <SourceSelect
          value={value}
          mode={mode}
          {...rest}
          getPopupContainer={triggerNode => triggerNode.parentNode}
        />
        {showCount && mode === 'tags' && (
          <span className={css.count}>
            {(value && value.length) || 0}/{maxLength}
          </span>
        )}
      </span>
    );
  }
);

export const { Option } = SourceSelect;

export interface SelectProps extends SourceSelectProps {
  showCount?: boolean;
  maxLength?: number;
}
