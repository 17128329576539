// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_skuToolTip_bPSB1 {\n  padding: 24px;\n  overflow-y: auto;\n  max-width: 480px;\n  display: flex;\n  flex-wrap: wrap;\n  background-color: #fff;\n  gap: 12px 20px;\n}\n.style_skuToolTip_bPSB1 .style_sku_cxURH {\n  width: 200px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  padding: 12px;\n}\n.style_skuToolTip_bPSB1 .style_sku_cxURH .style_imgBox_jDPvQ {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/goodsPop/skuTooltipInfo/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,sBAAA;EACA,cAAA;AACF;AARA;EAUI,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAdA;EAgBM,aAAA;EACA,uBAAA;EACA,mBAAA;AACN","sourcesContent":[".skuToolTip {\n  padding: 24px;\n  overflow-y: auto;\n  max-width: 480px;\n  display: flex;\n  flex-wrap: wrap;\n  background-color: #fff;\n  gap: 12px 20px;\n\n  .sku {\n    width: 200px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding: 12px;\n\n    .imgBox {\n      display: flex;\n      justify-content: center;\n      margin-bottom: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skuToolTip": "style_skuToolTip_bPSB1",
	"sku": "style_sku_cxURH",
	"imgBox": "style_imgBox_jDPvQ"
};
export default ___CSS_LOADER_EXPORT___;
