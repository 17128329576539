// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_label_Pgkky {\n  height: 20px;\n  color: #333;\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layouts/conditionUnit/style.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".label {\n  height: 20px;\n  color: #333;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "style_label_Pgkky"
};
export default ___CSS_LOADER_EXPORT___;
