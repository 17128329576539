import React, { PropsWithChildren, ReactNode, memo } from 'react';
import classNames from 'classnames';
import { FlagFilled } from '@ant-design/icons';
import { Popover } from '@/components/widgets/popover';
import { SubStrategySellerTradeMemoConfig } from '@/requests/remindStrategy/types';
import useRequest from '@/hooks/useRequest';
import userConfig from '@/requests/userConfig';
import { keyBy } from '@/polyfill/array';
import css from './style.less';
import AutoEllipsisText from '@/components/widgets/autoEllipsisText';

type SellerTradePopProps = {
  value: SubStrategySellerTradeMemoConfig;
};

export const FilterFlags = memo(
  ({ filterFlags }: { filterFlags?: number[] }) => {
    const { data = [] } = useRequest(() => userConfig.sellerFlagsWithGrey());

    if (!filterFlags?.length) {
      return null;
    }

    return (
      <div
        style={{ display: 'inline-flex', gap: '12px', alignItems: 'center' }}
      >
        {filterFlags.map(i => (
          <FlagFilled
            key={i}
            style={{
              color: keyBy(data, 'flag')[i]?.code.toLowerCase()
            }}
          />
        ))}
      </div>
    );
  }
);

export const SellerTradePop = memo(
  (props: PropsWithChildren<SellerTradePopProps>) => {
    const { value, children } = props;
    const { filterFlags, filterKeywords, rejectKeywords } = value || {};

    const renderContent = () => {
      return (
        <div className={css.content}>
          {filterFlags?.length ? (
            <div>
              备注旗帜：
              <FilterFlags filterFlags={filterFlags} />
            </div>
          ) : null}
          {filterKeywords?.length ? (
            <AutoEllipsisText
              text={`备注包含关键词：${filterKeywords.join('、')}`}
            />
          ) : null}

          {rejectKeywords?.length ? (
            <AutoEllipsisText
              text={`备注不包含关键词：${rejectKeywords.join('、')}`}
            />
          ) : null}
        </div>
      );
    };

    return (
      <Popover
        title="订单备注（卖家）"
        content={renderContent}
        placement="left"
      >
        {children}
      </Popover>
    );
  }
);
