import React, { memo, useEffect } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { Table, Column } from '@/components/widgets/table';
import { buildQuickDateRangeFromToday } from '@/utils/date';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import commonCss from '@/css/common.less';
import { useTitleRegister } from '@/hooks/titleRender';
import { buildColumnsWithSorter } from '@/services/sort';
import WangWang from '@/components/works/wangwang';
import { ExportButton } from '@/components/works/exportButton';
import {
  DunningBuyDelayAlert,
  ItemWithDelayIcon,
  ItemWithFinalDate
} from '@/components/works/components/delayIcon';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import { RecordDetailDto } from '@/requests/remindBuyStats/type';
import { Pagination } from '@/components/widgets/pagination';
import {
  Card,
  DetailSearch,
  Statistic,
  StatisticTabs,
  StrategyStatsTable,
  ReplyNumTitle
} from '@/components/works/statisticComponents';
import { Tooltip } from '@/components/widgets/tooltip';
import { DelayData } from '@/types/delayData';
import { RemindBuyStatModel } from './model';
import { buildStatistics, numberToPercentage } from '../service';
import css from './style.less';
import { PlatformWrapper } from '@/components/works/platformWrapper';

const statisticItems = [
  { key: 'remindNum', name: '催拍次数' },
  { key: 'remindBuyerNum', name: '催拍人数' },
  { key: 'hasTradeBuyerNum', name: '下单人数' },
  { key: 'paidPayment', name: '付款金额' },
  {
    key: 'conversionRate',
    name: <Tooltip title="催拍成功率=下单人数/催拍人数">成功率</Tooltip>,
    percent: true
  }
];

const timeStatsColumnsConfig = buildColumnsWithSorter([
  { title: '日期', dataIndex: 'businessDay', width: '200px' },
  {
    title: '催拍次数',
    dataIndex: 'remindNum',
    isDelayData: true,
    render: value => <ItemWithFinalDate value={value} />
  },
  {
    title: '催拍人数',
    dataIndex: 'remindBuyerNum',
    isDelayData: true,
    render: value => <ItemWithFinalDate value={value} />
  },
  {
    title: '下单人数',
    dataIndex: 'hasTradeBuyerNum',
    isDelayData: true,
    render: value => <ItemWithFinalDate value={value} />
  },
  {
    title: '付款人数',
    dataIndex: 'paidBuyerNum',
    isDelayData: true,
    render: value => <ItemWithFinalDate value={value} />
  },
  {
    title: '付款订单数',
    dataIndex: 'paidTradeNum',
    isDelayData: true,
    render: value => <ItemWithFinalDate value={value} />
  },
  {
    title: '付款金额(元)',
    dataIndex: 'paidPayment',
    isDelayData: true,
    render: value => <ItemWithFinalDate value={value} />
  },
  {
    title: (
      <TooltipWithIcon
        label="催拍成功率"
        tooltip="催拍成功率=下单人数/催拍人数"
      />
    ),
    dataIndex: 'conversionRate',
    isDelayData: true,
    render: (value, record) => (
      <ItemWithFinalDate
        value={{
          ...value,
          finalDate: (record.hasTradeBuyerNum as DelayData<any>).finalDate,
          data: `${(value * 100).toFixed(2)}%`
        }}
      />
    )
  },
  { title: <ReplyNumTitle label="回复人数" />, dataIndex: 'replyBuyerNum' },
  {
    title: (
      <TooltipWithIcon label="回复率" tooltip="回复率=回复人数/催拍人数" />
    ),
    dataIndex: 'replyRate',
    render: value => `${(value * 100).toFixed(2)}%`
  }
]);

const taskStatsColumnsConfig = buildColumnsWithSorter([
  { title: '任务名称', dataIndex: 'strategyName', width: '200px' },
  { title: '催拍次数', dataIndex: 'remindNum', defaultSortOrder: 'descend' },
  { title: '催拍人数', dataIndex: 'remindBuyerNum' },
  { title: '下单人数', dataIndex: 'hasTradeBuyerNum' },
  { title: '付款人数', dataIndex: 'paidBuyerNum' },
  { title: '付款订单数', dataIndex: 'paidTradeNum' },
  { title: '付款金额(元)', dataIndex: 'paidPayment' },
  {
    title: (
      <TooltipWithIcon
        label="催拍成功率"
        tooltip="催拍成功率=下单人数/催拍人数"
      />
    ),
    dataIndex: 'conversionRate',
    render: value => `${(value * 100).toFixed(2)}%`
  },
  { title: <ReplyNumTitle label="回复人数" />, dataIndex: 'replyBuyerNum' },
  {
    title: (
      <TooltipWithIcon label="回复率" tooltip="回复率=回复人数/催拍人数" />
    ),
    dataIndex: 'replyRate',
    render: value => `${(value * 100).toFixed(2)}%`
  }
]);

export default memo(() => {
  useTitleRegister(() => <>催拍数据</>);

  const {
    state: {
      dateRange,
      statsLoading,
      statsTableLoading,
      detailLoading,
      stats,
      statsTabKey,
      strategyStatsList,
      detailRecordList,
      statsPage,
      page,
      pageSize,
      total
    },
    initStats,
    initStrategyStatsList,
    initRecord,
    changeStatsPage,
    changeStatsTabKey,
    handleStrategyStatsListSearch,
    handleDetailSearch,
    handleDateRangeChange,
    handleDetailPageChange,
    handleExportDetail,
    handleExportStats
  } = useAgentReducer(RemindBuyStatModel);

  useEffect(() => {
    initStats();
    initStrategyStatsList();
    initRecord();
  }, []);

  const strategyStatsTableConfig =
    statsTabKey === 'time'
      ? {
          dataSource: stats.valueList,
          loading: statsLoading,
          columns: timeStatsColumnsConfig,
          sumMap: stats.sumMap,
          avgMap: numberToPercentage(stats.avgMap, [
            'conversionRate',
            'replyRate'
          ])
        }
      : {
          dataSource: strategyStatsList.valueList,
          loading: statsTableLoading,
          columns: taskStatsColumnsConfig,
          sumMap: strategyStatsList.sumMap,
          avgMap: numberToPercentage(strategyStatsList.avgMap, [
            'conversionRate',
            'replyRate'
          ])
        };

  const summaryColumns = strategyStatsTableConfig.columns
    .slice(1)
    .map(val => val.dataIndex);

  const statistics = buildStatistics(statisticItems, stats);

  return (
    <div className={commonCss.main}>
      <DunningBuyDelayAlert />
      <Card
        titleTop={
          <>
            <div className={commonCss.search}>
              <div className={commonCss.item}>
                <CustomRangePicker
                  options={buildQuickDateRangeFromToday()}
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  onSubmit={handleDateRangeChange}
                />
              </div>
            </div>
            <Statistic
              statisticItems={statisticItems}
              statistics={statistics}
              loading={statsLoading}
            />
          </>
        }
        title={
          <>
            催拍效果统计
            <ExportButton
              onExport={handleExportStats}
              emptyText="导出统计为空"
            />
          </>
        }
      >
        <StatisticTabs
          activeKey={statsTabKey}
          remindType="REMIND_BUY"
          onChange={changeStatsTabKey}
          onSearch={handleStrategyStatsListSearch}
        />

        <StrategyStatsTable
          key={statsTabKey}
          currentPage={statsPage}
          summaryColumns={summaryColumns as string[]}
          onPageChange={changeStatsPage}
          {...strategyStatsTableConfig}
        />
      </Card>

      <Card
        className={css.content}
        titleTop={
          <DetailSearch remindType="REMIND_BUY" onSearch={handleDetailSearch} />
        }
        firstHeading
        title={
          <>
            催拍明细
            <ExportButton onExport={handleExportDetail} />
          </>
        }
      >
        <Table
          rowKey={record => `${record.id}-${record?.taobaoTid?.data}`}
          bordered
          size="small"
          dataSource={detailRecordList}
          showSorterTooltip={false}
          loading={detailLoading}
          pagination={false}
        >
          <Column
            title="催拍时间"
            dataIndex="remindBuyTime"
            width="170px"
            onCell={({ rowSpan }: { rowSpan: number }) => ({ rowSpan })}
          />
          <Column
            title="次数 - 任务名称"
            dataIndex="taskName"
            width="200px"
            onCell={({ rowSpan }: { rowSpan: number }) => ({ rowSpan })}
          />
          <Column
            title="催拍客服"
            dataIndex="subAccountNick"
            width="170px"
            onCell={({ rowSpan }: { rowSpan: number }) => ({ rowSpan })}
          />
          <Column<RecordDetailDto & { rowSpan: number }>
            title="买家账号"
            dataIndex="buyerNick"
            width="170px"
            render={(value, record) => (
              <WangWang openUid={record.buyerOpenUid} nick={value} />
            )}
            onCell={({ rowSpan }: { rowSpan: number }) => ({ rowSpan })}
          />
          <Column
            title="下单时间"
            dataIndex="tradeCreatedTime"
            width="170px"
            render={value =>
              value ? <ItemWithDelayIcon value={value} /> : '-'
            }
          />
          <Column
            title="下单金额(元)"
            dataIndex="tradePayment"
            width="170px"
            render={value =>
              value ? <ItemWithDelayIcon value={value} /> : '-'
            }
          />
          <Column
            title="订单号"
            dataIndex="taobaoTid"
            width="170px"
            render={value =>
              value ? <ItemWithDelayIcon value={value} /> : '-'
            }
          />
          <Column
            title="付款时间"
            dataIndex="tradePayTime"
            width="170px"
            render={value =>
              value ? <ItemWithDelayIcon value={value} /> : '-'
            }
          />
          <Column
            title="付款金额(元)"
            dataIndex="tradePaidPayment"
            width="170px"
            render={value =>
              value ? <ItemWithDelayIcon value={value} /> : '-'
            }
          />
          <Column
            title="买家是否回复"
            dataIndex="buyerReplied"
            width="170px"
            render={v => (v ? '是' : '否')}
          />
        </Table>
        <Pagination
          className={css.pagination}
          hideOnSinglePage
          showQuickJumper
          showSizeChanger={false}
          current={page}
          pageSize={pageSize}
          total={total}
          onChange={handleDetailPageChange}
        />
      </Card>
    </div>
  );
});
