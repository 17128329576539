// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_selectWidth_ARhAG {\n  width: 80px;\n  margin: 0 4px;\n}\n.style_prefix_yqHaQ {\n  margin: 0 12px 0 4px;\n}\n.style_errorBorder_oDMv4 {\n  border-color: #f23c3c;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/excludePaidItemSelector/style.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;AAEA;EACE,oBAAA;AAAF;AAGA;EACE,qBAAA;AADF","sourcesContent":[".selectWidth {\n  width: 80px;\n  margin: 0 4px;\n}\n\n.prefix {\n  margin: 0 12px 0 4px;\n}\n\n.errorBorder {\n  border-color: #f23c3c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWidth": "style_selectWidth_ARhAG",
	"prefix": "style_prefix_yqHaQ",
	"errorBorder": "style_errorBorder_oDMv4"
};
export default ___CSS_LOADER_EXPORT___;
