import React, { memo, useState } from 'react';
import { TradeStatusesPop } from '@/components/works/customPop';
import { Icon } from '@/components/widgets/icon';
import { TradeStatuses } from '@/types/tradeStatus';
import { useDialogMethods } from '@/components/widgets/modal';
import { TradeStatusesSelectModal } from './tradeStatusesSelectModal';
import css from './style.less';

type TradeStatusesSelectorProps = {
  value?: TradeStatuses[];
  onChange?: (v: TradeStatuses[]) => void;
};

/**
 * 订单状态
 */
export const TradeStatusesSelector = memo(
  (props: TradeStatusesSelectorProps) => {
    const { value, onChange } = props;
    const [openDialog] = useDialogMethods();

    const openTradeStatusesSelectModal = async () => {
      const result = (await openDialog(TradeStatusesSelectModal, {
        tradeStatuses: value || [],
        tradeStatusesType: value?.length ? 'POINT' : 'ALL'
      })) as { tradeStatuses: TradeStatuses[] };

      if (!result) {
        return;
      }

      onChange?.(result.tradeStatuses);
    };

    return (
      <>
        <span className={css.item}>
          {value?.length ? (
            <TradeStatusesPop className={css.text} tradeStatuses={value} />
          ) : (
            '不限'
          )}
          <Icon
            className={css.icon}
            type="bianji"
            onClick={openTradeStatusesSelectModal}
          />
        </span>
      </>
    );
  }
);
