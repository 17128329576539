import {
  OriginAgent,
  MiddleWarePresets,
  weakSharing,
  middleWare
} from 'agent-reducer';
import { takeLoading } from '@/middleWares/takeUiCache';
import {} from '@/utils/date';
import { State } from './type';
import { templateModalService } from './service';
import { RemindTimesType } from '@/requests/shop/type';

const takeLatest = MiddleWarePresets.takeLatest();

export class TemplateModal implements OriginAgent<State> {
  state: State = {
    dataSource: [],
    rowKeys: []
  };

  setRowKeys(rowKeys: number[]): State {
    return { ...this.state, rowKeys };
  }

  @middleWare(takeLoading(), takeLatest)
  async initial(type, remindTimesType?: RemindTimesType): Promise<State> {
    const dataSource = await templateModalService.getTemplateList(
      type,
      remindTimesType
    );
    return {
      ...this.state,
      dataSource: dataSource.map((v, i) => ({ ...v, id: i }))
    };
  }
}

export const templateModalRef = weakSharing(() => new TemplateModal());
