import React, { memo } from 'react';
import { Checkbox, CheckboxGroup } from '@/components/widgets/checkbox';
import { ConsignTypes } from '@/types/consignTypes';

export type ConsignTypesCheckboxPorps = {
  value?: ConsignTypes[];
  onChange?: (value: ConsignTypes[]) => void;
};

/**
 * 订单发货类型
 */
export const ConsignTypesCheckbox = memo((props: ConsignTypesCheckboxPorps) => {
  const { value, onChange } = props;

  const handleChange = (v: ConsignTypes[]) => {
    onChange?.(v);
  };

  return (
    <CheckboxGroup
      value={value}
      onChange={v => handleChange(v as ConsignTypes[])}
    >
      <Checkbox value="ALL_CONSIGNED">整单一起发货</Checkbox>
      <Checkbox value="PART_CONSIGNED">拆分发货（部分发货）</Checkbox>
      <Checkbox value="PART_CONSIGNED_ALL">拆分发货（全部发完）</Checkbox>
    </CheckboxGroup>
  );
});
