import { ItemCategoryDto, SellerCategoryDto } from '@/requests/shop/type';
import shopRequest from '@/requests/shop';
import { TreeData } from '@/types/treeData';
import { CategoryDto } from './type';
import { DataNode } from 'antd/lib/tree';

class AccountService {
  fetchItemCatList(): Promise<ItemCategoryDto[]> {
    return shopRequest.fetchItemCatList();
  }

  fetchSellerCatList(): Promise<SellerCategoryDto[]> {
    return shopRequest.fetchSellerCatList();
  }

  private getChildren = (sellerCid: string, list: CategoryDto[]) => {
    const parents = list.filter(({ parentCid }) => parentCid === sellerCid);
    const others = list.filter(({ parentCid }) => parentCid !== sellerCid);
    if (parents.length) {
      return parents.map(dto => ({
        key: dto.cid,
        title: dto.name,
        value: dto.cid,
        order: dto.sortOrder,
        children: this.getChildren(dto.cid, others)
      }));
    }
    return null;
  };

  buildItemCategoryTree = (list: CategoryDto[]): DataNode[] => {
    const newList = list.sort(
      (a, b) => (a.sortOrder || 0) - (b.sortOrder || 0)
    );
    const parents = newList.filter(({ parentCid }) => String(parentCid) === '0');
    const others = newList.filter(({ parentCid }) => parentCid);
    return parents.map(({ cid, name, sortOrder }) => ({
      key: cid,
      title: name,
      value: cid,
      order: sortOrder,
      children: this.getChildren(cid, others)
    }));
  };
}

export default new AccountService();
