import axios, { AxiosRequestConfig, Method } from 'axios';
import { warnError } from '@/components/infos';
import { invokeApi } from '@/libs/api/index';
import { Interceptor } from './type';
import { getPlatform } from '@/services/platform';

export interface Response {
  status: number;
  data: any;
}

let token: string | undefined;
let shopId: number | undefined;
let isStaff: boolean | undefined;
let clientId: string | undefined;

export const restConfig: AxiosRequestConfig = {
  headers: { 'X-Requested-With': 'XMLHttpRequest' }
};

export const getHttpCache = () => ({
  token,
  shopId,
  isStaff,
  clientId
});

export const setInitialHttpCache = (
  tk: string,
  id: number,
  is: boolean,
  cid: string
): void => {
  token = tk;
  shopId = id;
  isStaff = is;
  clientId = cid;
};
export const setWebInitialHttpCache = (id: number) => {
  shopId = id;
};

function request(
  path: string,
  requestConfig: AxiosRequestConfig
): Promise<any> {
  const { params, headers: initHeaders } = requestConfig;
  const headers = token
    ? { token, ...initHeaders, ...restConfig.headers }
    : { ...initHeaders, ...restConfig.headers,};
  const config = {
    ...requestConfig,
    params: isStaff ? { ...params, clientId } : { ...params, shopId, clientId },
    headers
  };

  return invokeApi('http', { path, config });
}

export const exportRequest = async (
  config: AxiosRequestConfig
): Promise<void> => {
  const headers = token
    ? { token, ...restConfig.headers }
    : { ...restConfig.headers };
  const { isJD } = getPlatform();
  if (isJD && config.url) {
    config.url = config.url.replace('api', 'jd-api');
  }
  const res = await axios({
    ...config,
    responseType: 'blob',
    headers,
    params: { shopId }
  });
  if (res.headers['content-type'] === 'application/json') {
    // 说明是普通对象数据，读取信息
    const fileReader = new FileReader();
    fileReader.readAsText(res.data);
    fileReader.onloadend = function (e) {
      const resultString = (e?.target as { result: string }).result;
      const result = JSON.parse(resultString);
      if (!resultString || !result?.errorMsg) {
        return;
      }
      warnError(result.errorMsg);
    };
    return;
  }
  if (!res) {
    throw new Error('下载错误');
  }
  const temp = res.headers['content-disposition'].split(';')[1].split('=')[1];
  const fileName = decodeURIComponent(temp.toString());
  const blob = new Blob([res.data], {
    type: 'application/vnd.ms-excel;charset=UTF-8'
  });
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(blobUrl);
  a.remove();
};

let interceptor: undefined | Interceptor;

export const setRestConfig = (config: AxiosRequestConfig): void => {
  Object.assign(restConfig, config);
};

function getResponseData(rsp: any): any {
  const { data } = rsp;
  if (interceptor) {
    return interceptor.success(rsp);
  }
  return data;
}

function getErrorResponse({ response }: any): any {
  if (interceptor) {
    return interceptor.error(response);
  }
  return Promise.reject(response);
}

export function setInterceptor(inter: Interceptor): void {
  interceptor = inter;
}

export class Http {
  private parentUrl: string;

  private urls: Array<string> = [''];

  private requestBody?: unknown;

  private requestParams?: Record<string, unknown>;

  private getPath() {
    const pathArray = [this.parentUrl, ...this.urls].filter((path: string) =>
      path.trim()
    );
    return pathArray.join('/');
  }

  private reset(): void {
    this.urls = [''];
    this.requestBody = undefined;
    this.requestParams = undefined;
  }

  private run(requestConfig: AxiosRequestConfig): Promise<any> {
    const promise = request(this.getPath(), requestConfig);
    this.reset();
    return promise;
  }

  constructor(url: string) {
    this.parentUrl = url.replace(/\/$/, ''); // 移除末尾的斜杠
  }

  /**
   * 添加后续url路径
   *
   * @param url 后续url路径，前后可以不带'/'
   */
  path(url = ''): Http {
    this.urls = this.urls.concat(url.split('/'));
    return this;
  }

  /**
   * 添加RequestBody
   *
   * @param requestBody 对应java controller的@RequestBody
   */
  setRequestBody<B>(requestBody: B): Http {
    this.requestBody = requestBody;
    return this;
  }

  /**
   * 添加RequestParams
   *
   * @param requestParams 对应java controller的@RequestParams
   */
  setRequestParams<P extends Record<string, unknown>>(requestParams: P): Http {
    this.requestParams = requestParams;
    return this;
  }

  private setRequestConfig(
    method: Method,
    config?: AxiosRequestConfig,
    data?: any
  ): AxiosRequestConfig {
    const params = this.requestParams;
    const { requestBody } = this;
    const base: AxiosRequestConfig = {
      method,
      withCredentials: true,
      params,
      data: data || requestBody
    };
    return {
      ...restConfig,
      ...config,
      ...base
    } as AxiosRequestConfig;
  }

  /**
   * 发起Http get请求
   * @param config  根据需求添加请求头等配置
   */
  get<T>(config?: AxiosRequestConfig): Promise<T> {
    return this.run(this.setRequestConfig('get', config)).then(
      getResponseData,
      getErrorResponse
    );
  }

  /**
   * 发起Http post请求
   * @param config  根据需求添加请求头等配置
   */
  post<T>(config?: AxiosRequestConfig): Promise<T> {
    return this.run(this.setRequestConfig('post', config)).then(
      getResponseData,
      getErrorResponse
    );
  }

  /**
   * 发起Http put请求
   * @param config  根据需求添加请求头等配置
   */
  put<T>(config?: AxiosRequestConfig): Promise<T> {
    return this.run(this.setRequestConfig('put', config)).then(
      getResponseData,
      getErrorResponse
    );
  }

  /**
   * 发起Http delete请求
   * @param config  根据需求添加请求头等配置
   */
  delete<T>(config?: AxiosRequestConfig): Promise<T> {
    return this.run(this.setRequestConfig('delete', config)).then(
      getResponseData,
      getErrorResponse
    );
  }
}

/**
 * 方法调用入口
 * @param path  初始路径
 *
 * 如：
 * http('api/xxx').path('fetch').setRequestBody({}).post();
 */

export function http(path: string): Http {
  return new Http(path);
}
