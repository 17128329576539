import Paragraph from 'antd/lib/typography/Paragraph';
import classNames from 'classnames';
import React, { memo } from 'react';
import { Props } from './type';
import css from './style.less';

export default memo(
  ({ text, maxWidth = '100%', rows = 1, className, ...rest }: Props) => (
    <span
      {...rest}
      style={{ maxWidth }}
      className={classNames(css.wrapper, className || '')}
    >
      <Paragraph
        ellipsis={{ tooltip: text, rows }}
        style={{ wordBreak: 'break-word' }}
      >
        {text}
      </Paragraph>
    </span>
  )
);
