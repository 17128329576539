import React from 'react';
import { message as antMessage } from 'antd';
import { Modal } from '@/components/widgets/modal';

type ErrorMessage = {
  errorMessage?: string;
  message: string;
  name?: string;
  stack?: string;
  reason?: string | Error;
  status?: number;
};

export const warnError = (error: unknown): void => {
  if (typeof error === 'string') {
    antMessage.destroy();
    antMessage.error(error);
  }
  const { errorMessage, message } = error as ErrorMessage;
  const errorMsg = errorMessage || message;
  if (!errorMsg) {
    return;
  }
  antMessage.destroy();
  antMessage.error(errorMsg);
};

export const saveIgnoreConfirm = (content: string): Promise<boolean> =>
  Modal.confirm({
    title: '温馨提示',
    content,
    okText: '不保存，离开'
  });

export const leaveConfirm = (content: string): Promise<boolean> =>
  Modal.confirm({
    title: '系统提示',
    content,
    okText: '确认'
  });
