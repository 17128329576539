import { DataNode } from 'antd/lib/tree';
import { DraggableLocation } from 'react-beautiful-dnd';

import { ItemGroupPointModifyCmd } from '@/requests/itemGroups/type';

type Return = {
  cmd: ItemGroupPointModifyCmd;
  data: DataNode[];
};

export const reorder = (
  list: DataNode[],
  startIndex: number,
  endIndex: number
): DataNode[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderChildren = (
  list: DataNode[],
  source: DraggableLocation,
  destination: DraggableLocation
): Return => {
  const current = list.find(v => String(v.key) === source.droppableId)!
    .children!;
  const next = list.find(v => String(v.key) === destination.droppableId)!
    .children!;
  const target = current[source.index];

  if (next.length === 20) {
    throw new Error('每个一级商品组下最多有20个二级商品组');
  }

  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);

    const res = list.map(v => ({
      ...v,
      children: String(v.key) === source.droppableId ? reordered : v.children
    }));

    return {
      data: res,
      cmd: {
        // originParentGroupId: Number(source.droppableId),
        targetParentGroupId: Number(destination.droppableId),
        groupId: Number(target.key),
        groupIds: reordered.map(v => Number(v.key))
      }
    };
  }

  current.splice(source.index, 1);
  next.splice(destination.index, 0, target);

  const res = list.map(v => {
    if (String(v.key) === source.droppableId) {
      return {
        ...v,
        children: current
      };
    }

    if (String(v.key) === destination.droppableId) {
      return {
        ...v,
        children: next
      };
    }

    return v;
  });

  return {
    data: res,
    cmd: {
      // originParentGroupId: Number(source.droppableId),
      targetParentGroupId: Number(destination.droppableId),
      groupId: Number(target.key),
      groupIds: next.map(v => Number(v.key))
    }
  };
};
