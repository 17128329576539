import { OriginAgent, sharing } from 'agent-reducer';
import { formatRangeFromNow, getValidateDateRange } from '@/utils/date';
import { UIState } from './type';

class UI implements OriginAgent<UIState> {
  state: UIState = {
    leftCollapsed: false,
    pageSize: 10,
    pageLoading: false
  };

  update(uiState: Partial<UIState>): UIState {
    return { ...this.state, ...uiState };
  }

  toggleLeftCollapsed(): UIState {
    const { leftCollapsed } = this.state;
    return { ...this.state, leftCollapsed: !leftCollapsed };
  }

  changePageSize(pageSize: number): UIState {
    return { ...this.state, pageSize };
  }

  updateGlobalDateRange(newRange: [string, string]): UIState {
    return { ...this.state, globalDateRange: [...newRange] };
  }

  changeScrollTop(scrollTop: number): UIState {
    return { ...this.state, scrollTop };
  }

  resetScrollTop(): UIState {
    return { ...this.state, scrollTop: 0 };
  }

  setPageLoading(pageLoading: boolean) {
    return { ...this.state, pageLoading };
  }
}

export const uiRef = sharing(() => UI);

export const getPageUi = (
  maxRangeDays: number,
  defaults?: { range?: [string, string]; pageSize?: number }
): { range: [string, string]; pageSize: number } => {
  const base = { range: formatRangeFromNow(0, 0), pageSize: 10 };
  const config = { ...base, ...defaults };
  const { globalDateRange = config.range, pageSize = config.pageSize } =
    uiRef.current.state;
  const range = getValidateDateRange(globalDateRange, maxRangeDays);
  return { range, pageSize };
};
