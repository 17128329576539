import { RemindTimesType } from '@/types/remindTimesType';
import {
  RemindTimeConfigButtonTextConfig,
  RemindTimeConfigProps
} from './type';
import {
  orderedRemindTimesType,
  remindTimesTextConfigMap,
  remindTimesTypeTextMap
} from './constants';
import { RemindType } from '@/types/remindType';

export const getRemindTimeButtonTextConfig = (
  textConfig: RemindTimeConfigProps['textConfig'],
  repeatEdit?: boolean
): Record<RemindTimesType, RemindTimeConfigButtonTextConfig> => {
  if (!textConfig) {
    return remindTimesTextConfigMap;
  }

  const propTextConfigs: Partial<
    Record<RemindTimesType, Partial<RemindTimeConfigButtonTextConfig> | string>
  > =
    typeof textConfig === 'string' ? { FIRST_REMIND: textConfig } : textConfig;

  const res = orderedRemindTimesType.reduce((pre, remindType) => {
    const propTextConfig = propTextConfigs[remindType];
    const defaultTextConfig = remindTimesTextConfigMap[remindType];

    const dealPropsTextConfig =
      typeof propTextConfig === 'string'
        ? { prefix: propTextConfig }
        : propTextConfig;

    //  可以编辑重复时所有prefix都变成第一次的
    if (repeatEdit && remindType !== 'FIRST_REMIND') {
      return {
        ...pre,
        [remindType]: {
          ...defaultTextConfig,
          ...dealPropsTextConfig,
          prefix: pre.FIRST_REMIND.prefix
        }
      };
    }

    return {
      ...pre,
      [remindType]: {
        ...defaultTextConfig,
        ...dealPropsTextConfig
      }
    };
  }, {} as Record<RemindTimesType, RemindTimeConfigButtonTextConfig>);

  return res;
};

export const getRemindTimesTypeOptions = (
  value: RemindTimesType[],
  remindType: RemindType
): Array<{ label: string; value: RemindTimesType }> => {
  if (remindType === 'REMIND_FINAL_PAY') {
    return [
      {
        label: '付尾款开始前',
        value: 'FIRST_REMIND'
      },
      {
        label: '付尾款开始后',
        value: 'SECOND_REMIND'
      },
      {
        label: '付尾款结束前',
        value: 'THIRD_REMIND'
      }
    ];
  }

  return value.map(v => ({
    label: remindTimesTypeTextMap[v].replace('推送', ''),
    value: v
  }));
};
