import React, { memo, useEffect, useState } from 'react';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { Select, Option } from '@/components/widgets/select';
import { RadioChangeEvent } from 'antd/lib/radio';
import { warnError } from '@/components/infos';
import shopRequest from '@/requests/shop';
import { RefundReasonDto } from '@/requests/shop/type';
import { CustomType } from '../type';
import css from './style.less';

type RefundReasonProps = {
  value?: number[];
  onChange?: (value: number[]) => void;
  cancelFormError?: () => void;
};

type RefundReasons = { type: CustomType; reasons: number[] };

export const RefundReasonSelector = memo((props: RefundReasonProps) => {
  const { value = [], onChange, cancelFormError } = props;

  const [reason, setReason] = useState<RefundReasonDto[] | undefined>();
  const [refundReasons, setRefundReasons] = useState<RefundReasons>({
    type: 'ALL',
    reasons: []
  });

  const fetchReason = async () => {
    const result = await shopRequest.fetchRefundReason();
    setReason(result as RefundReasonDto[]);
  };

  useEffect(() => {
    if (!value.length) {
      return;
    }
    setRefundReasons({ type: value.length ? 'FIXED' : 'ALL', reasons: value });
  }, [value]);

  useEffect(() => {
    try {
      fetchReason();
    } catch (e) {
      warnError(e);
    }
  }, []);

  const handleTypeChange = (e: RadioChangeEvent) => {
    cancelFormError?.();
    setRefundReasons({ ...refundReasons, type: e.target.value });
    onChange?.(e.target.value === 'ALL' ? [] : refundReasons.reasons);
  };

  const handleIdsChange = (v: number[]) => {
    cancelFormError?.();
    setRefundReasons({ ...refundReasons, reasons: v });
    onChange?.(v);
  };

  return (
    <div>
      <RadioGroup value={refundReasons.type} onChange={handleTypeChange}>
        <Radio value="ALL">不限</Radio>
        <Radio value="FIXED">指定退款原因</Radio>
      </RadioGroup>
      {refundReasons.type === 'FIXED' ? (
        <div className={css.item}>
          <Select
            mode="multiple"
            className={css.multipleSelect}
            placeholder="请选择退款原因"
            value={refundReasons.reasons}
            onChange={handleIdsChange}
          >
            {reason &&
              reason.map(({ id, reasonDesc }) => (
                <Option key={id} value={id}>
                  {reasonDesc}
                </Option>
              ))}
          </Select>
        </div>
      ) : null}
    </div>
  );
});
