import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { RangePicker } from '@/components/widgets/datePicker';
import { formatDayFromNow } from '@/utils/date';
import css from './style.less';
import {
  CustomRangePickerProps,
  DateRange,
  OptionProps,
  ShortcutOption
} from './type';

const defaultOptions: Array<ShortcutOption> = [
  { label: '昨', value: [-1, -1] },
  { label: '周', value: [-7, -1] },
  { label: '月', value: [-31, -1] }
];

const Option = ({ value, selected, onSelect }: OptionProps) => {
  const parentSelected = selected || { label: null };
  const { label } = value;

  const handleSelect = useCallback(() => {
    onSelect(value);
  }, [value, onSelect]);
  return (
    <span className={css.customBtnGroupTextWrap}>
      <span
        className={classNames(
          css.customBtnGroupText,
          parentSelected.label === label ? css.active : ''
        )}
        onClick={handleSelect}
      >
        {label}
      </span>
      <span className={css.customBtnGroupDivider} />
    </span>
  );
};

export const CustomRangePicker = memo((props: CustomRangePickerProps) => {
  const {
    value,
    options = defaultOptions,
    version,
    allowClear = false,
    onChange,
    onSubmit,
    maxDay = 0,
    extraFooter = null,
    ...rest
  } = props;

  const [dateRange, setDateRange] = useState<DateRange | undefined>(
    value ? new DateRange(value[0], value[1]) : undefined
  );

  const [selected, select] = useState<ShortcutOption | null>(null);

  const count = useRef<number>(0);

  const disabledDate = (currentDate: moment.Moment) => {
    if (maxDay > 0) {
      if (
        !dateRange ||
        !dateRange.from ||
        !dateRange.to ||
        count.current % 2 === 0
      ) {
        return false;
      }
      if (!dateRange || (!dateRange.from && !dateRange.to)) {
        return false;
      }
      return (
        Math.abs(currentDate.diff(dateRange.from, 'days')) >= maxDay &&
        Math.abs(currentDate.diff(dateRange.to, 'days')) >= maxDay
      );
    }
    return currentDate && currentDate > moment().endOf('day');
  };

  const handleOpenChange = open => {
    if (!open) {
      count.current = 0;
    }
  };

  const buildDateRange = (start, end, info): DateRange => {
    if (count.current % 2 === 0) {
      if (info.range === 'start') {
        return new DateRange(start, start);
      }
      return new DateRange(end, end);
    }
    if (start && !end) {
      return new DateRange(start, start);
    }
    if (!start && end) {
      return new DateRange(end, end);
    }
    return new Date(start).getTime() > new Date(end).getTime()
      ? new DateRange(end, start)
      : new DateRange(start, end);
  };

  const handleCalendarChange = (currentValue, format, info) => {
    if (currentValue) {
      const start = format[0];
      const end = format[1];
      setDateRange(buildDateRange(start, end, info));
      count.current += 1;
    } else {
      count.current = 0;
      setDateRange(undefined);
    }
  };

  const renderExtraFooter = () => {
    const footer = (
      <div className={css.footerExtra}>
        可查看订购后最近一年的历史数据 每次最多查{maxDay}天
      </div>
    );
    if (extraFooter) {
      return <div className={css.footerExtra}>{extraFooter}</div>;
    }
    if (extraFooter === null) {
      return null;
    }
    return maxDay ? footer : null;
  };

  const handleChange = useCallback((r: [string, string]) => {
    select(null);
  }, []);

  const handleSelect = useCallback(
    (option: ShortcutOption) => {
      select(option);
      const [from, to] = option.value;
      const rangeValue: [string, string] = [
        formatDayFromNow(from),
        formatDayFromNow(to)
      ];
      if (onSubmit) {
        onSubmit(rangeValue);
      }
    },
    [onSubmit]
  );

  const changeSelect = useCallback(() => {
    const valueRange = value ? new DateRange(value[0], value[1]) : undefined;
    if (!valueRange || !options) {
      return null;
    }
    const { from, to } = valueRange;
    const found = options.find(({ value: [start, end] }) => formatDayFromNow(start) === from && formatDayFromNow(end) === to);
    if (!found) {
      return null;
    }
    return found;
  }, [options, value]);

  useEffect(() => {
    select(changeSelect());
  }, [options, value]);

  const isSameDateRange = (
    range1: [string, string],
    range2: [string, string]
  ): boolean => {
    const [range1From, range1To] = range1;
    const [range2From, range2To] = range2;
    if (range1From && range1To && range2From && range2To) {
      return range1From === range2From && range1To === range2To;
    }
    if (!range1From && !range1To && !range2From && !range2To) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      onChange &&
      dateRange &&
      props.value &&
      !count.current &&
      !isSameDateRange(props.value, [dateRange.from, dateRange.to])
    ) {
      onChange([dateRange?.from, dateRange?.to]);
    }
  }, [dateRange]);

  useEffect(() => {
    setDateRange(
      props.value ? new DateRange(props.value[0], props.value[1]) : undefined
    );
  }, [props.value]);

  return (
    <span className={css.fastRangePicker}>
      <span className={css.customBtnGroup}>
        {options
          ? options.map(k => (
              <Option
                value={k}
                selected={selected}
                key={`fast_custome_btn_${k.label}`}
                onSelect={handleSelect}
              />
            ))
          : null}
      </span>
      <RangePicker
        {...rest}
        dropdownClassName={css.dropDown}
        allowClear={allowClear}
        value={dateRange ? [dateRange?.from, dateRange?.to] : undefined}
        onCalendarChange={handleCalendarChange}
        disabledDate={disabledDate}
        renderExtraFooter={renderExtraFooter}
        onOpenChange={handleOpenChange}
        onChange={handleChange}
      />
    </span>
  );
});
