import { Http, http } from '@/libs/http';
import {
  PresaleActivityWebDto,
  FindPresaleActivityByIdParams,
  deletePresaleActivityByIdParams,
  PresaleActivityModifiedCommand
} from './type';

export class PresaleActivityRequest {
  private http: Http = http('/api/shop/presale-activity');

  // 预售活动列表
  getPresaleActivityList(): Promise<PresaleActivityWebDto[]> {
    return this.http.path('list').get<PresaleActivityWebDto[]>();
  }

  // 预售活动根据id查询
  findPresaleActivityById(
    params: FindPresaleActivityByIdParams
  ): Promise<PresaleActivityWebDto> {
    return this.http
      .path('find-by-id')
      .setRequestBody(params)
      .post<PresaleActivityWebDto>();
  }

  // 预售活动根据id删除
  deletePresaleActivityById(
    params: deletePresaleActivityByIdParams
  ): Promise<boolean> {
    return this.http
      .path('delete-by-id')
      .setRequestBody(params)
      .post<boolean>();
  }

  // 预售活动新增或者更新
  modifiedPresaleActivity(
    params: PresaleActivityModifiedCommand
  ): Promise<boolean> {
    return this.http.path('modified').setRequestBody(params).post<boolean>();
  }

  //  是否有生效中-未开始的活动
  hasValidActivity(): Promise<boolean> {
    return this.http.path('has-valid-activity').post<boolean>();
  }
}

export const presaleActivityRequest = new PresaleActivityRequest();
