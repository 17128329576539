import { useAgentSelector } from 'use-agent-reducer';
import { accountRef } from '@/modules/account';
import { wwGroupListRef } from '@/modules/wwGroupList';
import { useEffect, useRef, useState } from 'react';
import { Platform } from '@/types/platform';
import { getItem } from '@/utils/storage';

type ParamsType = {
  subAccountIds?: number[];
  wangwangGroupIds?: number[];
};

export const getAccountLabel = ({
  account = '',
  subNick = '',
  userName = ''
}: {
  account?: string;
  subNick?: string;
  userName?: string;
} = {}): string => {
  const platform = (getItem('platform') || 'TB') as Platform;

  if (platform === 'JD') {
    return `${subNick || account}${userName ? `（${userName}）` : ''}`;
  }

  return account;
};

export const useIdsConvertNames = (params: ParamsType) => {
  const newPoprs = useRef(params);
  const { subAccountIds, wangwangGroupIds } = newPoprs.current;

  const [accountNames, setAccountNames] = useState<string[]>([]);
  const [wwGroupNames, setWwGroupNames] = useState<string[]>([]);

  const account = useAgentSelector(accountRef.current, s => s.account);
  const wwGroupList = useAgentSelector(
    wwGroupListRef.current,
    s => s.wwGroupList
  );

  useEffect(() => {
    const newAccountNames = subAccountIds?.flatMap(
      i => getAccountLabel(account.find(j => j.id === i)) || []
    );

    setAccountNames(newAccountNames || []);
  }, [subAccountIds]);

  useEffect(() => {
    const newWwGroupNames = wangwangGroupIds?.flatMap(
      i => wwGroupList.find(j => j.id === i)?.groupName || []
    );
    setWwGroupNames(newWwGroupNames || []);
  }, [wangwangGroupIds]);

  return { accountNames, wwGroupNames };
};
