import React, { memo, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { Table, Column } from '@/components/widgets/table';
import { RangePicker } from '@/components/widgets/datePicker';
import { Input } from '@/components/widgets/input';
import { Button } from '@/components/widgets/button';
import { Select, Option } from '@/components/widgets/select';
import { ImageLogContent } from '@/components/works/imageLogContent';
import { truncateString } from './service';
import { FormLike } from '@/components/widgets/formLike';
import ConditionUnit from '@/components/layouts/conditionUnit';
import useRequest from '@/hooks/useRequest';
import { useFrontQueryDriver } from '@/hooks/useFrontQueryDriver';
import { uiPrompt } from '@/avatars';
import enterpriseRequest from '@/requests/enterprise';
import css from './style.less';
import { CompareLogTypeWebDto } from '@/requests/enterprise/type';
import { message } from '@/components/widgets/message';
import { OperationAccountProps } from './type';
import { warnError } from '@/components/infos';

const OperationAccountSelect = memo(
  ({ value, onChange }: OperationAccountProps) => {
    const [accounts, setAccounts] = useState<any[]>([]);

    const getLogTypes = async () => {
      const result = await enterpriseRequest.listOperateAccounts();
      setAccounts(result.map(i => ({ value: i.id, label: i.staffName })));
    };
    const filterOption = (input, option) =>
      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    useEffect(() => {
      getLogTypes().catch(e => warnError(e));
    }, []);

    return (
      <Select
        options={accounts}
        value={value}
        onChange={onChange}
        style={{ width: 260 }}
        placeholder="请选择操作账号"
        showSearch
        filterOption={filterOption}
        optionFilterProp="children"
        mode="multiple"
      />
    );
  }
);

export default memo(() => {
  const [logTypes, setLogTypes] = useState<CompareLogTypeWebDto[]>([]);

  useRequest(() => enterpriseRequest.logTypes(), {
    onSuccess: data => {
      setLogTypes(data);
    },
    onError: uiPrompt.current.error
  });

  const {
    data = { dataList: [], totalCount: 0 },
    run,
    loading
  } = useRequest(v => enterpriseRequest.enterpriseCompareLogList(v), {
    manual: true,
    onError: uiPrompt.current.error
  });

  const { query, setQuery, search, searchAssign, reset } = useFrontQueryDriver(
    run,
    {
      from: moment().add(-6, 'day').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      logType: null,
      pageNo: 1,
      pageSize: 10
    }
  );

  const { pageNo, pageSize } = query;
  const { dataList, totalCount } = data;

  const handleSearch = () => {
    const { from, to } = query;
    if (moment(from).add(31, 'd').isBefore(to)) {
      message.warning('开始时间和结束时间不能超过31天');
      return;
    }

    searchAssign({ ...query, pageNo: 1 });
  };

  const handleFormChange = form => {
    const {
      dateRange: [from, to],
      ...rest
    } = form;

    setQuery({ ...rest, from, to });
  };

  const handlePageChange = pageNo => {
    searchAssign({ pageNo });
  };

  const renderContent = contents => (
    <>
      {contents.map(({ type, content }) =>
        type === 'TEXT' ? (
          <div key={uniqueId()} className={css.content}>
            {truncateString(content)}
          </div>
        ) : (
          <ImageLogContent key={content} imageId={content} />
        )
      )}
    </>
  );

  return (
    <>
      <div className={css.search}>
        <FormLike
          value={{ ...query, dateRange: [query.from, query.to] }}
          onChange={handleFormChange}
        >
          <ConditionUnit
            name="dateRange"
            label="时间范围"
            className={css.item}
            required
          >
            <RangePicker allowClear={false} />
          </ConditionUnit>

          <ConditionUnit name="keyword" label="操作内容" className={css.item}>
            <Input placeholder="请输入操作内容" />
          </ConditionUnit>

          <ConditionUnit name="logType" label="功能模块" className={css.item}>
            <Select className={css.selectWidthMid} placeholder="请选择">
              <Option value={null}>全部</Option>
              {logTypes.map(logType => (
                <Option key={logType.logType} value={logType.logType}>
                  {logType.logTypeCnName}
                </Option>
              ))}
            </Select>
          </ConditionUnit>

          <ConditionUnit
            name="enterpriseStaffIds"
            label="操作账号"
            className={css.item}
          >
            <OperationAccountSelect />
          </ConditionUnit>

          <div>
            <Button
              type="primary"
              style={{ marginRight: '12px' }}
              onClick={handleSearch}
            >
              搜索
            </Button>
            <Button onClick={() => reset()}>重置</Button>
          </div>
        </FormLike>
      </div>
      <div className={css.operationLogList}>
        <Table
          rowKey="id"
          loading={loading}
          dataSource={dataList}
          bordered
          pagination={{
            position: ['bottomCenter'],
            current: pageNo,
            total: totalCount,
            pageSize,
            onChange: handlePageChange,
            showSizeChanger: false
          }}
        >
          <Column
            width="15%"
            key="operateTime"
            dataIndex="operateTime"
            title="时间"
            render={value => <div style={{ minWidth: 100 }}>{value}</div>}
          />
          <Column
            width="20%"
            key="operateAccount"
            dataIndex="operateAccount"
            title="操作账号"
          />
          <Column
            width="15%"
            key="logType"
            dataIndex="logType"
            title="功能模块"
            render={value => (
              <div>
                {logTypes.find(i => i.logType === value)?.logTypeCnName}
              </div>
            )}
          />
          <Column
            dataIndex="contents"
            title="操作内容"
            width="50%"
            render={renderContent}
          />
        </Table>
      </div>
    </>
  );
});
