// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_dongdong_SHOVS {\n  font-size: 20px;\n  color: #1e9eff;\n  cursor: pointer;\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/dongdong/style.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":[".dongdong {\n  font-size: 20px;\n  color: #1e9eff;\n  cursor: pointer;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dongdong": "style_dongdong_SHOVS"
};
export default ___CSS_LOADER_EXPORT___;
