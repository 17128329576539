import { act, flow, Flows, Model, strict, weakSharing } from 'agent-reducer';
import { uiPrompt } from '@/avatars';
import { formatDayFromNow, checkRange } from '@/utils/date';
import shopRequest from '@/requests/shop';
import { State } from './type';

@strict()
export class RealTimeLoginModel implements Model<State> {
  state: State = {
    subAccountLoading: false,
    subAccountLoginStatus: [],
    subAccountPage: 1,
    dateRange: [formatDayFromNow(0), formatDayFromNow(0)],
    subAccountTotal: 0,
    subAccountSearchCondition: {
      loginStatus: 'ALL',
      wangwangGroupId: null
    }
  };

  @act()
  private subAccountLoad(): State {
    return { ...this.state, subAccountLoading: true };
  }

  @act()
  private subAccountUnload(): State {
    return { ...this.state, subAccountLoading: false };
  }

  @act()
  private changeState(newState: Partial<State>) {
    return { ...this.state, ...newState };
  }

  @act()
  changeSubAccountSearchCondition(
    value: State['subAccountSearchCondition']
  ): State {
    return this.changeState({ subAccountSearchCondition: value });
  }

  @flow(Flows.latest())
  async fetchAccount(newPage?: number): Promise<void> {
    const {
      dateRange,
      subAccountPage,
      subAccountSearchCondition: {
        subAccountName,
        loginStatus,
        wangwangGroupId
      }
    } = this.state;
    const [from, to] = dateRange;
    const page = newPage ?? subAccountPage;

    this.subAccountLoad();
    try {
      const { totalCount, dataList } =
        await shopRequest.subAccountLoginStatus({
          subAccountName: subAccountName || undefined,
          loginStatus: loginStatus === 'ALL' ? undefined : loginStatus,
          wangwangGroupId: wangwangGroupId || undefined,
          from,
          to,
          page,
          pageSize: 10
        });
      this.changeState({
        subAccountLoginStatus: dataList,
        subAccountTotal: totalCount,
        subAccountPage: page
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.subAccountUnload();
    }
  }

  @flow()
  handleDateRangeChange(range: [string | undefined, string | undefined]): void {
    const [from, to] = range;
    if (!from || !to) {
      return;
    }
    const dateRange: [string, string] = [from, to];
    if (!checkRange(dateRange, 31)) {
      uiPrompt.current.warning('最长可选31天');
      return;
    }
    this.changeState({ dateRange });
    this.fetchAccount(1);
  }
}

export const realTimeLoginRef = weakSharing(() => new RealTimeLoginModel());
