// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_checkbox_H3Bxm {\n  margin-right: 8px;\n}\n.style_editIcon_SwID6 {\n  color: #3d7eff;\n  cursor: pointer;\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindMainStrategyDialog/components/afterSale/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAAF","sourcesContent":[".checkbox {\n  margin-right: 8px;\n}\n\n.editIcon {\n  color: #3d7eff;\n  cursor: pointer;\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "style_checkbox_H3Bxm",
	"editIcon": "style_editIcon_SwID6"
};
export default ___CSS_LOADER_EXPORT___;
