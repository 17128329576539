import { getItem } from '@/utils/storage';
import Api from './api';
import JD from './JD';
import TB from './TB';
import { Platform } from './type';
import { enterpriseRouteConfig, shopRouteConfig } from '@/routeConfig';

//  强制调淘宝的页面
export const forceUserTbPath = [
  ...Object.values(enterpriseRouteConfig),
  ...Object.values(shopRouteConfig).slice(-3)
].map(v => v.path);

export function isLogin(path) {
  return forceUserTbPath.includes(path);
}

const getApi = (platformArg?: Platform) => {
  const platform = (getItem('platform') as Platform) || 'TB';

  const newPlatform =
    platformArg || (isLogin(window.location.pathname) ? 'TB' : platform);

  const PlatformApi = { TB, JD }[newPlatform];

  return new PlatformApi();
};

export function invokeApi<T extends keyof Api>(
  command: T,
  params?: Parameters<Api[T]>[0],
  platform?: Platform
): ReturnType<Api[T]> {
  const api = getApi(platform);

  return api[command](params as never) as ReturnType<Api[T]>;
}
