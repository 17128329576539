import React, { memo, useState } from 'react';
import { DisturbConfig } from '@/requests/remindStrategy/types';
import {
  RemindLimitComp,
  defaultDisturbConfig
} from '../../remindMainStrategyDialog/components/remindLimitComp';
import AfterSale from '../../remindMainStrategyDialog/components/afterSale';
import TradeStatus, {
  defaultTradeStatusFilterConfig
} from '../../remindMainStrategyDialog/components/tradeStatus';
import {
  AfterPayTradeFilter,
  defaultAfterPayTradeFilterConfig
} from '../../remindMainStrategyDialog/components/afterPayTradeFilter';
import css from './style.less';

export const MainStrategy = memo(() => {
  const [disturbConfig, setDisturbConfig] =
    useState<DisturbConfig>(defaultDisturbConfig);
  const [filterInAfterSalePeriodBuyer, setFilterInAfterSalePeriodBuyer] =
    useState(false);
  const [tradeStatusFilterConfig, setTradeStatusFilterConfig] = useState(
    defaultTradeStatusFilterConfig
  );
  const [afterPayTradeFilterConfig, setAfterPayTradeFilterConfig] = useState(
    defaultAfterPayTradeFilterConfig
  );

  return (
    <div className={css.ruleDialog}>
      <RemindLimitComp
        value={disturbConfig}
        onChange={setDisturbConfig}
        remindType="REMIND_BUY"
      />
      <AfterSale
        checkable={false}
        checked={filterInAfterSalePeriodBuyer}
        onCheckedChange={setFilterInAfterSalePeriodBuyer}
      />
      <TradeStatus
        checkable
        value={tradeStatusFilterConfig}
        onChange={v => {
          setTradeStatusFilterConfig({ ...tradeStatusFilterConfig, ...v });
        }}
      />
      <AfterPayTradeFilter
        afterPayTradeFilterConfig={afterPayTradeFilterConfig}
        afterPayTradeFilterConfigChange={setAfterPayTradeFilterConfig}
      />
    </div>
  );
});
