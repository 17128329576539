import React, { useEffect, useRef, useState } from 'react';
import { Modal, withGlobalDialogs } from '@/components/widgets/modal';
import { RedirectTo } from '@/components/layouts/route';
import staffAccountRequest from '@/requests/staffAccount';
import useRequest from '@/hooks/useRequest';
import { replace } from '@/libs/history';
import routeConfig, {
  enterpriseRouteConfig,
  shopRouteConfig
} from '@/routeConfig';
import App from './app';
import { Channel } from '@/utils/channel';
import { getItem, setItem } from '@/utils/storage';
import Loading from '@/components/widgets/loading/Loading';
import { message } from '@/components/widgets/message';
import { usePlatform } from '@/hooks/usePlatform';

export function isEnterprisePage(path) {
  return Object.values(enterpriseRouteConfig).some(
    route => route.path === path
  );
}

export function isShopPage(path) {
  return Object.values(shopRouteConfig).some(route => route.path === path);
}

export default withGlobalDialogs(({ to }: { to?: RedirectTo }) => {
  const memorizedShopIdRef = useRef<string | null>();
  const [currentPath, setCurrentPath] = useState('');
  const [loading, setLoading] = useState(false);
  const { platform } = usePlatform();

  const shopId = getItem('shopId');
  const isWeb = getItem('clientType') === 'web';
  const isLogin = /^\/(login|find-password)$/.test(currentPath);

  useEffect(() => {
    memorizedShopIdRef.current = shopId;
    setCurrentPath(window.location.pathname);
  }, []);

  const channel = new Channel('channel');

  const callback = async e => {
    const { shopId: channelShopId, platform: channelPlatform } = e;
    const isGuide = currentPath.includes(routeConfig.guide.path);

    if (
      isWeb &&
      !isGuide &&
      (String(channelShopId) !== memorizedShopIdRef.current ||
        platform !== channelPlatform) &&
      isShopPage(currentPath)
    ) {
      const confirm = await Modal.info({
        title: '提示',
        content: '当前页面已过期，请刷新页面重试',
        okText: '立即刷新'
      });
      if (confirm) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (isEnterprisePage(currentPath)) {
      setItem('pageAuthority', 'mainAccount');
    }

    channel.addEventListener('open', callback);
    return () => {
      channel.removeEventListener('open', callback);
    };
  }, [callback]);

  const { data } = useRequest(() => staffAccountRequest.getAuthorizeConfig(), {
    onBefore: () => {
      setLoading(true);
    },
    onSuccess: () => {
      // 登录后或登录后直接输入店铺路径跳转到引导页
      if (((!shopId && !isEnterprisePage(currentPath)) || isLogin) && isWeb) {
        replace(routeConfig.guide.path);
        setCurrentPath(routeConfig.guide.path);
      }
    },
    onError: err => {
      message.error(err);
      replace(routeConfig.login.path);
    },
    onFinally: () => {
      setLoading(false);
    }
  });

  if (loading) {
    return (
      <Loading loading height="100vh">
        {React.Fragment}
      </Loading>
    );
  }

  return <App to={to} />;
});
