// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_icon_t9AwY {\n  margin-left: 8px;\n  font-size: 16px;\n  color: #3d7fff;\n  cursor: pointer;\n}\n.style_blueText_FyLES {\n  cursor: pointer;\n  color: #3d7fff;\n  position: absolute;\n  top: 32px;\n  left: 52px;\n}\n.style_unitWrapper_OxDOA {\n  display: flex;\n  position: relative;\n  top: -20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/remindContentsMutipleEditor/style.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AACF;AAEA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AAAF;AAGA;EACE,aAAA;EACA,kBAAA;EACA,UAAA;AADF","sourcesContent":[".icon {\n  margin-left: 8px;\n  font-size: 16px;\n  color: #3d7fff;\n  cursor: pointer;\n}\n\n.blueText {\n  cursor: pointer;\n  color: #3d7fff;\n  position: absolute;\n  top: 32px;\n  left: 52px;\n}\n\n.unitWrapper {\n  display: flex;\n  position: relative;\n  top: -20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "style_icon_t9AwY",
	"blueText": "style_blueText_FyLES",
	"unitWrapper": "style_unitWrapper_OxDOA"
};
export default ___CSS_LOADER_EXPORT___;
