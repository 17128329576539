import { RouteConfig } from '@/types/route';
import { Permission } from '@/modules/cache/type';

export const enterpriseRouteConfig = {
  // 主账号企业管理菜单
  staffManage: { path: '/staffManage' },
  realTimeLogin: { path: '/real-time-login' },
  subAccounOperationsLog: { path: '/sub-account-operations-log' }
};

export const shopRouteConfig = {
  // 跟单数据
  dunningBuyStatistics: {
    path: '/statistics/dunning-buy-statistics',
    auth: [Permission.REMIND_BUY]
  },
  dunningPayStatistics: {
    path: '/statistics/dunning-pay-statistics',
    auth: [Permission.REMIND_PAY]
  },
  dunningPayStatisticsRemindPay: {
    path: '/statistics/dunning-pay-statistics/remind-pay'
  },
  dunningPayStatisticsRemindFinalPay: {
    path: '/statistics/dunning-pay-statistics/remind-final-pay'
  },
  paymentPushStatistics: {
    path: '/statistics/payment-push-statistics',
    auth: [Permission.PAYMENT_PUSH]
  },
  consignPushStatistics: {
    path: '/statistics/consign-push-statistics',
    auth: [Permission.CONSIGN_PUSH]
  },
  logisticStagnatePushStatistic: {
    path: '/statistics/logistic-stagnate-push-statistic',
    auth: [Permission.LOGISTIC_STAGNATE_PUSH]
  },
  deliveryPushStatistics: {
    path: '/statistics/delivery-push-statistics',
    auth: [Permission.DELIVERY_PUSH]
  },
  signPushStatistics: {
    path: '/statistics/sign-push-statistics',
    auth: [Permission.SIGNED]
  },
  signPushStatisticsPush: {
    path: '/statistics/sign-push-statistics/push'
  },
  signPushStatisticsConversion: {
    path: '/statistics/sign-push-statistics/conversion'
  },
  signPushStatisticsInvite: {
    path: '/statistics/sign-push-statistics/invite'
  },
  receivedPushStatistics: {
    path: '/statistics/received-push-statistics',
    auth: [Permission.RECEIVED]
  },
  receivedPushStatisticsPush: {
    path: '/statistics/received-push-statistics/push'
  },
  receivedPushStatisticsInvite: {
    path: '/statistics/received-push-statistics/invite'
  },
  receivedPushStatisticsConversion: {
    path: '/statistics/received-push-statistics/conversion'
  },
  refundPushStatistics: {
    path: '/statistics/refund-push-statistics',
    auth: [Permission.REFUND_PUSH]
  },
  exchangePushStatistics: {
    path: '/statistics/exchange-push-statistics',
    auth: [Permission.EXCHANGE_PUSH]
  },
  tradeNopaidClosedPushStatistics: {
    path: '/statistics/trade-nopaid-closed-push-statistics',
    auth: [Permission.TRADE_NOPAID_CLOSED_PUSH]
  },

  // 跟单助手
  dunningBuyStrategy: {
    path: '/strategy/dunning-buy-strategy',
    auth: [Permission.REMIND_BUY]
  },
  dunningBuyStrategyList: { path: '/strategy/dunning-buy-strategy/list' },
  dunningBuySetting: { path: '/strategy/dunning-buy-strategy/setting' },

  dunningPayStrategy: {
    path: '/strategy/dunning-pay-strategy',
    auth: [Permission.REMIND_PAY]
  },
  dunningPayStrategyList: { path: '/strategy/dunning-pay-strategy/list' },
  dunningPaySetting: { path: '/strategy/dunning-pay-strategy/setting' },
  dunningFinalPaymentList: {
    path: '/strategy/dunning-pay-strategy/finalPaymentList'
  },
  dunningFinalPaymentSetting: {
    path: '/strategy/dunning-pay-strategy/finalPaymentSetting'
  },

  paymentPushStrategy: {
    path: '/strategy/payment-push-strategy',
    auth: [Permission.PAYMENT_PUSH]
  },
  paymentPushStrategyList: { path: '/strategy/payment-push-strategy/list' },
  paymentPushSetting: { path: '/strategy/payment-push-strategy/setting' },

  frontPaidPaymentPushStrategyList: {
    path: '/strategy/payment-push-strategy/frontPaidList'
  },
  frontPaidPaymentPushSetting: {
    path: '/strategy/payment-push-strategy/frontPaidSetting'
  },
  finalPaidPaymentPushStrategyList: {
    path: '/strategy/payment-push-strategy/finalPaidList'
  },
  finalPaidPaymentPushSetting: {
    path: '/strategy/payment-push-strategy/finalPaidSetting'
  },

  consignPushStrategy: {
    path: '/strategy/consign-push-strategy',
    auth: [Permission.CONSIGN_PUSH]
  },
  consignPushStrategyList: { path: '/strategy/consign-push-strategy/list' },
  consignPushStrategySetting: {
    path: '/strategy/consign-push-strategy/setting'
  },

  logisticStagnatePushStrategy: {
    path: '/strategy/logistic-stagnate-push-strategy',
    auth: [Permission.LOGISTIC_STAGNATE_PUSH]
  },
  logisticStagnatePushStrategyList: {
    path: '/strategy/logistic-stagnate-push-strategy/list'
  },
  logisticStagnatePushStrategySetting: {
    path: '/strategy/logistic-stagnate-push-strategy/setting'
  },

  deliveryPushStrategy: {
    path: '/strategy/delivery-push-strategy',
    auth: [Permission.DELIVERY_PUSH]
  },
  deliveryPushStrategyList: { path: '/strategy/delivery-push-strategy/list' },
  deliveryPushStrategySetting: {
    path: '/strategy/delivery-push-strategy/setting'
  },

  signPushStrategy: {
    path: '/strategy/sign-push-strategy',
    auth: [Permission.SIGNED]
  },
  signPushStrategyList: { path: '/strategy/sign-push-strategy/list' },
  signPushStrategySetting: { path: '/strategy/sign-push-strategy/setting' },

  receivedPushStrategy: {
    path: '/strategy/received-push-strategy',
    auth: [Permission.RECEIVED]
  },
  receivedPushStrategyList: { path: '/strategy/received-push-strategy/list' },
  receivedPushStrategySetting: {
    path: '/strategy/received-push-strategy/setting'
  },

  refundPushStrategy: {
    path: '/strategy/refund-push-strategy',
    auth: [Permission.REFUND_PUSH]
  },
  refundPushStrategyList: { path: '/strategy/refund-push-strategy/list' },
  refundPushSetting: { path: '/strategy/refund-push-strategy/setting' },
  refundPushGuideList: { path: '/strategy/refund-push-strategy/guideList' },
  refundPushGuideSetting: {
    path: '/strategy/refund-push-strategy/guideSetting'
  },
  refundPushUrgeReturnList: {
    path: '/strategy/refund-push-strategy/urgeReturnList'
  },
  refundPushUrgeReturnSetting: {
    path: '/strategy/refund-push-strategy/urgeReturnSetting'
  },
  refundSuccessList: {
    path: '/strategy/refund-push-strategy/refundSuccessList'
  },
  refundSuccessSetting: {
    path: '/strategy/refund-push-strategy/refundSuccessSetting'
  },

  exchangePushStrategy: {
    path: '/strategy/exchange-push-strategy',
    auth: [Permission.EXCHANGE_PUSH]
  },
  exchangePushStrategyList: {
    path: '/strategy/exchange-push-strategy/list'
  },
  exchangePushStrategySetting: {
    path: '/strategy/exchange-push-strategy/setting'
  },

  tradeNopaidClosedPushStrategy: {
    path: '/strategy/trade-nopaid-closed-push-strategy',
    auth: [Permission.TRADE_NOPAID_CLOSED_PUSH]
  },
  tradeNopaidClosedPushStrategyList: {
    path: '/strategy/trade-nopaid-closed-push-strategy/list'
  },
  tradeNopaidClosedPushStrategySetting: {
    path: '/strategy/trade-nopaid-closed-push-strategy/setting'
  },
  // 接待设置
  replyTimeoutPush: {
    path: '/receive/reply-timeout-push',
    auth: [Permission.REPLY_TIMEOUT_PUSH]
  },
  replyTimeoutPushList: { path: '/receive/reply-timeout-push/list' },
  replyTimeoutPushSetting: { path: '/receive/reply-timeout-push/setting' },

  transferWordConfig: {
    path: '/receive/transfer-word-config',
    auth: [Permission.TRANSFER_WORD_PUSH]
  },
  transferWordConfigList: { path: '/receive/transfer-word-config/list' },
  transferWordConfigSetting: { path: '/receive/transfer-word-config/setting' },

  // 服务邀评
  inviteRatestrategy: {
    path: '/invite-rate/strategy',
    auth: [Permission.INVITE_RATE]
  },
  inviteRateStrategyList: { path: '/invite-rate/strategy/list' },
  inviteRateStrategySetting: { path: '/invite-rate/strategy/setting' },
  inviteRateStatistic: {
    path: '/invite-rate/statistic',
    auth: [Permission.INVITE_RATE]
  },

  // 营销增收
  buyerGroupManage: {
    path: '/marketing/buyer-group-manage',
    auth: [Permission.MEMBER_MARKETING]
  },
  buyerGroupManageList: { path: '/marketing/buyer-group-manage/list' },
  buyerGroupManageSetting: { path: '/marketing/buyer-group-manage/setting' },

  buyerMemberMarket: {
    path: '/marketing/buyer-member-market',
    auth: [Permission.MEMBER_MARKETING]
  },
  buyerMemberMarketList: { path: '/marketing/buyer-member-market/list' },
  buyerMemberMarketSetting: { path: '/marketing/buyer-member-market/setting' },
  buyerMemberMarketStatistic: {
    path: '/marketing/buyer-member-market/statistic'
  },
  buyerMemberMarketStats: {
    path: '/marketing/member-market-stats',
    auth: [Permission.MEMBER_MARKETING]
  },

  // 快捷通知
  memberMarketing: {
    path: '/quickNotification/member-marketing',
    auth: [Permission.MEMBER_MARKETING_PUSH]
  },
  memberMarketingList: { path: '/quickNotification/member-marketing/list' },
  memberMarketingSetting: {
    path: '/quickNotification/member-marketing/setting'
  },
  memberMarketingStatistic: {
    path: '/quickNotification/member-marketing/statistic'
  },

  tradeBatchPush: {
    path: '/quickNotification/trade-batch-push',
    auth: [Permission.TRADE_BATCH_PUSH]
  },
  tradeBatchPushList: { path: '/quickNotification/trade-batch-push/list' },
  tradeBatchPushEdit: { path: '/quickNotification/trade-batch-push/edit' },
  tradeBatchPushStatistic: {
    path: '/quickNotification/trade-batch-push/statistic'
  },

  // 商品搭配
  itemMatch: {
    path: '/itemRecommend/item-match',
    auth: [Permission.ITEM_RECOMMEND]
  },
  itemMatchList: { path: '/itemRecommend/item-match/list' },
  itemMatchSetting: { path: '/itemRecommend/item-match/setting' },
  itemConfig: {
    path: '/itemRecommend/item-config',
    auth: [Permission.ITEM_RECOMMEND]
  },
  itemConfigConsult: { path: '/itemRecommend/item-config/consult' },
  itemConfigPay: { path: '/itemRecommend/item-config/pay' },
  itemStatistics: {
    path: '/itemRecommend/item-statistics',
    auth: [Permission.ITEM_RECOMMEND]
  },
  itemStatisticsConsult: { path: '/itemRecommend/item-statistics/consult' },
  itemStatisticsPaid: { path: '/itemRecommend/item-statistics/paid' },

  // 系统设置
  itemGroupManage: { path: '/setting/item-group-manage' },
  cusTeamManage: { path: '/setting/cus-team-manage' },
  blacklistManage: { path: '/setting/blacklistManage' },
  subAccountPermissions: { path: '/setting/sub-account-permissions' },
  subAccountOnlineStatus: { path: '/setting/sub-account-online-status' },
  generalSetting: { path: '/setting/general-setting' },
  operationLog: { path: '/setting/operationLog' },

  login: {
    path: '/login'
  },
  findPassword: {
    path: '/find-password'
  },
  guide: {
    path: '/guide'
  }
};

const routeConfig = { ...shopRouteConfig, ...enterpriseRouteConfig };

const pathToAuthEntries = Object.values(routeConfig).map(
  ({ path, auth }: RouteConfig) => [path, auth]
);

export const pathToAuth = Object.fromEntries(pathToAuthEntries);

export default routeConfig;
