import { Http, http } from '@/libs/http';
import {
  RecordDetailListParams,
  RecordDetailListResult,
  StatsListParams,
  StatsListResult
} from './type';

export class TradeNopaidClosedPushStatsRequest {
  private http: Http = http('/api/shop/trade-nopaid-closed');

  // 查询明细接口
  recordList(params: RecordDetailListParams): Promise<RecordDetailListResult> {
    return this.http
      .path('record-list')
      .setRequestBody(params)
      .post<RecordDetailListResult>();
  }

  // 查询统计数据接口
  statsList(params: StatsListParams): Promise<StatsListResult> {
    return this.http
      .path('stats-list-v2')
      .setRequestBody(params)
      .post<StatsListResult>();
  }
}

export default new TradeNopaidClosedPushStatsRequest();
