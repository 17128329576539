import React, { memo } from 'react';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { EllipsisConfig } from 'antd/lib/typography/Base/index';

export const Paragraph = memo((props: Omit<ParagraphProps, 'ellipsis'> & { ellipsis?: EllipsisConfig }) => {
  const { style, ellipsis, ...rest } = props;

  // 如果不加onEllipsis有的时候没有溢出但是超过一行时也会显示tooltip
  return <Typography.Paragraph ellipsis={{ onEllipsis: () => {}, ...ellipsis }} style={{ marginBottom: '0px', ...style }} {...rest} />;
});
