import { Http, http } from '@/libs/http';
import { ClientDownloadInfoDto, LoginUser, SwitchUser } from './type';

export class GlobalRequest {
  private http: Http = http('/api');

  open(): Promise<LoginUser> {
    return this.http.path('/open-app').post<LoginUser>();
  }

  getSwitchUser(): Promise<SwitchUser> {
    return this.http.path('/get-current-user').post<SwitchUser>();
  }

  switch(userId: number): Promise<void> {
    return this.http
      .path('/switch-check')
      .setRequestParams({ switch_user_id: userId })
      .post<void>();
  }

  getAppkey(): Promise<string> {
    return this.http.path('/system-setting/app-key').get<string>();
  }

  getDownloadInfo(): Promise<ClientDownloadInfoDto> {
    return this.http.path('/client-version-info/download-info').post();
  }
}

export default new GlobalRequest();
