import type {
  BuyerNickSourceType,
  FileResultWebDto,
  TaobaoTidAndValidDtoRes,
  TaobaoTidBatchCheckDto
} from '@/requests/tardeBatchPush/type';
import routes from '@/routeConfig';
import { push } from '@/libs/history';
import { message } from '@/components/widgets/message';
import type { EditPageQuery, StatisticPageQuery } from './type';

export function toListPage(): void {
  push(routes.tradeBatchPushList.path);
}

export function toEditPage(query?: EditPageQuery): void {
  push(routes.tradeBatchPushEdit.path, query);
}

export function toStatisticPage(query: StatisticPageQuery): void {
  push(routes.tradeBatchPushStatistic.path, query);
}

export async function clipboardCopy(value: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(value);
    message.success('复制成功');
  } catch {
    message.error('复制失败');
  }
}

export function getExt(str: string): string {
  const index = str.lastIndexOf('.');
  if (index === -1) {
    return str;
  }
  return str.slice(index + 1);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function variableSelectorOptions(v?: string[]) {
  if (!v || !v.length) {
    return [];
  }

  return v.map(i => ({ value: i }));
}

export function getFileResult(params: {
  sourceType: BuyerNickSourceType;
  unifiedContent: boolean;
  flie?: FileResultWebDto;
  singleFile?: FileResultWebDto;
}): FileResultWebDto | undefined {
  const { sourceType, unifiedContent, flie, singleFile } = params;

  if (unifiedContent && sourceType === 'MANUAL_ENTRY') {
    return undefined;
  }

  if (unifiedContent && sourceType === 'FILE') {
    return flie;
  }

  return singleFile;
}

export function getTaobaoTids(params: {
  sourceType: BuyerNickSourceType;
  unifiedContent: boolean;
  taobaoTidCheckResultForFile: TaobaoTidBatchCheckDto;
  taobaoTidCheckResultForText: TaobaoTidBatchCheckDto;
  taobaoTidCheckResultForSingleFile: TaobaoTidBatchCheckDto;
}): TaobaoTidAndValidDtoRes[] {
  const {
    sourceType,
    unifiedContent,
    taobaoTidCheckResultForFile,
    taobaoTidCheckResultForText,
    taobaoTidCheckResultForSingleFile
  } = params;

  const formatTaobaoTids = (taobaoTids: TaobaoTidAndValidDtoRes[]) => {
    return taobaoTids.flatMap(i => (i.valid && !i.repeatBuyerTrade ? [i] : []));
  };

  if (unifiedContent && sourceType === 'MANUAL_ENTRY') {
    return formatTaobaoTids(taobaoTidCheckResultForText.taobaoTids);
  }

  if (unifiedContent && sourceType === 'FILE') {
    return formatTaobaoTids(taobaoTidCheckResultForFile.taobaoTids);
  }

  if (!unifiedContent) {
    return formatTaobaoTids(taobaoTidCheckResultForSingleFile.taobaoTids);
  }

  return [];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCheckResult(params: {
  sourceType: BuyerNickSourceType;
  unifiedContent: boolean;
  checkResult: TaobaoTidBatchCheckDto;
}) {
  const { sourceType, unifiedContent, checkResult } = params;

  if (unifiedContent && sourceType === 'MANUAL_ENTRY') {
    return { taobaoTidCheckResultForText: checkResult };
  }

  if (unifiedContent && sourceType === 'FILE') {
    return { taobaoTidCheckResultForFile: checkResult };
  }

  return { taobaoTidCheckResultForSingleFile: checkResult };
}
