import React, { Fragment, ReactNode, memo } from 'react';
import classNames from 'classnames';
import { FormLike, IOS } from '@/components/widgets/formLike';
import {
  ConditionUnit,
  SearchConditionUnit
} from '@/components/works/components/conditionUnit';
import { Input } from '@/components/widgets/input';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import {
  Title,
  SignTypeRadio,
  FormErrorRender,
  TransferWordTypeRadio
} from './components';
import { RefundReasonSelector } from './components/refundReason';
import { RefundTypeSelector } from './components/refundType';
import { ExchangeReasonSelector } from './components/exchangeReason';
import { ValidPaymentSelector } from './components/validPayment';
import { ValidDateTimeSelector } from './components/validDateTime';
import { ConsignTypesCheckbox } from './components/consignTypes';
import { RemindContentsEditor } from './components/remindContentsEditor';
import { StrategySettingTemplatePorps } from './type';
import css from './style.less';
import { Checkbox } from '@/components/widgets/checkbox';
import {
  HumanSendAccountSelector,
  SendAccountSelector
} from '@/components/works/sendAccount';
import {
  HumanSendAccountSelectorJD,
  SendAccountSelectorJD
} from '@/components/works/sendAccountJD';
import { AccountRadioSelector } from './components/accountRadioSelector';
import { EffectTyepRadio } from './components/effectType';
import { ItemSelector } from './components/itemSelector';
import { AddressesSelector } from './components/addressesSelector';
import { PresaleActivity } from './components/presaleActivity';
import { TradeStatusesSelector } from './components/tradeStatusesSelector';
import { RemindContentsMutipleEditor } from './components/remindContentsMutipleEditor';
import { SellerTradeSelector } from './components/sellerTradeSelector';
import { ExcludePaidItemSelector } from './components/excludePaidItemSelector';
import { PlatformWrapper } from '../../platformWrapper';
import { usePlatform } from '@/hooks/usePlatform';

export const StrategySettingTemplate = memo(
  (props: StrategySettingTemplatePorps) => {
    const {
      formConfig,
      formValue,
      formError,
      remindType = 'REMIND_FINAL_PAY',
      cancelFormError,
      onFormChange
    } = props;
    const { isJD } = usePlatform();

    return (
      <div className={css.main}>
        <div className={css.content}>
          <FormLike value={formValue} onChange={onFormChange}>
            <Title name="基础信息" />
            {formConfig.baseInfo.map(i => {
              const { type, field, label, config } = i;

              if (type === 'strategyNameInput') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.inputItem}
                      label={label}
                      name={field}
                    >
                      <Input
                        className={classNames(
                          css.input,
                          formError?.[field] ? css.errorBorder : null
                        )}
                        showCount
                        blurTrim
                        style={{ width: 400 }}
                        maxLength={20}
                        onFocus={() => cancelFormError?.(field)}
                        {...config}
                      />
                    </ConditionUnit>
                    <FormErrorRender error={formError?.[field]} />
                  </Fragment>
                );
              }

              if (type === 'activeRadio') {
                return (
                  <ConditionUnit
                    key={field}
                    className={css.item}
                    label={label}
                    name={field}
                    io={IOS.valueIO}
                  >
                    <RadioGroup>
                      <Radio value>开启</Radio>
                      <Radio value={false}>关闭</Radio>
                    </RadioGroup>
                  </ConditionUnit>
                );
              }

              if (type === 'effectTypeRadio') {
                return <EffectTyepRadio key={field} field={field} />;
              }

              return null;
            })}

            <Title name="限定条件" />
            {formConfig.limitConditions.map(i => {
              const { type, label, field } = i;

              if (type === 'validateTimeSelector') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      label={label}
                      name={field}
                    >
                      <ValidDateTimeSelector />
                    </ConditionUnit>
                    <FormErrorRender error={formError?.[field]} />
                  </Fragment>
                );
              }

              if (type === 'presaleActivity') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      label={label}
                      name={field}
                    >
                      <PresaleActivity remindType={remindType} />
                    </ConditionUnit>
                    <FormErrorRender error={formError?.[field]} />
                  </Fragment>
                );
              }

              if (type === 'itemSelector') {
                return (
                  <ConditionUnit
                    className={css.item}
                    key={field}
                    label={label}
                    name={field}
                  >
                    <ItemSelector label={label} remindType={remindType} />
                  </ConditionUnit>
                );
              }

              if (type === 'validPaymentSelector') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      label={label}
                      name={field}
                    >
                      <ValidPaymentSelector
                        cancelFormError={() => cancelFormError?.(field)}
                      />
                    </ConditionUnit>
                    <FormErrorRender error={formError?.[field]} />
                  </Fragment>
                );
              }

              if (type === 'addressesSelector') {
                return (
                  <ConditionUnit
                    className={css.item}
                    key={field}
                    label={label}
                    name={field}
                  >
                    <AddressesSelector />
                  </ConditionUnit>
                );
              }

              if (type === 'sellerTradeSelector') {
                return (
                  <ConditionUnit
                    className={css.item}
                    key={field}
                    label={label}
                    name={field}
                  >
                    <SellerTradeSelector />
                  </ConditionUnit>
                );
              }

              // jd发货提醒不显示但需要传值
              if (type === 'consignTypesCheckbox' && !isJD) {
                return (
                  <ConditionUnit
                    className={css.item}
                    key={field}
                    label={label}
                    name={field}
                  >
                    <ConsignTypesCheckbox />
                  </ConditionUnit>
                );
              }

              if (type === 'refundReasonSelector') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      key={field}
                      label={label}
                      name={field}
                    >
                      <RefundReasonSelector
                        cancelFormError={() => cancelFormError?.(field)}
                      />
                    </ConditionUnit>
                    <FormErrorRender error={formError?.[field]} />
                  </Fragment>
                );
              }

              if (type === 'refundTypeSelector') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      key={field}
                      label={label}
                      name={field}
                    >
                      <RefundTypeSelector
                        cancelFormError={() => cancelFormError?.(field)}
                      />
                    </ConditionUnit>
                    <FormErrorRender error={formError?.[field]} />
                  </Fragment>
                );
              }

              if (type === 'signTypeRadio') {
                return (
                  <ConditionUnit
                    className={css.item}
                    key={field}
                    label={label}
                    name={field}
                  >
                    <SignTypeRadio />
                  </ConditionUnit>
                );
              }

              if (type === 'exchangeReasonSelector') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      key={field}
                      label={label}
                      name={field}
                    >
                      <ExchangeReasonSelector
                        cancelFormError={() => cancelFormError?.(field)}
                      />
                    </ConditionUnit>
                    <FormErrorRender error={formError?.[field]} />
                  </Fragment>
                );
              }

              if (type === 'transferWordTypeRadio') {
                return (
                  <ConditionUnit
                    className={css.item}
                    key={field}
                    label={label}
                    name={field}
                  >
                    <TransferWordTypeRadio />
                  </ConditionUnit>
                );
              }

              if (type === 'accountRadioSelector') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      key={field}
                      label={label}
                      name={field}
                    >
                      <AccountRadioSelector
                        status={formError?.[field] ? 'error' : ''}
                        cancelFormError={() => cancelFormError?.(field)}
                      />
                    </ConditionUnit>
                  </Fragment>
                );
              }

              if (type === 'tradeStatusesSelector') {
                return (
                  <Fragment key={field}>
                    <ConditionUnit
                      className={css.item}
                      key={field}
                      label={label}
                      name={field}
                    >
                      <TradeStatusesSelector />
                    </ConditionUnit>
                  </Fragment>
                );
              }

              return null;
            })}

            {formConfig.filterConditions?.length ? (
              <>
                <Title name="过滤条件" />
                {formConfig.filterConditions.map(i => {
                  const { type, field } = i;

                  if (type === 'excludePaidItemSelector') {
                    return (
                      <SearchConditionUnit
                        showLabel={false}
                        key={field}
                        className={css.hideLabelUnit}
                        name={field}
                      >
                        <ExcludePaidItemSelector
                          limitDayError={formError?.[field]}
                          remindType={remindType}
                          cancelFormError={() => cancelFormError?.(field)}
                        />
                      </SearchConditionUnit>
                    );
                  }

                  return null;
                })}
              </>
            ) : null}

            {formConfig.sendAccount.length ? (
              <>
                <Title name="发送客服" />
                {formConfig.sendAccount.map(i => {
                  const { type, field, label } = i;

                  if (type === 'humanSendAccountSelector') {
                    return (
                      <PlatformWrapper
                        component={{
                          TB: (
                            <ConditionUnit
                              key={field}
                              className={css.contentUnit}
                              label={label}
                              name={field}
                            >
                              <HumanSendAccountSelector />
                            </ConditionUnit>
                          ),
                          JD: (
                            <ConditionUnit
                              key={field}
                              className={css.contentUnit}
                              label={label}
                              name={field}
                            >
                              <HumanSendAccountSelectorJD />
                            </ConditionUnit>
                          )
                        }}
                      />
                    );
                  }

                  if (type === 'sendAccountSelector') {
                    return (
                      <PlatformWrapper
                        component={{
                          TB: (
                            <ConditionUnit
                              key={field}
                              className={css.contentUnit}
                              label={label}
                              name={field}
                            >
                              <SendAccountSelector />
                            </ConditionUnit>
                          ),

                          JD: (
                            <ConditionUnit
                              key={field}
                              className={css.contentUnit}
                              label={label}
                              name={field}
                            >
                              <SendAccountSelectorJD />
                            </ConditionUnit>
                          )
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </>
            ) : null}

            <Title name="推送内容" />
            {formConfig.pushContent.map(i => {
              const { type, field, label } = i;

              if (type === 'filterRatedTradeCheckbox') {
                return (
                  <div className={css.contentUnit} key={field}>
                    <ConditionUnit
                      className={css.contentUnit}
                      label="排除已评价"
                      name="filterRatedTrade"
                      io={IOS.checkedIO}
                    >
                      <Checkbox>已评价的订单不推送</Checkbox>
                    </ConditionUnit>
                    <span className={css.grey}>推送内容含催评价时，请勾选</span>
                  </div>
                );
              }

              if (type === 'remindContentsEditor') {
                return (
                  <ConditionUnit
                    key={field}
                    className={css.contentUnit}
                    label={label}
                    name={field}
                  >
                    <RemindContentsEditor />
                  </ConditionUnit>
                );
              }

              if (type === 'remindContentsMultipleEditor') {
                return (
                  <ConditionUnit
                    key={field}
                    className={css.contentUnit}
                    label={label}
                    name={field}
                    showLabel={false}
                  >
                    <RemindContentsMutipleEditor />
                  </ConditionUnit>
                );
              }

              return null;
            })}
          </FormLike>
        </div>
      </div>
    );
  }
);
