import React, { memo, useEffect } from 'react';
import moment from 'moment';
import { useAgentReducer } from 'use-agent-reducer';
import classNames from 'classnames';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { Button } from '@/components/widgets/button';
import { Table, Column } from '@/components/widgets/table';
import {
  TimeBarContainer,
  TimeBar,
  TimeRuler
} from '@/components/works/timeBar';
import { LoginDayDetailWebDto } from '@/types/loginTime';
import { uniqueId } from 'lodash';
import { OnlineTimes } from '../components';
import { RecordDialogProps } from './type';
import { RecordDialogModel } from './model';
import css from './style.less';

export default memo((props: DialogDeployProps<RecordDialogProps>) => {
  const { closeDialog, params } = props;

  const {
    state: { loading, type, name, staffDataSource, enterpriseDataSource },
    init
  } = useAgentReducer(new RecordDialogModel(params));

  useEffect(() => {
    init();
  }, []);

  return (
    <Dialog
      title="在线明细"
      onCancel={closeDialog}
      width={1000}
      footer={
        <Button type="default" onClick={() => closeDialog()}>
          关闭
        </Button>
      }
      bodyStyle={{ padding: '12px 24px' }}
    >
      <div className={css.header}>
        {type === 'staff' ? <span>员工</span> : null}
        {type === 'subAccount' ? <span>账号</span> : null}
        {name ? <span>：{name}</span> : null}
        <span className={css.status}>
          <span className={css.statusOnline}>
            <div className={classNames(css.badge, css.online)} />
            在线
          </span>
          <span>
            <div className={classNames(css.badge, css.offline)} />
            离线
          </span>
        </span>
      </div>
      <Table
        dataSource={type === 'staff' ? staffDataSource : enterpriseDataSource}
        rowKey="businessDay"
        size="small"
        loading={loading}
        bordered
        pagination={{
          size: 'default',
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          showSizeChanger: false
        }}
      >
        <Column title="日期" dataIndex="businessDay" />
        <Column
          title={<TimeRuler />}
          key={uniqueId()}
          render={(value, record: LoginDayDetailWebDto) => {
            const isToday =
              moment(record.businessDay).get('date') === moment().get('date');
            const now = new Date().toISOString();

            return (
              <TimeBarContainer style={{ margin: '6px 14px' }}>
                <TimeBar bgColor="#f0f2f5" end={isToday ? now : undefined} />
                {record.details.map(({ loginTime, lastOnlineTime }) => (
                  <TimeBar
                    key={uniqueId()}
                    bgColor="#3d7eff"
                    start={loginTime}
                    end={lastOnlineTime}
                    tooltip={`在线时间：${moment(loginTime).format(
                      'HH:mm:ss'
                    )}~${moment(lastOnlineTime).format('HH:mm:ss')}`}
                  />
                ))}
              </TimeBarContainer>
            );
          }}
        />
        <Column
          title="在线时长"
          key="onlineSeconds"
          render={(value, record: LoginDayDetailWebDto) => (
            <OnlineTimes
              times={record.details.map(item =>
                moment(item.lastOnlineTime).diff(
                  moment(item.loginTime),
                  'seconds'
                )
              )}
            />
          )}
        />
      </Table>
    </Dialog>
  );
});
