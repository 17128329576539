import { getThumbnail } from '@/utils/image';
import classNames from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';
import style from './style.less';
import useCreation from '@/hooks/useCreation';
import useUnmount from '@/hooks/useUnmount';
import { usePersistFn } from '@/hooks/usePersistFn';

type MiniImageProps = {
  src: string;
  size?: number | string;
  bordered?: boolean;
  lazyLoad?: boolean;
};

export default memo(
  ({
    src,
    size,
    bordered,
    lazyLoad,
    children,
    ...rest
  }: React.HTMLAttributes<HTMLDivElement> & MiniImageProps) => {
    const imgRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const canUseLazyLoad = IntersectionObserver && lazyLoad;
    const [url, setUrl] = useState(canUseLazyLoad ? '' : src); // 避免非懒加载状态下的二次渲染

    const intersectionObserver = useCreation(
      () =>
        canUseLazyLoad
          ? new IntersectionObserver(
              entries => {
                if (entries[0].isIntersecting) {
                  renderImg();
                }
              },
              { rootMargin: '10px' }
            )
          : undefined,
      [src]
    );

    const renderImg = usePersistFn(() => {
      if (!src) return;
      setUrl(src);
      intersectionObserver!.disconnect();
    });

    useEffect(() => {
      if (canUseLazyLoad) {
        intersectionObserver!.observe(imgRef.current!);
      } else {
        setUrl(src);
      }
    }, [src]);

    useUnmount(() => {
      if (canUseLazyLoad) {
        intersectionObserver!.disconnect();
      }
    });

    return (
      <div
        ref={imgRef}
        {...rest}
        style={{
          backgroundImage: `url(${getThumbnail(url)})`,
          width: size,
          ...rest?.style
        }}
        className={classNames(
          style.img,
          bordered ? style.bordered : '',
          rest.className
        )}
      >
        {children}
      </div>
    );
  }
);
