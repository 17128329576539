import React, { memo, useEffect, useState } from 'react';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { Select } from '@/components/widgets/select';
import { RadioChangeEvent } from 'antd/lib/radio';
import { warnError } from '@/components/infos';
import { RefundReasonDto } from '@/requests/shop/type';
import shopRequest from '@/requests/shop';
import css from './style.less';
import { CustomType } from '../type';

type ExchangeReasons = {
  type: CustomType;
  reasons: number[];
};

export type ExchangeReasonSelectorProps = {
  value?: number[];
  onChange?: (value: number[]) => void;
  cancelFormError?: () => void;
};

export const ExchangeReasonSelector = memo(
  (props: ExchangeReasonSelectorProps) => {
    const { value = [], onChange, cancelFormError } = props;

    const [exchangeReasonList, setExchangeReasonList] = useState<
      RefundReasonDto[]
    >([]);
    const [exchangeReasons, setExchangeReasons] = useState<ExchangeReasons>({
      type: 'ALL',
      reasons: []
    });

    const init = async () => {
      try {
        const list = await shopRequest.exchangeReasonList();
        setExchangeReasonList(list);
      } catch (error) {
        warnError(error);
      }
    };

    useEffect(() => {
      if (!value.length) {
        return;
      }
      setExchangeReasons({
        type: value.length ? 'FIXED' : 'ALL',
        reasons: value
      });
    }, [value]);

    useEffect(() => {
      init();
    }, []);

    const handleTypeChange = (e: RadioChangeEvent) => {
      cancelFormError?.();
      setExchangeReasons({ ...exchangeReasons, type: e.target.value });
      onChange?.(e.target.value === 'ALL' ? [] : exchangeReasons.reasons);
    };

    const handleReaseonChange = (v: number[]) => {
      cancelFormError?.();
      setExchangeReasons({ ...exchangeReasons, reasons: v });
      onChange?.(v);
    };

    return (
      <div>
        <RadioGroup value={exchangeReasons.type} onChange={handleTypeChange}>
          <Radio value="ALL">不限</Radio>
          <Radio value="FIXED">指定换货原因</Radio>
        </RadioGroup>
        {exchangeReasons.type === 'FIXED' ? (
          <div className={css.item}>
            <Select
              mode="multiple"
              className={css.multipleSelect}
              placeholder="请选择换货原因"
              options={exchangeReasonList.map(reaseon => ({
                label: reaseon.reasonDesc,
                value: reaseon.id
              }))}
              value={exchangeReasons.reasons}
              onChange={handleReaseonChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
);
