import { FormItem } from '@/components/widgets/formLike';
import React, { memo } from 'react';
import { ConditionUnitProps } from './type';
import css from './style.less';
import { Tooltip } from '@/components/widgets/tooltip';
import { QuestionCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

const ConditionUnit = memo(
  ({
    label,
    name,
    children,
    className,
    labelClassName,
    description,
    noWrapper,
    required,
    ...rest
  }: ConditionUnitProps) => {
    const ctn = (
      <>
        {label ? (
          <span className={classNames(css.label, labelClassName)}>
            {required ? <span style={{ color: '#f23c3c' }}>*</span> : null}
            {label}
            {description ? (
              <Tooltip title={description}>
                <QuestionCircleOutlined className={css.icon} />
              </Tooltip>
            ) : null}
            {[':', '：'].some(s => label.endsWith(s)) ? null : '：'}
          </span>
        ) : null}
        <FormItem {...rest} name={name}>
          {children}
        </FormItem>
      </>
    );

    return noWrapper ? ctn : <div className={className}>{ctn}</div>;
  }
);

export default ConditionUnit;
