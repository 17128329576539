import { Model, strict, act, flow, Flows } from 'agent-reducer';
import { formatDayFromNow, isValidDateRange } from '@/utils/date';
import { uiPrompt } from '@/avatars';
import { DeliveryPushStatsState } from './type';
import logisticsPushStatsRequest from '@/requests/logisticsPushStats';
import exportCenterRequest from '@/requests/exportCenter';
import {
  LogisticsPushDetailRecordWebDto,
  LogisticsPushStatsWebDto,
  LogisticsPushStrategyStatsWebDto
} from '@/requests/logisticsPushStats/type';
import { validateDateRange } from '@/services/formatStatsData';
import { PageDetail } from '@/types/pageDetail';

const taskType = 'DELIVERY_PUSH';
const remindType = 'DELIVERY_PUSH';

const initStatsData: LogisticsPushStatsWebDto = {
  valueList: [],
  avgMap: {},
  sumMap: {}
};

@strict()
export class DeliveryPushStatsModel implements Model<DeliveryPushStatsState> {
  state: DeliveryPushStatsState = {
    remindType,
    statsLoading: false,
    statsTableLoading: false,
    recordLoading: false,
    dateRange: [formatDayFromNow(-6), formatDayFromNow(0)],
    statsTabKey: 'time',
    strategyStatsList: initStatsData,
    stats: initStatsData,
    statsPage: 1,
    recordList: [],
    recordPage: 1,
    recordPageSize: 10,
    recordTotal: 0,
    recordSearchParams: {
      from: formatDayFromNow(-6),
      to: formatDayFromNow(0)
    }
  };

  @act()
  private loadStats(): DeliveryPushStatsState {
    return { ...this.state, statsLoading: true };
  }

  @act()
  private unloadStats(): DeliveryPushStatsState {
    return { ...this.state, statsLoading: false };
  }

  @act()
  private statsTableLoad(): DeliveryPushStatsState {
    return { ...this.state, statsTableLoading: true };
  }

  @act()
  private statsTableUnload(): DeliveryPushStatsState {
    return { ...this.state, statsTableLoading: false };
  }

  @act()
  private loadRecord(): DeliveryPushStatsState {
    return { ...this.state, recordLoading: true };
  }

  @act()
  private unloadRecord(): DeliveryPushStatsState {
    return { ...this.state, recordLoading: false };
  }

  @act()
  private changeState(
    state: Partial<DeliveryPushStatsState>
  ): DeliveryPushStatsState {
    return { ...this.state, ...state };
  }

  @act()
  changeStatsTabKey(statsTabKey: string): DeliveryPushStatsState {
    return { ...this.state, statsTabKey };
  }

  @act()
  changeStatsPage(statsPage: number): DeliveryPushStatsState {
    return { ...this.state, statsPage };
  }

  private fetchDetailRecord(
    params
  ): Promise<PageDetail<LogisticsPushDetailRecordWebDto>> {
    const { remindType, recordSearchParams, recordPage, recordPageSize } =
      this.state;

    return logisticsPushStatsRequest.recordList({
      remindType,
      page: recordPage,
      pageSize: recordPageSize,
      ...recordSearchParams,
      ...params
    });
  }

  private fetchStats(): Promise<LogisticsPushStatsWebDto> {
    const { remindType, dateRange } = this.state;
    const [from, to] = dateRange;

    return logisticsPushStatsRequest.statsList({ remindType, from, to });
  }

  private fetchStrategyStatsList(): Promise<LogisticsPushStrategyStatsWebDto> {
    const {
      dateRange: [from, to],
      subStrategyId
    } = this.state;
    return logisticsPushStatsRequest.strategyStatsList({
      from,
      to,
      subStrategyId,
      taskType
    });
  }

  @flow(Flows.latest())
  async initStats(): Promise<void> {
    this.loadStats();
    try {
      const stats = await this.fetchStats();

      this.changeState({ stats, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unloadStats();
    }
  }

  @flow(Flows.latest())
  async initStrategyStatsList(): Promise<void> {
    this.statsTableLoad();
    try {
      const strategyStatsList = await this.fetchStrategyStatsList();

      this.changeState({ strategyStatsList, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.statsTableUnload();
    }
  }

  @flow(Flows.latest())
  async initRecord(): Promise<void> {
    this.loadRecord();

    try {
      const detailRecord = await this.fetchDetailRecord({ page: 1 });
      const { dataList, totalCount } = detailRecord;
      this.changeState({
        recordList: dataList,
        recordTotal: totalCount,
        recordPage: 1
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unloadRecord();
    }
  }

  @flow(Flows.latest())
  handleDateRangeChange(
    dateRange: [string | undefined, string | undefined]
  ): void {
    if (!isValidDateRange(dateRange)) {
      return;
    }

    const validDateRange = validateDateRange(dateRange);
    if (!validDateRange) {
      return;
    }

    this.changeState({ dateRange: validDateRange });
    Promise.all([this.initStats(), this.initStrategyStatsList()]);
  }

  @flow(Flows.latest())
  async handleStrategyStatsListSearch(subStrategyId?: number): Promise<void> {
    this.changeState({ subStrategyId });

    const { dateRange } = this.state;
    const validDateRange = validateDateRange(dateRange);

    if (!validDateRange) {
      return;
    }

    this.initStrategyStatsList();
  }

  @flow()
  async handleDetailSearch(value): Promise<void> {
    this.loadRecord();
    const { range, ...rest } = value;
    this.changeState({
      recordPage: 1,
      recordSearchParams: { from: range[0], to: range[1], ...rest }
    });

    try {
      const { dataList, totalCount } = await this.fetchDetailRecord({
        page: 1
      });
      this.changeState({ recordList: dataList, recordTotal: totalCount });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unloadRecord();
    }
  }

  @flow(Flows.latest())
  async handleRecordPageChange(page: number, pageSize: number): Promise<void> {
    this.loadRecord();
    try {
      const { dataList, totalCount } = await this.fetchDetailRecord({
        page,
        pageSize
      });

      this.changeState({
        recordList: dataList,
        recordTotal: totalCount,
        recordPage: page,
        recordPageSize: pageSize
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unloadRecord();
    }
  }

  async handleExportDetail(): Promise<boolean> {
    const { recordSearchParams, recordTotal } = this.state;

    if (!recordTotal) {
      return false;
    }
    await exportCenterRequest.saveRecordDetailExportTask({
      taskType,
      ...recordSearchParams
    });
    return true;
  }

  async handleExportStats(): Promise<boolean> {
    const { stats, statsTabKey, strategyStatsList, dateRange, subStrategyId } =
      this.state;
    const [from, to] = dateRange;
    if (
      (!stats.valueList.length && statsTabKey === 'time') ||
      (!strategyStatsList.valueList.length && statsTabKey === 'task')
    ) {
      return false;
    }

    const params = { from, to };

    if (statsTabKey === 'time') {
      await exportCenterRequest.createDailySendStatsExportTask({
        remindType,
        ...params
      });
    } else {
      await exportCenterRequest.createStrategySendStatsExportTask({
        taskType,
        subStrategyId,
        ...params
      });
    }

    return true;
  }
}

export const deliveryPushStatsModel = new DeliveryPushStatsModel();
