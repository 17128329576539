import { Http, http } from '@/libs/http';
import { ListParams, ListDto, OperateAccount, LogTypes } from './type';

export class OperationLogRequest {
  private http: Http = http('/api/shop/compare-log');

  logList(params: ListParams): Promise<ListDto> {
    return this.http.path('list-v2').setRequestBody(params).post<ListDto>();
  }

  listOperateAccounts(): Promise<OperateAccount[]> {
    return this.http.path('list-operate-accounts').post<OperateAccount[]>();
  }

  logTypes(): Promise<LogTypes[]> {
    return this.http.path('log-types').get<LogTypes[]>();
  }
}

export default new OperationLogRequest();