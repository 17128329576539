import { act, flow, Flows, Model, strict, weakSharing } from 'agent-reducer';
import { uiPrompt } from '@/avatars';
import { formatDayFromNow, checkRange } from '@/utils/date';
import { State } from './type';
import staffRequest from '@/requests/staff';

@strict()
export class RealTimeLoginModel implements Model<State> {
  state: State = {
    staffLoading: false,
    staffLoginStatus: [],
    staffPage: 1,
    dateRange: [formatDayFromNow(0), formatDayFromNow(0)],
    staffTotal: 0,
    staffSearchCondition: {
      loginStatus: 'ALL'
    }
  };

  @act()
  private staffLoad(): State {
    return { ...this.state, staffLoading: true };
  }

  @act()
  private staffUnload(): State {
    return { ...this.state, staffLoading: false };
  }

  @act()
  private changeState(newState: Partial<State>) {
    return { ...this.state, ...newState };
  }

  @act()
  changeStaffSearchCondition(value: State['staffSearchCondition']): State {
    return this.changeState({ staffSearchCondition: value });
  }

  @flow(Flows.latest())
  async fetchStaff(newPage?: number): Promise<void> {
    const {
      dateRange,
      staffPage,
      staffSearchCondition: { staffName, loginStatus }
    } = this.state;
    const [from, to] = dateRange;
    const page = newPage ?? staffPage;

    this.staffLoad();
    try {
      const { totalCount, dataList } = await staffRequest.enterpriseStaffLoginStatus(
        {
          staffName: staffName || undefined,
          loginStatus: loginStatus === 'ALL' ? undefined : loginStatus,
          from,
          to,
          page,
          pageSize: 10
        }
      );
      this.changeState({
        staffLoginStatus: dataList,
        staffTotal: totalCount,
        staffPage: page
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.staffUnload();
    }
  }

  @flow()
  handleDateRangeChange(range: [string | undefined, string | undefined]): void {
    const [from, to] = range;
    if (!from || !to) {
      return;
    }
    const dateRange: [string, string] = [from, to];
    if (!checkRange(dateRange, 31)) {
      uiPrompt.current.warning('最长可选31天');
      return;
    }
    this.changeState({ dateRange });
    this.fetchStaff(1);
  }
}

export const realTimeLoginRef = weakSharing(() => new RealTimeLoginModel());
