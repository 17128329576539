import React, { useEffect, useState } from 'react';
import { Icon } from '@/components/widgets/icon';
import { message } from '@/components/widgets/message';

export function IconList(): JSX.Element {
  const [iconNames, setIconNames] = useState<string[]>([]);
  const [allIconCount, setAllIconCount] = useState(0);

  useEffect(() => {
    const svgEle = document.body.firstElementChild;

    if (!svgEle) {
      return;
    }

    const names = Array.from(svgEle.children).reduce((prev, curr) => {
      const [, ...rest] = curr.getAttribute('id')?.split('-') || [];
      const type = rest.join('-');
      if (type) {
        prev.push(type);
      }
      return prev;
    }, [] as string[]);

    setAllIconCount(names.length);
    setIconNames([...new Set(names)]);
  }, []);

  const copyIconName = async (name: string) => {
    const statsu = await navigator.permissions.query({
      name: 'clipboard-write'
    });
    if (statsu.state !== 'granted') {
      message.warning('没有剪切板权限');
      return;
    }
    try {
      await navigator.clipboard.writeText(name);
      message.success('复制成功');
    } catch (error) {
      message.warning('复制失败');
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <h3>icon列表</h3>
      <div>
        <p>总数: {allIconCount}</p>
        <p>去重后: {iconNames.length}</p>
      </div>
      {iconNames.map(name => (
        <Icon
          key={name}
          type={name}
          style={{ fontSize: '40px', cursor: 'pointer' }}
          title={name}
          onClick={() => copyIconName(name)}
        />
      ))}
    </div>
  );
}
