import React, { memo, useCallback, useState } from 'react';
import {
  Axis,
  Chart,
  Tooltip,
  Interval,
  View,
  Line,
  Legend,
  getTheme
} from 'bizcharts';
import { MarketingStatsDailySummaryValueList } from '@/requests/buyerMembermarketStats/type';

export const DailySummaryChart = memo(
  (props: { dailySummaryStats: MarketingStatsDailySummaryValueList[] }) => {
    const { dailySummaryStats } = props;

    const theme = getTheme();

    const data = ['sendNum', 'tradeCreatedNum', 'tradePaidNum'].flatMap(i =>
      dailySummaryStats.map(v => {
        const { businessDay, sendNum, tradeCreatedNum, tradePaidNum } = v;
        return {
          businessDay,
          name: i,
          numValue:
            // eslint-disable-next-line no-nested-ternary
            i === 'sendNum'
              ? sendNum
              : i === 'tradeCreatedNum'
              ? tradeCreatedNum
              : tradePaidNum
        };
      })
    );

    const averageData = dailySummaryStats.map(i => ({
      businessDay: i.businessDay,
      paymentValue: i.payment,
      name: 'payment'
    }));

    const scale = {
      businessDay: {
        sync: true,
        tickCount: 30
      },
      paymentValue: {
        min: 0,
        alias: '付款金额',
        tickCount: 4,
        type: 'linear-strict'
      },
      numValue: {
        min: 0,
        tickCount: 4,
        type: 'linear-strict'
      },
      name: {
        min: 0,
        formatter: v =>
          ({
            sendNum: '发送人次',
            tradeCreatedNum: '下单人次',
            tradePaidNum: '付款人次'
          }[v])
      }
    };

    // const colors = theme.colors10;
    const colors = ['#62daaa', '#7F64FF', '#A8BCE4'];
    /**
     * 图例开关状态
     */
    const legendMap = {};
    /**
     * 图表实例
     */
    let chartIns;
    return (
      <Chart
        height={400}
        padding={[50, 60]}
        scale={scale}
        data={data}
        autoFit
        onGetG2Instance={c => (chartIns = c)}
      >
        <Tooltip shared />
        <Line
          shape="smooth"
          color={['name', colors]}
          position="businessDay*numValue"
        />
        <Axis name="numValue" position="left" />
        <View data={averageData} scale={scale} padding={0}>
          <Axis name="paymentValue" position="right" />
          <Interval position="businessDay*paymentValue" color="#6699FF" />
        </View>
        <Legend
          custom
          items={[
            {
              name: '发送人次',
              value: 'sendNum',
              marker: {
                symbol: 'circle',
                style: { fill: colors[0] }
              }
            },
            {
              name: '下单人次',
              value: 'tradeCreatedNum',
              marker: {
                symbol: 'circle',
                style: { fill: colors[1] }
              }
            },
            {
              name: '付款人次',
              value: 'tradePaidNum',
              marker: {
                symbol: 'circle',
                style: { fill: colors[2] }
              }
            },
            {
              name: '付款金额',
              value: 'payment',
              marker: {
                symbol: 'square',
                style: { fill: '#6699FF' }
              }
            }
          ]}
          onChange={ev => {
            const { item } = ev;
            const { value } = item;
            const checked = !item.unchecked;
            // 设置图例项状态
            legendMap[value] = checked;
            if (value === 'payment') {
              // 通过filter控制元素显示隐藏
              const view = chartIns.views[0];
              view.filter('name', val => legendMap[val]);
              // 重新渲染，触发filter
              view.render(true);
            } else {
              // 通过filter控制元素显示隐藏
              chartIns.filter('name', val => legendMap[val] !== false);
              // 重新渲染，触发filter
              chartIns.render(true);
            }
          }}
        />
      </Chart>
    );
  }
);
