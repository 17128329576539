import React, { memo, useEffect, useState } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { Badge } from 'antd';
import type { TableProps } from 'antd';
import { Button } from '@/components/widgets/button';
import { Table, Column } from '@/components/widgets/table';
import { Dialog, Modal } from '@/components/widgets/modal';
import { Spin } from '@/components/widgets/spin';
import { message } from '@/components/widgets/message';
import { useLoading } from '@/hooks/useLoading';
import {
  ShopExportTaskWebDto,
  ExportTaskStatus
} from '@/requests/exportCenter/type';
import { ExportTaskModel } from './model';
import type { OperationProps, ExportTaskWebDto } from './type';
import { statusMap } from './constant';
import { sortDxCreated, sortTaskStatus } from './service';
import css from './style.less';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { exportRequest } from '@/libs/http';

const Operation = memo(({ value, onDelete, onDownload }: OperationProps) => {
  const { id, taskStatus } = value;

  return (
    <div>
      {taskStatus === 'SUCCESS' ? (
        <span className={css.operationBtn} onClick={() => onDownload(id)}>
          下载
        </span>
      ) : null}
      <span className={css.operationBtn} onClick={() => onDelete([id])}>
        删除
      </span>
    </div>
  );
});

const renderStatus = (value: ExportTaskStatus) => {
  const { color, text } = statusMap[value];
  return <Badge className={css.badge} color={color} text={text} />;
};

export default memo((props: DialogDeployProps) => {
  const {
    state: { exportTasks, selectIds, field, order, pageNo, pageSize },
    fetchExportTasks,
    deleteExportTasks,
    changeSelectIds,
    changeSort,
    changePagination
  } = useAgentReducer(ExportTaskModel);
  const { closeDialog } = props;
  const loading = useLoading();
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await fetchExportTasks();
      } catch (e) {
        message.error(e);
      }
    })();
  }, []);

  const handleDelete = async (ids: number[]) => {
    try {
      if (!ids.length) {
        message.info('请选择任务进行删除');
        return;
      }
      if (
        !(await Modal.confirm({
          title: '温馨提示',
          content: `是否要删除${ids.length > 1 ? `${ids.length}个` : '该'}任务`
        }))
      ) {
        return;
      }
      await deleteExportTasks(ids);
      message.success('删除成功');
    } catch (e) {
      message.error(e);
    }
  };

  const handleDownload = async (id: number) => {
    setDownloadLoading(true);
    try {
      await exportRequest({
        url: `/api/shop/export-center/download?taskId=${id}`,
        method: 'get'
      });
    } catch (e) {
      message.error(e);
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleTableChange: TableProps<ExportTaskWebDto>['onChange'] = (
    p,
    f,
    sort
  ) => {
    if (!Array.isArray(sort)) {
      changeSort({
        field: sort.field as keyof ExportTaskWebDto,
        order: sort.order
      });
    }
  };

  return (
    <Dialog
      title="导出中心"
      width="900px"
      footer={<Button onClick={closeDialog}>关闭</Button>}
      onCancel={closeDialog}
      bodyStyle={{ padding: '12px 0 24px 0', minHeight: 200 }}
    >
      <Spin spinning={downloadLoading}>
        <div className={css.btnGroup}>
          <Button
            type="primary"
            className={css.btn}
            onClick={() => handleDelete(selectIds)}
          >
            批量删除
          </Button>
          <Button type="primary" onClick={fetchExportTasks}>
            刷新，查看最新状态
          </Button>
        </div>
        <Table
          className={css.table}
          bordered
          loading={loading}
          dataSource={exportTasks}
          rowKey="id"
          showSorterTooltip={false}
          rowSelection={{
            selectedRowKeys: selectIds,
            onChange: changeSelectIds
          }}
          onChange={handleTableChange}
          pagination={{
            current: pageNo,
            onChange: changePagination,
            pageSize,
            total: exportTasks.length,
            position: ['bottomCenter'],
            hideOnSinglePage: true,
            className: css.pagination
          }}
        >
          <Column dataIndex="taskName" title="任务" width={200} />
          <Column
            dataIndex="dxCreated"
            title="添加时间"
            width={180}
            sorter={sortDxCreated}
            defaultSortOrder="descend"
            sortOrder={field === 'dxCreated' ? order : undefined}
          />
          <Column dataIndex="createdStaff" title="添加人" width={140} />
          <Column
            dataIndex="taskStatus"
            title="状态"
            width={100}
            render={renderStatus}
            sorter={sortTaskStatus}
            sortOrder={field === 'taskStatus' ? order : undefined}
          />
          <Column
            title="操作"
            width={140}
            render={(value: ShopExportTaskWebDto) => (
              <Operation
                value={value}
                onDelete={handleDelete}
                onDownload={handleDownload}
              />
            )}
          />
        </Table>
        <div className={css.tip}>
          温馨提示：批量导出记录中只会存放最近30天添加的导出任务，请及时保存备份。
        </div>
      </Spin>
    </Dialog>
  );
});
