import moment, { Duration, Moment } from 'moment';
import { ShortcutOption } from '@/components/layouts/customRangePicker/type';
import {
  DateRange,
  MayInvalidDateRange,
  FromAndToDto,
  DateRangeDto
} from '@/types/date';

export enum DatePattern {
  DATE = 'YYYY-MM-DD',
  DATETIME = 'YYYY-MM-DD HH:mm:ss',
  TIME = 'HH:mm:ss',
  SHORT_DATETIME = 'MM-DD HH:mm'
}

export function format(
  date: number | string | Date | Moment,
  pattern: DatePattern | string = DatePattern.DATE
): string {
  return date !== undefined && date !== null
    ? moment(date).format(pattern)
    : '';
}

export function formatNow(
  pattern: DatePattern | string = DatePattern.DATE
): string {
  return pattern !== undefined && pattern !== null
    ? format(moment.now(), pattern)
    : '';
}

export function formatDayFromNow(
  day: number,
  pattern: DatePattern | string = DatePattern.DATE
): string {
  return day !== undefined && day !== null
    ? moment().add(day, 'd').format(pattern)
    : '';
}

export function formatDayFromNowForMonth(
  month: number,
  pattern: DatePattern | string = DatePattern.DATE
): string {
  return month !== undefined && month !== null
    ? moment().add(month, 'M').format(pattern)
    : '';
}

export function formatYesterday(
  pattern: DatePattern | string = DatePattern.DATE
): string {
  return formatDayFromNow(-1, pattern);
}

export function toMoment(date: number | string | Date): Moment {
  return moment(date);
}

export const formatSecond = (second: number): string => {
  const minute = Math.floor(second / 60);
  const restSecond = Math.floor(second % 60);
  if (minute) {
    return restSecond ? `${minute}分${restSecond}秒` : `${minute}分`;
  }
  return `${restSecond}秒`;
};

export function compareDate(
  date1: string | number | Date,
  date2: string | number | Date
): number {
  const time1 = moment(date1).valueOf();
  const time2 = moment(date2).valueOf();
  return time1 - time2;
}

export const compareTime = (start: string, end: string): boolean => {
  const startTime = moment(start, 'HH:mm:ss');
  const endTime = moment(end, 'HH:mm:ss');
  return startTime.isBefore(endTime);
};

export function differTime(
  date1: string | number | Date,
  date2: string | number | Date
): number {
  const time1 = moment(date1).endOf('day').valueOf();
  const time2 = moment(date2).startOf('day').valueOf();
  return time1 - time2;
}

export function checkTimeRange(
  range: [string, string],
  maxRange: number
): boolean {
  const MAX_RANGE = maxRange * 86400 * 1000;
  const invalid = range && differTime(range[1], range[0]) > MAX_RANGE;
  return !invalid;
}

export const leftTime = (time: number): string => {
  const s = Math.floor(time % 60);
  const m = Math.floor((time / 60) % 60);
  const h = Math.floor(time / 3600);
  if (h === 0) {
    if (m === 0) {
      return `${s}秒`;
    }
    return s ? `${m}分钟${s}秒` : `${m}分钟`;
  }
  return s ? `${h}小时${m}分钟${s}秒` : `${h}小时${m}分钟`;
};

export const duration = (
  start: number | string | Date,
  end: number | string | Date
): Duration =>
  moment.duration(moment(end).valueOf() - moment(start).valueOf()).abs();

export const formatRange = (
  start: number | string | Date,
  end: number | string | Date,
  pattern: DatePattern = DatePattern.DATE
): [string, string] => [format(start, pattern), format(end, pattern)];

export const formatRangeFromNow = (
  start: number,
  end: number,
  pattern: DatePattern = DatePattern.DATE
): [string, string] => {
  const s = moment().add(start, 'd');
  const e = moment().add(end, 'd');
  return [s.format(pattern), e.format(pattern)];
};

export const checkRange = (
  range: [string, string],
  maxRangeDay: number
): boolean => {
  if (!range || !range[0] || !range[1]) {
    return false;
  }
  const start = moment(range[0]).startOf('day').valueOf();
  const end = moment(range[1]).endOf('day').valueOf();
  return end - start <= maxRangeDay * 86400 * 1000;
};

const getTime = (time: number): string =>
  time > 9 ? time.toString() : `0${time}`;

export const formatSeconds = (value: number, useColon = false): string => {
  if (value === 0) {
    return '00小时00分00秒';
  }
  let secondTime = Math.floor(value);
  let minuteTime = 0;
  let hourTime = 0;
  if (secondTime >= 60) {
    minuteTime = Math.floor(secondTime / 60);
    secondTime = Math.floor(secondTime % 60);
    if (minuteTime >= 60) {
      hourTime = Math.floor(minuteTime / 60);
      minuteTime = Math.floor(minuteTime % 60);
    }
  }
  let result = secondTime ? `${getTime(Math.floor(secondTime))}秒` : '00秒';

  if (minuteTime > 0) {
    result = `${getTime(Math.floor(minuteTime))}分${result}`;
  } else {
    result = `00分${result}`;
  }
  if (hourTime > 0) {
    result = `${getTime(Math.floor(hourTime))}小时${result}`;
  } else {
    result = `00小时${result}`;
  }
  if (useColon) {
    result = result
      .replace('00小时', '')
      .replace('小时', ':')
      .replace('分', ':')
      .replace('秒', '');
  }
  return result;
};

export const getValidateDateRange = (
  dateRange: [string, string],
  maxRangeDays: number
): [string, string, boolean] => {
  const [start, end] = dateRange;
  const miniStartTime = moment(end).valueOf() - maxRangeDays * 86400 * 1000;
  const miniStart = moment(miniStartTime);
  if (miniStart.isSameOrAfter(moment(start))) {
    return [miniStart.format(DatePattern.DATE), end, true];
  }
  return [...dateRange, false];
};

/**
 * 比较两个日期是否是同一天
 * @param date1
 * @param date2
 */
export function isSameDay(date1: Date, date2: Date): boolean {
  return date1.toLocaleDateString() === date2.toLocaleDateString();
}

export function buildQuickDateRange(
  isProfessional: boolean,
  includeToday = false
): ShortcutOption[] {
  return isProfessional
    ? [
        { label: '今', value: [0, 0] },
        { label: '昨', value: [-1, -1] },
        { label: '周', value: [-6, 0] },
        { label: '月', value: [-30, 0] }
      ]
    : [
        { label: '昨', value: [-1, -1] },
        { label: '周', value: includeToday ? [-6, 0] : [-7, -1] },
        { label: '月', value: includeToday ? [-30, 0] : [-31, -1] }
      ];
}

export function buildQuickDateRangeFromToday(): ShortcutOption[] {
  return [
    { label: '昨天', value: [-1, -1] },
    { label: '近7天', value: [-6, 0] },
    { label: '近31天', value: [-30, 0] }
  ];
}

export function buildQuickDateRangeFromYesterday(): ShortcutOption[] {
  return [
    { label: '昨天', value: [-1, -1] },
    { label: '近7天', value: [-7, -1] },
    { label: '近31天', value: [-31, -1] }
  ];
}

export function formatTodayRange(): [string, string] {
  return [formatDayFromNow(0), formatDayFromNow(0)];
}

export function formatYesterdayRange(): [string, string] {
  return [formatYesterday(), formatYesterday()];
}

export function inRange(range: [string, string], date: string): boolean {
  if (range && range.length === 2) {
    const [from, to] = range;
    return !(
      moment(date).isBefore(moment(from)) || moment(date).isAfter(moment(to))
    );
  }
  return false;
}

export function transToValidDateRange(dateRange: DateRange): DateRange {
  const [from, to] = dateRange;

  const fromIsAfterTo = moment(from).isAfter(moment(to));
  const validDateRange: DateRange = [
    fromIsAfterTo ? to : from,
    fromIsAfterTo ? from : to
  ];

  return validDateRange;
}

export function isValidDateRange(
  dateRange: MayInvalidDateRange
): dateRange is DateRange {
  return !!dateRange[0] && !!dateRange[1];
}

export function getDateRangeFromObj<T extends FromAndToDto>(obj: T): DateRange {
  const { from, to } = obj;
  return [from, to];
}

export function transObjWithRange<T extends FromAndToDto>(
  obj: T
): Omit<T, 'from' | 'to'> & DateRangeDto {
  const { from, to, ...rest } = obj;
  return { range: [from, to], ...rest };
}

export function transObjWithFromTo<T extends DateRangeDto>(
  obj: T
): Omit<T, 'range'> & FromAndToDto {
  const { range, ...rest } = obj;
  return { from: range[0], to: range[1], ...rest };
}
