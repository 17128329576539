import React, { memo, useEffect, useState } from 'react';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { Input } from '@/components/widgets/input';
import { FormLike } from '@/components/widgets/formLike';
import { Space } from '@/components/widgets/space';
import { SearchConditionUnit } from '@/components/works/components/conditionUnit';
import { Button } from '@/components/widgets/button';
import { ResetPasswordDailogProps } from './type';
import css from './style.less';
import { randomPassword } from '@/utils/randomPassword';
import { staffService } from '../service';
import { message } from '@/components/widgets/message';
import { warnError } from '@/components/infos';

export const ResetPasswordDailog = memo(
  (props: DialogDeployProps<ResetPasswordDailogProps>) => {
    const { closeDialog, params } = props;
    const { id, ...rest } = params;
    const [form, setForm] = useState({
      staffName: '',
      accountName: '',
      password: ''
    });

    useEffect(() => {
      setForm({ ...rest, password: '' });
    }, [params]);

    const handleFormChange = value => {
      setForm(value);
    };

    const handleOk = async () => {
      try {
        await staffService.resetPassword({
          staffId: id,
          password: form.password
        });
        message.success('重置密码成功');
        closeDialog(true);
      } catch (error) {
        warnError(error);
      }
    };

    const randomGeneratePassword = () => {
      setForm({ ...form, password: randomPassword() });
    };

    return (
      <Dialog title="重置密码" onCancel={closeDialog} onOk={handleOk}>
        <FormLike value={form} onChange={handleFormChange}>
          <Space direction="vertical" size="large">
            <SearchConditionUnit
              className={css.unit}
              labelClassName={css.label}
              label="员工姓名"
              name="staffName"
              required
            >
              <Input
                className={css.inputWidth}
                placeholder="请输入员工姓名"
                disabled
              />
            </SearchConditionUnit>
            <SearchConditionUnit
              className={css.unit}
              labelClassName={css.label}
              label="登录账号"
              name="accountName"
              required
            >
              <Input
                className={css.inputWidth}
                placeholder="请输入登录账号"
                disabled
              />
            </SearchConditionUnit>
            <SearchConditionUnit
              className={css.unit}
              labelClassName={css.label}
              label="登录密码"
              name="password"
              required
            >
              <Input
                className={css.inputWidth}
                placeholder="请输入登录密码"
                addonAfter={
                  <Button
                    type="ghost"
                    className={css.btnReset}
                    onClick={randomGeneratePassword}
                  >
                    随机生成
                  </Button>
                }
              />
            </SearchConditionUnit>
          </Space>
        </FormLike>
      </Dialog>
    );
  }
);
