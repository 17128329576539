/* eslint-disable @typescript-eslint/no-shadow */
import React, { ReactNode, memo, useEffect, useState } from 'react';
import css from './style.less';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import StatisticSearch from '@/components/works/statisticComponents/statisticSearch';
import { Range } from '@/types/date';
import { SummaryStats } from './components/summaryStats';
import { DailySummaryChart } from './components/dailySummaryChart';
import { FlowUsedARateChart } from './components/flowUsedRateChart';
import { memberMarketingStatsRequest } from '@/requests/buyerMembermarketStats/indes';
import {
  MarketingFlowUsedRateWebDto,
  MarketingStatsDailySummaryValueList,
  MarketingStatsDailySummaryWebDto,
  MarketingStatsSummaryWebDto
} from '@/requests/buyerMembermarketStats/type';
import { checkRange, formatDayFromNow } from '@/utils/date';
import { message } from '@/components/widgets/message';
import { Button } from '@/components/widgets/button';
import { DailySummaryTable } from './components/dailySummaryTable';
import exportCenterRequest from '@/requests/exportCenter';
import { ExportButton } from '@/components/works/exportButton';
import { RemindType } from '@/types/remindType';

const defaultSummary: MarketingStatsSummaryWebDto = {
  conversionRate: 0,
  lastUpdateTime: '',
  payment: 0,
  paymentIncrement: 0,
  sendNum: 0,
  tradeCreatedNum: 0,
  tradePaidNum: 0
};
const defaultFlowUsedRate: MarketingFlowUsedRateWebDto = {
  candidateBuyerNum: 0,
  flowUsedRate: 0,
  sendBuyerNum: 0
};
const defaultDailySummary: MarketingStatsDailySummaryWebDto = {
  valueList: [],
  sumMap: {},
  avgMap: {}
};
const defaultRange: Range = [formatDayFromNow(-6), formatDayFromNow(0)];

const CardWrapper = memo(
  (props: { children?: ReactNode; title: ReactNode }) => {
    const { title, children } = props;

    return (
      <div className={css.cardWrapper}>
        <div>{title}</div>
        <div style={{ position: 'relative' }}>{children}</div>
      </div>
    );
  }
);

const Search = memo(
  (props: {
    onChange: (stats: MarketingStatsDailySummaryWebDto, range: Range) => void;
  }) => {
    const { onChange } = props;

    const handleChange = async (value: { range: Range }) => {
      const [from, to] = value.range;

      if (!checkRange([from, to], 31)) {
        message.warning('一次最多选择31天');
        return;
      }

      try {
        const dailySummaryStats =
          await memberMarketingStatsRequest.dailySummary({
            from,
            to
          });
        onChange(dailySummaryStats, value.range);
      } catch (error) {
        message.error(error);
      }
    };

    return (
      <StatisticSearch onChange={handleChange} defaultRange={defaultRange} />
    );
  }
);

const BuyerMemberMarketStats = memo(() => {
  const [summary, setSummary] = useState(defaultSummary);
  const [flowUsedRate, setFlowUsedRate] = useState(defaultFlowUsedRate);
  const [dailySummary, setDailySummary] = useState(defaultDailySummary);
  const [dailySummaryDisplay, setDailySummaryDisplay] = useState<
    'chart' | 'table'
  >('chart');
  const [range, setRange] = useState(defaultRange);

  useEffect(() => {
    const headerDOM = document.getElementById('#page-header');
    const pageDOM = document.getElementById('#page-content');
    if (pageDOM) {
      pageDOM.setAttribute('style', 'background-color: #fff;');
    }
    if (headerDOM)
      headerDOM.setAttribute(
        'style',
        'box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1)'
      );
    return () => {
      pageDOM?.setAttribute('style', '');
      headerDOM?.setAttribute('style', '');
    };
  }, []);

  const init = async () => {
    const [summary, flowUsedRate, dailySummary] = await Promise.all([
      memberMarketingStatsRequest.summary(),
      memberMarketingStatsRequest.flowUsedRate(),
      memberMarketingStatsRequest.dailySummary({
        from: defaultRange[0],
        to: defaultRange[1]
      })
    ]);

    setSummary(summary);
    setFlowUsedRate(flowUsedRate);
    setDailySummary(dailySummary);
  };

  useEffect(() => {
    init();
  }, []);

  const handleChange = (
    stats: MarketingStatsDailySummaryWebDto,
    range: Range
  ) => {
    setDailySummary(stats);
    setRange(range);
  };

  const handleDailySummaryDisplay = () => [
    setDailySummaryDisplay(prev => (prev === 'chart' ? 'table' : 'chart'))
  ];

  const handleExport = async () => {
    if (!dailySummary.valueList.length) {
      return false;
    }
    const [from, to] = range;
    const params = {
      from,
      to,
      remindType: 'MEMBER_MARKETING' as RemindType
    };
    await exportCenterRequest.createDailySendStatsExportTask(params);
    return true;
  };

  return (
    <div className={css.wrapper}>
      <div className={css.topCard}>
        <CardWrapper
          title={
            <div className={css.cardTitle}>
              <TooltipWithIcon
                label="汇总数据"
                tooltip="统计使用营销增收功能以来的所有收益"
              />
              <span
                style={{ fontSize: '14px', color: '#ccc', marginLeft: '8px' }}
              >
                数据更新于{summary.lastUpdateTime}
              </span>
            </div>
          }
        >
          <SummaryStats summaryStats={summary} />
        </CardWrapper>
        <CardWrapper
          title={<div className={css.cardTitle}>近30天流量利用率</div>}
        >
          <FlowUsedARateChart flowUsedRateStats={flowUsedRate} />
          {flowUsedRate.sendBuyerNum && flowUsedRate.candidateBuyerNum ? (
            <div className={css.positionTooltip}>
              <TooltipWithIcon tooltip="已发送买家/总可发送买家" label="" />
            </div>
          ) : null}
        </CardWrapper>
      </div>
      <CardWrapper
        title={
          <div className={css.detailTitle}>
            <div className={css.cardTitle}>发送明细</div>
            <div className={css.operation}>
              {dailySummaryDisplay === 'table' ? (
                <ExportButton
                  type="default"
                  ghost={false}
                  onExport={handleExport}
                />
              ) : null}
              <Button
                className={css.dailySummarySwitch}
                onClick={handleDailySummaryDisplay}
              >
                {dailySummaryDisplay === 'table' ? '切换图表' : '切换表格'}
              </Button>
              <Search onChange={handleChange} />
            </div>
          </div>
        }
      >
        {dailySummaryDisplay === 'chart' ? (
          <DailySummaryChart
            dailySummaryStats={
              dailySummary.valueList.length
                ? dailySummary.valueList
                : ([{}] as MarketingStatsDailySummaryValueList[])
            }
          />
        ) : null}
        {dailySummaryDisplay === 'table' ? (
          <DailySummaryTable
            dailySummaryStats={dailySummary.valueList}
            {...dailySummary}
          />
        ) : null}
      </CardWrapper>
    </div>
  );
});

export default BuyerMemberMarketStats;
