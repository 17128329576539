import React, {
  ChangeEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { produce } from '@/libs/produce';
import {
  ChangeState,
  FormEquality,
  FormItemProps,
  FormLikeProps,
  FormSelector,
  FormState,
  InnerIOType,
  IO,
  Listener
} from './type';

/**
 * form 控制器，包含了当前 form 的 state 数据，
 * 并通过订阅模式，链接了每一个子节点 (FormItem)
 */
class FormController {
  state: unknown;

  // 接入 Form 组件的 onChange 回调
  handleChangeState?: (nextState: unknown) => any;

  handleSubmitState?: (nextState: unknown) => any;

  // 各个子节点 FormItem 注册的监听器集合
  listeners: Listener[] = [];

  constructor(state?: unknown) {
    this.state = state || {};
  }

  /**
   * 将最新的state发布到各个子节点
   * @param state 最新的 form state 数据
   */
  notify<T extends FormState>(state: T): void {
    // 复制监听器集合避免产生级连订阅（即出现在listener运行过程中产生新的订阅）
    const listeners = [...this.listeners];
    listeners.forEach(listener => {
      listener(state);
    });
    this.state = state;
  }

  /**
   * 修改 state 数据
   * @param state 新数据
   */
  changeState<T extends FormState>(state: T) {
    if (!this.handleChangeState) {
      return;
    }
    this.handleChangeState(state);
  }

  /**
   * 部分修改数据
   *
   * @param name 需要修改的路径
   * @param value 路径对应的数据
   */
  changeStatePartition<T extends FormState>(name: keyof T, value: any) {
    if (!this.handleChangeState) {
      return;
    }
    // 部分修改的核心部件 produce，
    // 该工具可以在不修改原始数据的情况下，根据提供的路径和数据生成新数据
    this.handleChangeState(
      produce(this.state as T)
        .set(name as string, value)
        .get()
    );
  }

  submitState<T extends FormState>(state: T) {
    if (!this.handleSubmitState) {
      return;
    }
    this.handleSubmitState(state);
  }

  submitStatePartition<T extends FormState>(name: keyof T, value: any) {
    if (!this.handleSubmitState) {
      return;
    }
    // 部分修改的核心部件 produce，
    // 该工具可以在不修改原始数据的情况下，根据提供的路径和数据生成新数据
    this.handleSubmitState(
      produce(this.state as T)
        .set(name as string, value)
        .get()
    );
  }

  /**
   * 订阅
   * @param listener 订阅监听方法
   */
  subscribe<T extends FormState>(listener: Listener<T>): () => void {
    // 注意，这是个内置的 arrow function
    const findIndex = () => this.listeners.findIndex(l => l === listener);
    // 取消订阅的 callback
    const unsubscribe = () => {
      const index = findIndex();
      this.listeners.splice(index, 1);
    };

    // 如果能找到，说明已经注册过，那直接返回取消注册方法即可
    if (findIndex() > -1) {
      return unsubscribe;
    }
    // 如果找不到，则加入监听集合
    (this.listeners as Listener<T>[]).push(listener);
    // 第一次注册需要及时通知当前 state
    listener(this.state as T);
    return unsubscribe;
  }
}

const FormLikeContext = React.createContext(new FormController());

/**
 * 区别 ant design 的 Form 组件，
 * 该组件并没有 submit 功能，一切由使用者自己决定，
 * 一个好的工具必然是一个纯粹的工具，
 * FormLike 只关注数据整理功能
 */
export const FormLike = memo(
  ({ value, children, onChange, onSubmit }: FormLikeProps) => {
    const mountRef = useRef(false);
    const ref = useRef(new FormController(value));
    // 链接控制器的change方法
    if (onChange) {
      ref.current.handleChangeState = onChange as (next: unknown) => any;
    }
    // 链接控制器的submit方法
    if (onSubmit) {
      ref.current.handleSubmitState = onSubmit as (next: unknown) => any;
    }

    // 当value变化时通知到各个子组件
    useEffect(() => {
      if (!mountRef.current) {
        mountRef.current = true;
        return;
      }
      ref.current.notify(value);
    }, [value]);

    return (
      <FormLikeContext.Provider value={ref.current}>
        {children}
      </FormLikeContext.Provider>
    );
  }
);

export const IOS: Record<InnerIOType, IO> = {
  defaultIO: ['value', (value: any) => value],
  switchIo: ['checked', (checked: boolean) => checked],
  checkedIO: [
    'checked',
    (event: ChangeEvent<HTMLInputElement>) => event.target.checked
  ],
  valueIO: [
    'value',
    (event: ChangeEvent<HTMLInputElement>) => event.target.value
  ]
};

/**
 * 子组件链接包装组件，
 * 包装组件需要被包装组件符合 value:T onChange(value:T)=>any 接口，
 * props：
 * children:被包装组
 * name:子组件value对应form value中路径
 * valueMapper:如果onChange的出参，不符合 onChange(value:T)=>any ，可设置callback转换
 * targetValue:现实中有大量 onChange(event:{target:{value}})=>any 这类接口，通过设置 targetValue 可支持
 */
export const FormItem = memo(
  <T extends FormState>({
    children,
    name,
    io,
    targetValue,
    targetChecked
  }: FormItemProps) => {
    const [valuePropName, valueMapper] = io || IOS.defaultIO;

    const context = useContext(FormLikeContext);

    const { state } = context;

    const initialValue = produce(state as T).get(name);

    const [value, setValue] = useState(initialValue);

    const handleWrapValue = useCallback(
      (v: any) => {
        function getValueFromTarget(data: any): any {
          return targetValue
            ? (
                data as ChangeEvent<
                  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
                >
              ).target.value
            : data;
        }
        function getCheckedFromTarget(data: any): any {
          return targetChecked
            ? (data as ChangeEvent<HTMLInputElement>).target.checked
            : data;
        }
        function mapValue(data: any) {
          return valueMapper(data);
        }
        return mapValue(getValueFromTarget(getCheckedFromTarget(v)));
      },
      [valueMapper]
    );

    const handleChange = useCallback(
      (v: any) => {
        const val = handleWrapValue(v);
        setValue(val);
        context.changeStatePartition(name, val);
      },
      [name, valueMapper]
    );

    const handleSubmit = useCallback(
      (v: any) => {
        const val = handleWrapValue(v);
        setValue(val);
        context.submitStatePartition(name, val);
      },
      [name, valueMapper]
    );

    useEffect(
      () =>
        context.subscribe((s: T) => {
          setValue(produce(s as T).get(name));
        }),
      []
    );

    const mergedChild = useMemo(() => {
      const child = React.Children.only(children);
      if (child && React.isValidElement(child)) {
        const sourceProps = child.props;
        const valueProps = targetChecked
          ? { checked: value }
          : { [valuePropName]: value };
        const props = {
          ...sourceProps,
          ...valueProps,
          onChange: handleChange,
          onSubmit: handleSubmit
        };
        return React.cloneElement(child, props);
      }
      return child;
    }, [
      children,
      value,
      valuePropName,
      targetChecked,
      handleChange,
      handleSubmit
    ]);

    return <>{mergedChild}</>;
  }
);

export const useFormSelector = <T extends FormState = FormState, R = unknown>(
  selector?: FormSelector<T, R>,
  equalityCall?: FormEquality
): R => {
  const actualSelector = (selector || ((s: T) => s)) as FormSelector<T, R>;

  const context = useContext(FormLikeContext);
  const { state } = context;
  const initialValue = actualSelector(state as T) as R;
  const prevValueRef = useRef<R>(initialValue);

  const [value, setValue] = useState<R>(initialValue);

  useEffect(
    () =>
      context.subscribe((s: T) => {
        const prev = prevValueRef.current;
        const current = actualSelector(s) as R;
        prevValueRef.current = current;
        if (
          Object.is(prev, current) ||
          (equalityCall && equalityCall(prev, current))
        ) {
          return;
        }
        setValue(current);
      }),
    []
  );

  return value;
};

export function useFormChange<T extends FormState = FormState>(): (
  state: ChangeState<T>
) => void {
  const context = useContext(FormLikeContext);
  return function changeState(state: ChangeState<T>) {
    const s = typeof state === 'function' ? state(context.state as T) : state;
    context.changeState(s);
  };
}

export function useFormSubmit<T extends FormState = FormState>(): (
  state: ChangeState<T>
) => void {
  const context = useContext(FormLikeContext);
  return function submitState(state: ChangeState<T>) {
    const s = typeof state === 'function' ? state(context.state as T) : state;
    context.submitState(s);
  };
}
