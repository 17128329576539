import { Http, http } from '@/libs/http';
import { contentTemplateCmd, contentTemplateDto } from './type';

export class ContentTemplateRequest {
  private http: Http = http('/api/content-template');

  contentTemplate(params: contentTemplateCmd): Promise<contentTemplateDto[]> {
    return this.http
      .path('list-v2')
      .setRequestParams(params)
      .get<contentTemplateDto[]>();
  }
}

export default new ContentTemplateRequest();
