import { Model, strict, act, flow, Flows, avatar } from 'agent-reducer';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { getExt } from '@/pages/tardeBatchPush/services';
import { uiPrompt } from '@/avatars';
import { State } from './type';
import { staffService } from '../service';
import { exportRequest } from '@/libs/http';
import { noop } from '@/polyfill/func';

const defaultState = { uploading: false, fileList: [], errorMessages: [] };

export class BatchAddStaffDrawerModel implements Model<State> {
  state: State = defaultState;

  propmt = avatar({
    closeDrawer: () => {
      noop();
    }
  });

  @act()
  private changeState(state: Partial<State>): State {
    return { ...this.state, ...state };
  }

  @act()
  resetState(): State {
    return { ...this.state, ...defaultState };
  }

  @act()
  handleCloseErrorMsg(): State {
    return { ...this.state, errorMessages: [] };
  }

  @flow()
  handleRemoveFile(file: UploadFile<any>): void {
    const { fileList } = this.state;
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    this.changeState({ fileList: newFileList });
  }

  @flow()
  async downloadTemplate(): Promise<void> {
    try {
      await exportRequest({
        url: `/api/file/download-template`,
        method: 'post',
        data: { fileName: 'STAFF_IMPORT_TEMPLATE' }
      });
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow()
  beforeUpload(file: RcFile): boolean {
    if (!['xls', 'xlsx'].includes(getExt(file.name))) {
      uiPrompt.current.modalError({
        title: '温馨提示',
        content: '文件格式不正确，请重新上传'
      });
      return false;
    }
    if (file.size > 10 * 1024 ** 3) {
      uiPrompt.current.modalError({
        title: '温馨提示',
        content: '文件大小不可超过10M'
      });
      return false;
    }
    this.changeState({ fileList: [...this.state.fileList, file] });
    this.handleCloseErrorMsg();
    return false;
  }

  @flow()
  async handleUploadFile(): Promise<void> {
    const { fileList } = this.state;

    this.changeState({ uploading: true });
    try {
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('file', file as RcFile);
      });
      const { success, errorMessages } = await staffService.importStaff(
        formData
      );
      if (success) {
        uiPrompt.current.success('导入成功');
        this.propmt.current.closeDrawer();
        return;
      }
      this.changeState({ errorMessages, fileList: [] });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.changeState({ uploading: false });
    }
  }
}
