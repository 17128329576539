import {
  middleWare,
  MiddleWarePresets,
  OriginAgent,
  sharing
} from 'agent-reducer';
import { CacheKey, CacheState } from '@/modules/cache/type';
import shopRequest from '@/requests/shop';
import { useAgentSelector } from 'use-agent-reducer';
import { useMemo } from 'react';

class Cache implements OriginAgent<CacheState> {
  state: CacheState = {
    shopName: ''
  };

  cache(cacheKey: CacheKey, cacheData: unknown) {
    const { state } = this;
    return { ...state, [cacheKey]: cacheData };
  }

  remove(cacheKey: CacheKey) {
    const { state } = this;
    const omittedEntries = Object.entries(state).filter(
      ([k]) => k !== cacheKey
    );
    return Object.fromEntries(omittedEntries);
  }

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async initial(newCash: Partial<CacheState>) {
    const permissions = await this.fetchPermission(newCash);

    if (newCash.fromShop) {
      const { athenaAdRequestParams } = await shopRequest.visitShopConfig();
      return {
        ...this.state,
        ...newCash,
        permissions,
        subName: athenaAdRequestParams.subNick,
        sellerName: athenaAdRequestParams.sellerNick
      };
    }
    return {
      ...this.state,
      ...newCash,
      permissions
    };
  }

  @middleWare(MiddleWarePresets.takeNothing())
  async fetchPermission(newCash: Partial<CacheState>) {
    const { pageAuthority } = newCash;
    const permissions =
      pageAuthority !== 'mainAccount'
        ? await shopRequest.fetchShopPermission()
        : [];
    return permissions;
  }
}

export const cacheRef = sharing(() => Cache);

export const usePermissionSet = (): Set<string> => {
  const roleArray = useAgentSelector(
    cacheRef.current,
    s => s.permissions as string[]
  );
  return useMemo(() => new Set<string>(roleArray), [roleArray]);
};
