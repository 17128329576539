/* eslint-disable react/jsx-no-constructed-context-values */
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { DataNode } from 'antd/lib/tree';
import classNames from 'classnames';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';

import { uiPrompt } from '@/avatars';
import useRequest from '@/hooks/useRequest';
import { itemGroupRequest } from '@/requests/itemGroups';
import { ItemGroupPointModifyCmd } from '@/requests/itemGroups/type';

import { editInputClassName } from '../group';
import { reorder, reorderChildren } from './service';
import css from './style.less';

type Props = {
  isDragDisabled: boolean;
  expandedKeys: React.Key[];
  onExpand: (
    v: React.Key[],
    arg: {
      expanded: boolean;
      node: DataNode;
    }
  ) => void;
};

const Context = createContext<Props>({
  isDragDisabled: false,
  expandedKeys: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onExpand: () => {}
});

const NodeRender: React.FC<{
  node: DataNode;
  last?: boolean;
  expanded?: boolean;
  changeExpanded?: (v: boolean) => void;
  draggableProvided: DraggableProvided;
}> = ({ node, last, expanded, changeExpanded, draggableProvided }) => {
  const { isDragDisabled } = useContext(Context);
  const { title, children } = node;

  if (!node.title) {
    return null;
  }

  return (
    <div
      style={{
        minHeight:
          (children && children.length === 0) || expanded === false
            ? 25
            : undefined
      }}
      className={classNames(
        'ant-tree-treenode',
        last ? 'ant-tree-treenode-leaf-last' : null,
        children
          ? 'ant-tree-treenode-switcher-open'
          : 'ant-tree-treenode-switcher-close'
      )}
    >
      <span aria-hidden="true" className="ant-tree-indent">
        {children ? null : <span className="ant-tree-indent-unit" />}
      </span>

      {children?.length ? (
        <span
          onClick={() => changeExpanded?.(!expanded)}
          className={classNames(
            'ant-tree-switcher',
            expanded ? 'ant-tree-switcher_open' : 'ant-tree-switcher_close'
          )}
        >
          <DownOutlined className="ant-tree-switcher-icon" />
        </span>
      ) : (
        <span className="ant-tree-switcher ant-tree-switcher-noop" />
      )}

      <span className="ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal">
        <span className={classNames('ant-tree-title', css.title)}>
          {isDragDisabled || node.key === 0 ? null : (
            <span>
              <MenuOutlined
                {...draggableProvided.dragHandleProps}
                className={css.order}
              />
            </span>
          )}
          {title}
        </span>
      </span>
    </div>
  );
};

const ChildNodeList: React.FC<{
  listId: string;
  list?: DataNode[];
  expanded?: boolean;
}> = ({ listId, list = [], expanded }) => {
  if (list.length === 0 || !expanded) {
    return (
      <Droppable droppableId={listId} type="CHILD">
        {dropProvided => (
          <div
            style={{ height: 10 }}
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  return (
    <Droppable droppableId={listId} type="CHILD">
      {dropProvided => (
        <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
          {list.map((node, index) => (
            <Draggable
              key={node.key}
              draggableId={String(node.key)}
              index={index}
            >
              {dragProvided => (
                <div
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                >
                  <NodeRender
                    draggableProvided={dragProvided}
                    node={node}
                    last={index === list.length - 1}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const ParentNode: React.FC<{ node: DataNode; index: number }> = ({
  node,
  index
}) => {
  const { key, children } = node;
  const { expandedKeys, onExpand } = useContext(Context);

  const expanded = expandedKeys.includes(key);

  const handleExpand = (v: boolean) => {
    const res = expanded
      ? expandedKeys.filter(k => k !== key)
      : [...expandedKeys, key];
    onExpand(res, { expanded: v, node });
  };

  return (
    <Draggable draggableId={String(key)} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div>
            <NodeRender
              draggableProvided={provided}
              expanded={expanded}
              node={node}
              changeExpanded={handleExpand}
            />
          </div>
          <ChildNodeList
            expanded={expanded}
            listId={String(key)}
            list={children}
          />
        </div>
      )}
    </Draggable>
  );
};

export const DraggableTree: React.FC<
  { data: DataNode[]; refresh(): void } & Omit<Props, 'isDragDisabled'>
> = ({ data, expandedKeys, refresh, onExpand }) => {
  const [treeData, setTreeData] = useState(data);
  const [isDragDisabled, setIsDragDisabled] = useState(false);

  const { run } = useRequest(
    (v: ItemGroupPointModifyCmd) => itemGroupRequest.updateGroupItemPoint(v),
    {
      manual: true,
      onError: e => {
        uiPrompt.current.error(e);
        setTreeData(data);
      },
      onSuccess: refresh
    }
  );

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (result.type === 'PARENT') {
      const res = reorder(treeData, source.index, destination.index);
      run({
        // originParentGroupId: 0,
        targetParentGroupId: 0,
        groupId: Number(data[source.index].key),
        groupIds: res.map(v => Number(v.key)).filter(v => v) // 排除未分组id
      });
      setTreeData(res);

      return;
    }

    try {
      const res = reorderChildren(treeData, source, destination);
      setTreeData(res.data);
      run(res.cmd);
    } catch (e) {
      uiPrompt.current.error(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsDragDisabled(
        !!document.getElementsByClassName(editInputClassName)?.length
      );
    }, 0);
    setTreeData(data);
  }, [data]);

  return (
    <Context.Provider value={{ expandedKeys, isDragDisabled, onExpand }}>
      <div className="ant-tree ant-tree-icon-hide ant-tree-unselectable">
        <div className="ant-tree-list">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="board" type="PARENT">
              {provider => (
                <div ref={provider.innerRef} {...provider.droppableProps}>
                  {treeData.map((node, index) => (
                    <ParentNode key={node.key} node={node} index={index} />
                  ))}
                  {provider.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </Context.Provider>
  );
};

export default DraggableTree;
