import React, { memo } from 'react';
import GoodsPop, { GoodsPopProps } from '@/components/works/goodsPop';
import { TaobaoIidskuids } from '@/types/esItem';
import { splitIidSkuIds } from '../../goodsSelectModal/service';
import { usePlatform } from '@/hooks/usePlatform';

type GoodsPopInfoProps = {
  taobaoIidskuids: TaobaoIidskuids[];
  itemPrefix?: string;
  showSku?: boolean;
} & Partial<GoodsPopProps>;

// 主策略无 sku
export const GoodsPopInfo = memo(
  ({
    taobaoIidskuids,
    itemPrefix = '已选择',
    showSku,
    ...rest
  }: GoodsPopInfoProps) => {
    const { isJD } = usePlatform();
    const { taobaoIids, taobaoSkuIds } = splitIidSkuIds(taobaoIidskuids);

    const newShowSku = isJD ? true : showSku;

    const label = newShowSku && taobaoSkuIds.length
        ? `已选择 ${taobaoIids.length} 件商品，${taobaoSkuIds.length}个SKU`
        : `已选择 ${taobaoIids.length} 件商品`;

    return (
      <GoodsPop
        title="关联商品"
        taobaoIidskuids={taobaoIidskuids}
        label={label}
        {...rest}
      />
    );
  }
);
