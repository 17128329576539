type TreeNode<T> = {
  value: T;
  children: TreeNode<T>[];
};

/**
 * @description: 计算树中末子节点的路径
 * @param data 末子节点的值
 * @param tree 树
 * @return {*}
 */
export const computePathByNodeValue = <T, K extends TreeNode<T>>(
  data: T,
  tree: K[]
): T[] => {
  return tree.reduce((result: T[], { value, children }) => {
    if (value === data) {
      return [value];
    }
    if (result.length || !children || !children.length) {
      return result;
    }
    const found = computePathByNodeValue(data, children);
    if (found.length) {
      return [value, ...found];
    }
    return result;
  }, []);
};

export const flatTreeData = (tree: any[]) =>
  tree.reduce(
    (ret, cur) => [
      ...ret,
      cur,
      ...(cur.children ? flatTreeData(cur.children) : [])
    ],
    []
  );

export const reduceParentKey = (key: React.Key, tree: any[]): React.Key =>
  tree.reduce((ret, cur) => {
    if (cur.children) {
      if (cur.children.some(item => item.key === key)) {
        return cur.key;
      }
      if (reduceParentKey(key, cur.children)) {
        return reduceParentKey(key, cur.children);
      }
    }
    return ret;
  }, null);
