import React, { memo, useCallback, useMemo } from 'react';
import { TimePicker as TimeSelect } from 'antd';
import { TimePickerProps } from 'antd/es/time-picker';
import moment, { Moment } from 'moment';

export const TimePicker = TimeSelect;

export const FormatTimePicker = memo(
  (props: TimePickerProps & { timeFormat?: string }) => {
    const { value, onChange, timeFormat = 'HH:mm:ss', ...rest } = props;

    const timeValue: Moment | undefined | null = useMemo(() => {
      if (!value) {
        return undefined;
      }
      return moment(value, timeFormat);
    }, [value]);

    const handleChange = (momentValue, value) => {
      if (!onChange) {
        return;
      }
      onChange(
        momentValue
          ? (moment(momentValue).format('HH:mm:ss') as unknown as Moment)
          : null,
        value
      );
    };

    return (
      <TimePicker
        {...rest}
        value={timeValue}
        onChange={handleChange}
        getPopupContainer={trigger => trigger}
      />
    );
  }
);
