import {
  middleWare,
  MiddleWarePresets,
  OriginAgent,
} from 'agent-reducer';
import { Node, State } from './type';
import { partition } from '@/polyfill/array';
import { AreaWebDto } from '@/requests/shop/type';

export class AddressDialogModel implements OriginAgent<State> {
  state: State = {
    nodeTree: [],
    nodeMap: new Map(),
    checkedList: [],
    allCities: [],
    expendProvince: -1
  };

  // 初始化表格数据
  @middleWare(MiddleWarePresets.takeLatest())
  async init(checkedList: number[], area:AreaWebDto[] = []):Promise<State> {
    const [allProvinces, allCities] = partition(
      area,
      a => a.parentAreaId === 1
    );
    const nodeMap = new Map<number, Node>(
      area.map(area => [area.areaId, area])
    );
    const nodeTree = allProvinces.map(ap => ({
      ...ap,
      children: allCities.filter(a => a.parentAreaId === ap.areaId)
    }));
    return {
      ...this.state,
      nodeTree,
      nodeMap,
      checkedList,
      allCities
    };
  }

  setExpendProvince(expendProvince): State {
    return {
      ...this.state,
      expendProvince
    };
  }

  onChangeSelect(list: number[], origin: Node[]): State {
      const { checkedList } = this.state;
      const originIds = new Set(origin.map(o => o.areaId));
      const checked = checkedList.filter(
        id => !originIds.has(id)
      );
      return {
        ...this.state,
        checkedList: [...list, ...checked]
      }
  }

  selectAll(): State {
    const { checkedList, allCities } = this.state;
    if (checkedList.length === allCities.length) {
      return {
        ...this.state,
        checkedList: []
      };
    }
    return {
      ...this.state,
      checkedList: allCities.map(ac => ac.areaId)
    };
  }
}