// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_noLimitedWrap_5JCWN {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 200px;\n  border: 1px solid #f0f0f0;\n}\n.style_hidden_D2fB5 {\n  display: none;\n}\n.style_item_ERy-a {\n  display: flex;\n  align-items: center;\n  padding: 12px 0;\n}\n.style_item_ERy-a .style_select_Lhhv6 {\n  width: 340px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/sellerTradeSelector/sellerTradeDialog/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,yBAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AADF;AAFA;EAMI,YAAA;AADJ","sourcesContent":[".noLimitedWrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 200px;\n  border: 1px solid #f0f0f0;\n}\n\n.hidden {\n  display: none;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  padding: 12px 0;\n\n  .select {\n    width: 340px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noLimitedWrap": "style_noLimitedWrap_5JCWN",
	"hidden": "style_hidden_D2fB5",
	"item": "style_item_ERy-a",
	"select": "style_select_Lhhv6"
};
export default ___CSS_LOADER_EXPORT___;
