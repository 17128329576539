import { Model, strict, act, flow, Flows } from 'agent-reducer';
import logisticsPushStatsRequest from '@/requests/logisticsPushStats';
import type {
  LogisticsPushDetailRecordWebDto,
  LogisticsPushStatsWebDto,
  LogisticsPushStrategyStatsWebDto
} from '@/requests/logisticsPushStats/type';
import { uiPrompt } from '@/avatars';
import { formatDayFromNow, isValidDateRange } from '@/utils/date';
import type { PageDetail } from '@/types/pageDetail';
import exportCenterRequest from '@/requests/exportCenter';
import type { LogisticsPushStatState } from './type';
import { validateDateRange } from '@/services/formatStatsData';

const remindType = 'LOGISTIC_STAGNATE_PUSH';
const taskType = 'LOGISTIC_STAGNATE_PUSH';

const initStatsData: LogisticsPushStatsWebDto = {
  valueList: [],
  avgMap: {},
  sumMap: {}
};

@strict()
export class LogisticsPushStatModel implements Model<LogisticsPushStatState> {
  state: LogisticsPushStatState = {
    remindType,
    statsLoading: false,
    statsTableLoading: false,
    detailLoading: false,
    dateRange: [formatDayFromNow(-6), formatDayFromNow(0)],
    statsTabKey: 'time',
    stats: initStatsData,
    strategyStatsList: initStatsData,
    statsPage: 1,
    page: 1,
    pageSize: 10,
    total: 0,
    detailRecordList: [],
    recordSearchParams: {
      from: formatDayFromNow(-6),
      to: formatDayFromNow(0)
    }
  };

  @act()
  private statsLoad(): LogisticsPushStatState {
    return { ...this.state, statsLoading: true };
  }

  @act()
  private statsUnload(): LogisticsPushStatState {
    return { ...this.state, statsLoading: false };
  }

  @act()
  private statsTableLoad(): LogisticsPushStatState {
    return { ...this.state, statsTableLoading: true };
  }

  @act()
  private statsTableUnload(): LogisticsPushStatState {
    return { ...this.state, statsTableLoading: false };
  }

  @act()
  private detailLoad(): LogisticsPushStatState {
    return { ...this.state, detailLoading: true };
  }

  @act()
  private deatilUnload(): LogisticsPushStatState {
    return { ...this.state, detailLoading: false };
  }

  @act()
  private changeState(
    state: Partial<LogisticsPushStatState>
  ): LogisticsPushStatState {
    return { ...this.state, ...state };
  }

  @act()
  changeStatsTabKey(statsTabKey: string): LogisticsPushStatState {
    return { ...this.state, statsTabKey, statsPage: 1 };
  }

  @act()
  changeStatsPage(statsPage: number): LogisticsPushStatState {
    return { ...this.state, statsPage };
  }

  private fetchDetailRecord(
    params
  ): Promise<PageDetail<LogisticsPushDetailRecordWebDto>> {
    const { remindType, recordSearchParams, pageSize, page } = this.state;

    return logisticsPushStatsRequest.recordList({
      remindType,
      page,
      pageSize,
      ...recordSearchParams,
      ...params
    });
  }

  private fetchStats(): Promise<LogisticsPushStatsWebDto> {
    const { remindType, dateRange } = this.state;
    const [from, to] = dateRange;

    return logisticsPushStatsRequest.statsList({ remindType, from, to });
  }

  private fetchStrategyStatsList(): Promise<LogisticsPushStrategyStatsWebDto> {
    const {
      dateRange: [from, to],
      subStrategyId
    } = this.state;
    return logisticsPushStatsRequest.strategyStatsList({
      from,
      to,
      subStrategyId,
      taskType
    });
  }

  @flow(Flows.latest())
  async initStats(): Promise<void> {
    this.statsLoad();
    try {
      const stats = await this.fetchStats();

      this.changeState({ stats, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.statsUnload();
    }
  }

  @flow(Flows.latest())
  async initStrategyStatsList(): Promise<void> {
    this.statsTableLoad();
    try {
      const strategyStatsList = await this.fetchStrategyStatsList();

      this.changeState({ strategyStatsList, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.statsTableUnload();
    }
  }

  @flow(Flows.latest())
  async initRecord(): Promise<void> {
    this.detailLoad();
    try {
      const detailRecord = await this.fetchDetailRecord({ page: 1 });
      const { dataList, totalCount } = detailRecord;

      this.changeState({
        detailRecordList: dataList,
        total: totalCount,
        page: 1
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  @flow()
  handleDateRangeChange(
    dateRange: [string | undefined, string | undefined]
  ): void {
    if (!isValidDateRange(dateRange)) {
      return;
    }

    const validDateRange = validateDateRange(dateRange);
    if (!validDateRange) {
      return;
    }

    this.changeState({ dateRange: validDateRange });
    Promise.all([this.initStats(), this.initStrategyStatsList()]);
  }

  @flow(Flows.latest())
  async handleStrategyStatsListSearch(subStrategyId?: number): Promise<void> {
    this.changeState({ subStrategyId });

    const { dateRange } = this.state;
    const validDateRange = validateDateRange(dateRange);

    if (!validDateRange) {
      return;
    }

    this.initStrategyStatsList();
  }

  @flow()
  async handleDetailSearch(value): Promise<void> {
    this.detailLoad();
    const { range, ...rest } = value;
    this.changeState({
      page: 1,
      recordSearchParams: { from: range[0], to: range[1], ...rest }
    });

    try {
      const { dataList, totalCount } = await this.fetchDetailRecord({
        page: 1
      });
      this.changeState({ detailRecordList: dataList, total: totalCount });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  @flow(Flows.latest())
  async handleDetailPageChange(page: number): Promise<void> {
    this.detailLoad();
    try {
      const { dataList, totalCount } = await this.fetchDetailRecord({ page });
      this.changeState({ detailRecordList: dataList, total: totalCount, page });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  async handleExportDetail(): Promise<boolean> {
    const { recordSearchParams, total } = this.state;

    if (!total) {
      return false;
    }
    await exportCenterRequest.saveRecordDetailExportTask({
      taskType: remindType,
      ...recordSearchParams
    });
    return true;
  }

  async handleExportStats(): Promise<boolean> {
    const { stats, statsTabKey, strategyStatsList, dateRange, subStrategyId } =
      this.state;
    const [from, to] = dateRange;
    if (
      (!stats.valueList.length && statsTabKey === 'time') ||
      (!strategyStatsList.valueList.length && statsTabKey === 'task')
    ) {
      return false;
    }

    const params = { from, to };

    if (statsTabKey === 'time') {
      await exportCenterRequest.createDailySendStatsExportTask({
        remindType,
        ...params
      });
    } else {
      await exportCenterRequest.createStrategySendStatsExportTask({
        taskType,
        subStrategyId,
        ...params
      });
    }

    return true;
  }
}
