import React, { memo } from 'react';
import { Tooltip } from '@/components/widgets/tooltip';
import yanchiImg from '@/images/yanchi.png';
import css from './index.less';
import { DelayData } from '@/types/delayData';
import { compareDate, formatNow } from '@/utils/date';

const DelayIcon = memo(
  (props: React.PropsWithChildren<{ finalDate: string }>) => {
    const { finalDate } = props;

    return (
      <Tooltip
        title={
          <div>
            {finalDate}
            <span className={css.text}>显示最终数据</span>
          </div>
        }
      >
        <img className={css.yanchiImg} src={yanchiImg} alt="" />
      </Tooltip>
    );
  }
);

export const ItemWithDelayIcon = memo(
  ({ value }: { value: DelayData<string | number> }) => (
    <div className={css.itemText}>
      {value?.data ?? '-'}
      {value?.delay ? <DelayIcon finalDate={value.finalDate} /> : null}
    </div>
  )
);

export const ItemWithFinalDate = memo(
  ({ value }: { value: DelayData<string | number> }) => {
    const delay = value.finalDate
      ? compareDate(value?.finalDate, formatNow()) > 0
      : false;

    const { data, finalDate } = value || {};

    return (
      <div className={css.itemText}>
        {data ?? (value || '-')}
        {delay ? <DelayIcon finalDate={finalDate} /> : null}
      </div>
    );
  }
);

export const DunningBuyDelayAlert = memo(() => (
  <div className={css.content}>
    <div className={css.left}>
      <img className={css.yanchiImg} src={yanchiImg} alt="" />
      <span>非最终数据</span>
    </div>
    <div>
      1、当天催拍数据（催拍次数、催拍人数）
      <span className={css.gray}>当天24点完成统计</span>
      2、催拍效果数据（下单、付款数据）
      <span className={css.gray}>
        催拍3天后完成统计，如1日催拍，4日可查看最终数据
      </span>
    </div>
  </div>
));

export const DunningPayDelayAlert = memo((props: { delay?: number }) => {
  const { delay = 2 } = props;

  return (
    <div className={css.content}>
      <div className={css.left}>
        <img className={css.yanchiImg} src={yanchiImg} alt="" />
        <span>非最终数据</span>
      </div>
      <div>
        1、当天催付数据（催付次数、催付订单数）
        <span className={css.gray}>当天24点完成统计</span>
        2、催付效果数据（付款数据）
        <span className={css.gray}>
          催付{delay}天后完成统计；如1日催付，{delay + 1}日可查看最终数据
        </span>
      </div>
    </div>
  );
});

export const AfterConsultItemRecommendAlert = memo(() => {
  return (
    <div className={css.content}>
      <div className={css.left}>
        <img className={css.yanchiImg} src={yanchiImg} alt="" />
        <span>非最终数据</span>
      </div>
      <div>
        转化计算周期为3个自然天，建议下发3天后查看转化数据。如1日进行商品推荐，建议4日查看转化结果
      </div>
    </div>
  );
});

export default DelayIcon;
