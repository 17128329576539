// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_autoTooltipText_dsqP8 {\n  display: inline-block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  width: max-content;\n}\n.style_autoTooltipText_dsqP8 .style_spanWidth_n6bRw {\n  display: block;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/autoTooltipText/style.less"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;AANA;EAOI,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".autoTooltipText {\n  display: inline-block;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  width: max-content;\n  .spanWidth {\n    display: block;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoTooltipText": "style_autoTooltipText_dsqP8",
	"spanWidth": "style_spanWidth_n6bRw"
};
export default ___CSS_LOADER_EXPORT___;
