// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_main_QCgzj {\n  display: flex;\n  margin-bottom: 4px;\n}\n.style_checkbox_BfFjf {\n  margin-right: 8px;\n}\n.style_btn_RxDJB {\n  color: #1890ff;\n  margin-left: 8px;\n  cursor: pointer;\n}\n.style_label_oT3Gr {\n  flex: none;\n}\n.style_editIcon_mFmfP {\n  color: #3d7eff;\n  cursor: pointer;\n  margin-right: 32px;\n  vertical-align: middle;\n}\n.style_ruleList_gUjc3 {\n  margin: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindMainStrategyDialog/components/tradeStatus/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;AAEA;EACE,iBAAA;AAAF;AAGA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;AADF;AAIA;EACE,UAAA;AAFF;AAKA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;AAHF;AAMA;EACE,aAAA;AAJF","sourcesContent":[".main {\n  display: flex;\n  margin-bottom: 4px;\n}\n\n.checkbox {\n  margin-right: 8px;\n}\n\n.btn {\n  color: #1890ff;\n  margin-left: 8px;\n  cursor: pointer;\n}\n\n.label {\n  flex: none;\n}\n\n.editIcon {\n  color: #3d7eff;\n  cursor: pointer;\n  margin-right: 32px;\n  vertical-align: middle;\n}\n\n.ruleList {\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "style_main_QCgzj",
	"checkbox": "style_checkbox_BfFjf",
	"btn": "style_btn_RxDJB",
	"label": "style_label_oT3Gr",
	"editIcon": "style_editIcon_mFmfP",
	"ruleList": "style_ruleList_gUjc3"
};
export default ___CSS_LOADER_EXPORT___;
