import React, { memo, useEffect, useRef, useState } from 'react';
import css from './style.less';
import { Table, Column } from '@/components/widgets/table';
import { RangePicker } from '@/components/widgets/datePicker';
import { Input } from '@/components/widgets/input';
import { Button } from '@/components/widgets/button';
import { Select } from '@/components/widgets/select';
import { useAgentReducer, useAgentSelector } from 'use-agent-reducer';
import {
  initialDate,
  logTypesRef,
  OperationLogModel
} from '@/pages/operationLog/model';
import { warnError } from '@/components/infos';
import {
  ListParams,
  LogContent,
  LogDto,
  LogType
} from '@/requests/operationLog/type';
import moment from 'moment';
import { message } from '@/components/widgets/message';
import {
  operationLogService,
  truncateString
} from '@/pages/operationLog/service';
import {
  LogTypeProps,
  OperationAccountProps,
  Option
} from '@/pages/operationLog/type';
import { ImageLogContent } from '@/components/works/imageLogContent';
import { uniqueId } from 'lodash';
import { LogOperatePlatformTextMap } from '@/requests/operationLog/enum';

const LogTypeSelect = memo(({ value, onChange }: LogTypeProps) => {
  const {
    state: { logTypes },
    getLogTypes
  } = useAgentReducer(logTypesRef.current);
  useEffect(() => {
    getLogTypes().catch(e => warnError(e));
  }, []);
  return (
    <Select
      options={logTypes}
      value={value}
      onChange={onChange}
      style={{ width: 160 }}
      placeholder="请选择"
    />
  );
});

const OperationAccountSelect = memo(
  ({ value, onChange }: OperationAccountProps) => {
    const [accounts, setAccounts] = useState<Option[]>([]);
    const getLogTypes = async () => {
      const result = await operationLogService.listOperateAccounts();
      setAccounts(result.map(i => ({ value: i.id, label: i.staffName })));
    };
    const filterOption = (input, option) =>
      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    useEffect(() => {
      getLogTypes().catch(e => warnError(e));
    }, []);

    return (
      <Select
        options={accounts}
        value={value}
        onChange={onChange}
        style={{ width: 260 }}
        placeholder="请选择操作账号"
        showSearch
        filterOption={filterOption}
        optionFilterProp="children"
        mode="multiple"
      />
    );
  }
);

const RenderContent = memo(({ contents }: { contents: LogContent[] }) => {
  return (
    <>
      {contents.map(({ type, content }) =>
        type === 'TEXT' ? (
          <div key={uniqueId()} className={css.content}>
            {truncateString(content)}
          </div>
        ) : (
          <ImageLogContent key={uniqueId()} imageId={content} />
        )
      )}
    </>
  );
});

export default memo(() => {
  const {
    state: {
      pageNo,
      dataSource,
      total,
      from,
      to,
      keyword,
      enterpriseStaffIds,
      logType,
      version,
      pageSize
    },
    getLogList
  } = useAgentReducer(OperationLogModel);
  const logTypes = useAgentSelector(logTypesRef.current, s => s.logTypes);
  const [selectedAccount, setSelectedAccount] = useState<number[] | undefined>(
    enterpriseStaffIds
  );
  const [selectedLogType, setSelectedLogType] = useState<
    LogType | 'ALL' | undefined
  >('ALL');
  const [content, setContent] = useState(keyword);
  const [dateRange, setDateRange] = useState<[string, string]>([from, to]);
  const onPageChange = (page: number) => {
    getLogList({ pageNo: page }).catch(e => warnError(e));
  };
  const onSearch = () => {
    const [start, end] = dateRange;
    if (moment(start).add(31, 'd').isBefore(end)) {
      message.warning('开始时间和结束时间不能超过31天');
      return;
    }
    const params: Partial<ListParams> = {
      logType: selectedLogType === 'ALL' ? undefined : selectedLogType,
      enterpriseStaffIds: selectedAccount,
      keyword: content,
      from: dateRange[0],
      to: dateRange[1],
      pageNo: 1
    };
    getLogList(params);
  };
  const onReset = () => {
    const params: Partial<ListParams> = {
      logType: undefined,
      enterpriseStaffIds: undefined,
      keyword: undefined,
      from: initialDate.start,
      to: initialDate.end,
      pageNo: 1
    };
    getLogList(params).catch(e => warnError(e));
  };
  useEffect(() => {
    getLogList().catch(e => warnError(e));
  }, []);
  useEffect(() => {
    setSelectedAccount(enterpriseStaffIds);
    setSelectedLogType(logType || 'ALL');
    setContent(keyword);
    setDateRange([from, to]);
  }, [version]);

  return (
    <>
      <div>
        <div className={css.searchWrapper}>
          <div className={css.searchItem}>
            <span>时间范围：</span>
            <RangePicker
              value={dateRange}
              onChange={value => setDateRange(value)}
              allowClear={false}
            />
          </div>
          <div className={css.searchItem}>
            <span>操作内容：</span>
            <Input
              value={content}
              onChange={value => setContent(value)}
              placeholder="请输入搜索操作内容"
              maxLength={10}
            />
          </div>
        </div>
        <div className={css.searchWrapper}>
          <div className={css.searchItem}>
            <span>功能模块：</span>
            <LogTypeSelect
              value={selectedLogType}
              onChange={value => setSelectedLogType(value)}
            />
          </div>
          <div className={css.searchItem}>
            <span>操作账号：</span>
            <OperationAccountSelect
              value={selectedAccount}
              onChange={value => setSelectedAccount(value)}
            />
          </div>
          <div>
            <Button type="primary" onClick={onSearch}>
              搜索
            </Button>
            <Button onClick={onReset}>重置</Button>
          </div>
        </div>
      </div>
      <div className={css.operationLogList}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          bordered
          pagination={{
            position: ['bottomCenter'],
            current: pageNo,
            total,
            pageSize,
            onChange: page => onPageChange(page),
            showSizeChanger: false
          }}
        >
          <Column
            width="15%"
            key="operateTime"
            dataIndex="operateTime"
            title="时间"
            render={value => <div style={{ minWidth: 100 }}>{value}</div>}
          />
          <Column
            width="20%"
            key="operateAccount"
            dataIndex="operateAccount"
            title="操作账号"
            render={(_, v: LogDto) =>
              `${v.operateAccount}(${
                LogOperatePlatformTextMap[v.logOperateSource]
              })`
            }
          />
          <Column
            width="15%"
            key="logType"
            dataIndex="logType"
            title="功能模块"
            render={value => (
              <div>{logTypes.find(i => i.value === value)?.label}</div>
            )}
          />
          <Column
            dataIndex="contents"
            title="操作内容"
            width="50%"
            render={contents => <RenderContent contents={contents} />}
          />
        </Table>
      </div>
    </>
  );
});
