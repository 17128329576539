import { RemindType } from '@/types/remindType';
import routes from '@/routeConfig';

type Route = {
  list: string;
  setting: string;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const remindTypeRoute: Record<RemindType, Route> = {
  REMIND_BUY: {
    list: routes.dunningBuyStrategyList.path,
    setting: routes.dunningBuySetting.path
  },
  REMIND_PAY: {
    list: routes.dunningPayStrategyList.path,
    setting: routes.dunningPaySetting.path
  },
  REMIND_FINAL_PAY: {
    list: routes.dunningFinalPaymentList.path,
    setting: routes.dunningFinalPaymentSetting.path
  },
  PAYMENT_PUSH: {
    list: routes.paymentPushStrategyList.path,
    setting: routes.paymentPushSetting.path
  },
  FRONT_PAID_PAYMENT_PUSH: {
    list: routes.frontPaidPaymentPushStrategyList.path,
    setting: routes.frontPaidPaymentPushSetting.path
  },
  FINAL_PAID_PAYMENT_PUSH: {
    list: routes.finalPaidPaymentPushStrategyList.path,
    setting: routes.finalPaidPaymentPushSetting.path
  },
  CONSIGN_PUSH: {
    list: routes.consignPushStrategyList.path,
    setting: routes.consignPushStrategySetting.path
  },
  LOGISTIC_STAGNATE_PUSH: {
    list: routes.logisticStagnatePushStrategyList.path,
    setting: routes.logisticStagnatePushStrategySetting.path
  },
  DELIVERY_PUSH: {
    list: routes.deliveryPushStrategyList.path,
    setting: routes.deliveryPushStrategySetting.path
  },
  SIGN_PUSH: {
    list: routes.signPushStrategyList.path,
    setting: routes.signPushStrategySetting.path
  },
  RECEIVED_PUSH: {
    list: routes.receivedPushStrategyList.path,
    setting: routes.receivedPushStrategySetting.path
  },
  APPLY_REFUND_PUSH: {
    list: routes.refundPushStrategyList.path,
    setting: routes.refundPushSetting.path
  },
  GUIDE_RETURN_GOODS: {
    list: routes.refundPushGuideList.path,
    setting: routes.refundPushGuideSetting.path
  },
  URGE_RETURN_GOODS_PUSH: {
    list: routes.refundPushUrgeReturnList.path,
    setting: routes.refundPushUrgeReturnSetting.path
  },
  REFUND_SUCCESS_PUSH: {
    list: routes.refundSuccessList.path,
    setting: routes.refundSuccessSetting.path
  },
  APPLY_EXCHANGE_PUSH: {
    list: routes.exchangePushStrategyList.path,
    setting: routes.exchangePushStrategySetting.path
  },
  TRADE_NOPAID_CLOSED_PUSH: {
    list: routes.tradeNopaidClosedPushStrategyList.path,
    setting: routes.tradeNopaidClosedPushStrategySetting.path
  },
  REPLY_TIMEOUT_PUSH: {
    list: routes.replyTimeoutPushList.path,
    setting: routes.replyTimeoutPushSetting.path
  },
  INVITE_RATE: {
    list: routes.inviteRateStrategyList.path,
    setting: routes.inviteRateStrategySetting.path
  }
};

export const showItemSku = [
  'REMIND_PAY',
  'REMIND_FINAL_PAY',
  'PAYMENT_PUSH',
  'FRONT_PAID_PAYMENT_PUSH',
  'FINAL_PAID_PAYMENT_PUSH',
  'CONSIGN_PUSH',
  'LOGISTIC_STAGNATE_PUSH',
  'DELIVERY_PUSH',
  'SIGN_PUSH',
  'RECEIVED_PUSH',
  'APPLY_REFUND_PUSH',
  'GUIDE_RETURN_GOODS',
  'URGE_RETURN_GOODS_PUSH',
  'REFUND_SUCCESS_PUSH',
  'APPLY_EXCHANGE_PUSH',
  'TRADE_NOPAID_CLOSED_PUSH'
];
