import React, { memo } from 'react';
import {
  Chart,
  Interval,
  Tooltip,
  Axis,
  Coordinate,
  Interaction,
  Legend
} from 'bizcharts';
import { MarketingFlowUsedRateWebDto } from '@/requests/buyerMembermarketStats/type';
import { FlowUsedRateMap } from '../../constants';
import { ListItem } from 'bizcharts/lib/plots/core/dependents';
import { Empty } from '@/components/widgets/empty';

export const FlowUsedARateChart = memo(
  (props: { flowUsedRateStats: MarketingFlowUsedRateWebDto }) => {
    const { flowUsedRateStats } = props;
    const { candidateBuyerNum, sendBuyerNum, flowUsedRate } = flowUsedRateStats;

    const originData = Object.entries(flowUsedRateStats)
      .map(i => {
        const [item, count] = i;

        return {
          item: FlowUsedRateMap[item],
          count:
            item === 'flowUsedRate' ? `${(count * 100).toFixed(2)}%` : count
        };
      })
      .concat({ item: '待发送买家', count: candidateBuyerNum - sendBuyerNum });

    const data = [
      {
        item: '已发送买家',
        count: sendBuyerNum
      },
      {
        item: '待发送买家',
        count: candidateBuyerNum - sendBuyerNum
      },
      {
        item: '总可发送买家'
      },
      {
        item: '流量利用率'
      }
    ];

    return sendBuyerNum && candidateBuyerNum ? (
      <div style={{ position: 'relative' }}>
        <Chart
          height={240}
          data={data}
          autoFit
          onGetG2Instance={c => {
            c.removeInteraction('legend-filter');
          }}
        >
          <Coordinate type="theta" radius={0.7} innerRadius={0.7} />
          <Tooltip showTitle={false} />
          {/* <Axis visible={false} /> */}
          <Legend
            position="right-bottom"
            name="item"
            offsetX={-30}
            itemHeight={16}
            itemName={{
              spacing: 2, // 文本同滑轨的距离
              formatter: (text: string, item: ListItem, index: number) =>
                `${text}:`,
              style: { fontSize: 14 }
            }}
            itemValue={{
              formatter: (text: string, item: ListItem, index: number) =>
                `${originData.filter(i => i.item === text)[0].count}`,
              style: { fontSize: 14 }
            }}
          />
          <Interval
            position="count"
            adjust="stack"
            color="item"
            style={{
              lineWidth: 1,
              stroke: '#fff'
            }}
            label={[
              'count',
              {
                content: data =>
                  `${data.item}\n${`${(
                    (data.count / candidateBuyerNum) *
                    100
                  ).toFixed(2)}%`}`,
                offset: 15
              }
            ]}
          />
          <Interaction type="element-single-selected" />
        </Chart>
      </div>
    ) : (
      <Empty style={{ marginTop: '8%' }} />
    );
  }
);
