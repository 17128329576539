import React, { PropsWithChildren, memo, useState } from 'react';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { TextArea } from '@/components/widgets/input';
import { Checkbox } from '@/components/widgets/checkbox';
import { RadioGroup } from '@/components/widgets/radio';
import {
  sendMessageSpeedOptions,
  traceOrderSelfModifyAllowedOptions,
  trackOrderEnableOptions
} from '../constants';
import ConditionUnit from '@/components/layouts/conditionUnit';
import css from './style.less';
import { CheckboxOptionType } from 'antd/es/checkbox/Group';
import { verifyParams } from './service';
import { compact } from 'lodash';
import shopRequest from '@/requests/shop';
import { warnError } from '@/components/infos';
import { uiPrompt } from '@/avatars';
import { Spin } from '@/components/widgets/spin';
import { Button } from '@/components/widgets/button';
import { usePlatform } from '@/hooks/usePlatform';

const CheckboxWrapper = memo(
  (
    props: PropsWithChildren<{
      checkboxLabel: string;
      options: (string | CheckboxOptionType)[];
      value: any;
      onChange: (v: any) => void;
    }>
  ) => {
    const { checkboxLabel, options, value, onChange } = props;
    const { checked, radioValue } = value;

    const handleCheckboxChange = e => {
      onChange({
        checked: e.target.checked,
        radioValue: e.target.value || undefined
      });
    };

    const handleRadioChange = e => {
      onChange({
        ...value,
        radioValue: e.target.value
      });
    };

    return (
      <div className={css.item}>
        <Checkbox
          checked={checked}
          style={{ marginRight: '12px' }}
          onChange={handleCheckboxChange}
        >
          {checkboxLabel}
        </Checkbox>
        <RadioGroup
          value={radioValue}
          options={options}
          disabled={!checked}
          onChange={handleRadioChange}
        />
      </div>
    );
  }
);

export const BatchDialog = memo((props: DialogDeployProps<void>) => {
  const { closeDialog } = props;
  const { isJD } = usePlatform();
  const [loading, setLoading] = useState(false);
  const [subAccounts, setSubAccounts] = useState('');
  const [trackOrderEnable, setTrackOrderEnable] = useState({
    checked: false,
    radioValue: undefined
  });
  const [traceOrderSelfModifyAllowed, setTraceOrderSelfModifyAllowed] =
    useState({
      checked: false,
      radioValue: undefined
    });
  const [sendMessageSpeed, setSendMessageSpeed] = useState({
    checked: false,
    radioValue: undefined
  });

  const handleOk = async () => {
    const inputList = compact(subAccounts.trim().split('\n'));
    const params = {
      subNicks: isJD ? inputList : undefined,
      subAccounts: isJD ? undefined : inputList,
      trackerOrderEnabledOption: trackOrderEnable.checked,
      trackerOrderEnabled: trackOrderEnable.radioValue,
      traceOrderSelfModifyAllowedOption: traceOrderSelfModifyAllowed.checked,
      traceOrderSelfModifyAllowed: traceOrderSelfModifyAllowed.radioValue,
      sendMessageSpeedLevelOption: sendMessageSpeed.checked,
      sendMessageSpeedLevel: sendMessageSpeed.radioValue
    };

    const err = verifyParams(params);

    if (err) {
      uiPrompt.current.warning(err);
      return;
    }

    try {
      setLoading(true);
      const res = await shopRequest.batchUpdatePermission(params);
      uiPrompt.current.success('操作成功');
      closeDialog(res);
    } catch (error) {
      warnError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog title="批量操作" onCancel={closeDialog} onOk={handleOk}>
      <Spin spinning={loading}>
        <ConditionUnit label="操作内容" name="" className={css.unit}>
          <div>
            <CheckboxWrapper
              checkboxLabel="跟单开关"
              options={trackOrderEnableOptions}
              value={trackOrderEnable}
              onChange={setTrackOrderEnable}
            />
            {isJD ? null : (
              <>
                <CheckboxWrapper
                  checkboxLabel="是否允许客服自己开启/关闭跟单开关"
                  options={traceOrderSelfModifyAllowedOptions}
                  value={traceOrderSelfModifyAllowed}
                  onChange={setTraceOrderSelfModifyAllowed}
                />
                <CheckboxWrapper
                  checkboxLabel="发送速度"
                  options={sendMessageSpeedOptions}
                  value={sendMessageSpeed}
                  onChange={setSendMessageSpeed}
                />
              </>
            )}
          </div>
        </ConditionUnit>

        <ConditionUnit required label="操作对象" name="">
          <div>
            <TextArea
              className={css.item}
              autoSize={{ minRows: 12, maxRows: 12 }}
              placeholder={
                isJD
                  ? '请换行填写子账号，单次支持处理200个子账号\n示例：\nAAAAAAAA\nBBBBBBBB'
                  : '请换行填写子账号，单次支持处理200个子账号\n示例：\nXX旗舰店:YY\nXX旗舰店:ZZ'
              }
              value={subAccounts}
              onChange={setSubAccounts}
            />
          </div>
        </ConditionUnit>
      </Spin>
    </Dialog>
  );
});
