import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import {
  DatePicker as SourceDatePicker,
  TimePicker as SourceTimePicker,
  DatePickerProps,
  TimeRangePickerProps
} from 'antd';
import { toMoment, format, formatNow, formatYesterday } from '@/utils/date';
import { EventValue, RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { IRangePickerProps } from './type';
import css from './style.less';

export const DatePicker = memo((props: DatePickerProps) => (
  <SourceDatePicker {...props} />
));

export const RangeTimePicker = memo((props: TimeRangePickerProps) => {
  const timeFormat = 'HH:mm:ss';
  const { value, onChange, ...rest } = props;

  const rangeValue: RangeValue<Moment> | undefined = useMemo(() => {
    if (!value || !Array.isArray(value)) {
      return undefined;
    }
    const [start, end] = value;
    return [moment(start, timeFormat), moment(end, timeFormat)];
  }, [value]);

  const handleChange = useCallback(
    (moments: unknown, values) => {
      if (!onChange) {
        return;
      }
      const [start, end] = values;
      onChange(start && end ? values : undefined, [timeFormat, timeFormat]);
    },
    [onChange]
  );

  return (
    <SourceTimePicker.RangePicker
      {...rest}
      value={rangeValue}
      onChange={handleChange}
      getPopupContainer={trigger => trigger}
    />
  );
});

export const RangePicker = memo((props: IRangePickerProps) => {
  const { value, onChange, ...rest } = props;

  const rangeValue: [EventValue<Moment>, EventValue<Moment>] | undefined =
    useMemo(() => {
      if (!value || !Array.isArray(value)) {
        return value;
      }
      const [start, end] = value;
      return [toMoment(start), toMoment(end)];
    }, [value]);

  const handleChange = useCallback(
    (moments: unknown, values: [string, string]) => {
      if (!onChange) {
        return;
      }
      onChange(values);
    },
    [onChange]
  );
  return (
    <SourceDatePicker.RangePicker
      {...rest}
      value={rangeValue}
      onChange={handleChange}
      getPopupContainer={trigger => trigger}
    />
  );
});

export const CustomRangePicker = memo((props: IRangePickerProps) => {
  const [timeType, setTimeType] = useState<string>('');
  const { value, version, onChange, onShortcutChange, ...rest } = props;

  useEffect(() => {
    setTimeType('');
  }, [version]);

  const rangeValue: [EventValue<Moment>, EventValue<Moment>] | undefined =
    useMemo(() => {
      if (!value || !Array.isArray(value)) {
        return value;
      }
      const [start, end] = value;
      return [toMoment(start), toMoment(end)];
    }, [value]);

  const handleChange = useCallback(
    (moments: unknown, values: [string, string]) => {
      if (!onChange) {
        return;
      }
      setTimeType('');
      onChange(values);
    },
    [onChange]
  );

  const handleBtnClick = useCallback(
    type => {
      if (!onChange) {
        return;
      }
      setTimeType(type);
      if (type === 'NOW') {
        onChange([formatNow(), formatNow()]);
      }
      if (type === 'YESTERDAY') {
        onChange([formatYesterday(), formatYesterday()]);
      }
      if (type === 'WEEK' || type === 'MOUTH') {
        const time = new Date().getTime();
        const num = type === 'WEEK' ? -6 : -30;
        const start = new Date(time + num * 24 * 3600 * 1000);
        onChange([format(start), format(time)]);
      }
      if (onShortcutChange) {
        onShortcutChange();
      }
    },
    [onChange]
  );

  const nowClass = classNames(css.info, timeType === 'NOW' ? css.active : '');
  const yesClass = classNames(
    css.info,
    timeType === 'YESTERDAY' ? css.active : ''
  );
  const weekClass = classNames(css.info, timeType === 'WEEK' ? css.active : '');
  const mouthClass = classNames(
    css.info,
    timeType === 'MOUTH' ? css.active : ''
  );
  return (
    <span>
      <SourceDatePicker.RangePicker
        {...rest}
        value={rangeValue}
        onChange={handleChange}
        getPopupContainer={trigger => trigger}
      />
      <span className={css.main}>
        <span className={nowClass} onClick={() => handleBtnClick('NOW')}>
          今
        </span>
        <span className={css.line} />
        <span className={yesClass} onClick={() => handleBtnClick('YESTERDAY')}>
          昨
        </span>
        <span className={css.line} />
        <span className={weekClass} onClick={() => handleBtnClick('WEEK')}>
          周
        </span>
        <span className={css.line} />
        <span className={mouthClass} onClick={() => handleBtnClick('MOUTH')}>
          月
        </span>
      </span>
    </span>
  );
});
