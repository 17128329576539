import React, { ChangeEvent, memo, useCallback } from 'react';
import { Input as SourceInput, InputNumber as SourceInputNumber } from 'antd';
import { InputProps, TextAreaProps } from './type';
import css from './style.less';

export const CustomInput = React.forwardRef((props: InputProps, ref: any) => {
  const {
    onChange,
    showCount = false,
    maxLength,
    value,
    trim,
    ...rest
  } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: val } = event.target;
      if (!onChange) {
        return;
      }
      onChange(trim ? val.trim() : val, event);
    },
    [onChange]
  );

  return (
    <span className={css.input}>
      <SourceInput
        value={value}
        ref={ref}
        maxLength={maxLength}
        onChange={handleChange}
        {...rest}
      />
      {showCount && maxLength && (
        <span className={css.count}>
          {(value && value.toString().length) || 0}/{maxLength}
        </span>
      )}
    </span>
  );
});

export const Input = React.forwardRef(
  (props: InputProps & { blurTrim?: boolean }, ref) => {
    const { onBlur, blurTrim, onChange, ...rest } = props;
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
      if (onBlur) {
        onBlur(e);
      }
      if (!blurTrim) {
        return;
      }
      if (onChange) {
        onChange(e.target.value.trim(), e);
      }
    };

    return (
      <CustomInput
        {...rest}
        ref={ref}
        onChange={onChange}
        onBlur={handleBlur}
      />
    );
  }
);

export const { Group } = SourceInput;

const SourceTextArea = SourceInput.TextArea;

export const TextArea = React.forwardRef((props: TextAreaProps, ref: any) => {
  const { onChange, ...rest } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      if (!onChange) {
        return;
      }
      onChange(value, event);
    },
    [onChange, props.maxLength]
  );

  return <SourceTextArea {...rest} ref={ref} onChange={handleChange} />;
});

export const InputNumber = SourceInputNumber;
