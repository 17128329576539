// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_img_iHqGt {\n  aspect-ratio: calc(1/1);\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n.style_bordered_saZvl {\n  border: 1px solid #e6e8ed;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/miniImage/style.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;AACF;AAEA;EACE,yBAAA;AAAF","sourcesContent":[".img {\n  aspect-ratio: calc(1/1);\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.bordered {\n  border: 1px solid rgb(230, 232, 237);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "style_img_iHqGt",
	"bordered": "style_bordered_saZvl"
};
export default ___CSS_LOADER_EXPORT___;
