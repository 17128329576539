import { Unit } from '@/types/unitTime';
import { RemindTimesType } from '@/types/remindTimesType';
import { RemindTimeConfigButtonTextConfig } from './type';

export const timeUnitTextMap: Record<Unit, string> = {
  HOURS: '小时',
  MINUTES: '分钟',
  DAYS: '天',
  SECONDS: '秒'
};

export const timeUnitRangeMap: Record<Unit, [number, number]> = {
  HOURS: [1, 24],
  MINUTES: [1, 60],
  DAYS: [1, 30],
  SECONDS: [1, 60]
};

export const remindTimesTypeTextMap: Record<RemindTimesType, string> = {
  FIRST_REMIND: '第一次推送',
  SECOND_REMIND: '第二次推送',
  THIRD_REMIND: '第三次推送',
  FOURTH_REMIND: '第四次推送',
  FIFTH_REMIND: '第五次推送'
};

export const orderedRemindTimesType: RemindTimesType[] = [
  'FIRST_REMIND',
  'SECOND_REMIND',
  'THIRD_REMIND',
  'FOURTH_REMIND',
  'FIFTH_REMIND'
];

export const remindTimesTextConfigMap: Record<
  RemindTimesType,
  RemindTimeConfigButtonTextConfig
> = {
  FIRST_REMIND: {
    prefix: '请写前缀',
    suffix: '后',
    tip: `${remindTimesTypeTextMap.FIRST_REMIND}时间`
  },
  SECOND_REMIND: {
    prefix: remindTimesTypeTextMap.FIRST_REMIND,
    suffix: '后',
    tip: `${remindTimesTypeTextMap.SECOND_REMIND}时间`
  },
  THIRD_REMIND: {
    prefix: remindTimesTypeTextMap.SECOND_REMIND,
    suffix: '后',
    tip: `${remindTimesTypeTextMap.THIRD_REMIND}时间`
  },
  FOURTH_REMIND: {
    prefix: remindTimesTypeTextMap.THIRD_REMIND,
    suffix: '后',
    tip: `${remindTimesTypeTextMap.FOURTH_REMIND}时间`
  },
  FIFTH_REMIND: {
    prefix: remindTimesTypeTextMap.FOURTH_REMIND,
    suffix: '后',
    tip: `${remindTimesTypeTextMap.FIFTH_REMIND}时间`
  }
};
