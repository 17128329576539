import React, { memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import css from './style.less';

interface ContainerProps {
  readonly className?: string;
}

export const LayoutContainer = memo(
  ({ children, className }: PropsWithChildren<ContainerProps>) => (
    <div className={classNames(css.container, className)}>{children}</div>
  )
);

const Logo = memo(() => {
  return (
    <div className={css.logo}>
      <span className={css.image} />
      <div>
        <div className={classNames(css.appName, css.nowrap)}>智能跟单助手</div>
      </div>
    </div>
  );
});

export const LeftContainer = memo(
  ({ children, className }: PropsWithChildren<ContainerProps>) => (
    <div className={classNames(css.left, className)}>
      <Logo />
      <div className={css.menuWrap}>{children}</div>
    </div>
  )
);

export const RightContainer = memo(
  ({ children, className }: PropsWithChildren<ContainerProps>) => (
    <div className={classNames(css.right, className)}>{children}</div>
  )
);

interface DecoratorProps {
  readonly title: string;
  readonly desc?: string;
  readonly className?: string;
}

export const Decorator = memo(({ title, desc, className }: DecoratorProps) => (
  <div className={classNames(css.dev, className)}>
    <span className={css.title}>{title}</span>
    <span className={css.desc}>{desc}</span>
  </div>
));

interface DevelopingProps {
  readonly title: string;
  readonly className?: string;
}

export const Developing = memo(({ title, className }: DevelopingProps) => (
  <Decorator title={title} className={className} desc="正在开发中......" />
));
