import { http } from '@/libs/http';
import type { PageDetail } from '@/types/pageDetail';
import type {
  ConsignPushDetailRecordParams,
  ConsignPushDetailRecordWebDto,
  ConsignPushStatsParams,
  ConsignPushStatsWebDto
} from './type';

class ConsignPushStatsRequest {
  private http = http('/api/shop/consign-push');

  // 查询明细接口
  recordList(
    params: ConsignPushDetailRecordParams
  ): Promise<PageDetail<ConsignPushDetailRecordWebDto>> {
    return this.http.path('record-list').setRequestParams(params).get();
  }

  // 查询统计数据接口
  statsList(params: ConsignPushStatsParams): Promise<ConsignPushStatsWebDto> {
    return this.http.path('stats-list-v2').setRequestBody(params).post();
  }
}

export default new ConsignPushStatsRequest();
