import { OriginAgent, sharing } from 'agent-reducer';
import { PositionCodes } from '@/components/works/advertisement/type';

class Stack implements OriginAgent {
  state = {
    values: [
      { name: 'ad', visible: null },
    ],
    current: null,
    positionCodes: [] as PositionCodes[],
  };

  setDialogVisible(key: string, visible: boolean, codes?: string[]) {
    const { values, current, positionCodes: preCodes } = this.state;
    const positionCodes = codes || preCodes;
    if (current !== null) {
      return this.state;
    }
    const nextValues = values.map(value => {
      if (value.name === key && value.visible === null) {
        return { ...value, visible };
      }
      return { ...value };
    });

    const ready = nextValues.every(v => v.visible !== null);
    if (!ready) {
      return { values: nextValues, current: null, positionCodes: [] };
    }
    const first = nextValues.find(v => v.visible);
    if (!first) {
      return { values: nextValues, current: null, positionCodes: [] };
    }
    return { values: nextValues, current: first.name, positionCodes };
  }
}

export const stack = sharing(() => Stack);
