import { useState } from 'react';
import { Platform } from '@/types/platform';
import { getItem } from '@/utils/storage';
import useCreation from './useCreation';

export const usePlatform = () => {
  const [state, setState] = useState<Platform>('TB');

  const platform = (getItem('platform') || 'TB') as Platform;

  useCreation(() => {
    setState(platform);
  }, [platform]);

  return { platform: state, isTB: state === 'TB', isJD: state === 'JD' };
};

export const isJD = () => {
  return getItem('platform') === 'JD';
};
