export function computeQuantity(
  image: HTMLImageElement,
  maxSize?: { width: number; height: number }
): number {
  const { width, height } = image;
  const { width: maxWidth = width, height: maxHeight = height } = maxSize || {};
  const widthPress = maxWidth / width;
  const heightPress = maxHeight / height;
  if (widthPress >= 1 && heightPress >= 1) {
    return 1;
  }
  return Math.min(widthPress, heightPress);
}

export function createImage(
  url: string,
  width?: number,
  height?: number
): Promise<HTMLImageElement> {
  return new Promise(resolve => {
    const image = new Image(width, height);
    image.src = url;
    image.onload = function () {
      resolve(image);
    };
  });
}

export function imageBlobToBase64(imageBlob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    reader.onerror = reject;
    reader.onloadend = function () {
      const stream = this.result;
      if (!stream) {
        reject();
        return;
      }
      resolve(stream.toString());
    };
  });
}

export function readImage(file: File): Promise<HTMLImageElement> {
  const loadFile = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      const stream = this.result;
      if (!stream) {
        reject();
        return;
      }
      resolve(stream.toString());
    };
  }) as Promise<string>;
  return loadFile.then((base64Content: string) => createImage(base64Content));
}

export function pressImage(
  image: HTMLImageElement,
  quantity = 1
): Promise<HTMLImageElement> {
  const press = new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = image.width * quantity;
    canvas.height = image.height * quantity;
    const newImage = new Image(canvas.width, canvas.height);
    newImage.src = image.src;
    newImage.onload = function () {
      const context = canvas.getContext('2d');
      if (!context) {
        reject();
        return;
      }
      context.drawImage(newImage, 0, 0, canvas.width, canvas.height);
      const base64Content = canvas.toDataURL('image/png', quantity);
      resolve(base64Content);
    };
  }) as Promise<string>;
  return press.then((base64Content: string) => createImage(base64Content));
}

export function getImageData(
  image: HTMLImageElement
): Omit<ImageData, 'data'> & { data: Array<number> } {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d');
  context!.drawImage(image, 0, 0, canvas.width, canvas.height);
  const { data, width, height } = context!.getImageData(
    0,
    0,
    canvas.width,
    canvas.height
  );
  const array: Array<number> = [];
  for (let i = 0; i < data.length; i++) {
    array.push(data[i]);
  }
  return {
    data: array,
    width,
    height
  };
}


// unit为整十像素
export function getThumbnail(url: string, unit = 'sum') {
  if (!url) {
    return ''
  }
  return `${url}_${unit}.jpg`
}
