import React, { memo, useEffect } from 'react';
import { Popover } from '@/components/widgets/popover';
import { Tree } from '@/components/widgets/tree';
import { useAgentReducer } from 'use-agent-reducer';
import { itemGroupModelRef } from '@/pages/itemGroup/model';
import { DataNode } from '@/pages/itemGroup/type';

type ItemGroupPopProps = {
  title?: string;
  label?: string;
  ids: number[];
  className?: string;
  isTablePop?: boolean;
};

export const searchGroupById = (tree, key: number): DataNode[] => {
  return tree.flatMap(node => {
    if (node?.key === key) {
      return { ...node }; // 返回包含父节点和匹配节点的对象
    }

    if (node.children && node.children.length > 0) {
      const foundInChildren = searchGroupById(node.children, key);

      if (foundInChildren.length) {
        return {
          ...node,
          children: foundInChildren
        }; // 找到子节点对象
      }
    }

    return [];
  });
};

export const mergeSameKeyChildren = (treeData: DataNode[]): DataNode[] => {
  return treeData.reduce((result: DataNode[], current) => {
    const existingItem = result.find(item => item.key === current.key);

    if (existingItem) {
      existingItem.children = [
        ...(existingItem.children || []),
        ...(current.children || [])
      ];
    } else {
      result.push({ ...current });
    }

    return result;
  }, []);
};

export const ItemGroupPop = memo((props: ItemGroupPopProps) => {
  const {
    title = '',
    label = '',
    ids = [],
    className,
    isTablePop = false
  } = props;

  const {
    state: { originGroupTree },
    init
  } = useAgentReducer(itemGroupModelRef.current);

  const newItemGroupList = originGroupTree.flatMap(i =>
    i.children?.length ? i : []
  );
  const allParenteCids = Array.from(new Set(newItemGroupList.map(i => i.key)));
  const itemGroupNum = ids.filter(i => !allParenteCids.includes(i)).length;

  const childrenIds = ids.filter(i => !allParenteCids.includes(i));
  const treeData = childrenIds.flatMap(i =>
    searchGroupById(newItemGroupList, i)
  );
  const newTreeData = mergeSameKeyChildren(treeData);

  useEffect(() => {
    init();
  }, []);

  return (
    <Popover
      placement="bottomLeft"
      title={title || `共${itemGroupNum}个商品组`}
      content={
        <div style={{ maxHeight: '192px', overflow: 'auto' }}>
          <Tree treeData={newTreeData} defaultExpandAll />
        </div>
      }
    >
      <span className={className}>
        {label ||
          (isTablePop
            ? `${itemGroupNum}个关联商品组`
            : `已选择 ${itemGroupNum} 个商品组`)}
      </span>
    </Popover>
  );
});
