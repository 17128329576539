// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_footer_XAsQr {\n  text-align: right;\n  background: transparent;\n}\n.style_footer_XAsQr .style_button_SmPer + .style_button_SmPer {\n  margin-left: 8px;\n}\n.ant-modal-content {\n  border-radius: 12px !important;\n}\n.ant-modal-header {\n  border-radius: 12px 12px 0 0 !important;\n}\n.ant-modal-body {\n  user-select: text;\n}\n.ant-modal-footer .style_footer_XAsQr {\n  display: flex;\n  flex-direction: row-reverse;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/modal/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,uBAAA;AACF;AAHA;EAKI,gBAAA;AACJ;AAGA;EACE,8BAAA;AADF;AAIA;EACE,uCAAA;AAFF;AAKA;EACE,iBAAA;AAHF;AAMA;EAEI,aAAA;EACA,2BAAA;AALJ","sourcesContent":[".footer {\n  text-align: right;\n  background: transparent;\n\n  .button + .button {\n    margin-left: 8px;\n  }\n}\n\n:global(.ant-modal-content) {\n  border-radius: 12px !important;\n}\n\n:global(.ant-modal-header) {\n  border-radius: 12px 12px 0 0 !important;\n}\n\n:global(.ant-modal-body) {\n  user-select: text;\n}\n\n:global(.ant-modal-footer) {\n  .footer {\n    display: flex;\n    flex-direction: row-reverse;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "style_footer_XAsQr",
	"button": "style_button_SmPer"
};
export default ___CSS_LOADER_EXPORT___;
