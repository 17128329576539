import React, { memo, useEffect, useMemo, useState } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { DataNode } from 'antd/lib/tree';
import { Button } from '@/components/widgets/button';
import EditableText from '@/components/works/editableText';
import AutoCompleteCompose from '@/components/widgets/autoCompleteCompose';
import { Empty } from '@/components/layouts/empty';
import { Spin } from '@/components/widgets/spin';
import { Affix } from '@/components/widgets/affix';
import { Modal } from '@/components/widgets/modal';
import { Icon } from '@/components/widgets/icon';
import { Select, Option } from '@/components/widgets/select';
import { usePersistFn } from '@/hooks/usePersistFn';
import { block } from '@/libs/history';
import { themeColor } from '@/utils/color';
import { DraggableTree } from '../draggableTree';
import { flatTreeData, isEmptyKey, updateRenderTree } from '../../util';
import { itemGroupModelRef } from '../../model';
import css from '../../style.less';
import { usePlatform } from '@/hooks/usePlatform';

export const editInputClassName = 'editable-text';
export const placeholderMap = {
  GROUP_NAME: '请输入商品组名称',
  TAOBAO_IID: '请输入商品ID',
  TAOBAO_SKU_ID: '请输入SKU编号'
};

export default memo(props => {
  const {
    prompt,
    state: {
      groupLoading,
      searchType,
      keyword,
      groupTree,
      expandedKeys,
      currentGroupId
    },
    changeState,
    handleChangeSearchType,
    handleChangeKeyword,
    init,
    fetchGroup,
    handleAddFirstItemGroup,
    handleAddSecondItemGroup,
    handleExpand,
    handleDeleteGroup,
    create,
    update,
    hanldeSelectItemGroup,
    handleGroupSearch
  } = useAgentReducer(itemGroupModelRef.current);

  const { isJD } = usePlatform();

  prompt.implement({
    warnModel: (actionType, usingFeats) => {
      Modal.warn({
        title: '温馨提示',
        content: (
          <>
            <div>该客服组正在被使用，不可{actionType} </div>
            <div>使用中的功能：{usingFeats.join('、')}</div>
          </>
        )
      });
    }
  });

  const options = useMemo(
    () => (searchType === 'GROUP_NAME' ? flatTreeData(groupTree) : []),
    [groupTree, searchType]
  );

  const unblock = block(async bx => {
    const hasEditing =
      document.getElementsByClassName(editInputClassName).length;
    if (hasEditing) {
      const leave = await Modal.confirm({
        title: '温馨提示',
        content: '有未保存的商品组，确认离开？'
      });
      if (!leave) {
        return;
      }
    }
    unblock();
    bx.retry();
  });

  useEffect(() => {
    init();
  }, []);

  const groupValidator = (v, key) => {
    if (!v?.trim()) {
      return '请输入商品组名称';
    }
    if (options.some(d => d.title === v && d.key !== key)) {
      return '商品组名称不可重复';
    }
  };

  const handleOk = usePersistFn((value: string, key: React.Key) => {
    const isCreate = isEmptyKey(key);

    if (isCreate) {
      create({ value, key });
      return;
    }
    update({ value, key });
  });

  const handleCancel = (key: React.Key) => {
    if (isEmptyKey(key)) {
      const newGroupTree = updateRenderTree(groupTree, key);

      changeState({ groupTree: newGroupTree });
    }
  };

  const groupData = useMemo(() => {
    const deleteConfirmTip = v => {
      if (isJD) {
        return v
          ? '删除一级商品组会同时删掉其关联的所有二级商品组，且取消答案、跟单任务等的关联关系，是否确认'
          : '删除二级商品组会取消其与答案、跟单任务等的关联关系，是否确认';
      }

      return v
        ? '删除一级商品组会同时删掉其关联的所有二级商品组，是否确认'
        : '确认删除吗';
    };

    const loop = (data: DataNode[]): DataNode[] =>
      data.map(item => {
        const title = (
          <EditableText
            value={item.title as string}
            onOk={v => handleOk(v.trim(), item.key)}
            onClick={() => hanldeSelectItemGroup(item.key)}
            onCancel={() => handleCancel(item.key)}
            onDelete={() => handleDeleteGroup(item.key)}
            className={css.treeNode}
            activeStyle={{
              color: item.key === currentGroupId ? themeColor : undefined
            }}
            inputOptions={{
              autoFocus: true,
              maxLength: 20,
              className: editInputClassName,
              id: `${item.key}`
            }}
            validate={v => groupValidator(v, item.key)}
            extraAction={
              item.children ? (
                <Button
                  type="link"
                  onClick={() => handleAddSecondItemGroup(item.key)}
                  icon={<Icon type="a-tianjiabeifen2" />}
                />
              ) : null
            }
            edit={isEmptyKey(item.key)}
            hiddenAction={item.key === 0}
            deleteConfirmTip={deleteConfirmTip(item.children)}
          />
        );

        if (item.children) {
          return { title, key: item.key, children: loop(item.children) };
        }

        return {
          title,
          key: item.key
        };
      });

    return loop(groupTree);
  }, [groupTree, currentGroupId]);

  return (
    <>
      <Affix target={() => document.getElementById('item-group')}>
        <div style={{ background: '#fff', padding: '12px' }}>
          <div className={css.header}>
            <h3>商品组</h3>
            <Button type="primary" onClick={handleAddFirstItemGroup}>
              新建商品组
            </Button>
          </div>
          <div style={{ display: 'flex' }}>
            <Select
              value={searchType}
              style={{ width: '100px' }}
              onChange={handleChangeSearchType}
            >
              <Option value="GROUP_NAME">搜名称</Option>
              {isJD ? (
                <Option value="TAOBAO_SKU_ID">搜SKU编号</Option>
              ) : (
                <Option value="TAOBAO_IID">搜商品ID</Option>
              )}
            </Select>
            <AutoCompleteCompose
              value={keyword}
              onChange={v => handleChangeKeyword(v)}
              onSearch={v => handleGroupSearch(v)}
              placeholder={placeholderMap[searchType]}
              showSearch
              options={options.map(({ title, key }) => ({
                label: title,
                value: key
              }))}
            />
          </div>
        </div>
      </Affix>

      <div style={{ overflow: 'auto' }}>
        <Spin spinning={groupLoading}>
          <div className={css.groupMain}>
            {groupTree.length ? (
              <DraggableTree
                data={groupData}
                expandedKeys={expandedKeys}
                onExpand={handleExpand}
                refresh={fetchGroup}
              />
            ) : (
              <Empty height={320} />
            )}
          </div>
        </Spin>
      </div>
    </>
  );
});
