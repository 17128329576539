import React, { memo } from 'react';
import classNames from 'classnames';
import { EmptyProps } from '@/components/layouts/empty/type';
import { Empty as AntdEmpty } from 'antd';
import css from './style.less';

export const Empty = memo(
  ({ className, text = '暂无数据', visible = true, height: H }: EmptyProps) => {
    const style = {
      ...(visible ? {} : { display: 'none' }),
      ...(H ? { height: H } : {})
    };
    return (
      <div className={classNames(css.empty, className)} style={style}>
        <AntdEmpty description={text} className={classNames(css.text)} />
      </div>
    );
  }
);
