import { Model, strict, act, flow, Flows } from 'agent-reducer';
import { TradeStatus, TradeStatusRuleWebDto } from '@/types/tradeStatus';
import systemSettingRequest from '@/requests/systemSetting';
import userConfigRequest from '@/requests/userConfig';
import { uiPrompt } from '@/avatars';
import { State, Params, Result } from './type';
import { tradeStatusList } from './constants';

@strict()
export class TradeStatusEditorModel implements Model<State> {
  state: State = {
    saveLoading: false,
    tradeDay: 30,
    tradeStatusRules: [],
    tradeDayList: [30],
    checkedTradeStatus: [],
    timeoutTradeStatus: []
  };

  constructor(params: Params) {
    const {
      tradeDay,
      tradeStatusRules,
      omitTradeStatus = [],
      timeoutTradeStatus = ['WAIT_SEND', 'SELLER_CONSIGNED_PART', 'SENT']
    } = params;

    this.state.tradeDay = tradeDay;

    this.state.tradeStatusRules = tradeStatusList.reduce(
      (rules, tradeStatus) => {
        if (omitTradeStatus.includes(tradeStatus)) {
          return rules;
        }
        const rule = tradeStatusRules.find(
          val => val.tradeStatus === tradeStatus
        );
        if (rule) {
          return rules.concat(rule);
        }
        if (
          timeoutTradeStatus.includes(tradeStatus) ||
          tradeStatus === 'CLOSE'
        ) {
          return rules.concat({
            tradeStatus,
            tradeStatusCondition: { filterType: 'ALL' }
          });
        }
        return rules.concat({ tradeStatus });
      },
      [] as TradeStatusRuleWebDto[]
    );

    this.state.checkedTradeStatus = tradeStatusRules.map(
      val => val.tradeStatus
    );

    this.state.timeoutTradeStatus = timeoutTradeStatus;
  }

  @act()
  load(): State {
    return { ...this.state, saveLoading: true };
  }

  @act()
  unload(): State {
    return { ...this.state, saveLoading: false };
  }

  @act()
  private changeState(state: Partial<State>): State {
    return { ...this.state, ...state };
  }

  @flow(Flows.latest())
  async init(): Promise<void> {
    try {
      const tradeDayList = await userConfigRequest.supportAfterSaleDays();
      this.changeState({ tradeDayList });
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @act()
  changeTradeDay(tradeDay: number): State {
    return { ...this.state, tradeDay };
  }

  @act()
  changeRule(rule: TradeStatusRuleWebDto): State {
    const { tradeStatusRules, checkedTradeStatus } = this.state;

    return {
      ...this.state,
      tradeStatusRules: tradeStatusRules.map(val => {
        if (val.tradeStatus === rule.tradeStatus) {
          return rule;
        }
        return val;
      }),
      checkedTradeStatus: !checkedTradeStatus.includes(rule.tradeStatus)
        ? checkedTradeStatus.concat(rule.tradeStatus)
        : checkedTradeStatus
    };
  }

  @act()
  addCheckedTradeStatus(tradeStatus: TradeStatus): State {
    const { checkedTradeStatus } = this.state;

    if (!checkedTradeStatus.includes(tradeStatus)) {
      return {
        ...this.state,
        checkedTradeStatus: checkedTradeStatus.concat(tradeStatus)
      };
    }

    return this.state;
  }

  @act()
  changeCheckedTradeTatus(checkedTradeStatus: TradeStatus[]): State {
    const { tradeStatusRules } = this.state;

    return {
      ...this.state,
      checkedTradeStatus,
      tradeStatusRules: tradeStatusRules.map(rule => {
        if (checkedTradeStatus.includes(rule.tradeStatus)) {
          return rule;
        }
        return {
          ...rule,
          tradeStatusCondition: {
            filterType: 'ALL',
            tradeCloseReason: undefined,
            filterTime: rule.tradeStatusCondition?.filterTime
          }
        };
      })
    };
  }

  confirm(): Result | null {
    const { tradeStatusRules, tradeDay, checkedTradeStatus } = this.state;

    const checkedRules = tradeStatusRules.reduce((rules, curr) => {
      const { tradeStatus, tradeStatusCondition } = curr;

      if (!checkedTradeStatus.includes(tradeStatus)) {
        return rules;
      }

      if (!tradeStatusCondition || !Object.keys(tradeStatusCondition).length) {
        return rules.concat(curr);
      }

      const { filterType, filterTime, tradeCloseReason } = tradeStatusCondition;

      if (filterType === 'ALL') {
        return rules.concat({
          tradeStatus,
          tradeStatusCondition: { filterType: 'ALL' }
        });
      }

      if (filterType === 'TIMEOUT') {
        return rules.concat({
          tradeStatus,
          tradeStatusCondition: { filterType: 'TIMEOUT', filterTime }
        });
      }

      if (filterType === 'CLOSE_REASON') {
        return rules.concat({
          tradeStatus,
          tradeStatusCondition: { filterType: 'CLOSE_REASON', tradeCloseReason }
        });
      }

      return rules;
    }, [] as TradeStatusRuleWebDto[]);

    const limit = tradeDay - 1;
    if (
      checkedRules.some(rule => {
        if (
          !rule.tradeStatusCondition ||
          !rule.tradeStatusCondition.filterTime
        ) {
          return false;
        }
        const { value, unit } = rule.tradeStatusCondition.filterTime;

        if (unit === 'HOURS') {
          return value > limit * 24;
        }

        return unit === 'DAYS' && value > limit;
      })
    ) {
      uiPrompt.current.warning(`订单状态的超时时间，不能大于${limit}天`);
      return null;
    }

    return { tradeDay, tradeStatusRules: checkedRules };
  }
}
