import { Http, http } from '@/libs/http';
import { ShopAfterSaleConfigWebDto } from '@/types/afterSale';
import {
  FatigueConfigWebDto,
  RemindTypeDto,
  FatigueConfigWebUpdateCmd,
  OfficeTimeConfigWebDto,
  OfficeTimeConfigWebUpdateCmd,
  SellerFlag,
  BatchSendErrorTypeDto
} from './type';
import { UnitTime } from '@/types/unitTime';

export class UserConfigRequest {
  private http: Http = http('/api/shop/user-config');

  afterSaleConfig(): Promise<ShopAfterSaleConfigWebDto> {
    return this.http.path('/after-sale-config').get();
  }

  modifyAfterSaleConfig(params: ShopAfterSaleConfigWebDto): Promise<void> {
    return this.http
      .path('/modify-after-sale-config')
      .setRequestBody(params)
      .post();
  }

  supportAfterSaleDays(): Promise<number[]> {
    return this.http.path('support-after-sale-days').get();
  }

  remindTypes(): Promise<RemindTypeDto[]> {
    return this.http.path('/remind-types').get();
  }

  fatigueConfig(): Promise<FatigueConfigWebDto> {
    return this.http.path('/fatigue-config').get();
  }

  modifyFatigueConfig(params: FatigueConfigWebUpdateCmd): Promise<void> {
    return this.http
      .path('/modify-fatigue-config')
      .setRequestBody(params)
      .post();
  }

  officeTimeConfig(): Promise<OfficeTimeConfigWebDto> {
    return this.http.path('/office-time-config').get();
  }

  modifyOfficeTimeConfig(params: OfficeTimeConfigWebUpdateCmd): Promise<void> {
    return this.http
      .path('/modify-office-time-config')
      .setRequestBody(params)
      .post();
  }

  sellerFlagsWithGrey(): Promise<SellerFlag[]> {
    return this.http.path('/seller-flags-with-grey').post();
  }

  marketingErrorTypes(): Promise<BatchSendErrorTypeDto[]> {
    return this.http.path('/marketing-error-types').post();
  }

  validReplyConfig(): Promise<UnitTime> {
    return this.http.path('/valid-reply-config').post();
  }

  updateValidReplyConfig(params: UnitTime): Promise<void> {
    return this.http
      .path('/update-valid-reply-config')
      .setRequestBody(params)
      .post();
  }
}

export default new UserConfigRequest();
