// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_main_\\+KTIa {\n  display: flex;\n  background-color: #fffbe4;\n  border: 1px solid #ffe88d;\n  color: #666666;\n  padding: 8px 12px;\n  border-radius: 8px;\n  line-height: 20px;\n  word-break: break-all;\n}\n.style_icon_1hC75 {\n  font-size: 20px;\n  color: #f1983e;\n  width: 32px;\n  text-align: center;\n  padding-top: 2px;\n}\n.style_text_zjWcn {\n  line-height: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/layouts/alert/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;AACF;AAEA;EACE,eAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AAAF;AAGA;EACE,iBAAA;AADF","sourcesContent":[".main {\n  display: flex;\n  background-color: #fffbe4;\n  border: 1px solid #ffe88d;\n  color: #666666;\n  padding: 8px 12px;\n  border-radius: 8px;\n  line-height: 20px;\n  word-break: break-all;\n}\n\n.icon {\n  font-size: 20px;\n  color: #f1983e;\n  width: 32px;\n  text-align: center;\n  padding-top: 2px;\n}\n\n.text {\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "style_main_+KTIa",
	"icon": "style_icon_1hC75",
	"text": "style_text_zjWcn"
};
export default ___CSS_LOADER_EXPORT___;
