import type { OriginAgent } from "agent-reducer";
import { middleWare, MiddleWarePresets } from "agent-reducer";
import { takeLoading } from "@/middleWares/takeUiCache";
import exportCenterRequest from "@/requests/exportCenter";
import type { ExportTaskModelState } from "./type";

export class ExportTaskModel implements OriginAgent<ExportTaskModelState> {
  state: ExportTaskModelState = {
    exportTasks: [],
    selectIds: [],
    pageNo: 1,
    pageSize: 10,
    order: "descend",
    field: "dxCreated",
  };

  @middleWare(takeLoading(), MiddleWarePresets.takeLatest())
  async fetchExportTasks(): Promise<ExportTaskModelState> {
    const exportTasks = await exportCenterRequest.getExportTaskList();
    return {
      ...this.state,
      exportTasks,
      selectIds: [],
      pageNo: 1,
      order: "descend",
      field: "dxCreated",
    };
  }

  @middleWare(takeLoading(), MiddleWarePresets.takeLatest())
  async deleteExportTasks(ids: number[]): Promise<ExportTaskModelState> {
    await exportCenterRequest.deleteTasks(ids);
    const exportTasks = await exportCenterRequest.getExportTaskList();
    const currentIds = new Set(exportTasks.map((val) => val.id));
    const selectIds = this.state.selectIds.filter((id) => currentIds.has(id));
    return {
      ...this.state,
      exportTasks,
      selectIds,
    };
  }

  changeSelectIds(keys: React.Key[]): ExportTaskModelState {
    return { ...this.state, selectIds: keys as number[] };
  }

  changeSort(
    sort: Pick<ExportTaskModelState, "field" | "order">
  ): ExportTaskModelState {
    return { ...this.state, field: sort.field, order: sort.order };
  }

  changePagination(pageNo: number, pageSize: number): ExportTaskModelState {
    return { ...this.state, pageNo, pageSize, selectIds: [] };
  }
}
