import React, { memo } from 'react';
import { Tooltip as SourceTooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import css from './style.less';

export const Tooltip = memo((props: TooltipProps & { noWrap?: boolean }) => {
  const { noWrap = true, overlayClassName = '', ...rest } = props;
  return (
    <SourceTooltip
      {...rest}
      overlayClassName={`${overlayClassName} ${css.tooltip}`}
      overlayStyle={noWrap ? { minWidth: 'max-content' } : {}}
    />
  );
});
