// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_popContent_dYzJT:hover {\n  color: #5a91ff;\n}\n.style_label_DOW-c {\n  display: inline-flex;\n  padding: 0 10px;\n  border-radius: 9px;\n  line-height: 18px;\n  cursor: default;\n  font-size: 12px;\n  color: #1dc11d;\n  flex-grow: 0;\n  border: 1px solid #1dc11d;\n}\n.style_label_DOW-c .style_activityName_lJUJw {\n  margin-right: 4px;\n  max-width: 100px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.style_label_DOW-c.style_default_J2Akb {\n  color: #999;\n  border: 1px solid #999;\n}\n.style_presaleTooltipTitle_bBQ9j {\n  background: white;\n}\n.style_presaleTooltipTitleLabel_Od-Ww {\n  color: #666666;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/presalePop/style.less"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;AAGA;EACE,oBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,yBAAA;AADF;AARA;EAWI,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAGE;EACE,WAAA;EACA,sBAAA;AADJ;AAKA;EACE,iBAAA;AAHF;AAKA;EACE,cAAA;AAHF","sourcesContent":[".pop {\n  &Content:hover {\n    color: #5a91ff;\n  }\n}\n.label {\n  display: inline-flex;\n  padding: 0 10px;\n  border-radius: 9px;\n  line-height: 18px;\n  cursor: default;\n  font-size: 12px;\n  color: #1dc11d;\n  flex-grow: 0;\n  border: 1px solid #1dc11d;\n  .activityName {\n    margin-right: 4px;\n    max-width: 100px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n  &.default {\n    color: #999;\n    border: 1px solid #999;\n  }\n}\n\n.presaleTooltipTitle {\n  background: white;\n}\n.presaleTooltipTitleLabel {\n  color: #666666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popContent": "style_popContent_dYzJT",
	"label": "style_label_DOW-c",
	"activityName": "style_activityName_lJUJw",
	"default": "style_default_J2Akb",
	"presaleTooltipTitle": "style_presaleTooltipTitle_bBQ9j",
	"presaleTooltipTitleLabel": "style_presaleTooltipTitleLabel_Od-Ww"
};
export default ___CSS_LOADER_EXPORT___;
