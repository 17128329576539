import { useEffect, useRef, useState } from 'react';
import { usePersistFn } from '../usePersistFn';

type UseFrontQueryDriverReturns<QueryType> = {
  searchQuery: QueryType;
  query: QueryType;
  setQuery: React.Dispatch<React.SetStateAction<QueryType>>;
  search(): void;
  searchAssign: (query?: Partial<QueryType>) => void;
  searchByPageable: (page: number, size?: number) => void;
  refresh: () => void;
  reset: (query?: Partial<QueryType>) => void;
};

type UseFrontQueryDriverConfig<QueryType> = {
  //  默认请求一次
  leading?: boolean;
  //  初始化的，不是默认的
  initialQuery?: QueryType;
};

export function useFrontQueryDriver<QueryType extends Record<string, unknown>>(
  callback: (query: QueryType) => unknown,
  defaultQuery: QueryType,
  config?: UseFrontQueryDriverConfig<QueryType>
): UseFrontQueryDriverReturns<QueryType> {
  const { leading = true, initialQuery } = config || {};
  const searchQueryRef = useRef(initialQuery || defaultQuery);
  const [query, setQuery] = useState<QueryType>(searchQueryRef.current);

  const letCallback = usePersistFn((q: QueryType) => {
    searchQueryRef.current = q;
    setQuery(q);
    callback(q);
  });

  useEffect(() => {
    if (leading) {
      letCallback(searchQueryRef.current);
    }
  }, []);

  const search = usePersistFn(() => {
    const newQuery = 'page' in query ? { ...query, page: 1 } : query;
    letCallback(newQuery);
  });

  const searchAssign = usePersistFn((newQuery: Partial<QueryType> = {}) => {
    letCallback({ ...searchQueryRef.current, ...newQuery });
  });

  const searchByPageable = usePersistFn((page: number, size?: number) => {
    searchAssign({ page, size } as unknown as QueryType);
  });

  const refresh = usePersistFn(() => {
    searchAssign({});
  });

  const reset = usePersistFn((newQuery: Partial<QueryType> = {}) => {
    letCallback({ ...defaultQuery, ...newQuery });
  });

  return {
    searchQuery: searchQueryRef.current,
    query,
    setQuery,
    search,
    searchAssign,
    searchByPageable,
    refresh,
    reset
  };
}
