// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_unit_Bqtr4 {\n  display: flex;\n  margin-top: 18px;\n}\n.style_unit_Bqtr4:nth-child(1) {\n  margin-top: 0px;\n}\n.style_label_46B\\+- {\n  min-width: 100px;\n  display: flex;\n  justify-content: end;\n}\n.style_inputWidth_roLCp {\n  width: 350px;\n}\n.style_inputWidth_roLCp .ant-input-group-addon {\n  padding: 0;\n}\n.style_btnReset_c6xnF {\n  border: none;\n  background: none;\n  height: 28px;\n}\n.style_shopPermissionConfigurator_IE8PH {\n  display: flex;\n  flex-direction: column;\n}\n.style_shopPermissionConfigurator_IE8PH .style_permissionShop_WJY13 {\n  display: flex;\n}\n.style_errorMsg_5rWkc {\n  color: #f23c3c;\n  margin: 4px 0 0 92px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/staffManage/addNoPhoneStaffDailog/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;AACJ;AAGA;EACE,gBAAA;EACA,aAAA;EACA,oBAAA;AADF;AAIA;EACE,YAAA;AAFF;AACA;EAKM,UAAA;AAHN;AAQA;EACE,YAAA;EACA,gBAAA;EACA,YAAA;AANF;AASA;EACE,aAAA;EACA,sBAAA;AAPF;AAKA;EAKI,aAAA;AAPJ;AAWA;EACE,cAAA;EACA,oBAAA;AATF","sourcesContent":[".unit {\n  display: flex;\n  margin-top: 18px;\n\n  &:nth-child(1) {\n    margin-top: 0px;\n  }\n}\n\n.label {\n  min-width: 100px;\n  display: flex;\n  justify-content: end;\n}\n\n.inputWidth {\n  width: 350px;\n\n  :global {\n    .ant-input-group-addon {\n      padding: 0;\n    }\n  }\n}\n\n.btnReset {\n  border: none;\n  background: none;\n  height: 28px;\n}\n\n.shopPermissionConfigurator {\n  display: flex;\n  flex-direction: column;\n\n  .permissionShop {\n    display: flex;\n  }\n}\n\n.errorMsg {\n  color: #f23c3c;\n  margin: 4px 0 0 92px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unit": "style_unit_Bqtr4",
	"label": "style_label_46B+-",
	"inputWidth": "style_inputWidth_roLCp",
	"btnReset": "style_btnReset_c6xnF",
	"shopPermissionConfigurator": "style_shopPermissionConfigurator_IE8PH",
	"permissionShop": "style_permissionShop_WJY13",
	"errorMsg": "style_errorMsg_5rWkc"
};
export default ___CSS_LOADER_EXPORT___;
