import { RedirectTo } from '@/components/layouts/route';
import { Modal, withGlobalDialogs } from '@/components/widgets/modal';
import { parse } from '@/libs/history/locationQs';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import React, { ComponentType, createElement } from 'react';
import { render } from 'react-dom';
import { warnError } from '@/components/infos';
import {
  setInitialHttpCache,
  setInterceptor,
  setWebInitialHttpCache
} from './http';
import { createInitial } from '@/modules';
import { replace } from './history';
import { setItem, getItem, removeItem } from '@/utils/storage';

window.rt17 = window.rt17 || React.createElement;

let failModal;
let permissionFail;

const failModalShow = async () => {
  if (failModal) {
    return;
  }
  failModal = Modal.error({
    title: '错误',
    content: '当前登录已失效，请重新登录'
  });
  const ok = await failModal;

  if (ok && window.localStorage.getItem('clientType') === 'web') {
    failModal = undefined;
    replace('/login');
  }

  if (ok) {
    failModal = undefined;
  }
};

const permissionFailModalShow = async () => {
  if (permissionFail) {
    return;
  }
  permissionFail = Modal.error({
    title: '错误',
    content: '权限已变更，请重新打开配置页'
  });
  const ok = await permissionFail;
  if (ok) {
    permissionFail = undefined;
  }
};

setInterceptor({
  success(res: any): any {
    // 广告接口返回值就是一个数组
    if (Array.isArray(res)) {
      return res;
    }
    const { success, data, errorCode, errorMsg: msg, requestId } = res;
    if (success) {
      return data;
    }
    if (errorCode === 'config-enable-access-error') {
      permissionFailModalShow();
      return Promise.reject(res);
    }
    if (errorCode === 'concurrent_operation') {
      return Promise.reject(res);
    }
    if (errorCode === 'condition_conflict_error') {
      return Promise.reject(res);
    }
    const remainInfo = msg || '网络异常，请刷新重试';
    const info = requestId ? `${remainInfo}` : remainInfo;
    return Promise.reject(new Error(errorCode === '400' ? msg : info));
  },
  error(res: any): any {
    const {
      status,
      config: { url }
    } = res;
    const { pathname } = window.location;

    if (status === 401) {
      // 网页版登录页不弹窗
      if (
        url === '/api/staff/account/authorize-config' ||
        pathname === '/login'
      ) {
        return Promise.reject(res);
      }

      failModalShow();
      return Promise.reject(res);
    }
    return Promise.reject(res);
  }
});

window.addEventListener(
  'unhandledrejection',
  (event: PromiseRejectionEvent) => {
    const { reason } = event;
    warnError(event);
    console.error(reason); // eslint-disable-line
  }
);

const params = parse(window.location.search) || {};
const initial = async () => {
  const moduleInitial = createInitial(params);
  await moduleInitial();
};

const element = document.getElementById('root');

export default async function renderComponent(
  Component: ComponentType<{ to?: RedirectTo }>
): Promise<void> {
  const clientType =
    (params.clientId && 'client') || getItem('client') || 'web';
  const shopId = getItem('shopId');

  setItem('clientType', clientType);
  removeItem('pageAuthority');

  if (clientType === 'client') {
    const token = getItem('token');
    const pageAuthority = getItem('pageAuthority');

    const {
      token: clientToken = token,
      shopId: clientShopId = shopId,
      clientId,
      pageAuthority: clientPageAuthority = pageAuthority
    } = params;
    setItem('token', clientToken as string);
    setItem('shopId', (clientShopId || '') as string);
    setItem('pageAuthority', (clientPageAuthority || '') as string);

    setInitialHttpCache(
      clientToken as string,
      Number(clientShopId),
      clientPageAuthority === 'mainAccount',
      clientId as string
    );
    await initial().catch(warnError);
  }

  if (clientType === 'web' && shopId) {
    setWebInitialHttpCache(Number(shopId));
    await initial().catch(err => {
      warnError(err);
      removeItem('shopId');
      replace('/guide');
    });
  }

  const RootComponent = withGlobalDialogs(() => (
    <ConfigProvider locale={zh_CN}>
      <Component />
    </ConfigProvider>
  ));

  render(createElement(RootComponent), element);
}
