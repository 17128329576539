// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tooltip_Pchw6 .ant-tooltip-inner {\n  max-height: calc(60vh);\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/popover/style.less"],"names":[],"mappings":"AAAA;EAEI,sBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".tooltip {\n  :global(.ant-tooltip-inner) {\n    max-height: calc(60vh);\n    overflow-y: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "style_tooltip_Pchw6"
};
export default ___CSS_LOADER_EXPORT___;
