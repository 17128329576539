import { Model, strict, act, flow, Flows } from 'agent-reducer';
import { uiPrompt } from '@/avatars';
import { State, RecordDialogProps } from './type';
import staffRequest from '@/requests/staff';
import enterpriseRequest from '@/requests/enterprise';

@strict()
export class RecordDialogModel implements Model<State> {
  state: State = {
    loading: false,
    staffDataSource: [],
    enterpriseDataSource: []
  };

  constructor(props: RecordDialogProps) {
    const { id, type, from, to, name, shopId } = props;
    this.state.id = id;
    this.state.type = type;
    this.state.from = from;
    this.state.to = to;
    this.state.name = name;
    if (shopId) {
      this.state.shopId = shopId;
    }
  }

  @act()
  private load(): State {
    return { ...this.state, loading: true };
  }

  @act()
  private unload(): State {
    return { ...this.state, loading: false };
  }

  @act()
  private changeState(state: Partial<State>): State {
    return { ...this.state, ...state };
  }

  @flow(Flows.latest())
  async init(): Promise<void> {
    const { id, type, from, to, shopId } = this.state;

    if (!id || !type || !from || !to) {
      return;
    }

    this.load();
    try {
      if (type === 'staff') {
        const staffDataSource = await staffRequest.enterpriseStaffLoginDetails({
          staffId: id,
          from,
          to
        });
        this.changeState({ staffDataSource });
      }
      if (type === 'subAccount') {
        if (!shopId) {
          return;
        }
        const enterpriseDataSource =
          await enterpriseRequest.subAccountLoginDetails({
            subAccountId: id,
            from,
            to,
            shopId
          });
        this.changeState({ enterpriseDataSource });
      }
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unload();
    }
  }
}
