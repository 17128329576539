import { groupBy } from '@/polyfill/array';
import { AreaWebDto } from '@/requests/shop/type';
import {
  middleWare,
  MiddleWarePresets,
  OriginAgent,
  sharing
} from 'agent-reducer';
import { useAgentSelector } from 'use-agent-reducer';
import addressService from './service';
import { AddressState } from './type';

class Address implements OriginAgent<AddressState> {
  state: AddressState = {
    area: []
  };

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async initial() {
    const area = await addressService.fetchAreaList();
    return {
      area
    };
  }
}

export const addressRef = sharing(() => Address);

/**
 * @description: 根据城市id计算省的数量
 * @param {number[]} cityList 城市ids
 * @return {number}
 */
export const useProvinceNum = (cityList: number[] = []): number => {
  const area = useAgentSelector(addressRef.current, s => s.area);
  return calcProvinceNum(area, cityList);
};

export function calcProvinceNum(
  areaList: AreaWebDto[],
  cityList: number[] = []
): number {
  if (!cityList.length) {
    return 0;
  }

  const nodeMap = new Map<number, AreaWebDto>(
    areaList.map(area => [area.areaId, area])
  );
  const cities = cityList.map(id => nodeMap.get(id)!);
  const cityGroupDraft = groupBy<AreaWebDto, number>(
    cities,
    c => c.parentAreaId
  );

  return cityGroupDraft.size;
}
