import { Http, http } from '@/libs/http';
import {
  RecordDetailListParams,
  RecordDetailListResult,
  StatsListParams,
  StatsListResult,
  InviteRateStrategyStatsWebDto
} from './type';
import { RemindStrategyStatsWebQuery } from '../strategyStats/type';

export class InviteRateStatsRequest {
  private http: Http = http('/api/shop/invite-rate');

  // 查询明细接口
  recordDetailList(
    params: RecordDetailListParams
  ): Promise<RecordDetailListResult> {
    return this.http.path('record-list').setRequestParams(params).get();
  }

  // 查询统计数据接口
  statsList(params: StatsListParams): Promise<StatsListResult> {
    return this.http.path('stats-list-v2').setRequestBody(params).post();
  }

  // 按任务查询统计数据接口
  strategyStatsList(
    params: RemindStrategyStatsWebQuery
  ): Promise<InviteRateStrategyStatsWebDto> {
    return this.http
      .path('strategy-stats-list-v2')
      .setRequestBody(params)
      .post();
  }
}

export default new InviteRateStatsRequest();
