import React, { memo, useEffect } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { Table, Column } from '@/components/widgets/table';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import WangWang from '@/components/works/wangwang';
import { ExportButton } from '@/components/works/exportButton';
import { Card } from '@/components/works/statisticComponents/card';
import { RecordDetailDto } from '@/requests/tradeNopaidClosedPushStats/type';
import { buildQuickDateRangeFromToday } from '@/utils/date';
import commonCss from '@/css/common.less';
import { useTitleRegister } from '@/hooks/titleRender';
import { buildColumnsWithSorter } from '@/services/sort';
import StatisticTabs from '@/components/works/statisticComponents/statisticTabs';
import StrategyStatsTable from '@/components/works/statisticComponents/strategyStatsTable';
import { Statistic } from '@/components/works/statisticComponents';
import {
  buildStatistics,
  numberToPercentage
} from '../../remindStatistics/service';
import { StatsModel } from './model';
import css from './style.less';

const statisticItems = [{ key: 'inviteRateBuyerCount', name: '邀评人数' }];

const timeStatsColumnsConfig = buildColumnsWithSorter([
  { title: '日期', dataIndex: 'businessDay' },
  {
    title: '邀评人数',
    dataIndex: 'inviteRateBuyerCount',
    defaultSortOrder: 'descend'
  }
]);

const taskStatsColumnsConfig = buildColumnsWithSorter([
  { title: '任务名称', dataIndex: 'strategyName' },
  {
    title: '邀评人数',
    dataIndex: 'inviteRateBuyerCount',
    defaultSortOrder: 'descend'
  }
]);

export default memo(() => {
  useTitleRegister(() => <>服务邀评数据</>);

  const {
    state: {
      dateRange,
      statsLoading,
      statsTableLoading,
      detailLoading,
      stats,
      statsTabKey,
      strategyStatsList,
      detailRecordList,
      statsPage,
      page,
      pageSize,
      total
    },
    initStats,
    initStrategyStatsList,
    initRecord,
    changeStatsPage,
    changeStatsTabKey,
    handleStrategyStatsListSearch,
    handleDateRangeChange,
    handleDetailPageChange,
    handleExportDetail,
    handleExportStats
  } = useAgentReducer(StatsModel);

  useEffect(() => {
    initStats();
    initStrategyStatsList();
    initRecord();
  }, []);

  const strategyStatsTableConfig =
    statsTabKey === 'time'
      ? {
          dataSource: stats.valueList,
          loading: statsLoading,
          columns: timeStatsColumnsConfig,
          sumMap: stats.sumMap,
          avgMap: numberToPercentage(stats.avgMap)
        }
      : {
          dataSource: strategyStatsList.valueList,
          loading: statsTableLoading,
          columns: taskStatsColumnsConfig,
          sumMap: strategyStatsList.sumMap,
          avgMap: numberToPercentage(strategyStatsList.avgMap)
        };

  const summaryColumns = strategyStatsTableConfig.columns
    .slice(1)
    .map(val => val.dataIndex);

  const statistics = buildStatistics(statisticItems, stats);

  return (
    <div className={commonCss.main}>
      <div className={commonCss.search}>
        <div className={commonCss.item}>
          <CustomRangePicker
            options={buildQuickDateRangeFromToday()}
            value={dateRange}
            onChange={handleDateRangeChange}
            onSubmit={handleDateRangeChange}
          />
        </div>
      </div>
      <Statistic
        statisticItems={statisticItems}
        statistics={statistics}
        loading={statsLoading}
      />
      <Card
        title={
          <>
            服务邀评数据统计
            <ExportButton
              onExport={handleExportStats}
              emptyText="导出统计为空"
            />
          </>
        }
      >
        <StatisticTabs
          activeKey={statsTabKey}
          remindType="INVITE_RATE"
          onChange={changeStatsTabKey}
          onSearch={handleStrategyStatsListSearch}
        />

        <StrategyStatsTable
          key={statsTabKey}
          currentPage={statsPage}
          summaryColumns={summaryColumns as string[]}
          onPageChange={changeStatsPage}
          {...strategyStatsTableConfig}
        />
      </Card>

      <Card
        className={css.content}
        firstHeading
        title={
          <>
            服务邀评明细
            <ExportButton onExport={handleExportDetail} />
          </>
        }
      >
        <Table
          rowKey="id"
          bordered
          size="small"
          dataSource={detailRecordList}
          showSorterTooltip={false}
          loading={detailLoading}
          pagination={{
            size: 'default',
            position: ['bottomCenter'],
            hideOnSinglePage: true,
            showQuickJumper: true,
            showSizeChanger: false,
            current: page,
            pageSize,
            total,
            onChange: handleDetailPageChange
          }}
        >
          <Column
            title="服务邀评时间"
            dataIndex="inviteRateTime"
            width="170px"
          />
          <Column title="任务名称" dataIndex="taskName" width="200px" />
          <Column
            title="服务邀评客服"
            dataIndex="inviteRateSubAccount"
            width="170px"
          />
          <Column
            title="买家账号"
            dataIndex="buyerNick"
            width="170px"
            render={(value, record: RecordDetailDto) => (
              <WangWang openUid={record.buyerOpenUid} nick={value} />
            )}
          />
        </Table>
      </Card>
    </div>
  );
});
