import React, { memo } from 'react';
import classNames from 'classnames';
import { TradeStatusRuleWebDto } from '@/types/tradeStatus';
import css from './style.less';
import { timeoutUnitMap, tradeStatusMap, closeReasonMap } from './constants';

function ruleDisplay(rule: TradeStatusRuleWebDto): string {
  const {
    tradeStatus,
    tradeStatusCondition: { filterType, tradeCloseReason, filterTime } = {}
  } = rule;

  const tradeStatusText = tradeStatusMap[tradeStatus];

  if (filterType === 'TIMEOUT' && filterTime) {
    return `${tradeStatusText}${filterTime?.value || 1}${
      timeoutUnitMap[filterTime?.unit || 'DAYS']
    }后`;
  }

  if (filterType === 'CLOSE_REASON' && tradeCloseReason) {
    return `${tradeStatusText}（${closeReasonMap[tradeCloseReason]}）`;
  }

  return tradeStatusText;
}

export const TradeStatusList = memo(
  (porps: { rules: TradeStatusRuleWebDto[]; className?: string }) => {
    const { rules, className } = porps;

    return (
      <div className={classNames(css.ruleList, className)}>
        {rules.map(rule => (
          <div key={rule.tradeStatus} className={css.ruleItem}>
            {ruleDisplay(rule)}
          </div>
        ))}
      </div>
    );
  }
);
