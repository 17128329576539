import * as React from 'react';
import { Option, Select, SelectProps } from '@/components/widgets/select';
import classNames from 'classnames';
import { memo, useState } from 'react';
import css from './style.less';
import { cusTeamManangeRequest } from '@/requests/cusTeamManage';
import { WangwangGroupWebDto } from '@/requests/cusTeamManage/type';
import { FormItem as SoruceFormItem } from '@/components/widgets/form';

type CusTeamSelectProps = SelectProps & {
  singleSelect?: boolean;
  status?: '' | 'success' | 'warning' | 'error' | 'validating';
  showAllOption?: boolean;
  getCusTeam?: (cusTeam: WangwangGroupWebDto[]) => void;
};

export default memo(
  ({
    className,
    mode = 'multiple',
    placeholder = '请选择指定客服组',
    singleSelect,
    status,
    showAllOption = false,
    getCusTeam,
    ...restProps
  }: CusTeamSelectProps) => {
    const [team, setTeam] = useState<WangwangGroupWebDto[]>([]);
    const filterOption = (input: string, option) =>
      option.children?.[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;

    React.useEffect(() => {
      (async () => {
        const list = await cusTeamManangeRequest.list();
        setTeam(list);
        getCusTeam?.(list);
      })();
    }, []);

    return (
      <SoruceFormItem validateStatus={status} noStyle>
        <Select
          {...restProps}
          mode={singleSelect ? undefined : mode}
          showSearch
          filterOption={filterOption}
          placeholder={placeholder}
          className={classNames(css.itemSelect, className)}
        >
          {showAllOption ? <Option value={null}>全部</Option> : null}
          {team.map(
            v =>
              v && (
                <Option value={v.id} key={v.id}>
                  {v.groupName}（
                  <span style={{ color: 'orange' }}>{v.wangwangNum}</span>）
                </Option>
              )
          )}
        </Select>
      </SoruceFormItem>
    );
  }
);
