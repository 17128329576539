import React, { memo } from 'react';
import { Select, Option } from '@/components/widgets/select';
import css from './style.less';
import { Tooltip } from '@/components/widgets/tooltip';
import { Icon } from '@/components/widgets/icon';
import { Unit } from '@/types/unitTime';
import {
  pushDecsRemindType,
  remindTypeDecs,
  replyTimeOutTimes,
  times,
  moreTimes,
  unDisturbDay,
  unDisturbHour,
  unDisturbMinute
} from '../../constant';
import { RemindLimitCompProps } from './type';
import { DisturbConfig } from '@/requests/remindStrategy/types';
import useCreation from '@/hooks/useCreation';

export const defaultDisturbConfig: DisturbConfig = {
  maxTimes: 2,
  timeLimit: {
    unit: 'HOURS',
    value: 12
  }
};

export const RemindLimitComp = memo((props: RemindLimitCompProps) => {
  const {
    value = defaultDisturbConfig,
    remindType,
    isWhiteUser,
    onChange
  } = props;
  const { maxTimes, timeLimit } = value;
  const { value: timeLimitValue, unit: timeLimitUnit } = timeLimit;

  const handleChange = (v: Partial<DisturbConfig>) => {
    onChange({ ...value, ...v });
  };

  const timeLimitSelectOptions = useCreation(() => {
    if (remindType === 'REPLY_TIMEOUT_PUSH') {
      return times.concat(replyTimeOutTimes);
    }
    if (isWhiteUser) {
      return times.concat(moreTimes);
    }
    return times;
  }, [isWhiteUser, remindType]);

  const TimesLimitSelect = (
    <>
      <Select
        className={css.selectWidth}
        value={maxTimes}
        onChange={v => handleChange({ maxTimes: v })}
      >
        {timeLimitSelectOptions.map(({ key, value: label }) => (
          <Option value={key} key={key}>
            {label}
          </Option>
        ))}
      </Select>
      次
    </>
  );

  const RoundsLimit = (
    <span>
      1轮
      <Tooltip title={`1轮：第一次推送 → 第${isWhiteUser ? '五' : '二'}次推送`}>
        <Icon type="bangzhushuoming" className={css.roundsLimitTip} />
      </Tooltip>
    </span>
  );

  const renderRemindTypeDecs = () => {
    if (pushDecsRemindType.includes(remindType)) {
      return '推送';
    }
    if (remindType === 'AFTER_PAID_ITEM_RECOMMEND') {
      return '推荐';
    }
    return remindTypeDecs[remindType];
  };

  const remindTimeValueOptions = (unit: Unit) => {
    switch (unit) {
      case 'HOURS':
        return unDisturbHour;
      case 'DAYS':
        return unDisturbDay;
      case 'MINUTES':
        return unDisturbMinute;
      default:
        return unDisturbDay;
    }
  };

  return (
    <div className={css.item}>
      <div className={css.labelText}>
        <span style={{ color: '#f23c3c' }}>*</span>免打扰：
      </div>
      <div>
        同一买家
        <Select
          className={css.selectWidth}
          value={timeLimitValue}
          onChange={v =>
            handleChange({ timeLimit: { ...timeLimit, value: v } })
          }
        >
          {remindTimeValueOptions(timeLimitUnit).map(v => (
            <Option value={v} key={v}>
              {v}
            </Option>
          ))}
        </Select>
        <Select
          className={css.selectWidth}
          value={timeLimitUnit}
          onChange={v => handleChange({ timeLimit: { value: 1, unit: v } })}
        >
          {remindType === 'REPLY_TIMEOUT_PUSH' ? (
            <Option value="MINUTES">分钟</Option>
          ) : null}
          <Option value="HOURS">小时</Option>
          <Option value="DAYS">天</Option>
        </Select>
        内，最多
        {renderRemindTypeDecs()}
        {remindType === 'REMIND_BUY' ? RoundsLimit : TimesLimitSelect}
      </div>
    </div>
  );
});
