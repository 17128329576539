import React, { memo, useEffect } from 'react';
import { useAgentMethods, useAgentReducer } from 'use-agent-reducer';
import classNames from 'classnames';
import { Column, Table } from '@/components/widgets/table';
import { Tooltip } from '@/components/widgets/tooltip';
import { Input } from '@/components/widgets/input';
import { Icon } from '@/components/widgets/icon';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import ConditionUnit from '@/components/layouts/conditionUnit';
import { Select, Option } from '@/components/widgets/select';
import usePageContentScroll from '@/hooks/usePageContentScroll';
import { SubAccountLoginWebDto } from '@/requests/shop/type';
import { realTimeLoginRef } from './model';
import css from './style.less';
import {
  Container,
  Searcher,
  ShowRecord,
  OnlineTimes
} from '@/pages/realTimeLogin/components';
import { buildDateOptions } from '@/services/buildCoustomDateOptions';
import CusTeamSelector from '@/components/works/cusTeamSelector';

const loginStatusMap = {
  OFFLINE: '离线',
  ONLINE: '在线'
};

const ShopAccountListComp = memo(() => {
  const {
    state: {
      subAccountLoading,
      subAccountLoginStatus,
      subAccountPage,
      subAccountTotal,
      subAccountSearchCondition,
      dateRange
    },
    changeSubAccountSearchCondition,
    fetchAccount
  } = useAgentReducer(realTimeLoginRef.current);

  return (
    <Container
      title={
        <span>
          店铺账号在线明细
          <Tooltip title="指对应店铺子账号使用智能跟单助手的时间明细，并非登录千牛的时间">
            <Icon type="bangzhushuoming" />
          </Tooltip>
        </span>
      }
    >
      <Searcher
        value={subAccountSearchCondition}
        onChange={changeSubAccountSearchCondition}
        onClick={() => fetchAccount(1)}
      >
        <ConditionUnit
          className={css.formItem}
          label="在线状态"
          name="loginStatus"
        >
          <Select className={css.select}>
            <Option value="ALL">全部</Option>
            <Option value="ONLINE">在线</Option>
            <Option value="OFFLINE">离线</Option>
          </Select>
        </ConditionUnit>
        <ConditionUnit
          className={css.formItem}
          label="账号"
          name="subAccountName"
        >
          <Input className={css.select} placeholder="请输入搜索账号" />
        </ConditionUnit>
        <ConditionUnit
          className={css.formItem}
          label="客服组"
          name="wangwangGroupId"
        >
          <CusTeamSelector
            style={{ width: '200px' }}
            showAllOption
            singleSelect
          />
        </ConditionUnit>
      </Searcher>
      <div>
        <Table
          rowKey="account"
          loading={subAccountLoading}
          dataSource={subAccountLoginStatus}
          pagination={{
            size: 'default',
            position: ['bottomCenter'],
            current: subAccountPage,
            onChange: page => fetchAccount(page),
            total: subAccountTotal,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: true
          }}
          bordered
        >
          <Column title="账号" dataIndex="account" />
          <Column
            title="在线时长"
            dataIndex="onlineSeconds"
            render={value => <OnlineTimes times={[value]} />}
          />
          <Column
            title="当前在线状态"
            dataIndex="loginStatus"
            render={value => loginStatusMap[value]}
          />
          <Column
            title="当前登录员工"
            dataIndex="lastLoginStaffName"
            render={(value, record: SubAccountLoginWebDto) =>
              value && record.loginStatus === 'ONLINE' ? value : '-'
            }
          />
          <Column
            title="操作"
            key="action"
            render={(value, record: SubAccountLoginWebDto) => (
              <ShowRecord
                id={record.subAccountId}
                type="subAccount"
                from={dateRange[0]}
                to={dateRange[1]}
                name={record.account}
                shopId={record.shopId}
              />
            )}
          />
        </Table>
      </div>
    </Container>
  );
});

const DateSelector = memo(() => {
  const {
    state: { dateRange },
    handleDateRangeChange
  } = useAgentReducer(realTimeLoginRef.current);

  const scrollTop = usePageContentScroll();

  return (
    <Container
      className={classNames(css.dateSelector, { [css.scroll]: scrollTop > 0 })}
    >
      <CustomRangePicker
        options={buildDateOptions()}
        value={dateRange}
        onChange={handleDateRangeChange}
        onSubmit={handleDateRangeChange}
      />
    </Container>
  );
});

export default memo(() => {
  const { fetchAccount } = useAgentMethods(realTimeLoginRef.current);

  useEffect(() => {
    fetchAccount();
  }, []);

  return (
    <>
      <DateSelector />
      <ShopAccountListComp />
    </>
  );
});
