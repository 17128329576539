import React, { memo, useState } from 'react';
import classNames from 'classnames';
import MiniImage from '@/components/works/miniImage';
import { Paragraph } from '@/components/widgets/typography';
import { Tooltip } from '@/components/widgets/tooltip';
import { Checkbox } from '@/components/widgets/checkbox';
import { copy } from '@/utils/dxUtils';
import { SkuToolTip } from '../skuTooltip';
import { approveStatusMap } from '../../constants';
import { computeSkuSelectStatus, linkToGoodsDetail } from '../../service';
import { SpuItemProps } from './type';
import css from './style.less';
import { usePlatform } from '@/hooks/usePlatform';

export const SpuItem = memo((props: SpuItemProps) => {
  const {
    showSku,
    selected,
    item,
    onSkuSelect,
    onSpuSelect,
    type,
    taobaoSkuIdSet
  } = props;
  const {
    picUrl,
    title,
    outerIid,
    approveStatus,
    skuList = [],
    taobaoIid,
    price,
    url
  } = item;
  const { isJD, isTB } = usePlatform();

  const toJdDetail = (e: React.MouseEvent | undefined) => {
    e?.stopPropagation();
    if (isJD) {
      window.open(url);
    } else {
      linkToGoodsDetail(taobaoIid);
    }
  };

  const [hover, setHover] = useState(true);

  const keyLabel = isJD ? 'SPU编号' : 'ID';

  const handleClick = () => {
    onSpuSelect(item);
  };

  const {
    selectSkusForSpu,
    allSelected,
    partialSelected,
    notSelctedAndNoSkus,
    selectedAndNoSkus
  } = computeSkuSelectStatus(props);

  return (
    <div
      className={classNames(css.option, selected ? css.selected : null)}
      onClick={handleClick}
      onMouseEnter={() => setHover(false)}
      onMouseLeave={() => setHover(true)}
      style={!hover ? { background: '#FFF2F2' } : {}}
    >
      <div className={css.imgBox}>
        <MiniImage
          lazyLoad
          size={48}
          src={picUrl}
          className={css.img}
          onClick={toJdDetail}
        />
        <div className={css.approveStatusMask}>
          {approveStatusMap[approveStatus]}
        </div>
      </div>
      <div className={css.texts}>
        <Paragraph
          className={classNames(css.name, css.nameLink)}
          ellipsis={{ rows: 2, tooltip: title }}
          onClick={toJdDetail}
        >
          {title}
        </Paragraph>
        <div className={css.code}>
          <Tooltip title={`${keyLabel}U：${taobaoIid}`}>
            <span
              className={css.iid}
              onClick={e => {
                e.stopPropagation();
                copy(taobaoIid);
              }}
            >
              {keyLabel}
            </span>
          </Tooltip>
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '12px'
            }}
          >
            <Paragraph ellipsis={{ rows: 1, tooltip: { price } }}>
              ¥{price}
            </Paragraph>
            {outerIid ? (
              <Paragraph
                style={{ color: '#8c8c8c', minWidth: '64px' }}
                ellipsis={{ rows: 1, tooltip: outerIid }}
              >
                {`商品货号 ${outerIid}`}
              </Paragraph>
            ) : null}
          </div>
          {skuList.length && showSku ? (
            <Tooltip
              title={
                <SkuToolTip
                  itemSkus={skuList}
                  selectSkus={selectSkusForSpu}
                  onSelect={onSkuSelect}
                />
              }
              color="#fff"
              placement={type === 'selected' ? 'left' : 'right'}
              overlayInnerStyle={{ padding: '0px' }}
            >
              <span style={{ fontSize: '14px', marginRight: '24px' }}>
                {allSelected && isJD ? (
                  <span style={{ color: '#ffbc87' }}>不限SKU</span>
                ) : null}
                {partialSelected || (allSelected && isTB) ? (
                  <span style={{ color: '#ffbc87' }}>
                    已选{selectSkusForSpu.length}个SKU
                  </span>
                ) : null}
                {notSelctedAndNoSkus || selectedAndNoSkus ? (
                  <span style={{ color: '#3d7eff' }}>选择SKU</span>
                ) : null}
              </span>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div style={{ padding: '0px 16px 0px 12px' }}>
        {allSelected || selectedAndNoSkus ? <Checkbox checked /> : null}
        {partialSelected ? <Checkbox indeterminate /> : null}
        {notSelctedAndNoSkus ? <Checkbox /> : null}
      </div>
    </div>
  );
});
