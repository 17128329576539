import { ItemGroupDto } from '@/requests/itemGroups/type';
import { DataNode } from './type';

export const createEmptyKey = () => -Math.random();

export const isEmptyKey = key => key < 0;

export const transformGropList = (gropList: ItemGroupDto[]): DataNode[] => {
  const originGropList = gropList;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const loop = (gropList: ItemGroupDto[]) => {
    return gropList.map(t => ({
      key: t.id,
      title: t.groupName,
      itemNum: t.itemNum,
      children: originGropList.some(g => g.id === t.id)
        ? loop(t.children)
        : undefined
    }));
  };

  return loop(gropList);
};

export const updateRenderTree = (nodes, key) => {
  return nodes.flatMap(node => {
    if (node.key === key || node.children?.some(c => c.key === key)) {
      if (node.key === key) {
        return [];
      }
      return {
        ...node,
        children: updateRenderTree(
          node.children.filter(c => c.key !== key),
          key
        )
      };
    }

    return {
      ...node
    };
  });
};

export const searchGroupByName = (tree, searchValue: string) => {
  return tree.flatMap(node => {
    if (node?.title?.includes(searchValue)) {
      return { ...node }; // 返回包含父节点和匹配节点的对象
    }

    if (node.children && node.children.length > 0) {
      const foundInChildren = searchGroupByName(node.children, searchValue);

      if (foundInChildren.length) {
        return {
          ...node,
          children: foundInChildren
        }; // 找到子节点对象
      }
    }

    return [];
  });
};

export const flatTreeData = (tree: any[]) =>
  tree.reduce(
    (ret, cur) => [
      ...ret,
      cur,
      ...(cur.children ? flatTreeData(cur.children) : [])
    ],
    []
  );
