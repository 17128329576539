import React, { Key, memo, useState } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { Tree } from '@/components/widgets/tree';
import { categoryRef } from '@/modules/category';
import css from './style.less';

type CategorySelectProps = {
  value?: string[];
  onChange?: (value: Key[], checkInfo: any) => void;
};

export const CategorySelect = memo((props: CategorySelectProps) => {
  const { value, onChange } = props;
  const [sellerCatCids, setSellerCatCids] = useState(value || []);

  const { state: categoryState } = useAgentReducer(categoryRef.current);
  const { sellerCategoryList } = categoryState;

  const handleCheck = (e, checkInfo) => {
    setSellerCatCids(e);
    onChange?.(e, checkInfo);
  };

  return (
    <>
      <div className={css.treeHeader}>全部分类</div>
      <div className={css.treeContent}>
        <Tree
          checkedKeys={sellerCatCids}
          defaultExpandAll
          autoExpandParent
          defaultExpandParent
          checkable
          treeData={sellerCategoryList}
          onCheck={handleCheck}
        />
      </div>
    </>
  );
});
