import React, { memo } from 'react';
import { Tooltip } from '@/components/widgets/tooltip';
import { Icon } from '@/components/widgets/icon';
import { Checkbox } from '@/components/widgets/checkbox';
import { useDialogMethods } from '@/components/widgets/modal';
import { TradeStatusProps } from './type';
import { TradeStatusEditor, TradeStatusList } from '../tradeStatusEditor';
import { Result } from '../tradeStatusEditor/type';
import css from './style.less';

export const defaultTradeStatusFilterConfig = {
  filterEnable: false,
  filterLatestTradeDay: 30,
  tradeStatusFilterRules: []
};

export default memo((props: TradeStatusProps) => {
  const {
    checkable = false,
    value = defaultTradeStatusFilterConfig,
    onChange
  } = props;
  const { filterEnable, filterLatestTradeDay, tradeStatusFilterRules } = value;
  const [openDialog] = useDialogMethods<Result | undefined>();

  const openTradeStatusEditor = async () => {
    const res = await openDialog(TradeStatusEditor, {
      tradeDay: filterLatestTradeDay,
      tradeStatusRules: tradeStatusFilterRules,
      tradeDaySelectText: '，将不进行服务邀评'
    });

    if (res) {
      onChange({
        filterLatestTradeDay: res.tradeDay,
        tradeStatusFilterRules: res.tradeStatusRules
      });
    }
  };

  return (
    <div className={css.main}>
      {checkable ? (
        <Checkbox
          className={css.checkbox}
          checked={filterEnable}
          onChange={e => onChange({ filterEnable: e.target.checked })}
        />
      ) : (
        <Checkbox checked className={css.checkbox} disabled />
      )}
      <span className={css.label}>过滤订单状态</span>
      <Tooltip
        title={`买家近${filterLatestTradeDay}天的订单中，有选中的订单状态，将不进行服务邀评`}
      >
        <Icon type="bangzhushuoming" />
      </Tooltip>
      {filterEnable && !tradeStatusFilterRules.length ? (
        <span className={css.btn} onClick={openTradeStatusEditor}>
          请选择
        </span>
      ) : null}
      {filterEnable && tradeStatusFilterRules.length ? (
        <>
          <TradeStatusList
            rules={tradeStatusFilterRules}
            className={css.ruleList}
          />
          <Icon
            type="bianji"
            onClick={openTradeStatusEditor}
            className={css.editIcon}
          />
        </>
      ) : null}
    </div>
  );
});
