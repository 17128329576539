import React, { memo } from 'react';
import classNames from 'classnames';
import { shallowEqual, useAgentSelector } from 'use-agent-reducer';
import { Option, Select, SelectProps } from '@/components/widgets/select';
import { FormItem as SoruceFormItem } from '@/components/widgets/form';
import { accountRef } from '@/modules/account';
import css from './style.less';
import { RenderSubAccountItem } from '@/pages/subAccountPermissions/layout';

type AccountSelectProps = SelectProps & {
  singleSelect?: boolean;
  showAll?: boolean;
  status?: '' | 'success' | 'warning' | 'error' | 'validating';
};

export const AccountSelect = memo(
  ({
    className,
    mode = 'multiple',
    placeholder = '请选择指定客服',
    singleSelect,
    showAll = false,
    status,
    ...restProps
  }: AccountSelectProps) => {
    const account = useAgentSelector(
      accountRef.current,
      s => s.account,
      shallowEqual
    );

    return (
      <SoruceFormItem validateStatus={status} noStyle>
        <Select
          {...restProps}
          mode={singleSelect ? undefined : mode}
          showSearch
          optionFilterProp='title'
          placeholder={placeholder}
          className={classNames(css.itemSelect, className)}
        >
          {showAll ? <Option value={null}>全部</Option> : null}
          {account.map(v => (
            <Option value={v.id} key={v.id} title={v.account}>
              <RenderSubAccountItem {...v} />
            </Option>
          ))}
        </Select>
      </SoruceFormItem>
    );
  }
);
