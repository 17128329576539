// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_content_rNO9n {\n  margin-top: 24px;\n}\n.style_itemText_H\\+kMQ {\n  display: inline-flex;\n  align-items: center;\n}\n.style_pagination_KcFEj {\n  margin: 24px 0 0;\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/remindStatistics/dunningBuyStatistics/style.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,oBAAA;EACA,mBAAA;AAAF;AAGA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;AADF","sourcesContent":[".content {\n  margin-top: 24px;\n}\n\n.itemText {\n  display: inline-flex;\n  align-items: center;\n}\n\n.pagination {\n  margin: 24px 0 0;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "style_content_rNO9n",
	"itemText": "style_itemText_H+kMQ",
	"pagination": "style_pagination_KcFEj"
};
export default ___CSS_LOADER_EXPORT___;
