import React, { PropsWithChildren, memo, useEffect } from 'react';
import { Drawer } from '@/components/widgets/drawer';
import { Alert } from '@/components/widgets/alert';
import { BatchAddStaffDrawerProps, SetpWrapperProps } from './type';
import css from './style.less';
import { Upload } from '@/components/widgets/upload';
import { Button } from '@/components/widgets/button';

import { useAgentReducer } from 'use-agent-reducer';
import { BatchAddStaffDrawerModel } from './model';

const SetpWrapper = memo((props: PropsWithChildren<SetpWrapperProps>) => {
  const { title, children } = props;

  return (
    <div className={css.setpWrapper}>
      <div className={css.title}>{title}</div>
      <div className={css.content}>{children}</div>
    </div>
  );
});

export const BatchAddStaffDrawer = memo((props: BatchAddStaffDrawerProps) => {
  const { visible, onClose } = props;

  const {
    state: { uploading, fileList, errorMessages },
    propmt,
    downloadTemplate,
    beforeUpload,
    handleUploadFile,
    handleRemoveFile,
    handleCloseErrorMsg,
    resetState
  } = useAgentReducer(new BatchAddStaffDrawerModel());

  const closeDrawer = () => {
    onClose(false);
    resetState();
  };

  useEffect(() => {
    propmt.implement({
      closeDrawer() {
        onClose(false, true);
        resetState();
      }
    });
  });

  return (
    <>
      <Drawer
        title="批量添加员工"
        placement="right"
        width={680}
        onClose={closeDrawer}
        visible={visible}
        destroyOnClose
      >
        <SetpWrapper title="第1步：下载模板文件，按模板格式做好数据文件">
          <div className={css.link} onClick={downloadTemplate}>
            下载模板文件
          </div>
          <Alert
            type="warning"
            message="重要提示："
            description={
              <div style={{ whiteSpace: 'break-spaces' }}>
                {`1、导入文件格式必须和模板一致\n2、员工姓名、账号、密码均不能为空，必填。姓名长度1~10；账号长度3~16；密码长度8~15位，且字母/数字/符号至少2种；\n3、1次最多可导入1000个员工`}
              </div>
            }
          />
        </SetpWrapper>
        <SetpWrapper title="第2步：选择文件上传">
          <div className={css.uploadFileButton}>
            <Upload
              maxCount={1}
              name="file"
              accept=".xls, .xlsx"
              beforeUpload={beforeUpload}
              onRemove={handleRemoveFile}
              fileList={fileList}
            >
              <Button
                style={{ marginLeft: 0, marginRight: 12 }}
                disabled={uploading}
              >
                选择文件
              </Button>
            </Upload>
            {!fileList.length ? (
              <div style={{ color: '#333' }}>
                请选择excel文件上传，文件大小不可超过10M；
              </div>
            ) : null}
          </div>
          {errorMessages?.length ? (
            <Alert
              className={css.errorMsg}
              type="error"
              message="导入失败"
              description={
                <div className={css.errorMsgContent}>
                  {errorMessages.map(v => (
                    <p key={Math.random()}>{v}</p>
                  ))}
                </div>
              }
              closable
              onClose={handleCloseErrorMsg}
            />
          ) : null}
          <div>
            <Button
              type="primary"
              onClick={handleUploadFile}
              disabled={fileList.length === 0}
              loading={uploading}
              className={css.btn}
            >
              确认导入
            </Button>
            <Button type="default" onClick={closeDrawer} className={css.btn}>
              取消
            </Button>
          </div>
        </SetpWrapper>
      </Drawer>
    </>
  );
});
