import { Http, http } from '@/libs/http';
import { LoginDayDetailWebDto } from '@/types/loginTime';
import {
  SubAccountLoginDetailsParams,
  EnterpriseCompareLogListCmd,
  CompareLogWebDto,
  CompareLogTypeWebDto,
  StaffIdAndNameWebDto,
  ShopWithStaffPermissionDto
} from './type';
import { PageDetail } from '@/types/pageDetail';

export class EnterpriseRequest {
  private http: Http = http('/api/enterprise');

  subAccountLoginDetails(
    params: SubAccountLoginDetailsParams
  ): Promise<LoginDayDetailWebDto[]> {
    return this.http
      .path('/sub-account/login-details')
      .setRequestParams(params)
      .get();
  }

  enterpriseCompareLogList(
    params: EnterpriseCompareLogListCmd
  ): Promise<PageDetail<CompareLogWebDto>> {
    return this.http.path('/compare-log/list').setRequestBody(params).post();
  }

  logTypes(): Promise<CompareLogTypeWebDto[]> {
    return this.http.path('/compare-log/log-types').get();
  }

  listOperateAccounts(): Promise<StaffIdAndNameWebDto[]> {
    return this.http.path('/compare-log/list-operate-accounts').post();
  }

  // web店铺列表
  listShopWithStaffPermission(): Promise<ShopWithStaffPermissionDto[]> {
    return this.http.path('shop-list-with-permission').get();
  }
}

export default new EnterpriseRequest();
