import { strict, act, Model, sharing } from 'agent-reducer';
import { AccountListDto } from '@/requests/shop/type';
import { getItem, setItem } from '@/utils/storage';

type State = {
  jmAccount?: AccountListDto;
};

const JM_ACCOUNT = 'JM_ACCOUNT';

@strict()
class JmDongDongModel implements Model<State> {
  state: State = {
    jmAccount: undefined
  };

  constructor() {
    try {
      const str = getItem(JM_ACCOUNT);
      if (!str) {
        return;
      }
      const jmAccount = JSON.parse(str);
      this.state.jmAccount = jmAccount;
    } catch (error) {
      return;
    }
  }

  @act()
  changeJmAccount(jmAccount: AccountListDto): State {
    setItem(JM_ACCOUNT, JSON.stringify(jmAccount));
    return { ...this.state, jmAccount };
  }
}

export const jmDongDongModelRef = sharing(() => new JmDongDongModel());
