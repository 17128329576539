export class Channel {
  channelName: string;

  private channel: BroadcastChannel;

  private listeners: { [key: string]: Array<(data: any) => void> };

  constructor(channelName: string) {
    this.channelName = channelName;
    this.channel = new BroadcastChannel(channelName);
    this.listeners = {};
    this.setupListeners();
  }

  setupListeners(): void {
    this.channel.onmessage = (event: MessageEvent) => {
      const { type, data } = event.data;
      if (this.listeners[type]) {
        this.listeners[type].forEach(callback => {
          callback(data);
        });
      }
    };
  }

  sendMessage(type: string, data: any): void {
    this.channel.postMessage({ type, data });
  }

  addEventListener(type: string, callback: (data: any) => void): void {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  removeEventListener(type: string, callback: (data: any) => void): void {
    if (this.listeners[type]) {
      this.listeners[type] = this.listeners[type].filter(listener => {
        return listener !== callback;
      });
    }
  }

  closeChannel(): void {
    this.channel.close();
  }
}
