type RegType = {
  1: '只能包含特殊字符、空格、中英文和数字';
  2: '只能包含特殊字符、中英文和数字';
  3: '只能包含中英文下划线和数字';
  4: '只能包含中英文和数字';
  5: '只能包含数字';
  6: '只能是正整数';
  7: '只能字母/数字/符号至少2种, 8～15位';
  8: '只要包含空格';
  9: '只能包含数字，6位';
  10: '只能包含数字，11位';
};

/**
 *
 * @param str
 *  1: '只能包含特殊字符、空格、中英文和数字';
 *
 *  2: '只能包含特殊字符、中英文和数字';
 *
 *  3: '只能包含中英文下划线和数字';
 *
 *  4: '只能包含中英文和数字';
 *
 *  5: '只能包含数字';
 *
 *  6: '只能是正整数';
 *
 *  7: '只能是字母/数字/符号至少2种，8～15位';
 *
 *  8: '只要包含空格';
 *
 *  9: '只能包含数字，6位';
 * 
 *  10: '11位手机号';
 * @param type
 */
export const testString = (str: string, type: keyof RegType): boolean => {
  switch (type) {
    case 1:
      return /^[`\s~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、a-zA-Z0-9\u4e00-\u9fa5]+$/.test(
        str
      );
    case 2:
      return /^[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、a-zA-Z0-9\u4e00-\u9fa5]+$/.test(
        str
      );
    case 3:
      return /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/.test(str);
    case 4:
      return /^[a-zA-Z0-9\u4e00-\u9fa5]+$/.test(str);
    case 5:
      return /^[0-9]+$/.test(str);
    case 6:
      return Number.isInteger(str);
    case 7:
      return /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,15}$/.test(
        str
      );
    case 8:
      return /[ ]+/.test(str);
    case 9:
      return /^\d{6}$/.test(str);
    case 10:
      return /^1[0-9]{10}$/.test(str);
    default:
      throw new Error('type值错误');
  }
};

/**
 * 只支持4-20位数字和字母
 */
export const CHECK_EXPORT_PASSWORD_FORMAT_REG = /^[0-9a-zA-Z]{4,20}$/;
