import { middleWare, MiddleWarePresets, Model, sharing } from 'agent-reducer';
import { cusTeamManangeRequest } from '@/requests/cusTeamManage';
import { WangwangGroupWebDto } from '@/requests/cusTeamManage/type';

type State = {
  wwGroupList: WangwangGroupWebDto[];
};

class WwGroupList implements Model<State> {
  state: State = {
    wwGroupList: []
  };

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async initial(): Promise<State> {
    const wwGroupList = await cusTeamManangeRequest.list();

    return { ...this.state, wwGroupList };
  }
}

export const wwGroupListRef = sharing(() => new WwGroupList());
