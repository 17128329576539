import React, { memo } from 'react';
import { Modal as SourceModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import {
  withGlobalDialogs as wG,
  useDialogMethods as uD,
  Dialog as Dia
} from './dialogs';
import { ModalType, ShortcutConfig, Shortcuts } from './type';

function shortcutCallback(
  name: 'info' | 'success' | 'error' | 'warn' | 'confirm'
): (config: ShortcutConfig) => Promise<boolean> {
  return function shortcut(props: ShortcutConfig): Promise<boolean> {
    return new Promise(resolve => {
      function onOk() {
        resolve(true);
      }

      function onCancel() {
        resolve(false);
      }

      SourceModal[name]({
        okText: '确定',
        cancelText: '取消',
        ...props,
        centered: true,
        onOk,
        onCancel
      });
    });
  };
}

const CustomModal = memo((props: ModalProps) => <SourceModal {...props} />);

const shortcut: Shortcuts = {
  info: shortcutCallback('info'),
  success: shortcutCallback('success'),
  error: shortcutCallback('error'),
  warn: shortcutCallback('warn'),
  confirm: shortcutCallback('confirm')
};

export const Modal = Object.assign(CustomModal, shortcut) as ModalType;

export const withGlobalDialogs = wG;

export const useDialogMethods = uD;

export const Dialog = Dia;
