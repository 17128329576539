// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_modal_FauE- .style_radioWrap_HIem5 {\n  line-height: 40px;\n}\n.style_modal_FauE- .style_noLimitedWrap_Ujca1 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 260px);\n  max-height: 450px;\n  border: 1px solid #f0f0f0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/goodsSelectModal/style.less"],"names":[],"mappings":"AAEA;EAEI,iBAAA;AAFJ;AAAA;EAMI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,2BAAA;EACA,iBAAA;EACA,yBAAA;AAHJ","sourcesContent":["@input-width: 400px;\n\n.modal {\n  .radioWrap {\n    line-height: 40px;\n  }\n\n  .noLimitedWrap {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: calc(100vh - 260px);\n    max-height: 450px;\n    border: 1px solid #f0f0f0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "style_modal_FauE-",
	"radioWrap": "style_radioWrap_HIem5",
	"noLimitedWrap": "style_noLimitedWrap_Ujca1"
};
export default ___CSS_LOADER_EXPORT___;
