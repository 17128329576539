import { act, flow, Model, Flows, sharing } from 'agent-reducer';
import service from '@/requests/global';

type State = {
  appKey: string;
};

export class AppKeyModel implements Model<State> {
  state: State = {
    appKey: ''
  };

  @act()
  private setAppKey(appKey) {
    return {
      ...this.state,
      appKey
    };
  }

  @flow(Flows.latest())
  async initial() {
    const appKey = await service.getAppkey();

    this.setAppKey(appKey);
  }
}

export const appKeyModelRef = sharing(() => AppKeyModel);
