import {
  ContentWithImageAndKeyword,
  ContentWithKeyword
} from '@/services/parse/type';
import { getPlatform } from '../platform';

const { platform } = getPlatform();

function transformExpressions(keyword: string): string {
  const regWords = Array.from('*.?+$^[](){}|/')
    .map(w => `\\${w}`)
    .join('|');
  return keyword.replace(new RegExp(regWords, 'g'), w => `\\${w}`);
}

function transToMatchRegs(keys: Array<string>): Array<string> {
  return keys
    .map(text => transformExpressions(text))
    .sort((a, b) => b.length - a.length);
}

const emojisTB: Record<string, string> = {
  '/:^_^': '001.gif',
  '/:^$^': '002.gif',
  '/:Q': '003.gif',
  '/:815': '004.gif',
  '/:809': '005.gif',
  '/:^O^': '006.gif',
  '/:081': '007.gif',
  '/:087': '008.gif',
  '/:086': '009.gif',
  '/:H': '010.gif',
  '/:012': '011.gif',
  '/:806': '012.gif',
  '/:b': '013.gif',
  '/:^x^': '014.gif',
  '/:814': '015.gif',
  '/:^W^': '016.gif',
  '/:080': '017.gif',
  '/:066': '018.gif',
  '/:807': '019.gif',
  '/:805': '020.gif',
  '/:071': '021.gif',
  '/:072': '022.gif',
  '/:065': '023.gif',
  '/:804': '024.gif',
  '/:813': '025.gif',
  '/:818': '026.gif',
  '/:015': '027.gif',
  '/:084': '028.gif',
  '/:801': '029.gif',
  '/:811': '030.gif',
  '/:?': '031.gif',
  '/:077': '032.gif',
  '/:083': '033.gif',
  '/:817': '034.gif',
  '/:!': '035.gif',
  '/:068': '036.gif',
  '/:079': '037.gif',
  '/:028': '038.gif',
  '/:026': '039.gif',
  '/:007': '040.gif',
  '/:816': '041.gif',
  '/:\'""': '042.gif',
  '/:802': '043.gif',
  '/:027': '044.gif',
  '/:(Zz...)': '045.gif',
  '/:*&*': '046.gif',
  '/:810': '047.gif',
  '/:>_<': '048.gif',
  '/:018': '049.gif',
  '/:>O<': '050.gif',
  '/:020': '051.gif',
  '/:044': '052.gif',
  '/:819': '053.gif',
  '/:085': '054.gif',
  '/:812': '055.gif',
  '/:"': '056.gif',
  // '/:>M<': '057.gif',
  '/:>@<': '057.gif',
  '/:076': '058.gif',
  '/:069': '059.gif',
  '/:O': '060.gif',
  '/:067': '061.gif',
  '/:043': '062.gif',
  '/:P': '063.gif',
  '/:808': '064.gif',
  '/:>W<': '065.gif',
  '/:073': '066.gif',
  '/:008': '067.gif',
  '/:803': '068.gif',
  '/:074': '069.gif',
  '/:O=O': '070.gif',
  '/:036': '071.gif',
  '/:039': '072.gif',
  '/:045': '073.gif',
  '/:046': '074.gif',
  '/:048': '075.gif',
  '/:047': '076.gif',
  '/:girl': '077.gif',
  '/:man': '078.gif',
  '/:052': '079.gif',
  '/:(OK)': '080.gif',
  '/:8*8': '081.gif',
  '/:)-(': '082.gif',
  '/:lip': '083.gif',
  '/:-F': '084.gif',
  '/:-W': '085.gif',
  '/:Y': '086.gif',
  '/:qp': '087.gif',
  '/:$': '088.gif',
  '/:%': '089.gif',
  '/:(&)': '090.gif',
  '/:@': '091.gif',
  '/:~B': '092.gif',
  '/:U*U': '093.gif',
  '/:clock': '094.gif',
  '/:R': '095.gif',
  '/:C': '096.gif',
  '/:plane': '097.gif',
  '/:075': '098.gif'
};
const emojisJD: Record<string, string> = {
  '#E-s01': 's01.png',
  '#E-s02': 's02.png',
  '#E-s03': 's03.png',
  '#E-s04': 's04.png',
  '#E-s05': 's05.png',
  '#E-s06': 's06.png',
  '#E-s07': 's07.png',
  '#E-s08': 's08.png',
  '#E-s09': 's09.png',
  '#E-s10': 's10.png',
  '#E-s11': 's11.png',
  '#E-s12': 's12.png',
  '#E-s13': 's13.png',
  '#E-s14': 's14.png',
  '#E-s15': 's15.png',
  '#E-s16': 's16.png',
  '#E-s17': 's17.png',
  '#E-s18': 's18.png',
  '#E-s19': 's19.png',
  '#E-s20': 's20.png',
  '#E-s21': 's21.png',
  '#E-s22': 's22.png',
  '#E-s23': 's23.png',
  '#E-s24': 's24.png',
  '#E-s25': 's25.png',
  '#E-s26': 's26.png',
  '#E-s27': 's27.png',
  '#E-s28': 's28.png',
  '#E-s29': 's29.png',
  '#E-s30': 's30.png',
  '#E-s31': 's31.png',
  '#E-s32': 's32.png',
  '#E-s33': 's33.png',
  '#E-s34': 's34.png',
  '#E-s35': 's35.png',
  '#E-s36': 's36.png',
  '#E-s37': 's37.png',
  '#E-s38': 's38.png',
  '#E-s39': 's39.png',
  '#E-s40': 's40.png',
  '#E-s41': 's41.png',
  '#E-s42': 's42.png',
  '#E-s43': 's43.png',
  '#E-s444': 's44.png',
  '#E-s45': 's45.png',
  '#E-s46': 's46.png',
  '#E-s47': 's47.png',
  '#E-s48': 's48.png',
  '#E-s49': 's49.png',
  '#E-s50': 's50.png',
  '#-s51': 's51.png',
  '#-s52': 's52.png',
  '#-s53': 's53.png',
  '#-s54': 's54.png',
  '#-s55': 's55.png',
  '#-s56': 's56.png',
  '#-s57': 's57.png',
  '#-s58': 's58.png',
  '#-s59': 's59.png',
  '#-s60': 's60.png',
  '#-s61': 's61.png',
  '#-s62': 's62.png',
  '#-s63': 's63.png',
  '#-s64': 's64.png',
  '#-s65': 's65.png',
  '#-s66': 's66.png',
  '#-s67': 's67.png',
  '#-s68': 's68.png',
  '#-s69': 's69.png',
  '#-s70': 's70.png',
  '#-s71': 's71.png',
  '#-s72': 's72.png'
};

const emojis = { TB: emojisTB, JD: emojisJD }[platform];

const entries = Object.entries(emojis);

let entriesWithGif: Array<[string, string]> = [];

entriesWithGif = entries.map(([k, v]) => [
  k,
  require(`../../images/emoji/${v}`) // eslint-disable-line
]);

export const emojiIndex = Object.fromEntries(entriesWithGif);

export const keys = Object.keys(emojiIndex);

const emojiKeyRegArray = transToMatchRegs(keys);

const emojiReg = emojiKeyRegArray.join('|');

export class ParseService {
  parseWithEmoji(message: string): Array<ContentWithImageAndKeyword> {
    if (!message) {
      return [{ content: '' }];
    }
    const emo = message.match(new RegExp(emojiReg, 'g'));
    if (!emo || !emo.length) {
      return [{ content: message }];
    }
    const parts = message.split(new RegExp(emojiReg, 'g'));

    return parts
      .map((content, index) => {
        const image = emo[index];
        return image
          ? [{ content }, { content: emojiIndex[image], image: true }]
          : [{ content }];
      })
      .reduce((r, c) => r.concat(c), []);
  }

  parseWithKeyword(
    message: string,
    keywords: string[]
  ): Array<ContentWithKeyword> {
    const keywordArray = transToMatchRegs(keywords);

    const keywordReg = new RegExp(keywordArray.join('|'), 'gu');

    const kds = message.match(keywordReg);
    if (!kds || !kds.length) {
      return [{ content: message }];
    }
    const parts = message.split(keywordReg);
    return parts
      .map((c, index) =>
        kds[index]
          ? [{ content: c }, { content: kds[index], keyword: true }]
          : [{ content: c }]
      )
      .reduce((r, c) => r.concat(c), []);
  }

  parseWithEmojiAndKeyword(
    message: string,
    keywords: string[]
  ): Array<ContentWithImageAndKeyword> {
    const keywordArray = transToMatchRegs(keywords);
    const keywordReg = new RegExp(
      emojiKeyRegArray.concat(keywordArray).join('|'),
      'gu'
    );
    const kds = message.match(keywordReg);
    if (!kds || !kds.length) {
      return [{ content: message }];
    }
    const parts = message.split(keywordReg);
    return parts
      .map((c, index) => {
        const matchKey = kds[index];
        if (matchKey === undefined) {
          return [{ content: c }];
        }
        const image = emojiIndex[matchKey];
        if (image) {
          return [{ content: c }, { content: image, image: true }];
        }
        return [{ content: c }, { content: matchKey, keyword: true }];
      })
      .reduce(
        (
          r: Array<ContentWithImageAndKeyword>,
          c: Array<ContentWithImageAndKeyword>
        ) => r.concat(c),
        []
      );
  }
}

export default new ParseService();
