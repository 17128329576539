import React, { memo, useState } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { message } from 'antd';
import { Button, Submit } from '@/components/widgets/button';
import { Dialog } from '@/components/widgets/modal';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { DialogWidth } from '@/types/standardSize';
import useDebounceFn from '@/hooks/useDebounceFn';
import { RadioGroup, Radio } from '@/components/widgets/radio';
import { ItemGroupSelector } from './components/itemGroupSelector';
import { CategorySelect } from './components/categorySelector';
import ItemSelector from './components/itemSelector';
import { GoodsSelectModalProps } from './type';
import { GoodsSelectModalModel } from './model';
import {
  mergeIidSkuIdsJD,
  mergeIidSkuIds,
  validateItemConfig
} from './service';
import css from './style.less';
import { usePlatform } from '@/hooks/usePlatform';

const MODAL_ACTION_TITLE = {
  edit: '编辑商品',
  inspectionItem: '选择商品',
  addRecommendItem: '选择商品'
};

const NoLimited = memo(() => (
  <div className={css.noLimitedWrap}>
    <div>
      已选择“不限”
      <br />
      关联全部商品
    </div>
  </div>
));

export default memo((props: DialogDeployProps<GoodsSelectModalProps>) => {
  const { closeDialog, params } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const { isTB } = usePlatform();
  const {
    label = '咨询商品',
    require = false,
    modalAction,
    showItemRelatedType = true,
    itemRelatedType,

    showItem = true,
    showSku = false,
    limit = 10,
    taobaoIidskuids = [],
    selectRecommendItem = [],
    selectLittleItem = false,

    showItemGroup = true,
    itemGroupIds = [],

    showSellerCat = true,
    sellerCatCids = []
  } = params;
  const newShowSku = isTB ? showSku : true;

  const calcCustomItemRelatedType = () => {
    if (itemRelatedType) return itemRelatedType;

    if (taobaoIidskuids?.length || require) return 'ITEM';

    if (itemGroupIds.length) return 'ITEM_GROUP';

    if (sellerCatCids.length) return 'SELLER_CAT';

    return 'ALL';
  };

  const [customItemRelatedType, setCustomItemRelatedType] = useState(
    calcCustomItemRelatedType()
  );

  const model = useAgentReducer(new GoodsSelectModalModel(params));
  const {
    state,
    handleItemChange,
    handleSellerCatCidsChange,
    handleItemGroupIdsChange
  } = model;
  const {
    selectItems = [],
    selectSkus = [],
    selectSellerCatList,
    sellerCatNames,
    itemGroupNames,
    selectItemGroupIds
  } = state;

  const handleOk = useDebounceFn(async () => {
    const selectTaobaoIidskuids = isTB
      ? mergeIidSkuIds(selectSkus, selectItems)
      : mergeIidSkuIdsJD(selectSkus, selectItems);

    const err = validateItemConfig({
      relatedType: customItemRelatedType,
      taobaoIidskuids: selectTaobaoIidskuids,
      itemGroupIds: selectItemGroupIds,
      sellerCatCids: selectSellerCatList
    });

    if (err) {
      message.warning(err);
      return;
    }

    if (customItemRelatedType === 'ALL') {
      closeDialog({
        itemRelatedType: 'ALL',
        result: []
      });
      return;
    }

    setSaveLoading(true);

    if (customItemRelatedType === 'SELLER_CAT') {
      closeDialog({
        itemRelatedType: 'SELLER_CAT',
        result: selectSellerCatList,
        sellerCatNames
      });
    }

    if (customItemRelatedType === 'ITEM') {
      closeDialog({
        itemRelatedType: 'ITEM',
        result: selectTaobaoIidskuids,
        selectTaobaoSpuSku: { selectItems, selectSkus }
      });
    }

    if (customItemRelatedType === 'ITEM_GROUP') {
      closeDialog({
        itemRelatedType: 'ITEM_GROUP',
        result: selectItemGroupIds,
        itemGroupNames
      });
    }

    setSaveLoading(false);
  });

  return (
    <Dialog
      title={`${MODAL_ACTION_TITLE[modalAction]}`}
      onCancel={() => closeDialog(null)}
      footer={
        <div>
          <Button onClick={() => closeDialog(null)}>取消</Button>
          <Submit onClick={handleOk} loading={saveLoading}>
            确定
          </Submit>
        </div>
      }
      wrapClassName={css.modal}
      width={DialogWidth.LARGE}
    >
      {showItemRelatedType ? (
        <div className={css.radioWrap}>
          {label}：
          <RadioGroup
            value={customItemRelatedType}
            onChange={e => setCustomItemRelatedType(e.target.value)}
          >
            {require ? null : <Radio value="ALL">不限</Radio>}
            {showItem ? <Radio value="ITEM">指定商品</Radio> : null}
            {showItemGroup ? (
              <Radio value="ITEM_GROUP">指定商品组</Radio>
            ) : null}
            {showSellerCat ? (
              <Radio value="SELLER_CAT">指定店铺分类</Radio>
            ) : null}
          </RadioGroup>
        </div>
      ) : null}

      {/* TODO 兼容 */}
      {['ITEM', 'SKU'].includes(customItemRelatedType) ? (
        <ItemSelector
          limit={limit}
          showSku={newShowSku}
          items={selectItems}
          skus={selectSkus}
          taobaoIidskuids={taobaoIidskuids}
          modalAction={modalAction}
          selectLittleItem={selectLittleItem}
          selectRecommendItem={selectRecommendItem}
          onChange={handleItemChange}
        />
      ) : null}

      {customItemRelatedType === 'ITEM_GROUP' ? (
        <ItemGroupSelector
          itemGroupIds={selectItemGroupIds}
          onChange={handleItemGroupIdsChange}
        />
      ) : null}

      {customItemRelatedType === 'SELLER_CAT' ? (
        <CategorySelect
          value={selectSellerCatList}
          onChange={handleSellerCatCidsChange}
        />
      ) : null}

      {customItemRelatedType === 'ALL' ? <NoLimited /> : null}
    </Dialog>
  );
});
