import { Http, http } from '@/libs/http';
import {
  ShopExportTaskWebDto,
  RemindRecordDetailExportTaskWebSaveCmd,
  ExportTaskWebSaveCmd,
  RemindStrategyStatsWebQuery,
  RemindDailyStatsExportQuery
} from './type';
import moment from 'moment';

export class ExportCenterRequest {
  private http: Http = http('/api/shop/export-center');

  deleteTasks(taskIds: number[]): Promise<void> {
    return this.http.path('/delete-tasks').setRequestBody(taskIds).post();
  }

  getExportTaskList(): Promise<ShopExportTaskWebDto[]> {
    return this.http.path('/export-task-list').get();
  }

  saveRecordDetailExportTask(
    params: RemindRecordDetailExportTaskWebSaveCmd
  ): Promise<void> {
    return this.http
      .path('/save-record-detail-export-task')
      .setRequestBody(params)
      .post();
  }

  createRecordStatsExportTask(params: ExportTaskWebSaveCmd): Promise<void> {
    return this.http
      .path('/create-export-task')
      .setRequestBody({
        ...params,
        fileName: `${params.fileName}-${moment().format('YYYYMMDDHHmmssSS')}`
      })
      .post();
  }

  createDailySendStatsExportTask(
    params: RemindDailyStatsExportQuery
  ): Promise<void> {
    return this.http
      .path('/create-daily-send-stats-export-task')
      .setRequestBody(params)
      .post();
  }

  createDailyConversionStatsExportTask(
    params: RemindDailyStatsExportQuery
  ): Promise<void> {
    return this.http
      .path('/create-daily-conversion-stats-export-task')
      .setRequestBody(params)
      .post();
  }

  createDailyRateStatsExportTask(
    params: RemindDailyStatsExportQuery
  ): Promise<void> {
    return this.http
      .path('/create-daily-rate-stats-export-task')
      .setRequestBody(params)
      .post();
  }

  createStrategySendStatsExportTask(
    params: RemindStrategyStatsWebQuery
  ): Promise<void> {
    return this.http
      .path('/create-strategy-send-stats-export-task')
      .setRequestBody(params)
      .post();
  }

  createStrategyConversionStatsExportTask(
    params: RemindStrategyStatsWebQuery
  ): Promise<void> {
    return this.http
      .path('/create-strategy-conversion-stats-export-task')
      .setRequestBody(params)
      .post();
  }

  createStrategyRateStatsExportTask(
    params: RemindStrategyStatsWebQuery
  ): Promise<void> {
    return this.http
      .path('/create-strategy-rate-stats-export-task')
      .setRequestBody(params)
      .post();
  }
}

export default new ExportCenterRequest();
