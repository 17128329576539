import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject
} from 'react';
import { VDivProps } from './type';
import { useVirtualList } from 'ahooks';

function getChildHeightReducer<R = unknown>(
  itemHeightMap: (data: R, index: number) => number
) {
  return function reduceChildHeight(
    reduceHeight: number,
    data: R,
    index: number
  ) {
    return reduceHeight + itemHeightMap(data, index);
  };
}

export const VirtualDiv = memo(
  ({ itemHeight: ih, dataSource, renderItem, ...other }: VDivProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const [range, setRange] = useState<{
      start: number;
      end: number;
      paddingHeight: number;
    }>({
      start: 0,
      end: 0,
      paddingHeight: 0
    });

    const itemHeightMap = typeof ih === 'function' ? ih : undefined;

    const itemHeight = typeof ih === 'number' ? ih : undefined;

    const totalHeight = useMemo(() => {
      const array = dataSource;
      return itemHeightMap
        ? array.reduce(getChildHeightReducer(itemHeightMap), 0)
        : (itemHeight || 0) * array.length;
    }, [dataSource, itemHeightMap]);

    const compute = (
      scrollTop: number,
      listHeight: number,
      dataSourceList: unknown[]
    ): { start: number; end: number; paddingHeight: number } => {
      const array = dataSourceList;
      let start: number | null = null;
      let paddingHeight: number | null = null;
      let end = (array.length || 1) - 1;
      let reduceHeight = 0;
      const reduceCall = itemHeightMap
        ? getChildHeightReducer(itemHeightMap)
        : null;
      for (let i = 0; i < array.length; i++) {
        const prevReduceHeight = reduceHeight;
        reduceHeight = reduceCall
          ? reduceCall(prevReduceHeight, array[i], i)
          : (i + 1) * (itemHeight || 0);
        if (scrollTop - listHeight <= reduceHeight) {
          start = start !== null ? start : i;
          paddingHeight =
            paddingHeight !== null ? paddingHeight : prevReduceHeight;
        }
        if (scrollTop + listHeight * 2 <= reduceHeight) {
          end = i;
          break;
        }
      }
      return { start: start || 0, end, paddingHeight: paddingHeight || 0 };
    };

    useEffect(() => {
      const { current } = ref;
      if (!current) {
        return;
      }
      const r = compute(current.scrollTop, current.clientHeight, dataSource);
      setRange(r);
    }, [dataSource, totalHeight]);

    const inside = useMemo(() => {
      const { start, end, paddingHeight } = range;
      const childs = dataSource.slice(start, end + 1);
      return (
        <div style={{ height: totalHeight }}>
          <div style={{ height: paddingHeight }} />
          {childs.map(renderItem)}
        </div>
      );
    }, [dataSource, range, totalHeight]);

    const handleScroll = useCallback(
      e => {
        const { target } = e;
        const r = compute(target.scrollTop, target.clientHeight, dataSource);
        setRange(r);
      },
      [dataSource, totalHeight]
    );

    return (
      <div ref={ref} {...other} onScroll={handleScroll}>
        {inside}
      </div>
    );
  }
);

export const VirtualDiv2 = memo(
  ({ itemHeight, height, dataSource, renderItem, ...other }: VDivProps) => {
    const containerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const wrapperRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const [list] = useVirtualList(dataSource, {
      containerTarget: containerRef,
      wrapperTarget: wrapperRef,
      itemHeight,
      overscan: 10
    });

    return (
      <div
        ref={containerRef}
        {...other}
        style={{
          overflow: 'auto',
          height: height || itemHeight * Math.min(4, dataSource.length),
          overflowAnchor: 'none',
          ...other.style
        }}
      >
        <div ref={wrapperRef}>
          {list.map(({ data, index }) => renderItem(data, index))}
        </div>
      </div>
    );
  }
);
