import React, { memo, useMemo, useState } from 'react';
import emojiImg from '@/images/emoji.png';
import { emojiIndex, keys } from '@/services/parse';
import usePopOuterClickClose from '@/hooks/usePopOuterClickClose';
import css from './style.less';
import { EmojiProps } from './type';
import { Popover } from '../popover';

export const Emoji = memo((props: EmojiProps) => {
  const { onSelect, disabled } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const handleSelect = (key: string, path: string) => {
    onSelect(key, path);
  };

  const emojis = useMemo(
    () => (
      <div className={css.popContent}>
        {keys.map(emojiKey => {
          const path = emojiIndex[emojiKey];
          return (
            <img
              key={`emoji_${emojiKey}`}
              className={css.emojiIcon}
              src={path}
              onClick={() => handleSelect(emojiKey, path)}
            />
          );
        })}
      </div>
    ),
    [onSelect]
  );

  const updateVisible = (v: boolean) => {
    if (!disabled) {
      setVisible(v);
    }
  };

  return (
    <span
      onClick={() => updateVisible(true)}
      ref={usePopOuterClickClose(() => updateVisible(false))}
    >
      <Popover
        visible={visible}
        title=""
        content={emojis}
        trigger="click"
        overlayClassName={css.emojiPop}
      >
        <img
          src={emojiImg}
          className={`${css.emojiIcon} ${disabled ? css.disabled : ''}`}
        />
      </Popover>
    </span>
  );
});
