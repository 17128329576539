// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_xsMarginBottom_yFXPV {\n  margin-bottom: 4px;\n}\n.style_xsMarginBottom_yFXPV .style_checkbox_f9f-5 {\n  margin-right: 8px;\n}\n.style_xsMarginBottom_yFXPV .style_selectWidth_zpp9S {\n  width: 72px;\n  margin: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindMainStrategyDialog/components/afterPayTradeFilter/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,iBAAA;AACJ;AALA;EAQI,WAAA;EACA,aAAA;AAAJ","sourcesContent":[".xsMarginBottom {\n  margin-bottom: 4px;\n\n  .checkbox {\n    margin-right: 8px;\n  }\n\n  .selectWidth {\n    width: 72px;\n    margin: 0 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsMarginBottom": "style_xsMarginBottom_yFXPV",
	"checkbox": "style_checkbox_f9f-5",
	"selectWidth": "style_selectWidth_zpp9S"
};
export default ___CSS_LOADER_EXPORT___;
