import { Http, http } from '@/libs/http';
import {
  RemindStrategyStatsWebQuery,
  TradeRemindRecordStrategyStatsWebDto
} from './type';

export class TradeRemindRecordRequest {
  private http: Http = http('/api/shop/trade-remind-record');

  // 按任务查询统计数据接口
  strategyStatsList(
    params: RemindStrategyStatsWebQuery
  ): Promise<TradeRemindRecordStrategyStatsWebDto> {
    return this.http
      .path('strategy-stats-list-v2')
      .setRequestBody(params)
      .post();
  }
}

export const tradeRemindRecordRequest = new TradeRemindRecordRequest();
