import { Model, strict, act, flow, Flows } from 'agent-reducer';
import inviteRateStatsRequest from '@/requests/inviteRateStats';
import {
  StatsListResult,
  RecordDetailDto,
  InviteRateStrategyStatsWebDto
} from '@/requests/inviteRateStats/type';
import { uiPrompt } from '@/avatars';
import { formatDayFromNow, isValidDateRange } from '@/utils/date';
import exportCenterRequest from '@/requests/exportCenter';
import { validateDateRange } from '@/services/formatStatsData';
import type { PageDetail } from '@/types/pageDetail';
import type { StatsState } from './type';

const taskType = 'INVITE_RATE';

const initStatsData = {
  valueList: [],
  avgMap: {},
  sumMap: {}
};
@strict()
export class StatsModel implements Model<StatsState> {
  state: StatsState = {
    statsLoading: false,
    statsTableLoading: false,
    detailLoading: false,
    dateRange: [formatDayFromNow(-6), formatDayFromNow(0)],
    statsTabKey: 'time',
    stats: initStatsData,
    strategyStatsList: initStatsData,
    statsPage: 1,
    detailRecordList: [],
    page: 1,
    pageSize: 10,
    total: 0
  };

  @act()
  private statsLoad(): StatsState {
    return { ...this.state, statsLoading: true };
  }

  @act()
  private statsUnload(): StatsState {
    return { ...this.state, statsLoading: false };
  }

  @act()
  private statsTableLoad(): StatsState {
    return { ...this.state, statsTableLoading: true };
  }

  @act()
  private statsTableUnload(): StatsState {
    return { ...this.state, statsTableLoading: false };
  }

  @act()
  private detailLoad(): StatsState {
    return { ...this.state, detailLoading: true };
  }

  @act()
  private deatilUnload(): StatsState {
    return { ...this.state, detailLoading: false };
  }

  @act()
  private changeState(state: Partial<StatsState>): StatsState {
    return { ...this.state, ...state };
  }

  @act()
  changeStatsTabKey(statsTabKey: string): StatsState {
    return { ...this.state, statsTabKey, statsPage: 1 };
  }

  @act()
  changeStatsPage(statsPage: number): StatsState {
    return { ...this.state, statsPage };
  }

  private fetchDetailRecord(params): Promise<PageDetail<RecordDetailDto>> {
    const { dateRange, pageSize, page } = this.state;
    const [from, to] = dateRange;

    return inviteRateStatsRequest.recordDetailList({
      pageSize,
      page,
      from,
      to,
      ...params
    });
  }

  private fetchStats(): Promise<StatsListResult> {
    const [from, to] = this.state.dateRange;

    return inviteRateStatsRequest.statsList({ from, to });
  }

  private fetchStrategyStatsList(): Promise<InviteRateStrategyStatsWebDto> {
    const {
      dateRange: [from, to],
      subStrategyId
    } = this.state;
    return inviteRateStatsRequest.strategyStatsList({
      from,
      to,
      subStrategyId,
      taskType
    });
  }

  @flow(Flows.latest())
  async initStats(): Promise<void> {
    this.statsLoad();
    try {
      const stats = await this.fetchStats();

      this.changeState({ stats, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.statsUnload();
    }
  }

  @flow(Flows.latest())
  async initStrategyStatsList(): Promise<void> {
    this.statsTableLoad();
    try {
      const strategyStatsList = await this.fetchStrategyStatsList();

      this.changeState({ strategyStatsList, statsPage: 1 });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.statsTableUnload();
    }
  }

  @flow(Flows.latest())
  async initRecord(): Promise<void> {
    this.detailLoad();
    try {
      const detailRecord = await this.fetchDetailRecord({ page: 1 });
      const { dataList, totalCount } = detailRecord;
      this.changeState({
        detailRecordList: dataList,
        total: totalCount,
        page: 1
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  @flow()
  handleDateRangeChange(
    dateRange: [string | undefined, string | undefined]
  ): void {
    if (!isValidDateRange(dateRange)) {
      return;
    }

    const validDateRange = validateDateRange(dateRange);
    if (!validDateRange) {
      return;
    }

    this.changeState({ dateRange: validDateRange });
    Promise.all([
      this.initStats(),
      this.initStrategyStatsList(),
      this.initRecord()
    ]);
  }

  @flow(Flows.latest())
  async handleStrategyStatsListSearch(subStrategyId?: number): Promise<void> {
    this.changeState({ subStrategyId });

    const { dateRange } = this.state;
    const validDateRange = validateDateRange(dateRange);

    if (!validDateRange) {
      return;
    }

    this.initStrategyStatsList();
  }

  @flow(Flows.latest())
  async handleDetailPageChange(page: number): Promise<void> {
    this.detailLoad();
    try {
      const { dataList, totalCount } = await this.fetchDetailRecord({ page });
      this.changeState({ detailRecordList: dataList, total: totalCount, page });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.deatilUnload();
    }
  }

  async handleExportDetail(): Promise<boolean> {
    const { dateRange, total } = this.state;
    const [from, to] = dateRange;

    if (!total) {
      return false;
    }
    await exportCenterRequest.saveRecordDetailExportTask({
      taskType,
      from,
      to,
    });
    return true;
  }

  async handleExportStats(): Promise<boolean> {
    const { stats, statsTabKey, strategyStatsList, dateRange, subStrategyId } =
      this.state;
    const [from, to] = dateRange;
    if (
      (!stats.valueList.length && statsTabKey === 'time') ||
      (!strategyStatsList.valueList.length && statsTabKey === 'task')
    ) {
      return false;
    }

    const params = { from, to };

    if (statsTabKey === 'time') {
      await exportCenterRequest.createDailySendStatsExportTask({
        remindType: taskType,
        ...params
      });
    } else {
      await exportCenterRequest.createStrategySendStatsExportTask({
        taskType,
        subStrategyId,
        ...params
      });
    }

    return true;
  }
}
