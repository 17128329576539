import React, { memo, useEffect } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { Table, Column } from '@/components/widgets/table';
import { buildQuickDateRangeFromToday } from '@/utils/date';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import commonCss from '@/css/common.less';
import { useTitleRegister } from '@/hooks/titleRender';
import { buildColumnsWithSorter } from '@/services/sort';
import WangWang from '@/components/works/wangwang';
import { ConsignPushDetailRecordWebDto } from '@/requests/consignPushStats/type';
import { ExportButton } from '@/components/works/exportButton';
import {
  Card,
  DetailSearch,
  Statistic,
  StatisticTabs,
  StrategyStatsTable,
  ReplyNumTitle
} from '@/components/works/statisticComponents';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import { ConsignPushStatModel } from './model';
import { buildStatistics, numberToPercentage } from '../service';
import css from './style.less';
import { PlatformWrapper } from '@/components/works/platformWrapper';

const statisticItems = [
  { key: 'remindTradeNum', name: '发货提醒订单数' },
  { key: 'remindBuyerNum', name: '发货提醒人数' }
];

const timeStatsColumnsConfig = buildColumnsWithSorter([
  { title: '日期', dataIndex: 'businessDay', width: '200px' },
  { title: '发货提醒订单数', dataIndex: 'remindTradeNum' },
  { title: '发货提醒人数', dataIndex: 'remindBuyerNum' },
  { title: <ReplyNumTitle label="回复人数" />, dataIndex: 'replyBuyerNum' },
  {
    title: (
      <TooltipWithIcon label="回复率" tooltip="回复率=回复人数/发货提醒人数" />
    ),
    dataIndex: 'replyRate',
    render: value => `${(value * 100).toFixed(2)}%`
  }
]);

const taskStatsColumnsConfig = buildColumnsWithSorter([
  { title: '任务名称', dataIndex: 'strategyName', width: '200px' },
  {
    title: '发货提醒订单数',
    dataIndex: 'remindTradeNum',
    defaultSortOrder: 'descend'
  },
  { title: '发货提醒人数', dataIndex: 'remindBuyerNum' },
  { title: <ReplyNumTitle label="回复人数" />, dataIndex: 'replyBuyerNum' },
  {
    title: (
      <TooltipWithIcon label="回复率" tooltip="回复率=回复人数/发货提醒人数" />
    ),
    dataIndex: 'replyRate',
    render: value => `${(value * 100).toFixed(2)}%`
  }
]);

export default memo(() => {
  useTitleRegister(() => <>发货提醒数据</>);

  const {
    state: {
      dateRange,
      statsLoading,
      statsTableLoading,
      detailLoading,
      stats,
      statsTabKey,
      strategyStatsList,
      detailRecordList,
      statsPage,
      page,
      pageSize,
      total
    },
    initStats,
    initStrategyStatsList,
    initRecord,
    changeStatsPage,
    changeStatsTabKey,
    handleStrategyStatsListSearch,
    handleDetailSearch,
    handleDateRangeChange,
    handleDetailPageChange,
    handleExportDetail,
    handleExportStats
  } = useAgentReducer(ConsignPushStatModel);

  useEffect(() => {
    initStats();
    initStrategyStatsList();
    initRecord();
  }, []);

  const strategyStatsTableConfig =
    statsTabKey === 'time'
      ? {
          dataSource: stats.valueList,
          loading: statsLoading,
          columns: timeStatsColumnsConfig,
          sumMap: stats.sumMap,
          avgMap: numberToPercentage(stats.avgMap, ['replyRate'])
        }
      : {
          dataSource: strategyStatsList.valueList,
          loading: statsTableLoading,
          columns: taskStatsColumnsConfig,
          sumMap: strategyStatsList.sumMap,
          avgMap: numberToPercentage(strategyStatsList.avgMap, ['replyRate'])
        };

  const summaryColumns = strategyStatsTableConfig.columns
    .slice(1)
    .map(val => val.dataIndex);

  const statistics = buildStatistics(statisticItems, stats);

  return (
    <div className={commonCss.main}>
      <Card
        titleTop={
          <>
            <div className={commonCss.search}>
              <div className={commonCss.item}>
                <CustomRangePicker
                  options={buildQuickDateRangeFromToday()}
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  onSubmit={handleDateRangeChange}
                />
              </div>
            </div>
            <Statistic
              statisticItems={statisticItems}
              statistics={statistics}
              loading={statsLoading}
            />
          </>
        }
        title={
          <>
            发货提醒效果统计
            <ExportButton
              onExport={handleExportStats}
              emptyText="导出统计为空"
            />
          </>
        }
      >
        <StatisticTabs
          activeKey={statsTabKey}
          remindType="CONSIGN_PUSH"
          onChange={changeStatsTabKey}
          onSearch={handleStrategyStatsListSearch}
        />

        <StrategyStatsTable
          key={statsTabKey}
          currentPage={statsPage}
          summaryColumns={summaryColumns as string[]}
          onPageChange={changeStatsPage}
          {...strategyStatsTableConfig}
        />
      </Card>
      <Card
        className={css.content}
        titleTop={
          <DetailSearch
            remindType="CONSIGN_PUSH"
            onSearch={handleDetailSearch}
          />
        }
        firstHeading
        title={
          <>
            发货提醒明细
            <ExportButton onExport={handleExportDetail} />
          </>
        }
      >
        <Table
          rowKey="id"
          bordered
          size="small"
          dataSource={detailRecordList}
          showSorterTooltip={false}
          loading={detailLoading}
          pagination={{
            size: 'default',
            position: ['bottomCenter'],
            hideOnSinglePage: true,
            showQuickJumper: true,
            showSizeChanger: false,
            current: page,
            pageSize,
            total,
            onChange: handleDetailPageChange
          }}
        >
          <Column title="发货提醒时间" dataIndex="remindTime" width="170px" />
          <Column title="任务名称" dataIndex="taskName" width="200px" />
          <Column
            title="发货提醒客服"
            dataIndex="subAccountNick"
            width="170px"
          />
          <Column
            title="买家账号"
            dataIndex="buyerNick"
            width="170px"
            render={(value, record: ConsignPushDetailRecordWebDto) => (
              <WangWang openUid={record.buyerOpenUid} nick={value} />
            )}
          />
          <Column title="订单号" dataIndex="taobaoTid" width="170px" />
          <Column
            title="买家是否回复"
            dataIndex="buyerReplied"
            width="170px"
            render={v => (v ? '是' : '否')}
          />
        </Table>
      </Card>
    </div>
  );
});
