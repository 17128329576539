import React, { useState } from 'react';
import { Icon } from '@/components/widgets/icon';
import { MultipleRoundsTextareaProps } from './type';
import css from './style.less';
import { Tabs, TabPane } from '@/components/widgets/tabs';
import { Unit } from '../conditionUnit';
import { TimeoutSelector } from '../../remindSubStrategyForm/remindSubStrategyTemplate/components';
import { SubsectionTextarea } from '../subsectionTextarea';
import { groupBy } from '@/polyfill/array';
import { RemindTaskContentDto } from '@/types/remindContentType';
import { UnitTime } from '@/types/unitTime';
import { Modal } from '@/components/widgets/modal';
import { chineseNumArr } from '@/constants/chineseNum';

export function MultipleRoundsTextarea(
  props: MultipleRoundsTextareaProps
): JSX.Element {
  const {
    index,
    value,
    placeholder,
    renderVariableSelect,
    onChange,
    onDelete
  } = props;
  const round = index + 1;

  const [activeKey, setActiveKey] = useState('0');

  const { roundDelaySeconds, remindContents } = value;

  const roundRemindContents = remindContents.filter(
    remindContent => remindContent.round === round
  );

  const calcHeraCheckStatus = (remindContent: RemindTaskContentDto): string => {
    if (remindContent.heraCheckStatus) {
      return remindContent.heraCheckStatus;
    }

    return 'WAIT_CHECK';
  };

  const handleAdd = () => {
    onChange?.({
      ...value,
      remindContents: [
        ...remindContents,
        {
          heraCheckStatus: 'WAIT_CHECK',
          contents: [],
          replyOrder: 'TEXT_BEFORE_IMAGE',
          round
        }
      ]
    });
    setActiveKey(roundRemindContents.length.toString());
  };

  // 删除单轮推送次数
  const handleRemove = async (targetKey: string) => {
    const ok = await Modal.confirm({ title: '是否确认删除？', content: '' });
    if (!ok) {
      return;
    }
    const activeKeyNum = Number(activeKey);
    const targetKeyNum = Number(targetKey);
    const fliterRes = roundRemindContents.filter((_, i) => i !== targetKeyNum);
    const newRemindContents = groupBy(remindContents, 'round')
      .set(round, fliterRes)
      .values();

    onChange({ ...value, remindContents: [...newRemindContents].flat() });

    if (activeKeyNum >= targetKeyNum) {
      const nextKey = activeKeyNum - 1 < 0 ? 0 : activeKeyNum - 1;
      setActiveKey(nextKey.toString());
    }
  };

  const handleEdit = (targetKey, action: 'add' | 'remove') => {
    if (action === 'add') {
      handleAdd();
    } else {
      handleRemove(targetKey);
    }
  };

  const handleContentChange = (v: RemindTaskContentDto, index) => {
    const { round } = v;
    const remindContentsMap: any = groupBy(remindContents, 'round');

    const remindContentsWithRound = remindContentsMap
      .get(round)
      .map((contents, i) => {
        if (i === index) {
          return { ...contents, ...v };
        }
        return contents;
      });

    remindContentsMap.set(round, remindContentsWithRound);

    onChange?.({
      ...value,
      remindContents: [...remindContentsMap.values()].flat()
    });
  };

  const handleRoundDelaySecondsChange = (v: UnitTime) => {
    const newroundDelaySeconds = [...roundDelaySeconds];
    newroundDelaySeconds.splice(index, 1, v.value);

    onChange?.({
      ...value,
      roundDelaySeconds: newroundDelaySeconds
    });
  };

  const handleTabChange = activeKey => {
    setActiveKey(activeKey);
  };

  const handleDeleteRound = () => {
    onDelete?.();
    setActiveKey('0');
  };

  return (
    <>
      <Unit
        label={`第${chineseNumArr[index + 1]}轮推送`}
        className={css.unitWrapper}
      >
        {roundDelaySeconds.length > 1 ? (
          <Icon
            className={css.icon}
            type="shanchu"
            onClick={handleDeleteRound}
          />
        ) : null}
      </Unit>
      <Unit
        label="等待时间"
        className={css.unitWrapper}
        style={{ alignItems: 'center' }}
      >
        <TimeoutSelector
          value={{
            value: roundDelaySeconds[index],
            unit: 'SECONDS'
          }}
          onChange={handleRoundDelaySecondsChange}
        />
      </Unit>
      <Unit label="推送内容" className={css.unitWrapper}>
        <div className={css.textAreaMain}>
          <Tabs
            type="editable-card"
            onEdit={handleEdit}
            size="small"
            tabBarStyle={{ margin: 0 }}
            activeKey={activeKey}
            hideAdd={roundRemindContents.length >= 5}
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            addIcon={<a>+添加推送内容</a>}
            onChange={handleTabChange}
          >
            {roundRemindContents.map((remindContent, i) => (
              <TabPane
                style={{ padding: 0 }}
                tab={`随机推送${i + 1}`}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                closable={roundRemindContents.length > 1}
              >
                <SubsectionTextarea
                  key={`${i.toString()}`}
                  index={0}
                  length={0}
                  value={remindContent}
                  placeholder={placeholder}
                  type={calcHeraCheckStatus(remindContent)}
                  checkFailReason={remindContent?.checkFailReason}
                  renderVariableSelect={renderVariableSelect}
                  onChange={(v: RemindTaskContentDto) =>
                    handleContentChange(v, i)
                  }
                />
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Unit>
    </>
  );
}
