import { useState, useEffect, useRef, useCallback } from 'react';

const usePageContentScroll = (): number => {
  const [scrollTop, setScrollTop] = useState(0);
  const pageContentRef = useRef<HTMLElement | null>(null);

  const update = useCallback((e: Event) => {
    setScrollTop((e.target as HTMLElement)?.scrollTop);
  }, []);

  useEffect(() => {
    pageContentRef.current = document.getElementById('#page-content');

    if (pageContentRef.current) {
      pageContentRef.current.addEventListener('scroll', update);
      setScrollTop(pageContentRef.current.scrollTop);
    }

    return () => {
      if (pageContentRef.current) {
        pageContentRef.current.removeEventListener('scroll', update);
      }
    };
  }, []);

  return scrollTop;
};

export default usePageContentScroll;
