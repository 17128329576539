import React, { memo, PropsWithChildren, ReactNode } from 'react';
import { Platform } from '@/types/platform';
import { usePlatform } from '@/hooks/usePlatform';

type PlatformWrapperProps = {
  component?: Partial<Record<Platform, ReactNode>>;
};

export const PlatformWrapper = memo(
  (props: PropsWithChildren<PlatformWrapperProps>) => {
    const { component } = props;
    const { platform } = usePlatform();

    if (component && React.isValidElement(component[platform])) {
      return component[platform];
    }

    return null;
  }
);
