import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Tabs, TabPane } from '@/components/widgets/tabs';
import { Select, Option } from '@/components/widgets/select';
import { warnError } from '@/components/infos';
import shopRequest from '@/requests/shop';
import { RemindType } from '@/types/remindType';
import css from './style.less';

type StatisticTabsProps = {
  activeKey: string;
  remindType: RemindType;
  onChange: (activeKey: string) => void;
  onSearch: (id?: number) => void;
};

const StatisticTabs = memo((props: StatisticTabsProps) => {
  const { activeKey, remindType, onChange, onSearch } = props;
  const [strategyNameList, setStrategyNameList] = useState<any[]>([]);
  const [subStrategyId, setSubStrategyId] = useState<number | null>(null);

  useEffect(() => {
    shopRequest
      .getStrategyNameList(remindType)
      .then(res => {
        setStrategyNameList(res);
      })
      .catch(err => {
        warnError(err);
        setStrategyNameList([]);
      });
  }, [remindType]);

  const handleChange = (key: string) => {
    onChange(key);
  };

  const handleSearch = (id: number) => {
    setSubStrategyId(id);
    onSearch(id);
  };

  return (
    <div className={css.tabWrapper}>
      <Tabs activeKey={activeKey} onChange={handleChange}>
        <TabPane tab="按日期查看" key="time" />
        <TabPane tab="按任务查看" key="task" />
      </Tabs>
      {activeKey === 'task' ? (
        <div>
          任务名称：
          <Select
            style={{ width: '260px' }}
            allowClear
            showSearch
            optionFilterProp="children"
            value={subStrategyId}
            onChange={handleSearch}
          >
            {strategyNameList.map(v => (
              <Option key={v.id} value={v.id}>
                {v.strategyName}
              </Option>
            ))}
          </Select>
        </div>
      ) : null}
    </div>
  );
});

export default StatisticTabs;
