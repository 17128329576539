import type { ExportTaskStatus } from "@/requests/exportCenter/type";

export const statusMap: Record<
  ExportTaskStatus,
  { color: string; text: string; value: number }
> = {
  PROCESSING: { color: "#FF8600", text: "进行中", value: 1 },
  CREATED: { color: "#3D7EFF", text: "未开始", value: 0 },
  ERROR: { color: "#F23C3C", text: "失败", value: 3 },
  SUCCESS: { color: "#1DC11D", text: "成功", value: 2 },
};
