import React, { memo, useState } from 'react';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { Popover } from '@/components/widgets/popover';
import { Select, Option } from '@/components/widgets/select';
import { message } from '@/components/widgets/message';
import useMount from '@/hooks/useMount';
import { presaleActivityRequest } from '@/requests/presaleActivity';
import { PresaleActivityWebDto } from '@/requests/presaleActivity/type';
import { PresaleTooltipTitle } from '@/components/works/presalePop';
import { PresaleActivityProps } from './type';
import css from './style.less';
import { ActivityManageModal } from '@/pages/dunningPayStrategy/dunningFinalPaymentStrategy/list/activityManageModal';
import { useDialogMethods } from '@/components/widgets/modal';
import { remindTimesRequest } from '@/requests/remindTimesConfig';

export const activityStatusMap = {
  VALID: '生效中',
  INVALID: '已失效'
};

export const PresaleActivity = memo((props: PresaleActivityProps) => {
  const { value = { validActivityType: 'ALL' }, remindType, onChange } = props;
  const { validActivityType = 'ALL', activityId } = value;

  const [presaleList, setPresaleList] = useState<PresaleActivityWebDto[]>([]);
  const [openDialog] = useDialogMethods();

  const fetchPresaleActivityList = async () => {
    try {
      const res = await presaleActivityRequest.getPresaleActivityList();
      setPresaleList(res);
    } catch (error) {
      message.error(error);
    }
  };

  useMount(() => {
    fetchPresaleActivityList();
  });

  const filterOption = (input: string, option: any) =>
    option.key.split('-')[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const openPresaleDialog = async () => {
    const timeConfig = await remindTimesRequest.getRemindTimesConfigList(
      remindType
    );
    await openDialog(ActivityManageModal, { timeConfig });
    fetchPresaleActivityList();
  };

  const handleTypeChange = e => {
    onChange?.({ ...value, validActivityType: e.target.value });
  };

  const handleActivityChange = v => {
    onChange?.({ ...value, activityId: v });
  };

  return (
    <div className={css.item}>
      <div className={css.radio}>
        <RadioGroup value={validActivityType} onChange={handleTypeChange}>
          <Radio value="ALL">不限</Radio>
          <Radio value="SPECIFIED">指定活动</Radio>
        </RadioGroup>
        <span style={{ color: '#999' }}>
          多个预售活动的尾款支付时间存在交集时（如：活动A 10号~20号、活动B
          15号~20号），需分别指定活动和对应的商品
        </span>
      </div>

      {validActivityType === 'SPECIFIED' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Select
            value={activityId}
            showSearch
            filterOption={filterOption}
            style={{ width: 300 }}
            placeholder="请选择生效活动"
            onChange={handleActivityChange}
          >
            {presaleList.map(i => (
              <Option value={i.id} key={`${i.id}-${i.activityName}`}>
                <Popover
                  content={<PresaleTooltipTitle presaleDto={i} />}
                  placement="right"
                >
                  <div className={css.presaleOption}>
                    {i.activityName}/{activityStatusMap[i.activityStatus]}
                  </div>
                </Popover>
              </Option>
            ))}
          </Select>
          <span
            className={css.link}
            style={{ marginLeft: 4 }}
            onClick={openPresaleDialog}
          >
            新建 / 编辑活动
          </span>
        </div>
      ) : null}
    </div>
  );
});
