import React, { useState } from 'react';
import { Icon } from '@/components/widgets/icon';
import css from './style.less';
import { accountRef } from '@/modules/account';
import { useAgentSelector, useAgentReducer } from 'use-agent-reducer';
import {
  useDialogMethods,
  Dialog,
  DialogDeployProps
} from '@/components/widgets/modal/dialogs';
import { getAccountLabel } from '@/hooks/useCustomerNames';
import { Tooltip } from '@/components/widgets/tooltip';
import { AccountListDto } from '@/requests/shop/type';
import { Button } from '@/components/widgets/button';
import { Select } from '@/components/widgets/select';
import { message } from '@/components/widgets/message';
import { jmDongDongModelRef } from './model';
import shopRequest from '@/requests/shop';
import { uiPrompt } from '@/avatars';

type DongDongProps = {
  nick: string;
  buyerOpenUid: string;
  icon?: React.ReactNode;
};

const AccountSelectDialog = (props: DialogDeployProps<{ id?: number }>) => {
  const { closeDialog, params } = props;

  const accountList = useAgentSelector(accountRef.current, s => s.account);

  const [selectAccountId, setSelectAccountId] = useState<number | undefined>(
    params.id
  );

  const handleCancel = () => {
    closeDialog();
  };

  const handleOk = () => {
    const jmAccoount = accountList.find(v => v.id == selectAccountId);
    if (!jmAccoount) {
      message.warning({
        content: '请选择绑定账号',
        style: { marginTop: '40vh' }
      });
      return;
    }
    closeDialog(jmAccoount);
  };

  return (
    <Dialog
      title="更换绑定账号"
      onOk={handleOk}
      onCancel={handleCancel}
      width="600px"
      bodyStyle={{ padding: '0px' }}
    >
      <div>
        <div style={{ padding: '24px' }}>
          请选择本机最常登录账号，后续查看聊天都以此账号打开买家聊天窗
          <div style={{ marginTop: '24px' }}>
            <Select
              showSearch
              optionFilterProp="label"
              style={{ width: '200px' }}
              options={accountList.map(v => ({
                label: getAccountLabel(v),
                value: v.id
              }))}
              value={selectAccountId}
              onChange={id => setSelectAccountId(id)}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const DongDong = (props: DongDongProps) => {
  const { nick, icon, buyerOpenUid } = props;

  const {
    state: { jmAccount },
    changeJmAccount
  } = useAgentReducer(jmDongDongModelRef.current);

  const [openDialog] = useDialogMethods();

  const openDD = async (subNick: string, buyerOpenUid: string) => {
    if (!jmsdkPC) {
      return;
    }
    try {
      const realBuyerNick = await shopRequest.getPin({ buyerOpenUid });
      jmsdkPC.openDD({
        pin: subNick,
        client: realBuyerNick
      });
    } catch (error) {
      uiPrompt.current.error(error);
    }
  };

  const handleChangeJmAccoount = async () => {
    const ret = (await openDialog(AccountSelectDialog, {
      id: jmAccount?.id
    })) as AccountListDto | undefined;
    if (ret) {
      if (!jmAccount && ret.subNick) {
        openDD(ret.subNick, buyerOpenUid);
      }
      changeJmAccount(ret);
    }
  };

  const handleOpenDD = () => {
    if (!jmsdkPC) {
      return;
    }

    if (!jmAccount || !jmAccount.subNick) {
      handleChangeJmAccoount();
      return;
    }

    openDD(jmAccount.subNick, buyerOpenUid);
  };

  return (
    <>
      <span style={{ marginRight: '12px' }}>{nick}</span>
      <Tooltip
        title={
          <div>
            {jmAccount ? (
              <span>
                请登录 {jmAccount.subNick} 后查看聊天，或者
                <Button type="link" onClick={handleChangeJmAccoount}>
                  更换绑定账号
                </Button>
              </span>
            ) : (
              <span>查看聊天</span>
            )}
          </div>
        }
      >
        <span onClick={handleOpenDD}>
          {icon || <Icon type="dongdong1" className={css.dongdong} />}
        </span>
      </Tooltip>
    </>
  );
};

export default DongDong;
