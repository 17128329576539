import React, { memo } from 'react';
import classNames from 'classnames';
import { FormItem } from '@/components/widgets/formLike';
import { UnitProps, ConditionUnitProps } from './type';
import css from './style.less';

export const Unit = memo(
  ({ label, children, className, labelClassName, style }: UnitProps) => (
    <div className={className} style={style}>
      <div className={classNames(css.label, labelClassName)}>{label || ''}</div>
      {children}
    </div>
  )
);

export const ConditionUnit = memo(
  ({
    label,
    name,
    children,
    io,
    className,
    visible = true,
    showLabel = true
  }: ConditionUnitProps) => {
    if (!visible) {
      return null;
    }
    return (
      <div className={className}>
        {showLabel ? <div className={css.label}>{label || ''}</div> : null}
        <FormItem name={name} io={io}>
          {children}
        </FormItem>
      </div>
    );
  }
);

export const SearchConditionUnit = memo(
  ({
    label,
    name,
    children,
    io,
    className,
    labelClassName,
    visible = true,
    showLabel = true,
    required = false
  }: ConditionUnitProps) => {
    if (!visible) {
      return null;
    }
    return (
      <div className={className}>
        {showLabel ? (
          <div
            className={classNames(
              css.searchLabel,
              labelClassName,
              required && css.required
            )}
          >
            {label || ''}：
          </div>
        ) : null}
        <FormItem name={name} io={io}>
          {children}
        </FormItem>
      </div>
    );
  }
);
