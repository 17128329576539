import css from '@/entry/style.less';
import React, { memo, PropsWithChildren } from 'react';

export const PageContent = memo(({ children }: PropsWithChildren<{}>) => (
  <div className={css.body}>
    <div className={css.pageContent} id="#page-content">
      {children}
    </div>
  </div>
));
