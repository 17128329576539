import { Model, strict, act, flow, Flows } from 'agent-reducer';
import { State } from './type';
import { uiPrompt } from '@/avatars';
import shopRequest from '@/requests/shop';
import {
  EnterpriseSubAccountWebQuery,
  SendMessageSpeed
} from '@/requests/shop/type';
import { speedTextMap } from './constants';

@strict()
export class SubAccountPermissionsModel implements Model<State> {
  state: State = {
    loading: false,
    wangwangGroupList: [],
    subAccountListQuery: this.getDefaultSubAccountListQuery(),
    subAccountListQueryDisplay: this.getDefaultSubAccountListQuery(),
    subAccountList: [],
    page: 1,
    pageSize: 10,
    subAccountIds: [],
    subAccountTrackOrderInfo: {
      openTrackOrderNum: 0,
      limitNum: 0,
      expireDay: ''
    }
  };

  @act()
  private load(): State {
    return { ...this.state, loading: true };
  }

  @act()
  private unload(): State {
    return { ...this.state, loading: false };
  }

  @act()
  private changeState(state: Partial<State>): State {
    return { ...this.state, ...state };
  }

  private getDefaultSubAccountListQuery(): EnterpriseSubAccountWebQuery {
    return {
      wangwangGroupId: null,
      sendMessageSpeed: null,
      subAccountStatus: null
    };
  }

  @flow(Flows.latest())
  async fetchSubAccountList(newPage?: number): Promise<void> {
    const { subAccountListQuery, page } = this.state;

    this.load();
    try {
      const subAccountList = await shopRequest.subAccountManager(
        subAccountListQuery
      );
      this.changeState({
        subAccountList,
        page: newPage ?? page
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unload();
    }
  }

  @flow()
  handleSubAccountListQueryChange(query: EnterpriseSubAccountWebQuery): void {
    this.changeState({ subAccountListQueryDisplay: query });
  }

  @flow()
  async handleSubAccountListQuerySearch(): Promise<void> {
    const { subAccountListQueryDisplay } = this.state;
    this.changeState({ subAccountListQuery: subAccountListQueryDisplay });
    this.fetchSubAccountList(1);
  }

  @flow()
  handleSubAccountListQueryReset(): void {
    this.handleSubAccountListQueryChange(this.getDefaultSubAccountListQuery());
    this.handleSubAccountListQuerySearch();
  }

  @flow()
  async fetchSubAccountTrackOrderInfo() {
    try {
      const subAccountTrackOrderInfo =
        await shopRequest.subAccountTrackOrderInfo();

      this.changeState({ subAccountTrackOrderInfo });
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @act()
  handlePaginationChange(page: number, pageSize: number): State {
    const { page: prevPage } = this.state;
    if (page !== prevPage) {
      return { ...this.state, page, pageSize, subAccountIds: [] };
    }
    return { ...this.state, page, pageSize };
  }

  @flow()
  async handleTrackOrderEnableChange(
    subAccountId: number,
    traceOrderEnable: boolean
  ): Promise<void> {
    const { openTrackOrderNum = 0, limitNum = 1 } =
      this.state.subAccountTrackOrderInfo || {};

    if (traceOrderEnable && limitNum !== -1 && openTrackOrderNum >= limitNum) {
      uiPrompt.current.modalWarn({
        title: '温馨提示',
        content: `当前版本最多可开启${limitNum}个跟单账号，如需升级版本，请联系工作人员`
      });
      return;
    }

    try {
      await shopRequest.updateTraceOrderEnable({
        subAccountIds: [subAccountId],
        traceOrderEnable
      });
      this.fetchSubAccountList();
      this.fetchSubAccountTrackOrderInfo();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow()
  async handleTraceOrderSelfModifyAllowedChange(
    subAccountId: number,
    traceOrderSelfModifiedAllowed: boolean
  ): Promise<void> {
    try {
      await shopRequest.updateTraceOrderEnableModifyAllowed({
        subAccountIds: [subAccountId],
        traceOrderSelfModifiedAllowed
      });
      this.fetchSubAccountList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @act()
  handleSubAccountSelect(subAccountIds: React.Key[]): State {
    return { ...this.state, subAccountIds: subAccountIds as number[] };
  }

  @flow()
  async handleTrackOrderEnableBatchChange(value: boolean): Promise<void> {
    const { subAccountList, subAccountIds, subAccountTrackOrderInfo } =
      this.state;
    const trackOrderEnableSubAccountIds = subAccountList.flatMap(i =>
      i.trackOrderEnable ? i.id : []
    );
    const deDupSubAccountIds = [
      ...new Set([...trackOrderEnableSubAccountIds, ...subAccountIds])
    ];
    const { limitNum = 1 } = subAccountTrackOrderInfo || {};

    if (!subAccountIds.length) {
      uiPrompt.current.warning('请选择子账号');
      return;
    }

    if (value && limitNum !== -1 && deDupSubAccountIds.length > limitNum) {
      uiPrompt.current.modalWarn({
        title: '温馨提示',
        content: `当前版本最多可开启${limitNum}个跟单账号，如需升级版本，请联系工作人员`
      });
      return;
    }
    try {
      const ok = await uiPrompt.current.modalConfirm({
        title: '温馨提示',
        content: `确定批量${value ? '开启' : '关闭'}吗?`
      });
      if (!ok) {
        return;
      }
      await shopRequest.updateTraceOrderEnable({
        subAccountIds,
        traceOrderEnable: value
      });
      this.changeState({ subAccountIds: [] });
      this.fetchSubAccountList();
      this.fetchSubAccountTrackOrderInfo();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow()
  async handleTraceOrderSelfModifyAllowedBatchChange(
    value: boolean
  ): Promise<void> {
    const { subAccountIds } = this.state;
    if (!subAccountIds.length) {
      uiPrompt.current.warning('请选择子账号');
      return;
    }
    try {
      const ok = await uiPrompt.current.modalConfirm({
        title: '温馨提示',
        content: `确定批量${value ? '允许' : '不允许'}吗?`
      });
      if (!ok) {
        return;
      }
      await shopRequest.updateTraceOrderEnableModifyAllowed({
        subAccountIds,
        traceOrderSelfModifiedAllowed: value
      });
      this.changeState({ subAccountIds: [] });
      this.fetchSubAccountList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow()
  async handleSendMessageSpeedChange(
    subAccountId: number,
    sendMessageSpeed: SendMessageSpeed
  ): Promise<void> {
    try {
      await shopRequest.updateSendMessageSpeed({
        subAccountIds: [subAccountId],
        sendMessageSpeed
      });
      this.fetchSubAccountList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow()
  async handleSendMessageSpeedBatchChange(
    sendMessageSpeed: SendMessageSpeed
  ): Promise<void> {
    const { subAccountIds } = this.state;
    if (!subAccountIds.length) {
      uiPrompt.current.warning('请选择子账号');
      return;
    }
    try {
      const ok = await uiPrompt.current.modalConfirm({
        title: '温馨提示',
        content: `确定批量设置为 ${speedTextMap[sendMessageSpeed]} 吗`
      });
      if (!ok) {
        return;
      }
      await shopRequest.updateSendMessageSpeed({
        subAccountIds,
        sendMessageSpeed
      });
      this.changeState({ subAccountIds: [] });
      this.fetchSubAccountList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }
}
