import React, { memo } from 'react';
import { Popover as SourcePopover, PopoverProps } from 'antd';
import css from './style.less';

export const Popover = memo((props: PopoverProps) => {
  const { overlayClassName = '', ...rest } = props;
  return (
    <SourcePopover
      {...rest}
      overlayClassName={`${overlayClassName} ${css.tooltip}`}
    />
  );
});
