import React, { memo } from 'react';
import { ConditionUnit } from '@/components/works/components/conditionUnit';
import { RadioGroup, Radio } from '@/components/widgets/radio';
import { IOS } from '@/components/widgets/formLike';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';

import css from './style.less';
import { EffectTyepRadioProps } from './type';

/**
 * 开启状态
 */
export const EffectTyepRadio = memo((props: EffectTyepRadioProps) => {
  const { field, label } = props;

  const defalutLabel = (
    <TooltipWithIcon
      label="任务类型"
      tooltip="任务类型只影响效果统计，不影响任务优先级"
    />
  );

  return (
    <ConditionUnit
      key={field}
      className={css.effectType}
      label={label || defalutLabel}
      name={field}
      io={IOS.valueIO}
    >
      <RadioGroup>
        <Radio value="CARE">关怀类</Radio>
        <Radio value="MARKETING">营销类</Radio>
      </RadioGroup>
    </ConditionUnit>
  );
});
