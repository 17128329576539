import React, { memo } from 'react';
import { Checkbox } from '@/components/widgets/checkbox';
import { Select, Option } from '@/components/widgets/select';
import { Unit } from '@/types/unitTime';
import { AfterPayTradeFilterProps } from './type';
import { buyerPaymentDays, buyerPaymentHours } from '../../constant';
import css from './style.less';
import { AfterPayTradeFilterConfig } from '@/requests/remindStrategy/types';

export const defaultAfterPayTradeFilterConfig: AfterPayTradeFilterConfig = {
  filterEnable: false,
  timeLimit: { value: 12, unit: 'HOURS' }
};

export const AfterPayTradeFilter = memo((props: AfterPayTradeFilterProps) => {
  const { afterPayTradeFilterConfig, afterPayTradeFilterConfigChange } = props;
  const {
    filterEnable,
    timeLimit: { value, unit }
  } = afterPayTradeFilterConfig;

  const remindTimeValueOptions = (unit: Unit) => {
    switch (unit) {
      case 'HOURS':
        return buyerPaymentHours;
      case 'DAYS':
        return buyerPaymentDays;
      default:
        return buyerPaymentHours;
    }
  };

  const filterEnableChange = (filterEnable: boolean) => {
    afterPayTradeFilterConfigChange({
      ...afterPayTradeFilterConfig,
      filterEnable
    });
  };

  const timeValueChange = (v: number) => {
    afterPayTradeFilterConfigChange({
      ...afterPayTradeFilterConfig,
      timeLimit: { value: v, unit }
    });
  };

  const timeUnitChange = (u: Unit) => {
    afterPayTradeFilterConfigChange({
      ...afterPayTradeFilterConfig,
      timeLimit: { value: 1, unit: u }
    });
  };

  return (
    <div className={css.xsMarginBottom}>
      <Checkbox
        checked={filterEnable}
        className={css.checkbox}
        onChange={e => filterEnableChange(e.target.checked)}
      />
      买家
      <Select
        className={css.selectWidth}
        value={value}
        onChange={timeValueChange}
        disabled={!filterEnable}
      >
        {remindTimeValueOptions(unit).map(v => (
          <Option value={v} key={v}>
            {v}
          </Option>
        ))}
      </Select>
      <Select
        className={css.selectWidth}
        value={unit}
        onChange={value => timeUnitChange(value)}
        disabled={!filterEnable}
      >
        <Option value="HOURS">小时</Option>
        <Option value="DAYS">天</Option>
      </Select>
      内有付款的订单
    </div>
  );
});
