import { Http, http } from '@/libs/http';
import { WangwangGroupListCommand, WangwangGroupRelationWebDto, WangwangGroupSaveCommand, WangwangGroupWebDto } from './type';

export class CusTeamManangeRequest {
  private http: Http = http('/api/shop/wangwang-group');

  save(params: WangwangGroupSaveCommand): Promise<void> {
    return this.http
      .path('save')
      .setRequestBody(params)
      .post<void>();
  }

  update(params: WangwangGroupSaveCommand & { id: number }): Promise<void> {
    return this.http
      .path('update')
      .setRequestBody(params)
      .post<void>();
  }

  del(params: { wangwangGroupId: number; }): Promise<void> {
    return this.http
      .path('delete-group')
      .setRequestBody(params)
      .post<void>();
  }

  list(params?: WangwangGroupListCommand): Promise<WangwangGroupWebDto[]> {
    return this.http
      .path('list')
      .setRequestParams(params || {})
      .get<WangwangGroupWebDto[]>();
  }

  relation(params: { wangwangGroupId: number }): Promise<WangwangGroupRelationWebDto> {
    return this.http
      .path('check-relation')
      .setRequestParams(params)
      .get<WangwangGroupRelationWebDto>();
  }

}

export const cusTeamManangeRequest =  new CusTeamManangeRequest();