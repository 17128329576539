import { Http, http } from '@/libs/http';
import { PermissionShop } from '@/requests/shop/type';
import { LoginDayDetailWebDto } from '@/types/loginTime';
import { PageDetail } from '@/types/pageDetail';
import {
  DelStaffParams,
  EnterpriseStaffWebDto,
  ModifyStaffParams,
  VerifyStaffParams,
  VerifyStaffDto,
  EnterpriseStaffLoginWebDto,
  EnterpriseStaffLoginDetailParams,
  EnterpriseStaffLoginStatusParams,
  EnterpriseStaffBatchDeleteWebCmd,
  EnterpriseStaffImportResultWebDto,
  EnterpriseStaffPasswordResetWebCmd,
  EnterpriseStaffWebQuery
} from './type';

export class StaffRequest {
  private http: Http = http('/api/staff/enterprise-staff');

  deleteStaff(params: DelStaffParams): Promise<boolean> {
    return this.http.path('delete').setRequestBody(params).post<boolean>();
  }

  staffList(
    params: EnterpriseStaffWebQuery
  ): Promise<PageDetail<EnterpriseStaffWebDto>> {
    return this.http.path('list-v2').setRequestBody(params).post();
  }

  saveStaff(params: ModifyStaffParams): Promise<boolean> {
    return this.http.path('save-v3').setRequestBody(params).post<boolean>();
  }

  updateStaff(params: ModifyStaffParams): Promise<boolean> {
    return this.http.path('update-v3').setRequestBody(params).post<boolean>();
  }

  verifyStaff(params: VerifyStaffParams): Promise<VerifyStaffDto> {
    return this.http
      .path('verify-v2')
      .setRequestBody(params)
      .post<VerifyStaffDto>();
  }

  // 批量删除员工
  batchDeleteStaff(params: EnterpriseStaffBatchDeleteWebCmd): Promise<boolean> {
    return this.http.path('batch-delete').setRequestBody(params).post();
  }

  // 下载批量导入模板
  downloadTemplate(): Promise<string> {
    return this.http.path('download-template').post();
  }

  // 导入员工
  importStaff(params: FormData): Promise<EnterpriseStaffImportResultWebDto> {
    return this.http.path('import').setRequestBody(params).post();
  }

  // 重置密码
  resetPassword(params: EnterpriseStaffPasswordResetWebCmd): Promise<boolean> {
    return this.http.path('reset-password').setRequestBody(params).post();
  }

  listConfigurableShops(): Promise<PermissionShop[]> {
    return this.http.path('list-configurable-shops').get<PermissionShop[]>();
  }

  enterpriseStaffLoginStatus(
    params: EnterpriseStaffLoginStatusParams
  ): Promise<PageDetail<EnterpriseStaffLoginWebDto>> {
    return this.http.path('/login-status-v2').setRequestParams(params).get();
  }

  getphoneNumber(staffId: number): Promise<string> {
    return this.http.path('phone-number').setRequestParams({ staffId }).get();
  }

  enterpriseStaffLoginDetails(
    params: EnterpriseStaffLoginDetailParams
  ): Promise<LoginDayDetailWebDto[]> {
    return this.http.path('/login-details').setRequestParams(params).get();
  }
}

export default new StaffRequest();
