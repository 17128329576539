import {
  middleWare,
  MiddleWarePresets,
  Model,
  weakSharing
} from 'agent-reducer';
import { operationLogService } from './service';
import { LogTypeState, OperationLogState } from './type';
import { ListParams } from '@/requests/operationLog/type';
import moment from 'moment';

export const initialDate = {
  start: moment().add(-6, 'day').format('YYYY-MM-DD'),
  end: moment().format('YYYY-MM-DD')
};

export class OperationLogModel implements Model<OperationLogState> {
  state: OperationLogState = {
    pageNo: 1,
    pageSize: 10,
    enterpriseStaffIds: undefined,
    keyword: undefined,
    logType: undefined,
    from: initialDate.start,
    to: initialDate.end,
    dataSource: [],
    total: 0,
    version: 0
  };

  @middleWare(MiddleWarePresets.takeLatest())
  async getLogList(param?: Partial<ListParams>): Promise<OperationLogState> {
    const { pageNo, pageSize, enterpriseStaffIds, from, keyword, logType, to } =
      this.state;
    const params: ListParams = {
      pageSize,
      enterpriseStaffIds,
      keyword,
      logType,
      from,
      to,
      pageNo,
      ...(param || {})
    };
    const { totalCount, dataList } = await operationLogService.logList(params);
    return {
      ...this.state,
      dataSource: dataList,
      total: totalCount,
      version: this.state.version + 1,
      ...(param || {})
    };
  }
}
class LogTypesModel implements Model<LogTypeState> {
  state: LogTypeState = {
    logTypes: []
  };

  @middleWare(MiddleWarePresets.takeLatest())
  async getLogTypes(): Promise<LogTypeState> {
    const result = await operationLogService.logTypes();
    return {
      ...this.state,
      logTypes: [
        { value: 'ALL', label: '全部' },
        ...result.map(i => ({
          value: i.logType,
          label: i.logTypeCnName
        }))
      ]
    };
  }
}
export const logTypesRef = weakSharing(() => LogTypesModel);
