import { useMemo, useCallback, useEffect } from 'react';

export default function useResizeObserver(
  ref: React.RefObject<Element>,
  fn: (size: ResizeObserverSize) => void
): void {
  const callback = useCallback((entries: ResizeObserverEntry[]) => {
    const entry = entries[0];
    if (!entry) {
      return;
    }
    const contentBoxSize = entry.contentBoxSize[0];
    if (!contentBoxSize) {
      return;
    }
    fn(contentBoxSize);
  }, []);

  const observer = useMemo(() => new ResizeObserver(callback), []);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);
}
