// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_r1VF4 {\n  display: flex;\n  flex-direction: column;\n  max-width: 480px;\n  margin-bottom: 12px;\n}\n.style_itemSelect_ULEgB {\n  width: 390px;\n  margin: 7px 0;\n}\n.style_wrapperBox_vDajk {\n  display: inline-flex;\n  flex-direction: column;\n  background: #f7f8fa;\n  border-radius: 4px;\n  padding: 8px 12px;\n  margin-top: 8px;\n}\n.style_baseWrapperBox_YlX4n .style_item_nPg4h {\n  margin-top: 12px;\n  background: #f7f8fa;\n}\n.style_checkBox_lleXT {\n  pointer-events: none;\n}\n.style_checkBox_lleXT > span {\n  pointer-events: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/sendAccountJD/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAEA;EACE,YAAA;EACA,aAAA;AAAF;AAGA;EACE,oBAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AADF;AAIA;EAEI,gBAAA;EACA,mBAAA;AAHJ;AAOA;EACE,oBAAA;AALF;AAOE;EACE,oBAAA;AALJ","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  max-width: 480px;\n  margin-bottom: 12px;\n}\n\n.itemSelect {\n  width: 390px;\n  margin: 7px 0;\n}\n\n.wrapperBox {\n  display: inline-flex;\n  flex-direction: column;\n  background: #f7f8fa;\n  border-radius: 4px;\n  padding: 8px 12px;\n  margin-top: 8px;\n}\n\n.baseWrapperBox {\n  .item {\n    margin-top: 12px;\n    background: #f7f8fa;\n  }\n}\n\n.checkBox {\n  pointer-events: none;\n\n  & > span {\n    pointer-events: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_r1VF4",
	"itemSelect": "style_itemSelect_ULEgB",
	"wrapperBox": "style_wrapperBox_vDajk",
	"baseWrapperBox": "style_baseWrapperBox_YlX4n",
	"item": "style_item_nPg4h",
	"checkBox": "style_checkBox_lleXT"
};
export default ___CSS_LOADER_EXPORT___;
