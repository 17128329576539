import React, {
  PropsWithChildren,
  ReactElement,
  cloneElement,
  memo
} from 'react';
import { Button } from '@/components/widgets/button';
import { SelectProps } from '@/components/widgets/select';
import { Icon } from '@/components/widgets/icon';
import css from './style.less';

export const CopyAndResetWrapper = memo(
  ({
    children,
    onReset,
    onCopy,
    ...rest
  }: PropsWithChildren<{ onReset?: () => void; onCopy?: () => void }>) => {
    const innerChildren = React.Children.map(children, child => {
      const innerChild = child as ReactElement<SelectProps>;

      const { ...innerChildProps } = innerChild.props;

      return cloneElement(innerChild, { ...innerChildProps, ...rest });
    });

    return (
      <>
        {innerChildren}
        <Button
          type="link"
          onClick={onCopy}
          icon={<Icon type="fuzhi2" className={css.icon} />}
          className={css.button}
        />
        <Button
          type="link"
          onClick={onReset}
          icon={<Icon type="qingchu-2" className={css.icon} />}
          className={css.button}
        />
      </>
    );
  }
);
