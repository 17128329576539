import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useState, useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSorter<T = any>(initSorter: SorterResult<T>) {
  const [sorter, setSorter] = useState<SorterResult<T>>(initSorter);

  const handleSorterChange: TableProps<T>['onChange'] = useCallback(
    (p, f, s) => {
      if (!Array.isArray(s)) {
        setSorter(s);
      }
    },
    []
  );

  const getSortOrder = useCallback(
    (key: keyof T) => {
      return sorter.field === key ? sorter.order : null;
    },
    [sorter]
  );

  const resetSorter = useCallback(() => {
    setSorter({ ...initSorter });
  }, [initSorter]);

  return {
    sorter,
    setSorter,
    handleSorterChange,
    getSortOrder,
    resetSorter
  };
}

const orderMaps: Record<'descend' | 'ascend', 'asc' | 'desc'> = {
  ascend: 'asc',
  descend: 'desc'
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDataSourceSorter<T>(
  dataSource: T[],
  initSorter: SorterResult<T>
) {
  const [sorter, setSorter] = useState<SorterResult<T>>(initSorter);

  const handleSorterChange: TableProps<T>['onChange'] = useCallback(
    (p, f, s) => {
      if (!Array.isArray(s)) {
        setSorter(s);
      }
    },
    []
  );

  const sortedDataSource = useMemo(() => {
    if (!sorter.order) {
      return [...dataSource];
    }
    return orderBy(dataSource, [sorter.field], orderMaps[sorter.order]);
  }, [sorter, dataSource]);

  return { dataSource: sortedDataSource as T[], handleSorterChange };
}
