import { presaleActivityRequest } from '@/requests/presaleActivity';
import {
  deletePresaleActivityByIdParams,
  FindPresaleActivityByIdParams,
  PresaleActivityModifiedCommand,
  PresaleActivityWebDto
} from '@/requests/presaleActivity/type';
import { RemindType } from '@/types/remindType';
import { remindTimesRequest } from '@/requests/remindTimesConfig';
import { RemindTimesConfigWebDto } from '@/requests/remindTimesConfig/type';

class PresaleActivityService {
  // 预售活动列表
  getPresaleActivityList(): Promise<PresaleActivityWebDto[]> {
    return presaleActivityRequest.getPresaleActivityList();
  }

  // 预售活动根据id查询
  findPresaleActivityById(
    params: FindPresaleActivityByIdParams
  ): Promise<PresaleActivityWebDto> {
    return presaleActivityRequest.findPresaleActivityById(params);
  }

  // 预售活动根据id删除
  deletePresaleActivityById(
    params: deletePresaleActivityByIdParams
  ): Promise<boolean> {
    return presaleActivityRequest.deletePresaleActivityById(params);
  }

  // 预售活动新增或者更新
  modifiedPresaleActivity(
    params: PresaleActivityModifiedCommand
  ): Promise<boolean> {
    return presaleActivityRequest.modifiedPresaleActivity(params);
  }

  // 用户催尾款任务配置列表查询接口
  timesConfigList(remindType: RemindType): Promise<RemindTimesConfigWebDto[]> {
    return remindTimesRequest.getRemindTimesConfigList(remindType);
  }
}

export const presaleActivityService = new PresaleActivityService();
