import { middleWare, MiddleWarePresets, OriginAgent } from 'agent-reducer';
import { groupBy } from '@/polyfill/array';
import { State } from './type';
import { Node } from '../type';
import { AreaWebDto } from '@/requests/shop/type';

export class AddressPopModel implements OriginAgent<State> {
  state: State = {
    cityGroup: {}
  };

  // 初始化数据
  @middleWare(MiddleWarePresets.takeLatest())
  async init(cityList: number[], area:AreaWebDto[] = []) {
    const nodeMap = new Map<number, Node>(
      area.map(area => [area.areaId, area])
    );
    const cities = cityList.map(id => nodeMap.get(id)!);
    const cityGroupDraft = groupBy<Node, number>(cities, c => c.parentAreaId);
    const cityGroup = [...cityGroupDraft].reduce(
      (pre, [provinceId, cities]) => {
        const provinceName = nodeMap.get(provinceId)!.areaName;
        const cityNames = cities.map(c => c!.areaName);
        return {
          ...pre,
          [provinceName]: cityNames
        };
      },
      {}
    );
    const provinceNum = cityGroupDraft.size;
    return {
      provinceNum,
      cityGroup
    };
  }
}
