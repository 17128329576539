import { avatar } from 'agent-reducer';
import { noop } from '@/polyfill/func';
import { ShortcutConfig } from '@/components/widgets/modal/type';

export const uiPrompt = avatar({
  success(msg: string): void {
    noop();
  },
  warning(msg: string): void {
    noop();
  },
  error(msg: unknown): void {
    noop();
  },
  modalError(msg: ShortcutConfig): Promise<boolean> {
    return Promise.resolve(true);
  },
  modalConfirm(msg: ShortcutConfig): Promise<boolean> {
    return Promise.resolve(true);
  },
  modalWarn(msg: ShortcutConfig): Promise<boolean> {
    return Promise.resolve(true);
  },
  expireModalConfirm(
    msg: ShortcutConfig & { subContent: string }
  ): Promise<boolean> {
    return Promise.resolve(true);
  }
});
