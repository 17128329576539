import React, { memo, useState } from 'react';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { ValidReplyDialogProps } from './type';
import { message } from '@/components/widgets/message';
import { warnError } from '@/components/infos';
import {
  UnitTimeProps,
  UnitTimeSelector
} from '@/components/works/unitTimeSelector';
import userConfigRequest from '@/requests/userConfig';
import useRequest from '@/hooks/useRequest';
import { UnitTime } from '@/types/unitTime';
import { Spin } from '@/components/widgets/spin';

const unitRange: Pick<UnitTimeProps, 'unitRange'>['unitRange'] = [
  'DAYS',
  'HOURS'
];
const timeRange: Pick<UnitTimeProps, 'timeRange'>['timeRange'] = {
  DAYS: [1, 2, 3],
  HOURS: [1, 2, 3, 6, 12]
};

export const ValidReplyDialog = memo(
  (props: DialogDeployProps<ValidReplyDialogProps>) => {
    const { closeDialog, params } = props;
    const { onOk } = params;
    const [state, setState] = useState<UnitTime>();

    const { loading: fetchLoading } = useRequest(
      () => userConfigRequest.validReplyConfig(),
      {
        onError: warnError,
        onSuccess: v => {
          setState(v);
        }
      }
    );

    const { run: updateValidReplyConfig, loading: updateLoading } = useRequest(
      (config: UnitTime) => userConfigRequest.updateValidReplyConfig(config),
      {
        manual: true,
        onError: warnError,
        onSuccess: v => {
          message.success('保存成功');
          onOk?.();
          closeDialog(true);
        }
      }
    );

    const handleOk = async () => {
      updateValidReplyConfig(state!);
    };

    const hanldeChange = v => {
      setState(v);
    };

    if (!state) {
      return null;
    }

    return (
      <Dialog
        title="有效回复统计时长"
        onCancel={closeDialog}
        bodyStyle={{ padding: '24px' }}
        onOk={handleOk}
      >
        <Spin spinning={fetchLoading || updateLoading}>
          <div>
            买家
            <UnitTimeSelector
              unitRange={unitRange}
              timeRange={timeRange}
              unitTime={state}
              onChange={hanldeChange}
            />
            内回复，算作有效回复
          </div>
        </Spin>
      </Dialog>
    );
  }
);
