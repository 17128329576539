import React, { memo, useState } from 'react';
import { contentTemplateDto } from '@/requests/contentTemplate/type';
import remindContentService from '@/services/remindContent';
import { RemindTaskContentDto } from '@/types/remindContentType';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import { Modal } from '@/components/widgets/modal';
import { Icon } from '@/components/widgets/icon';
import { SubsectionTextarea } from '@/components/works/components/subsectionTextarea';
import TemplateModal from '@/components/works/templateModal';
import { AddTextareaInfo } from '@/components/works/components/customTextarea';
import { Value } from '@/components/works/components/subsectionTextarea/type';
import { RemindType } from '@/types/remindType';
import { RemindContentsEditorProps, VariableSelectorProps } from './type';
import css from './style.less';
import { useLocation } from 'react-router';
import { parse } from 'typing-qs';
import { Parsers } from 'typing-qs/libs';
import { RemindTimesType } from '@/types/remindTimesType';
import { AutoComplete } from '@/components/widgets/autoComplate';
import { Tooltip } from '@/components/widgets/tooltip';
import { PlatformWrapper } from '@/components/works/platformWrapper';
import { getPlatform } from '@/services/platform';
import { usePlatform } from '@/hooks/usePlatform';

const { isJD } = getPlatform();

// 内容条数限制
const CONTENT_LIMIT = 10;
// 输入框 placeholder
const RemindContentsEditorPlaceholder: Partial<Record<RemindType, string>> = {
  REMIND_BUY: '请输入推送内容:\n催促、引导买家下单',
  REMIND_PAY: '请输入推送内容:\n催促、引导买家付款',
  PAYMENT_PUSH: '请输入推送内容:\n订单确认、发送说明书等',
  FRONT_PAID_PAYMENT_PUSH: '请输入推送内容:\n订单确认、发送说明书等',
  FINAL_PAID_PAYMENT_PUSH: '请输入推送内容:\n订单确认、发送说明书等',
  CONSIGN_PUSH: '请输入推送内容',
  LOGISTIC_STAGNATE_PUSH: '请输入推送内容',
  DELIVERY_PUSH: '请输入推送内容',
  SIGN_PUSH: '请输入推送内容:\n提醒已签收、提醒确认收货等',
  RECEIVED_PUSH: '请输入推送内容:\n催评价等',
  APPLY_REFUND_PUSH: '请输入推送内容:\n退款挽回、发送退款流程等',
  GUIDE_RETURN_GOODS: '请输入推送内容:\n退货引导等',
  URGE_RETURN_GOODS_PUSH: '请输入推送内容:\n提醒买家退货等',
  REFUND_SUCCESS_PUSH: '请输入推送内容:\n退款成功提醒等',
  APPLY_EXCHANGE_PUSH: '请输入推送内容',
  INVITE_RATE: '请输入推送内容',
  TRANSFER_WORD_PUSH: '请输入推送内容',
  TRADE_NOPAID_CLOSED_PUSH: '请输入推送内容:\n询问取消原因，发送优惠券等'
};
// 变量
const ExtraVariables: Partial<Record<RemindType, string[]>> = {
  REMIND_BUY: ['客服昵称'],
  REMIND_PAY: ['客服昵称', '催付订单链接'],
  REMIND_FINAL_PAY: ['客服昵称', '尾款支付链接'],
  PAYMENT_PUSH: ['客服昵称', '订单确认链接', '订单号'],
  FRONT_PAID_PAYMENT_PUSH: ['客服昵称', '订单确认链接', '订单号'],
  FINAL_PAID_PAYMENT_PUSH: ['客服昵称', '订单确认链接', '订单号'],
  CONSIGN_PUSH: ['客服昵称', '物流公司', '运单号', '物流详情', '订单号'],
  LOGISTIC_STAGNATE_PUSH: [
    '客服昵称',
    '物流公司',
    '运单号',
    '物流详情',
    '订单号'
  ],
  DELIVERY_PUSH: ['客服昵称', '派送订单链接', '运单号', '订单号'],
  SIGN_PUSH: ['客服昵称', '签收订单链接'],
  RECEIVED_PUSH: isJD ? ['客服昵称'] : ['客服昵称', '待评价订单链接'],
  APPLY_REFUND_PUSH: isJD ? ['客服昵称'] : ['客服昵称', '修改退款申请链接'],
  GUIDE_RETURN_GOODS: ['客服昵称', '订单详情链接'],
  URGE_RETURN_GOODS_PUSH: ['客服昵称', '订单详情链接'],
  REFUND_SUCCESS_PUSH: ['客服昵称', '订单详情链接'],
  APPLY_EXCHANGE_PUSH: ['客服昵称'],
  TRADE_NOPAID_CLOSED_PUSH: ['客服昵称'],
  REPLY_TIMEOUT_PUSH: ['客服昵称'],
  TRANSFER_WORD_PUSH: ['客服昵称'],
  INVITE_RATE: ['客服昵称']
};
// 添加按钮文案
const AddTextareaInfoName = {
  REMIND_BUY: '催拍',
  REMIND_PAY: '催付',
  PAYMENT_PUSH: '付款关怀',
  FRONT_PAID_PAYMENT_PUSH: '付定金关怀',
  FINAL_PAID_PAYMENT_PUSH: '付尾款关怀',
  CONSIGN_PUSH: '发货提醒',
  LOGISTIC_STAGNATE_PUSH: '物流停滞提醒',
  DELIVERY_PUSH: '派送提醒',
  SIGN_PUSH: '签收提醒',
  RECEIVED_PUSH: '确认收货关怀',
  APPLY_REFUND_PUSH: '退款关怀',
  GUIDE_RETURN_GOODS: '退款关怀',
  URGE_RETURN_GOODS_PUSH: '退款关怀',
  REFUND_SUCCESS_PUSH: '退款关怀',
  INVITE_RATE: '服务邀评',
  APPLY_EXCHANGE_PUSH: '推送内容',
  TRANSFER_WORD_PUSH: '转接话术',
  REPLY_TIMEOUT_PUSH: '等待超时回复'
};

// 不显示帮我想想
const disabledLetMeSee = [
  'FRONT_PAID_PAYMENT_PUSH',
  'FINAL_PAID_PAYMENT_PUSH',
  'GUIDE_RETURN_GOODS',
  'TRANSFER_WORD_PUSH',
  'TRADE_NOPAID_CLOSED_PUSH',
  'REPLY_TIMEOUT_PUSH'
];

export const VariableSelector = memo((props: VariableSelectorProps) => {
  const { options, onSelect } = props;
  const [value, setValue] = useState('');

  const handleSelect = v => {
    onSelect(v);
    setValue('');
  };

  return (
    <AutoComplete
      value={value}
      options={options}
      style={{ width: 160 }}
      onSelect={handleSelect}
      onChange={setValue}
      placeholder="插入变量"
    />
  );
});

/**
 * 推送内容
 */
export const RemindContentsEditor = memo((props: RemindContentsEditorProps) => {
  const {
    value = {
      remindType: '' as RemindType,
      remindContents: [
        {
          heraCheckStatus: 'WAIT_CHECK',
          contents: [],
          replyOrder: 'TEXT_BEFORE_IMAGE'
        }
      ]
    },
    onChange
  } = props;
  const { remindType, remindContents } = value;
  const { search } = useLocation();
  const { platform } = usePlatform();
  const showSubsection = { TB: true, JD: false }[platform];

  const { remindTimesType } = parse<{ remindTimesType?: RemindTimesType }>(
    search,
    {
      template: {
        remindTimesType: Parsers.string()
      }
    }
  );

  const limit =
    remindContents.length >= CONTENT_LIMIT ? CONTENT_LIMIT : undefined;

  const [openDialog] = useDialogMethods();

  const [templateContents, setTemplateContents] = useState<
    contentTemplateDto[]
  >([]);

  const openTemplateModal = async () => {
    const [result, templates] = (await openDialog(TemplateModal, {
      type: remindType,
      num: remindContents.filter(v => remindContentService.isValidContent(v))
        .length,
      remindTimesType:
        remindType === 'REMIND_FINAL_PAY' ? remindTimesType : null // 只有催尾款模版需要，其他跟单不需要
    })) as contentTemplateDto[][];

    setTemplateContents(templates);
    if (result) {
      onChange?.({
        ...value,
        remindContents: remindContents
          .concat(
            result.map(({ content }) => ({
              heraCheckStatus: 'CHECK_SUCCESS',
              replyOrder: 'TEXT_BEFORE_IMAGE',
              contents: [content]
            }))
          )
          .filter(v => remindContentService.isValidContent(v))
      });
    }
  };

  const handleContentChange = (index: number) => (v: Value) => {
    onChange?.({
      ...value,
      remindContents: remindContents.map((content, i) => {
        if (index === i) {
          return { ...content, ...v };
        }
        return content;
      })
    });
  };

  const handleTextareaDelete = (index: number) => async () => {
    if (remindContentService.isValidContent(remindContents[index])) {
      const ok = await Modal.confirm({
        title: '是否确认删除？',
        content: ''
      });
      if (!ok) {
        return;
      }
    }
    onChange?.({
      ...value,
      remindContents: remindContents.filter((_, i) => index !== i)
    });
  };

  const calcHeraCheckStatus = (remindContent: RemindTaskContentDto): string => {
    if (remindContent.heraCheckStatus) {
      return remindContent.heraCheckStatus;
    }

    const result = templateContents.some(
      item =>
        remindContent.contents?.length === 1 &&
        item.content === remindContent.contents[0]
    );

    return result ? 'CHECK_SUCCESS' : 'WAIT_CHECK';
  };

  const handleAddContent = () => {
    onChange?.({
      ...value,
      remindContents: remindContents.concat({
        heraCheckStatus: 'WAIT_CHECK',
        contents: [],
        replyOrder: 'TEXT_BEFORE_IMAGE'
      })
    });
  };

  return (
    <>
      {disabledLetMeSee.includes(remindType) ? null : (
        <PlatformWrapper
          component={{
            TB: (
              <span className={css.blueText} onClick={openTemplateModal}>
                <Icon className={css.icon} type="linggan" />
                <span>帮我想想</span>
              </span>
            )
          }}
        />
      )}
      <div>
        {remindContents.map((remindContent, index) => (
          <SubsectionTextarea
            showSubsection={showSubsection}
            key={`${index.toString()}`}
            index={index}
            value={remindContent}
            length={remindContents.length}
            onChange={handleContentChange(index)}
            onDelete={handleTextareaDelete(index)}
            placeholder={RemindContentsEditorPlaceholder[remindType]}
            // extraVariables={ExtraVariables[remindType]}
            type={calcHeraCheckStatus(remindContent)}
            checkFailReason={remindContent?.checkFailReason}
            renderVariableSelect={handleSelect => (
              <VariableSelector
                options={
                  ExtraVariables[remindType]?.map(v => ({
                    value: v,
                    label:
                      v === '客服昵称' ? (
                        <Tooltip title="假设子账号名称为“XX旗舰店:小A”，发送时变量会自动替换成“小A”；店铺主号直接替换为“XX旗舰店”">
                          {v}
                        </Tooltip>
                      ) : (
                        v
                      )
                  })) ?? []
                }
                onSelect={v => handleSelect(`{${v}}`)}
              />
            )}
          />
        ))}

        <AddTextareaInfo
          name={AddTextareaInfoName[remindType]}
          limit={limit}
          onChange={handleAddContent}
        />
      </div>
    </>
  );
});
