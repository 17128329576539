import { RefObject, useEffect, useRef } from 'react';

const usePopOuterClickClose = <T extends HTMLElement>(
  closeMethod: () => any
): RefObject<T> => {
  const popOuterClickCloseRef = useRef<T | null>(null);

  const closeMethodRef = useRef(closeMethod);

  closeMethodRef.current = closeMethod;

  useEffect(() => {
    const hide = (event: MouseEvent) => {
      const autoCloseDiv = popOuterClickCloseRef.current;
      if (!autoCloseDiv) {
        return;
      }
      if (
        event.target === autoCloseDiv ||
        autoCloseDiv.contains(event.target as Node)
      ) {
        return;
      }
      closeMethodRef.current();
    };
    document.addEventListener('click', hide);
    return () => {
      document.removeEventListener('click', hide);
    };
  }, []);

  return popOuterClickCloseRef;
};

export default usePopOuterClickClose;
