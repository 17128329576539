import { TradeStatusRuleWebDto } from '@/types/tradeStatus';
import { timeoutPrefixMap, timeoutUnitMap } from './constants';

export function joinTimeoutText(rule: TradeStatusRuleWebDto): string {
  const { tradeStatus, tradeStatusCondition: { filterTime } = {} } = rule;

  return `${timeoutPrefixMap[tradeStatus]}${filterTime?.value || 1}${
    timeoutUnitMap[filterTime?.unit || 'DAYS']
  }后`;
}

export function stopPropagation(e: React.MouseEvent): void {
  e.stopPropagation();
}
