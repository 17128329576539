import React, { memo } from 'react';
import moment from 'moment';
import { DatePattern } from '@/utils/date';
import { RangePicker } from '@/components/widgets/datePicker';
import { TimePicker } from '@/components/widgets/timePicker';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import {
  PointTimeRangePickerProps,
  FixedTimeRangePickerProps,
  ValidDateTimeSelectorProps,
  DateRange,
  CycleDateTimeRange
} from './type';
import css from './style.less';
import { RadioChangeEvent } from 'antd/lib/radio';

// 指定时间段
export const PointTimeRangePicker = memo((props: PointTimeRangePickerProps) => {
  const {
    value = {
      end: `${moment().format(DatePattern.DATE)} 23:59:59`,
      start: `${moment().format(DatePattern.DATE)} 00:00:00`
    },
    onChange
  } = props;

  const handleChange = (range: [string, string]) => {
    const [start, end] = range;
    onChange?.({ start, end });
  };

  return (
    <RangePicker
      value={[value.start, value.end]}
      onChange={handleChange}
      showTime
      allowClear={false}
    />
  );
});
// 每日固定时间段
export const FixedTimeRangePicker = memo((props: FixedTimeRangePickerProps) => {
  const {
    value = {
      dateRange: {
        end: moment().format(DatePattern.DATE),
        start: moment().format(DatePattern.DATE)
      },
      timeRange: {
        start: '00:00:00',
        end: '23:59:59'
      }
    },
    onChange
  } = props;

  const { dateRange, timeRange } = value;

  const handleRangeChange = (range: [string, string]) => {
    const [start, end] = range;
    onChange?.({ ...value, dateRange: { start, end } });
  };

  const handleStartTimeChange = (_, start: string) => {
    onChange?.({ ...value, timeRange: { ...timeRange, start } });
  };

  const handleEndTimeChange = (_, end: string) => {
    onChange?.({ ...value, timeRange: { ...timeRange, end } });
  };

  return (
    <>
      <RangePicker
        allowClear={false}
        value={[dateRange.start, dateRange.end]}
        onChange={handleRangeChange}
      />
      <span className={css.marginText}>每天</span>
      <TimePicker
        value={moment(timeRange.start, 'HH:mm:ss')}
        onChange={handleStartTimeChange}
        allowClear={false}
      />
      <span className={css.marginText}>至</span>
      <TimePicker
        value={moment(timeRange.end, 'HH:mm:ss')}
        onChange={handleEndTimeChange}
        allowClear={false}
      />
    </>
  );
});

/**
 * 生效时间
 */

const defaultRange = {
  cycleDateTimeRange: {
    dateRange: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    },
    timeRange: {
      start: '00:00:00',
      end: '23:59:59'
    }
  },
  fixedDateTimeRange: {
    start: `${moment().format('YYYY-MM-DD')} 00:00:00`,
    end: `${moment().format('YYYY-MM-DD')} 23:59:59`
  }
};
export const ValidDateTimeSelector = memo(
  (props: ValidDateTimeSelectorProps) => {
    const {
      value = {
        type: 'LONG_TIME_EFFECTIVE',
        range: defaultRange
      },
      onChange
    } = props;

    const { type, range } = value;
    const { cycleDateTimeRange, fixedDateTimeRange } = range || defaultRange;

    const validDateTypeChange = (e: RadioChangeEvent) => {
      onChange?.({ ...value, type: e.target.value });
    };

    const fixedDateTimeRangeChange = (v: DateRange) => {
      onChange?.({
        ...value,
        range: { ...range, fixedDateTimeRange: v }
      });
    };

    const cycleDateTimeRangeChange = (v: CycleDateTimeRange) => {
      onChange?.({
        ...value,
        range: { ...range, cycleDateTimeRange: v }
      });
    };

    return (
      <div>
        <RadioGroup value={type} onChange={validDateTypeChange}>
          <Radio value="LONG_TIME_EFFECTIVE">长期有效</Radio>
          <Radio value="POINTED_TIME_RANGE">指定时间段</Radio>
          <Radio value="FIXED_TIME">每日固定时间</Radio>
        </RadioGroup>
        {type === 'POINTED_TIME_RANGE' ? (
          <div className={css.item}>
            <PointTimeRangePicker
              value={fixedDateTimeRange}
              onChange={fixedDateTimeRangeChange}
            />
          </div>
        ) : null}
        {type === 'FIXED_TIME' ? (
          <div className={css.item}>
            <FixedTimeRangePicker
              value={cycleDateTimeRange}
              onChange={cycleDateTimeRangeChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
);
