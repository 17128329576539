import React from 'react';
import classNames from 'classnames';
import { Icon } from '@/components/widgets/icon';
import { Tooltip } from '@/components/widgets/tooltip';
import {
  ConfigGroupProps,
  ConfigColumnProps,
  ConfigItemProps,
  HelpTooltipProps
} from './type';
import css from './style.less';

export const ConfigGroup = (props: ConfigGroupProps): JSX.Element => {
  const { title, children } = props;

  return (
    <div className={css.configGroup}>
      {title ? <div className={css.title}>{title}</div> : null}
      {children}
    </div>
  );
};

export const ConfigColunm = (props: ConfigColumnProps): JSX.Element => {
  const { label, children, contentClassName, onEditClick } = props;

  return (
    <div className={css.configColumn}>
      <div className={css.label}>{label}</div>
      <div className={classNames(css.content, contentClassName)}>
        <div className={css.children}>{children}</div>
        {onEditClick ? (
          <Icon type="bianji" onClick={onEditClick} className={css.editIcon} />
        ) : null}
      </div>
    </div>
  );
};

export const ConfigItem = (props: ConfigItemProps): JSX.Element => {
  const {
    label,
    children,
    className,
    labelClassName,
    labelMaxWords = 4,
    flex = false
  } = props;

  return (
    <div
      className={classNames(
        css.configItem,
        { [css.flexItem]: flex },
        className
      )}
    >
      <span
        className={classNames(
          css.configItemLabel,
          { [css.flexShrinkZero]: flex },
          labelClassName
        )}
        style={{ width: `${labelMaxWords}em` }}
      >
        {label}
      </span>
      {children}
    </div>
  );
};

export const HelpTooltip = (props: HelpTooltipProps): JSX.Element => {
  const { title } = props;

  return (
    <Tooltip title={title}>
      <Icon type="bangzhushuoming" className={css.bangzhu} />
    </Tooltip>
  );
};
