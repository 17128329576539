// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_treeHeader_SaFBO {\n  height: 44px;\n  width: 100%;\n  padding: 0 12px;\n  font-size: 14px;\n  line-height: 40px;\n  background-color: #f0f2f5;\n}\n.style_treeContent_jqi2V {\n  height: calc(100vh - 260px);\n  max-height: 450px;\n  overflow-y: auto;\n  border: 1px solid #e0e0e0;\n  border-top: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/goodsSelectModal/components/categorySelector/style.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AACF;AAEA;EACE,2BAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;AAAF","sourcesContent":[".treeHeader {\n  height: 44px;\n  width: 100%;\n  padding: 0 12px;\n  font-size: 14px;\n  line-height: 40px;\n  background-color: #f0f2f5;\n}\n\n.treeContent {\n  height: calc(100vh - 260px);\n  max-height: 450px;\n  overflow-y: auto;\n  border: 1px solid #e0e0e0;\n  border-top: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"treeHeader": "style_treeHeader_SaFBO",
	"treeContent": "style_treeContent_jqi2V"
};
export default ___CSS_LOADER_EXPORT___;
