// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_content_AkIk8 {\n  padding: 16px 12px 0 12px !important;\n}\n.style_flexCenter_4otCy {\n  display: flex;\n  align-items: center;\n}\n.style_configItem_nTNuY {\n  display: flex;\n  align-items: center;\n  margin-bottom: 16px !important;\n}\n.style_editIcon_I2yVC {\n  color: #3d7eff;\n  cursor: pointer;\n  font-size: 18px;\n  margin-left: 16px;\n}\n.style_errorMessage_yl29U {\n  position: absolute;\n  font-size: 12px;\n  color: red;\n}\n.style_errorInput_W0\\+Ws {\n  border-color: red;\n}\n.style_errorInput_W0\\+Ws:hover,\n.style_errorInput_W0\\+Ws:focus {\n  border-color: red;\n  box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/generalSetting/shopNick/style.less"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;AAAF;AAGA;EAJE,aAAA;EACA,mBAAA;EAMA,8BAAA;AADF;AAIA;EACE,cAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AAFF;AAKA;EACE,kBAAA;EACA,eAAA;EACA,UAAA;AAHF;AAMA;EACE,iBAAA;AAJF;AAME;;EAEE,iBAAA;EACA,4CAAA;AAJJ","sourcesContent":[".content {\n  padding: 16px 12px 0 12px !important;\n}\n\n.flexCenter {\n  display: flex;\n  align-items: center;\n}\n\n.configItem {\n  .flexCenter();\n\n  margin-bottom: 16px !important;\n}\n\n.editIcon {\n  color: #3d7eff;\n  cursor: pointer;\n  font-size: 18px;\n  margin-left: 16px;\n}\n\n.errorMessage {\n  position: absolute;\n  font-size: 12px;\n  color: red;\n}\n\n.errorInput {\n  border-color: red;\n\n  &:hover,\n  &:focus {\n    border-color: red;\n    box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "style_content_AkIk8",
	"flexCenter": "style_flexCenter_4otCy",
	"configItem": "style_configItem_nTNuY",
	"editIcon": "style_editIcon_I2yVC",
	"errorMessage": "style_errorMessage_yl29U",
	"errorInput": "style_errorInput_W0+Ws"
};
export default ___CSS_LOADER_EXPORT___;
