// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_btnGroup_bfAde {\n  margin: 14px 24px;\n}\n.style_btn_aSsuU {\n  margin-right: 12px;\n}\n.style_table_SJrZg .ant-table-selection-column {\n  border-right: none !important;\n}\n.style_table_SJrZg .ant-table {\n  max-height: 400px;\n  overflow-y: auto;\n  padding: 0 24px;\n}\n.style_tip_yKJsi {\n  margin-top: 12px;\n  margin-left: 24px;\n  color: #666;\n}\n.style_operationBtn_4CpdI {\n  color: #3d7eff;\n  cursor: pointer;\n}\n.style_operationBtn_4CpdI + .style_operationBtn_4CpdI {\n  margin-left: 32px;\n}\n.style_badge_YrgBV .ant-badge-status-dot {\n  height: 8px;\n  width: 8px;\n}\n.style_pagination_wKztS {\n  margin-top: 0 !important;\n  padding-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/exportCenter/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,kBAAA;AAAF;AAGA;EAGM,6BAAA;AAHN;AAAA;EAOM,iBAAA;EACA,gBAAA;EACA,eAAA;AAJN;AASA;EACE,gBAAA;EACA,iBAAA;EACA,WAAA;AAPF;AAUA;EACE,cAAA;EACA,eAAA;AARF;AAUE;EACE,iBAAA;AARJ;AAYA;EAGM,WAAA;EACA,UAAA;AAZN;AAiBA;EACE,wBAAA;EACA,oBAAA;AAfF","sourcesContent":[".btnGroup {\n  margin: 14px 24px;\n}\n\n.btn {\n  margin-right: 12px;\n}\n\n.table {\n  :global {\n    .ant-table-selection-column {\n      border-right: none !important;\n    }\n\n    .ant-table {\n      max-height: 400px;\n      overflow-y: auto;\n      padding: 0 24px;\n    }\n  }\n}\n\n.tip {\n  margin-top: 12px;\n  margin-left: 24px;\n  color: #666;\n}\n\n.operationBtn {\n  color: #3d7eff;\n  cursor: pointer;\n\n  & + & {\n    margin-left: 32px;\n  }\n}\n\n.badge {\n  :global {\n    .ant-badge-status-dot {\n      height: 8px;\n      width: 8px;\n    }\n  }\n}\n\n.pagination {\n  margin-top: 0 !important;\n  padding-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnGroup": "style_btnGroup_bfAde",
	"btn": "style_btn_aSsuU",
	"table": "style_table_SJrZg",
	"tip": "style_tip_yKJsi",
	"operationBtn": "style_operationBtn_4CpdI",
	"badge": "style_badge_YrgBV",
	"pagination": "style_pagination_wKztS"
};
export default ___CSS_LOADER_EXPORT___;
