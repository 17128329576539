import React, { memo, useCallback } from 'react';
import { InputNumber } from 'antd';
import { RangeInputNumberProps } from './type';
import css from './style.less';

export const RangeInputNumber = memo((props: RangeInputNumberProps) => {
  const {
    value,
    className,
    placeholder,
    onChange,
    limit,
    step,
    maxLength = 10,
    decimal = true
  } = props;
  const [start = '', end = ''] = value || [];
  const handleStartChange = useCallback(
    v => {
      const startValue = limit && Number(v) > limit ? limit : v;
      onChange?.([startValue, end]);
    },
    [end]
  );
  const handleEndChange = useCallback(
    v => {
      const endValue = limit && Number(v) > limit ? limit : v;
      onChange?.([start, endValue]);
    },
    [start]
  );
  const handleStartBlur = () => {
    let startValue = '';

    if (start) {
      if (decimal) {
        startValue = Number(start).toFixed(2);
      } else {
        startValue = String(start);
      }
    }

    onChange?.([startValue, end]);
  };
  const handleEndBlur = () => {
    let endValue = '';

    if (end) {
      if (decimal) {
        endValue = Number(end).toFixed(2);
      } else {
        endValue = String(end);
      }
    }

    onChange?.([start, endValue]);
  };
  return (
    <>
      <InputNumber
        className={className}
        value={start}
        stringMode
        step={step}
        placeholder={placeholder}
        onChange={handleStartChange}
        onBlur={handleStartBlur}
        maxLength={maxLength}
      />
      <span className={css.info}>~</span>
      <InputNumber
        className={className}
        value={end}
        stringMode
        step={step}
        placeholder={placeholder}
        onChange={handleEndChange}
        onBlur={handleEndBlur}
        maxLength={maxLength}
      />
    </>
  );
});
