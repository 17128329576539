// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_setpWrapper_2KzhP {\n  margin-bottom: 24px;\n}\n.style_setpWrapper_2KzhP .style_content_s6N6u {\n  margin: 16px 0 0 52px;\n}\n.style_setpWrapper_2KzhP .style_content_s6N6u .style_link_3sLAP {\n  cursor: pointer;\n  color: #3d7eff;\n  margin-bottom: 16px;\n  display: inline-flex;\n}\n.style_setpWrapper_2KzhP .style_content_s6N6u .style_uploadFileButton_TsotE {\n  display: flex;\n  align-items: center;\n}\n.style_setpWrapper_2KzhP .style_content_s6N6u .style_btn_swvNi {\n  margin: 72px 24px 0 0 !important;\n}\n.style_setpWrapper_2KzhP .style_content_s6N6u .style_errorMsg_a5KhO {\n  margin-top: 12px;\n}\n.style_setpWrapper_2KzhP .style_content_s6N6u .style_errorMsg_a5KhO .style_errorMsgContent_ta5JV {\n  overflow: scroll;\n  max-height: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/staffManage/batchAddStaffDrawer/style.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAFA;EAMI,qBAAA;AADJ;AALA;EASM,eAAA;EACA,cAAA;EACA,mBAAA;EACA,oBAAA;AADN;AAXA;EAgBM,aAAA;EACA,mBAAA;AAFN;AAfA;EAqBM,gCAAA;AAHN;AAlBA;EAyBM,gBAAA;AAJN;AArBA;EA4BQ,gBAAA;EACA,iBAAA;AAJR","sourcesContent":[".setpWrapper {\n  margin-bottom: 24px;\n  .title {\n  }\n\n  .content {\n    margin: 16px 0 0 52px;\n\n    .link {\n      cursor: pointer;\n      color: #3d7eff;\n      margin-bottom: 16px;\n      display: inline-flex;\n    }\n\n    .uploadFileButton {\n      display: flex;\n      align-items: center;\n    }\n\n    .btn {\n      margin: 72px 24px 0 0 !important;\n    }\n\n    .errorMsg {\n      margin-top: 12px;\n\n      .errorMsgContent {\n        overflow: scroll;\n        max-height: 200px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setpWrapper": "style_setpWrapper_2KzhP",
	"content": "style_content_s6N6u",
	"link": "style_link_3sLAP",
	"uploadFileButton": "style_uploadFileButton_TsotE",
	"btn": "style_btn_swvNi",
	"errorMsg": "style_errorMsg_a5KhO",
	"errorMsgContent": "style_errorMsgContent_ta5JV"
};
export default ___CSS_LOADER_EXPORT___;
