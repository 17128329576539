/**
 * @description: 随机密码
 * @param {number} length 密码位数
 * @returns {string}
 */
export const randomPassword = (length = 8): string => {
  const lowerCaseArr = 'abcdefghijklmnopqrstuvwxyz';
  const blockLetterArr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberArr = '0123456789';
  let password = '';

  const randomMode = Math.floor(Math.random() * 2).toString();

  const specifyRandom = (...arg) => {
    arg.forEach(item => {
      for (let i = 0; i < length / 2; i++) {
        const randomNumber = Math.floor(Math.random() * item.length);
        password += item.substring(randomNumber, randomNumber + 1);
      }
    });
  };

  switch (randomMode) {
    case '0':
      specifyRandom(lowerCaseArr, numberArr);
      break;
    case '1':
      specifyRandom(blockLetterArr, numberArr);
      break;
    default:
      specifyRandom(lowerCaseArr, numberArr);
  }

  const shuffledPassword = password
    .split('')
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  return shuffledPassword.join('');
};
