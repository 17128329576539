import { OriginAgent, weakSharing } from 'agent-reducer';
import { MenuItemProps } from './type';

class MenuSelection implements OriginAgent<MenuItemProps | null> {
  state: MenuItemProps | null;

  constructor() {
    this.state = null;
  }

  select(title: string, url: string) {
    return { title, url };
  }
}

export const menuSelectionRef = weakSharing(() => MenuSelection);
