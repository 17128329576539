import React, { memo } from 'react';
import { useAgentReducer } from 'use-agent-reducer';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { FormLike, IOS, FormItem } from '@/components/widgets/formLike';
import ConditionUnit from '@/components/layouts/conditionUnit';
import { InputNumber } from '@/components/widgets/input';
import { Select } from '@/components/widgets/select';
import { Switch } from '@/components/widgets/switch';
import { Button } from '@/components/widgets/button';
import { warnError } from '@/components/infos';
import { message } from '@/components/widgets/message';
import { Params } from './type';
import { FatigueDialogModel } from './model';
import css from './style.less';

export default memo((props: DialogDeployProps<Params>) => {
  const { closeDialog, params } = props;

  const {
    state: { saveLoading, fatigueConfig, remindTypes },
    load,
    unload,
    confirm,
    changeFatigueConfig
  } = useAgentReducer(new FatigueDialogModel(params));

  const handleOk = async () => {
    const res = confirm();
    if (!res) {
      return;
    }
    load();
    try {
      await params.onOk(res);
      message.success('保存成功');
    } catch (error) {
      warnError(error);
    } finally {
      unload();
    }

    closeDialog();
  };

  return (
    <Dialog
      title="疲劳度控制"
      onCancel={closeDialog}
      width={500}
      bodyStyle={{ padding: '24px' }}
      footer={[
        <Button
          key="ok"
          onClick={handleOk}
          type="primary"
          loading={saveLoading}
        >
          确定
        </Button>,
        <Button key="cancel" onClick={() => closeDialog()}>
          取消
        </Button>
      ]}
    >
      <div>
        <FormLike value={fatigueConfig} onChange={changeFatigueConfig}>
          <ConditionUnit
            className={css.unit}
            label="开关："
            name="active"
            io={IOS.switchIo}
          >
            <Switch checkedChildren="已开启" unCheckedChildren="已关闭" />
          </ConditionUnit>
          <div className={css.unit}>
            <span className={css.label}>规则：</span>
            同一买家
            <FormItem name="timeLimit.value">
              <InputNumber className={css.input} />
            </FormItem>
            天内，最多推送
            <FormItem name="maxTimes">
              <InputNumber className={css.input} />
            </FormItem>
            次
          </div>
          <ConditionUnit
            className={css.unit}
            label="例外功能："
            name="excludeTypes"
          >
            <Select
              className={css.select}
              mode="multiple"
              options={remindTypes.map(val => ({
                label: val.name,
                value: val.code
              }))}
            />
          </ConditionUnit>
        </FormLike>
      </div>
      <div className={css.tip}>发送次数到达上限后，例外功能可继续发送</div>
    </Dialog>
  );
});
