import React, { memo, useEffect, useState } from 'react';
import {
  formatDayFromNow,
  checkRange,
  formatNow,
  buildQuickDateRangeFromToday
} from '@/utils/date';
import { FormLike } from '@/components/widgets/formLike';
import ConditionUnit from '@/components/layouts/conditionUnit';
import commonCss from '@/css/common.less';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import { warnError } from '@/components/infos';
import moment from 'moment';
import { Select, Option } from '@/components/widgets/select';
import { AccountSelect } from '../../accountSelector';
import { Button } from '@/components/widgets/button';
import { Input } from '@/components/widgets/input';
import shopRequest from '@/requests/shop';
import { RemindType } from '@/types/remindType';
import { conditionsMap } from './conditionsMap';
import useRequest from '@/hooks/useRequest';
import { remindTimesRequest } from '@/requests/remindTimesConfig';
import { getRemindTimesTypeOptions } from '../../remindTimeConfig/service';
import { PlatformWrapper } from '../../platformWrapper';
import { isNil } from 'lodash';

type Range = [string, string];
type RemindTimesType = 'FIRST_REMIND' | 'SECOND_REMIND' | 'THIRD_REMIND';

type DetailSearchProps = {
  remindType: RemindType;
  onChange?: (value: { range: Range }) => Promise<void>;
  onSearch?: (value: { range: Range }) => Promise<void>;
};

export type SearchConditions = {
  remindTimesType: RemindTimesType | null;
  subStrategyId: number | null;
  subAccountId: number | null;
  buyerReplied: boolean | null;
  taobaoTid: string | null;
  range: Range;
  refundClosed: boolean | null;
};

const range: Range = [formatDayFromNow(-6), formatDayFromNow(0)];

const initConfig: SearchConditions = {
  remindTimesType: null,
  subStrategyId: null,
  subAccountId: null,
  buyerReplied: null,
  taobaoTid: null,
  refundClosed: null,
  range
};

export const SimpleBoolSelector = (props: {
  value?: boolean | null;
  labels?: string[];
  onChange?: (v: boolean | null) => void;
}) => {
  const { value, labels = [], onChange } = props;

  // eslint-disable-next-line no-nested-ternary
  const valueAsNum = isNil(value) ? null : value ? 1 : 0;
  const handleChange = (v: number | null) => {
    onChange?.(v === null ? null : !!v);
  };

  return (
    <Select
      style={{ width: 120 }}
      options={[
        { label: '全部', value: null },
        { label: labels[0] || '是', value: 1 },
        { label: labels[1] || '否', value: 0 }
      ]}
      value={valueAsNum}
      onChange={handleChange}
    />
  );
};

const DetailSearch = memo((props: DetailSearchProps) => {
  const { onChange, remindType, onSearch } = props;
  const [config, setConfig] = useState({ ...initConfig });
  const [strategyNameList, setStrategyNameList] = useState<any[]>([]);

  // 显示的搜索项
  const conditions = conditionsMap[remindType];

  const { data = [] } = useRequest(
    async () => {
      const res = await remindTimesRequest.enableRemindTimesTypes(remindType);
      const options = getRemindTimesTypeOptions(res, remindType);

      return [{ label: '全部', value: null }, ...options];
    },
    { onError: warnError }
  );

  useEffect(() => {
    setConfig({ ...config });
  }, [remindType]);

  useEffect(() => {
    setConfig({ ...config, subStrategyId: null });

    shopRequest
      .getStrategyNameList(remindType, config.remindTimesType)
      .then(res => {
        setStrategyNameList(res);
      })
      .catch(err => {
        warnError(err);
        setStrategyNameList([]);
      });
  }, [config.remindTimesType, remindType]);

  const handleSubmit = async (value?: { range: Range }) => {
    const newConfig = { ...config, ...value };

    const {
      range: [start, end]
    } = newConfig;

    const newStart = moment(start).isBefore(moment(end)) ? start : end;
    const newEnd = moment(end).isAfter(moment(start)) ? end : start;

    if (!checkRange([newStart, formatNow()], 180)) {
      warnError('仅可查看最近180天的发送明细');
      return;
    }

    if (!checkRange([newStart, newEnd], 31)) {
      warnError('一次最多选择31天');
      return;
    }

    setConfig(newConfig);

    await onChange?.(newConfig);
  };

  const handleChange = value => {
    setConfig(value);
    // handleSubmit(value);
  };

  const handleBlur = (v: React.FocusEvent<HTMLInputElement>) => {
    if (!v) {
      return;
    }

    const newTaobaoTid =
      typeof v?.target?.value === 'string'
        ? v?.target?.value.replace(/\D+/g, '')
        : null;

    setConfig({ ...config, taobaoTid: newTaobaoTid });
  };

  const handleSearch = () => {
    const {
      range: [start, end]
    } = config;

    if (!checkRange([start, end], 31)) {
      warnError('一次最多选择31天');
      return;
    }
    onSearch?.(config);
  };

  const handleReset = () => {
    setConfig({ ...initConfig });
    onSearch?.(initConfig);
  };

  return (
    <>
      <FormLike value={config} onChange={handleChange} onSubmit={handleSubmit}>
        <div className={commonCss.search}>
          <ConditionUnit name="range" className={commonCss.item} label="">
            <CustomRangePicker options={buildQuickDateRangeFromToday()} />
          </ConditionUnit>
          {conditions?.includes('sendNum') ? (
            <ConditionUnit
              name="remindTimesType"
              label="发送次数"
              className={commonCss.item}
            >
              <Select style={{ width: '180px' }} options={data} />
            </ConditionUnit>
          ) : null}
          {conditions?.includes('taskName') ? (
            <ConditionUnit
              name="subStrategyId"
              label="任务名称"
              className={commonCss.item}
            >
              <Select
                style={{ width: '260px' }}
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {strategyNameList.map(v => (
                  <Option key={v.id} value={v.id}>
                    {v.strategyName}
                  </Option>
                ))}
              </Select>
            </ConditionUnit>
          ) : null}
          {conditions?.includes('account') ? (
            <ConditionUnit
              name="subAccountId"
              label="客服"
              className={commonCss.item}
            >
              <AccountSelect
                singleSelect
                style={{ width: 180, margin: 0 }}
                allowClear
                showAll
              />
            </ConditionUnit>
          ) : null}
          {/* <PlatformWrapper
            component={{
              JD: (
                <ConditionUnit
                  name="recordStatus"
                  label="发送结果"
                  className={commonCss.item}
                >
                  <Select style={{ width: 180 }}>
                    <Option value={null}>全部</Option>
                    <Option value="SUCCESS">成功</Option>
                    <Option value="FAILED">失败</Option>
                  </Select>
                </ConditionUnit>
              )
            }}
          /> */}
          {conditions?.includes('buyerReplied') ? (
            <ConditionUnit
              name="buyerReplied"
              label="买家是否回复"
              className={commonCss.item}
            >
              <SimpleBoolSelector />
            </ConditionUnit>
          ) : null}
          {conditions?.includes('order') ? (
            <ConditionUnit
              name="taobaoTid"
              label="订单号"
              className={commonCss.item}
            >
              <Input style={{ width: 180 }} onBlur={handleBlur} />
            </ConditionUnit>
          ) : null}
          {conditions?.includes('refundClosed') ? (
            <ConditionUnit
              name="refundClosed"
              label="是否成功挽回"
              className={commonCss.item}
            >
              <SimpleBoolSelector />
            </ConditionUnit>
          ) : null}
          <div className={commonCss.item}>
            <Button type="primary" onClick={handleSearch}>
              搜索
            </Button>
            <Button onClick={handleReset}>重置</Button>
          </div>
        </div>
      </FormLike>
    </>
  );
});

export default DetailSearch;
