import React, { memo } from 'react';
import { useLoading } from '@/hooks/useLoading';
import Loading from '@/components/widgets/loading/Loading';
import css from './index.less';
import DelayIcon from '../../components/delayIcon';
import { DelayData } from '@/types/delayData';

type Statistics = {
  key: string;
  name: React.ReactNode;
  percent?: boolean;
};

type StatisticProps = {
  statisticItems: Array<Statistics>;
  statistics: Record<string, DelayData<number> | number | string>;
  loading?: boolean;
};

const Statistic = memo((props: StatisticProps) => {
  const { statistics, statisticItems, loading = false } = props;

  const renderStatisticItem = ({ key, name, percent }: Statistics) => {
    const {
      data,
      finalDate = '',
      delay = false
    } = typeof statistics[key] === 'object'
      ? (statistics[key] as DelayData<number>)
      : { data: statistics[key] };

    return (
      <div className={css.statisticItem} key={key}>
        <div className={css.statisticName}>
          {name}
          {delay && <DelayIcon finalDate={finalDate} />}
        </div>
        <div className={css.statisticTotal}>
          {percent ? `${(Number(data) * 100).toFixed(2)}%` : data}
        </div>
      </div>
    );
  };

  return (
    <div className={css.statistic}>
      <Loading loading={loading}>
        {statisticItems.map(renderStatisticItem)}
      </Loading>
    </div>
  );
});

export default Statistic;
