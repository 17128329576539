// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_select_alvFi {\n  position: relative;\n}\n.style_count_q405X {\n  position: absolute;\n  right: 4px;\n  bottom: 0;\n  padding: 6px;\n  color: #999999;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/select/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;AAAF","sourcesContent":[".select {\n  position: relative;\n}\n\n.count {\n  position: absolute;\n  right: 4px;\n  bottom: 0;\n  padding: 6px;\n  color: #999999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "style_select_alvFi",
	"count": "style_count_q405X"
};
export default ___CSS_LOADER_EXPORT___;
