import React, { memo } from 'react';
import { Checkbox } from '@/components/widgets/checkbox';
import { RadioGroup } from '@/components/widgets/radio';
import CusTeamSelector from '../cusTeamSelector';
import { TooltipWithIcon } from '../tooltipWithIcon';
import {
  BaseAccountSelectorProps,
  HumanAccountSelectorProps,
  SendAccountSelectorProps,
  SilenceAccountSelectorProps,
  HumanSendAccountSelectorProps
} from './type';
import css from './style.less';
import { AccountSelect } from '../accountSelector';
import { RemindType } from '@/types/remindType';

const humanAccountTypesOptions = [
  { label: '最后服务客服', value: 'LATEST' },
  { label: '指定客服', value: 'POINTED' },
  { label: '指定客服组', value: 'WANGWANG_GROUP' }
];

const silenceAccountTypesOptions = [
  { label: '指定客服', value: 'POINTED' },
  { label: '指定客服组', value: 'WANGWANG_GROUP' }
];

const BaseAccountSelector = memo((props: BaseAccountSelectorProps) => {
  const {
    accountTypesOptions,
    sendAccountTypes,
    subAccountIds = [],
    wangwangGroupIds = [],
    selectorStyle,
    onChange
  } = props;

  const accountCheckedTypesChange = v => {
    onChange?.({
      sendAccountTypes: [v.target.value],
      subAccountIds,
      wangwangGroupIds
    });
  };

  const accountValueChange = v => {
    onChange?.({
      sendAccountTypes,
      subAccountIds: v,
      wangwangGroupIds
    });
  };

  const cusTeamValueChange = v => {
    onChange?.({
      sendAccountTypes,
      subAccountIds,
      wangwangGroupIds: v
    });
  };

  return (
    <div className={css.baseWrapperBox}>
      <RadioGroup
        value={sendAccountTypes[0]}
        options={accountTypesOptions}
        onChange={accountCheckedTypesChange}
      />
      {sendAccountTypes.includes('POINTED') ? (
        <div className={css.item} style={selectorStyle}>
          <div>指定客服</div>
          <AccountSelect value={subAccountIds} onChange={accountValueChange} />
        </div>
      ) : null}
      {sendAccountTypes.includes('WANGWANG_GROUP') ? (
        <div className={css.item} style={selectorStyle}>
          <div>指定客服组</div>
          <CusTeamSelector
            value={wangwangGroupIds}
            onChange={cusTeamValueChange}
          />
        </div>
      ) : null}
    </div>
  );
});

const HumanAccountSelector = memo((props: HumanAccountSelectorProps) => {
  const { enabled, onChange, ...rest } = props;

  const humanAccountCheckedChange = v => {
    onChange({
      enabled: v.target.checked,
      ...rest
    });
  };

  const handleChange = v => {
    onChange({ enabled, ...v });
  };

  return (
    <div className={css.wrapper}>
      <Checkbox
        checked={enabled}
        onChange={humanAccountCheckedChange}
        className={css.checkBox}
      >
        对人工接待的买家发送
      </Checkbox>
      {enabled ? (
        <div className={css.wrapperBox}>
          <span style={{ marginBottom: '8px' }}>人工接待的买家</span>
          <BaseAccountSelector
            accountTypesOptions={humanAccountTypesOptions}
            {...rest}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </div>
  );
});

const SilenceAccountSelector = memo((props: SilenceAccountSelectorProps) => {
  const { enabled, onChange, ...rest } = props;

  const silenceAccountCheckedChange = v => {
    onChange({
      enabled: v.target.checked,
      ...rest
    });
  };

  const handleChange = v => {
    onChange({ enabled, ...v });
  };

  return (
    <div className={css.wrapper}>
      <Checkbox
        checked={enabled}
        onChange={silenceAccountCheckedChange}
        className={css.checkBox}
      >
        对智能客服助手接待或静默的买家发送
      </Checkbox>
      {enabled ? (
        <div className={css.wrapperBox}>
          <span style={{ marginBottom: '8px', borderRadius: '4px' }}>
            智能客服助手接待或静默的买家
          </span>
          <BaseAccountSelector
            accountTypesOptions={silenceAccountTypesOptions}
            {...rest}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </div>
  );
});

export const SendAccountSelectorJD = memo((props: SendAccountSelectorProps) => {
  const {
    value = {
      humanSessionSendAccountConfig: {
        enabled: true,
        sendAccountTypes: ['LATEST'],
        subAccountIds: [],
        wangwangGroupIds: []
      },
      silenceSendAccountConfig: {
        enabled: true,
        sendAccountTypes: ['RANDOM'],
        subAccountIds: [],
        wangwangGroupIds: []
      }
    },
    onChange
  } = props;

  const { humanSessionSendAccountConfig, silenceSendAccountConfig } = value;

  const handleHumanSelectorChange = config => {
    onChange?.({ ...value, humanSessionSendAccountConfig: config });
  };

  const handleSilenceSelectorChange = config => {
    onChange?.({ ...value, silenceSendAccountConfig: config });
  };
  return (
    <div>
      <HumanAccountSelector
        {...humanSessionSendAccountConfig}
        onChange={handleHumanSelectorChange}
      />
      <SilenceAccountSelector
        {...silenceSendAccountConfig}
        onChange={handleSilenceSelectorChange}
      />
    </div>
  );
});

export const HumanSendAccountSelectorJD = memo(
  (props: HumanSendAccountSelectorProps) => {
    const {
      value = {
        humanSessionSendAccountConfig: {
          enabled: true,
          sendAccountTypes: ['LATEST'],
          subAccountIds: [],
          wangwangGroupIds: []
        }
      },
      onChange
    } = props;
    const { humanSessionSendAccountConfig } = value;

    const handleHumanSelectorChange = config => {
      onChange?.({
        ...value,
        humanSessionSendAccountConfig: { ...config, enabled: true }
      });
    };

    return (
      <BaseAccountSelector
        accountTypesOptions={humanAccountTypesOptions}
        {...humanSessionSendAccountConfig}
        onChange={handleHumanSelectorChange}
        selectorStyle={{ padding: '8px' }}
      />
    );
  }
);

export const SendAccountSelectorLabelJD = memo(
  ({ remindType }: { remindType?: RemindType }) => {
    return (
      <div>
        发送客服
        <TooltipWithIcon
          label=""
          tooltip={
            <ol style={{ paddingLeft: 20, margin: 0 }}>
              {remindType === 'REMIND_BUY' ? (
                <li>智能客服助手接待的买家暂不催拍</li>
              ) : null}
            </ol>
          }
        />
      </div>
    );
  }
);
