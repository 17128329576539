import React, { memo, ReactNode, useEffect, useMemo } from 'react';
import css from './style.less';
import { warnError } from '@/components/infos';
import { Tooltip } from '@/components/widgets/tooltip';
import { AddressPopModel } from './model';
import { useAgentReducer, useAgentSelector } from 'use-agent-reducer';
import { addressRef } from '@/modules/address';
import { TooltipPlacement } from 'antd/es/tooltip';

export const AddressPop = memo(
  ({
    cityList = [],
    children,
    className,
    placement = 'left',
  }: {
    cityList: number[];
    children: ReactNode;
    className?: string;
    placement?: TooltipPlacement;
  }) => {
    const {
      state: { cityGroup },
      init
    } = useAgentReducer(AddressPopModel);
    const area = useAgentSelector(addressRef.current ,s => s.area);

    useEffect(() => {
      (async () => {
        try {
          await init(cityList, area);
        } catch (e) {
          warnError(e);
        }
      })();
    }, [cityList]);

    const ctnRender = useMemo(
      () => (
        <div className={css.wrapper}>
          {Object.keys(cityGroup).map(province => (
            <div key={province} className={css.provinceCtn}>
              <div className={css.province}>{province}</div>
              <div className={css.cityList}>
                {(cityGroup[province] || []).map(city => (
                  <div className={css.city} key={city}>
                    {city}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ),
      [cityGroup]
    );

    return (
      <Tooltip
        placement={placement}
        title={ctnRender}
        overlayClassName={css.tooltip}
      >
        <div className={className}>
          {children}
        </div>
      </Tooltip>
    );
  }
);
