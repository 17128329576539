import React, { memo } from 'react';
import classNames from 'classnames';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Select, Option } from '@/components/widgets/select';
import { InputNumber } from '@/components/widgets/input';
import { RangeInputNumber } from '@/components/widgets/rangeInputNumber';
import { ValidAmountOperator } from '@/requests/remindStrategy/types';
import { ValidPaymentProps, ValidPaymentSelectorProps } from './type';
import css from './style.less';

// 指定金额
export const ValidPayment = memo((props: ValidPaymentProps) => {
  const {
    value = {
      min: 100,
      max: 100,
      operator: 'MORE_THEN_EQUAL'
    },
    onChange
  } = props;

  const { min, max, operator } = value || {};

  const handleOperatorChange = (val: ValidAmountOperator) => {
    if (val !== 'BETWEEN' && operator === 'BETWEEN') {
      onChange?.({ operator: val, min: 100, max: 100 });
      return;
    }
    onChange?.({
      operator: val,
      min: val === 'BETWEEN' ? 100 : min,
      max: val === 'BETWEEN' ? 200 : max
    });
  };

  const handlePaymentNumChange = (val?: number) => {
    onChange?.({ ...value, min: val, max: val });
  };

  const handlePaymentRangeChange = (val: [number, number]) => {
    onChange?.({ ...value, min: val[0], max: val[1] });
  };

  return (
    <>
      <Select
        className={css.paymentOperatorSelect}
        value={operator}
        onChange={handleOperatorChange}
      >
        <Option value="MORE_THEN_EQUAL">大于等于</Option>
        <Option value="LESS_THEN_EQUAL">小于等于</Option>
        <Option value="BETWEEN">区间</Option>
      </Select>
      {operator !== 'BETWEEN' ? (
        <InputNumber
          value={max}
          onChange={handlePaymentNumChange}
          maxLength={11}
          precision={2}
          max={99999999.99}
          className={css.paymentNumInput}
        />
      ) : (
        <RangeInputNumber
          className={classNames(css.paymentRangeInput)}
          limit={99999999.99}
          step="0.01"
          value={[min, max]}
          onChange={handlePaymentRangeChange}
          maxLength={11}
        />
      )}
      <span className={css.marginText}>元</span>
    </>
  );
});

/**
 * 订单金额、换货金额、退款金额、换货金额
 */
export const ValidPaymentSelector = memo((props: ValidPaymentSelectorProps) => {
  const {
    value = {
      type: 'ALL',
      range: { min: 100, max: 100, operator: 'MORE_THEN_EQUAL' }
    },
    cancelFormError,
    onChange
  } = props;
  const { type, range } = value;

  const validPaymentTypeChange = (e: RadioChangeEvent) => {
    cancelFormError?.();
    onChange?.({ ...value, type: e.target.value });
  };

  const validPaymentRangeChange = v => {
    cancelFormError?.();
    onChange?.({ ...value, range: v });
  };

  return (
    <div>
      <RadioGroup value={type} onChange={validPaymentTypeChange}>
        <Radio value="ALL">不限</Radio>
        <Radio value="FIXED">指定金额</Radio>
      </RadioGroup>
      {type === 'FIXED' ? (
        <div className={css.item}>
          <ValidPayment value={range} onChange={validPaymentRangeChange} />
        </div>
      ) : null}
    </div>
  );
});
