import { ShopExportTaskWebDto } from "@/requests/exportCenter/type";
import { statusMap } from "./constant";

export function sortDxCreated(
  a: ShopExportTaskWebDto,
  b: ShopExportTaskWebDto
): number {
  return new Date(a.dxCreated).getTime() - new Date(b.dxCreated).getTime();
}

export function sortTaskStatus(
  a: ShopExportTaskWebDto,
  b: ShopExportTaskWebDto
): number {
  return statusMap[a.taskStatus].value - statusMap[b.taskStatus].value;
}

