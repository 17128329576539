import {
  ListDto,
  ListParams,
  LogTypes,
  OperateAccount
} from '@/requests/operationLog/type';
import { OperationLogRequest } from '@/requests/operationLog';

export class OperationLogService {
  private operationLogRequest = new OperationLogRequest();

  logList(params: ListParams): Promise<ListDto> {
    return this.operationLogRequest.logList(params);
  }

  listOperateAccounts(): Promise<OperateAccount[]> {
    return this.operationLogRequest.listOperateAccounts();
  }

  logTypes(): Promise<LogTypes[]> {
    return this.operationLogRequest.logTypes();
  }
}

export const operationLogService = new OperationLogService();

export function truncateString(string: string, maxLength = 2000): string {
  if (string.length > maxLength) {
    return `${string.slice(0, maxLength)}...`;
  }
  return string;
}
