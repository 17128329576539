import React, { memo, useState } from 'react';
import { Dialog } from '@/components/widgets/modal';
import { DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { Select } from '@/components/widgets/select';
import { FormItem, FormLike } from '@/components/widgets/formLike';
import { InputNumber } from '@/components/widgets/input';
import { message } from '@/components/widgets/message';
import { TradeStatusRuleWebDto } from '@/types/tradeStatus';
import css from './style.less';
import { tradeStatusMap, timeoutUnitMap } from './constants';

export default memo(
  (
    props: DialogDeployProps<{ rule: TradeStatusRuleWebDto; tradeDay: number }>
  ) => {
    const { closeDialog, params } = props;

    const [rule, setRule] = useState(() => {
      if (!params.rule.tradeStatusCondition?.filterTime) {
        return {
          ...params.rule,
          tradeStatusCondition: {
            filterType: 'TIMEOUT',
            filterTime: { value: 1, unit: 'DAYS' }
          }
        };
      }
      return params.rule;
    });

    const daysLimit = params.tradeDay - 1;

    const handleOk = () => {
      const { filterTime } = rule.tradeStatusCondition || {};
      const { unit, value } = filterTime || {};

      if (
        unit === 'HOURS' &&
        (!value || value < 1 || value > 99 || !Number.isInteger(value))
      ) {
        message.warning('请输入1~99的整数');
        return;
      }
      if (
        unit === 'DAYS' &&
        (!value || value < 1 || value > daysLimit || !Number.isInteger(value))
      ) {
        message.warning(`请输入1~${daysLimit}的整数`);
        return;
      }
      closeDialog({
        ...rule,
        tradeStatusCondition: {
          filterType: 'TIMEOUT',
          filterTime
        }
      } as TradeStatusRuleWebDto);
    };

    return (
      <Dialog
        title={`编辑${tradeStatusMap[rule.tradeStatus]}超时时间`}
        width={460}
        onCancel={closeDialog}
        onOk={handleOk}
      >
        <div className={css.timeoutEditorBody}>
          <div className={css.flexCenter}>
            <FormLike value={rule} onChange={setRule}>
              <FormItem name="tradeStatusCondition.filterTime.value">
                <InputNumber className={css.width} />
              </FormItem>
              <FormItem name="tradeStatusCondition.filterTime.unit">
                <Select
                  className={css.width}
                  options={Object.entries(timeoutUnitMap).map(
                    ([value, label]) => ({
                      label,
                      value
                    })
                  )}
                />
              </FormItem>
            </FormLike>
          </div>
        </div>
      </Dialog>
    );
  }
);
