/**
 * 将驼峰转为大写下划线
 */
export function camelToUpperSnakeCase(camelStr: string){
	return camelStr.replace(/[A-Z]/g,(s)=> {
		return ` ${s}`;
	}).trim().replaceAll(' ','_').toUpperCase();
}

// 字符串转base64
export const getEncode64 = (str: string): string => btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      (match, p1) => String.fromCharCode('0x' + p1)
    )
  );