// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_adPopWrapper_E0pnH {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow: auto;\n  outline: 0;\n  -webkit-overflow-scrolling: touch;\n  z-index: 1000;\n  background-color: #00000073;\n  text-align: center;\n}\n.style_adPopWrapper_E0pnH .style_adPopPosition_wmCUv {\n  display: inline-block;\n  box-sizing: border-box;\n  position: relative;\n  top: 100px;\n  width: auto;\n  margin: 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/advertisement/style.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,cAAA;EACA,UAAA;EACA,iCAAA;EACA,aAAA;EACA,2BAAA;EACA,kBAAA;AACF;AAZA;EAeI,qBAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,cAAA;AAAJ","sourcesContent":[".adPopWrapper {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow: auto;\n  outline: 0;\n  -webkit-overflow-scrolling: touch;\n  z-index: 1000;\n  background-color: #00000073;\n  text-align: center;\n\n\n  .adPopPosition {\n    display: inline-block;\n    box-sizing: border-box;\n    position: relative;\n    top: 100px;\n    width: auto;\n    margin: 0 auto;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adPopWrapper": "style_adPopWrapper_E0pnH",
	"adPopPosition": "style_adPopPosition_wmCUv"
};
export default ___CSS_LOADER_EXPORT___;
