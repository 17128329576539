import React, {
  cloneElement,
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { useAgentReducer } from 'use-agent-reducer';
import { DownOutlined } from '@ant-design/icons';
import { push } from '@/libs/history';
import { menuSelectionRef } from './model';
import { MenuItemProps, MenuProps, MenuUrl } from './type';
import css from './style.less';
import { Icon } from '@/components/widgets/icon';
import { usePermissionSet } from '@/modules/cache';
import { RedirectTo } from '@/components/layouts/route';

function isMatched(
  pathname: string,
  url: string | { path: string; [key: string]: any }
): boolean {
  const path = typeof url === 'string' ? url : url.path;
  return pathname === path || pathname.startsWith(`${path}`);
}

export const Menu = memo(
  ({
    title,
    type,
    children,
    expand = true,
    url,
    activeIcon,
    icon,
    defaultSpread = true
  }: MenuProps) => {
    const { select } = useAgentReducer(menuSelectionRef.current);
    const [spread, setSpread] = useState(defaultSpread);

    const location = useLocation();

    const { pathname } = location;
    const path = url?.path || '';

    const matched = isMatched(pathname, path);
    const isCurrentMenu = pathname.startsWith(`/${type}`);

    useEffect(() => {
      if (!matched) {
        return;
      }
      if (url) {
        select(title, path);
      }
    }, [matched, path]);

    const handleToggleSpread = useCallback(() => {
      if (!expand && url) {
        push(url.path);
        return;
      }
      setSpread(s => !s);
    }, [url]);

    return (
      <div className={classNames(css.menu)}>
        <div
          className={classNames(
            css.titleWrap,
            !expand ? css.item : '',
            matched && !expand ? css.selected : ''
          )}
          onClick={handleToggleSpread}
        >
          <div className={css.flex}>
            <div className={css.title}>
              <Icon
                className={css.icon}
                type={isCurrentMenu ? activeIcon : icon}
              />
              {title}
            </div>
          </div>
          {expand ? (
            <DownOutlined rotate={spread ? 180 : 0} style={{ fontSize: 10 }} />
          ) : null}
        </div>
        {children ? (
          <div
            className={classNames(css.content, spread ? css.block : css.none)}
          >
            {children}
          </div>
        ) : null}
      </div>
    );
  }
);

export const MenuItem = memo(({ title, url }: MenuItemProps) => {
  const { select } = useAgentReducer(menuSelectionRef.current);

  const location = useLocation();
  const { pathname } = location;
  const path = typeof url === 'string' ? url : url.path;

  const matched = isMatched(pathname, url);

  useEffect(() => {
    if (!matched) {
      return;
    }
    select(title, path);
  }, [matched, path]);

  const handleClick = useCallback(() => {
    push(path);
  }, [path]);

  return (
    <div
      onClick={handleClick}
      className={classNames(css.item, matched ? css.selected : '')}
    >
      <div className={css.itemTitle}>{title}</div>
    </div>
  );
});

export const findStartPatSelected = (
  menu: ReactElement<{ children: ReactNode }>,
  typeList: string[]
): RedirectTo => {
  const children = React.Children.toArray(
    menu.props.children
  ) as ReactElement<MenuProps>[];
  const menuList = typeList
    .map(type =>
      children.find(i => !React.isValidElement(i) || i.props.type === type)
    )
    .filter(i => i) as ReactElement<MenuProps>[];
  const firstMenu = menuList[0] as ReactElement<MenuProps>;
  if (firstMenu.props.url) {
    const { url } = firstMenu.props;
    return typeof url === 'string' ? url : url!.path;
  }
  if (firstMenu) {
    const menuItems = React.Children.toArray(firstMenu.props.children);
    const firstMenuItem = menuItems[0] as ReactElement<MenuItemProps>;
    const { url } = firstMenuItem.props;
    return typeof url === 'string' ? url : url!.path;
  }
  return '';
};

export const useAvailableMenuUrls = (
  menu: ReactElement<{ children: ReactNode }>
): MenuUrl[] => {
  const roleSet = usePermissionSet();

  return useMemo(() => {
    const children = React.Children.toArray(menu.props.children);

    const urls: MenuUrl[] = children.flatMap(c => {
      if (
        !React.isValidElement(c) ||
        (c.type !== MenuItem && c.type !== Menu)
      ) {
        return [];
      }
      if (c.type === MenuItem || (c.type === Menu && c.props.url)) {
        return c.props.url;
      }
      const subs = React.Children.toArray(c.props.children);
      return subs
        .map(s => {
          if (!React.isValidElement(s) || s.type !== MenuItem) {
            return undefined;
          }
          return s.props.url;
        })
        .filter(url => url);
    });
    return urls.filter(u => {
      if (typeof u === 'string') {
        return true;
      }
      const { auth } = u;
      return !auth || auth.some(r => roleSet.has(r));
    });
  }, [menu, roleSet]);
};

export const useMenuFilter = (
  menu: ReactElement<{ children: ReactNode }>,
  menuUrls: MenuUrl[]
): ReactElement =>
  useMemo(() => {
    const menuPaths = menuUrls.map(url =>
      typeof url === 'string' ? url : url.path
    );
    const menuPathSet = new Set<string>(menuPaths);
    const menuChildren = React.Children.map(menu.props.children, c => {
      if (
        !React.isValidElement(c) ||
        (c.type !== MenuItem && c.type !== Menu)
      ) {
        return c;
      }
      if (c.type === Menu && c.props.url) {
        const menu = c as ReactElement<MenuProps>;
        const { url } = menu.props;
        const itemPath = typeof url === 'string' ? url : url!.path;
        return menuPathSet.has(itemPath) ? menu : null;
      }
      const subMenu = c as ReactElement<MenuProps>;
      const subs = React.Children.toArray(subMenu.props.children);
      const subChildren = subs.filter(s => {
        if (!React.isValidElement(s) || s.type !== MenuItem) {
          return true;
        }
        const u: MenuUrl = s.props.url;
        if (typeof u === 'string') {
          return true;
        }
        const { path } = u;
        return !path || menuPathSet.has(path);
      });
      if (!subChildren.length) {
        return null;
      }
      const { children, ...subMenuCleanProps } = subMenu.props;
      return cloneElement(subMenu, subMenuCleanProps, subChildren);
    });
    return cloneElement(menu, {}, menuChildren);
  }, [menu, menuUrls]);
