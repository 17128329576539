// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_search_Zg4wB {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  margin-bottom: 12px;\n  gap: 24px;\n}\n.style_search_Zg4wB .style_selectWidthMid_GBzTJ {\n  width: 180px;\n}\n.style_search_Zg4wB .style_item_mPs6S {\n  display: flex;\n  align-items: center;\n}\n.style_operationLogList_6CnZC {\n  background: #ffffff;\n  border-radius: 6px;\n  padding: 20px 24px;\n}\n.style_content_AvFS0 {\n  white-space: pre-wrap;\n  word-break: break-word;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/subAccountOperationsLog/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACF;AANA;EAQI,YAAA;AACJ;AATA;EAYI,aAAA;EACA,mBAAA;AAAJ;AAIA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;AAFF;AAKA;EACE,qBAAA;EACA,sBAAA;AAHF","sourcesContent":[".search {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  margin-bottom: 12px;\n  gap: 24px;\n\n  .selectWidthMid {\n    width: 180px;\n  }\n\n  .item {\n    display: flex;\n    align-items: center;\n  }\n}\n\n.operationLogList {\n  background: #ffffff;\n  border-radius: 6px;\n  padding: 20px 24px;\n}\n\n.content {\n  white-space: pre-wrap;\n  word-break: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "style_search_Zg4wB",
	"selectWidthMid": "style_selectWidthMid_GBzTJ",
	"item": "style_item_mPs6S",
	"operationLogList": "style_operationLogList_6CnZC",
	"content": "style_content_AvFS0"
};
export default ___CSS_LOADER_EXPORT___;
