import React, { memo, useState } from 'react';
import { withRouteNode } from '@/components/layouts/route';
import moment from 'moment';

import { StrategySettingTemplate } from '../../remindSubStrategyForm/remindSubStrategyTemplate';
import {
  ItemConfig,
  FormValue,
  FormConfig
} from '../../remindSubStrategyForm/remindSubStrategyTemplate/type';

const defaultState: Partial<FormValue> = {
  strategyName: '2121',
  active: true,
  validDate: {
    type: 'LONG_TIME_EFFECTIVE',
    range: {
      cycleDateTimeRange: {
        dateRange: {
          end: moment().format('YYYY-MM-DD'),
          start: moment().format('YYYY-MM-DD')
        },
        timeRange: {
          start: '00:00:00',
          end: '23:59:59'
        }
      },
      fixedDateTimeRange: {
        end: `${moment().format('YYYY-MM-DD')} 23:59:59`,
        start: `${moment().format('YYYY-MM-DD')} 00:00:00`
      }
    }
  },
  itemFilterConfig: {
    relatedType: 'ITEM',
    sellerCatCids: [],
    relatedTaobaoIids: ['599567801224']
  },
  validAmount: {
    type: 'ALL',
    range: {
      min: 100,
      max: 100,
      operator: 'MORE_THEN_EQUAL'
    }
  },
  receiverAddressFilterConfig: {
    type: 'ALL',
    areaIds: []
  },
  consignTypes: ['ALL_CONSIGNED', 'PART_CONSIGNED', 'PART_CONSIGNED_ALL'],

  refundReason: {
    refundReasonType: 'ALL',
    refundReasonIds: []
  },
  refundType: {
    refundTaskType: 'ALL',
    refundTypes: []
  },
  signType: 'ALL',
  exchangeReason: {
    exchangeReasonType: 'ALL',
    exchangeReasonIds: []
  },
  sendAccountConfig: {
    humanSessionSendAccountConfig: {
      enabled: true,
      sendAccountTypes: ['LATEST'],
      subAccountIds: [],
      wangwangGroupIds: []
    },
    silenceSendAccountConfig: {
      enabled: true,
      sendAccountTypes: ['RANDOM'],
      subAccountIds: [],
      wangwangGroupIds: []
    }
  },
  filterRatedTrade: true,
  remindContents: {
    remindType: 'REFUND_SUCCESS_PUSH',
    remindContents: [
      {
        heraCheckStatus: 'WAIT_CHECK',
        contents: [],
        replyOrder: 'TEXT_BEFORE_IMAGE'
      }
    ]
  },
  subAccountFilterConfig: {
    type: 'POINTED',
    subAccountIds: [],
    wangwangGroupIds: []
  }
};

const formConfig: FormConfig<ItemConfig[]> = {
  baseInfo: [
    {
      field: 'strategyName', // 表单 field
      label: '任务名称', // 表单 label
      type: 'strategyNameInput' // 组件类型
    },
    {
      field: 'active',
      label: '开启状态',
      type: 'activeRadio'
    }
  ],
  limitConditions: [
    {
      field: 'validDateTime',
      label: '生效时间',
      type: 'validateTimeSelector'
    },
    {
      field: 'itemFilterConfig',
      label: '咨询商品',
      type: 'itemSelector'
    },
    {
      field: 'validAmount',
      label: '付款金额',
      type: 'validPaymentSelector'
    },
    {
      field: 'receiverAddressFilterConfig',
      label: '买家收货地址',
      type: 'addressesSelector'
    },
    {
      field: 'consignTypes',
      label: '订单发货类型',
      type: 'consignTypesCheckbox'
    },
    {
      field: 'refundReasons',
      label: '退款原因',
      type: 'refundReasonSelector'
    },
    {
      field: 'refundTypes',
      label: '退款类型',
      type: 'refundTypeSelector'
    },
    {
      field: 'signType',
      label: '物流状态',
      type: 'signTypeRadio'
    },
    {
      field: 'exchangeReason',
      label: '换货原因',
      type: 'exchangeReasonSelector'
    },
    {
      field: 'subAccountFilterConfig',
      label: '接待客服',
      type: 'accountRadioSelector'
    }
  ],
  // filterConditions: [],
  sendAccount: [
    {
      field: 'sendAccountConfig',
      label: '发送客服',
      type: 'humanSendAccountSelector'
    }
    // {
    //   field: 'sendAccountConfig',
    //   label: '发送客服',
    //   type: 'sendAccountSelector'
    // }
  ],
  pushContent: [
    {
      field: 'filterRatedTrade',
      label: '排除已评价',
      type: 'filterRatedTradeCheckbox'
    },
    {
      field: 'remindContents',
      label: '推送内容',
      type: 'remindContentsEditor'
    }
  ]
};

const StrategySettingTemplateDemo = memo(() => {
  const [state, setState] = useState(defaultState);
  const [formError, setFormError] = useState<
    Record<string, string | undefined>
  >({});

  const onFormChange = v => {
    // eslint-disable-next-line no-console
    console.log('state', v);
    setState(v);
  };

  const cancelFormError = v => {
    setFormError({
      e: undefined
    });
  };

  return (
    <div>
      <StrategySettingTemplate
        formConfig={formConfig}
        formValue={state}
        formError={formError}
        onFormChange={v => {
          onFormChange(v);
        }}
        cancelFormError={cancelFormError}
      />
    </div>
  );
});

export default StrategySettingTemplateDemo;
