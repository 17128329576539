import { FormComProps } from '@/types/formComProps';
import { memo } from 'react';
import * as React from 'react';
import { AutoComplete } from '../autoComplate';
import { AutoCompleteProps, Input } from 'antd';

export default memo(
  ({
    value,
    options,
    maxLength = 999,
    onChange,
    onSearch,
    ...rest
  }: FormComProps<string> & AutoCompleteProps & { maxLength?: number }) => {
    const handleAutoCompleteInputChange = (label: string) =>
      onChange?.(label.slice(0, maxLength));

    return (
      <AutoComplete
        value={value}
        options={options}
        filterOption={(v, option) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return option!.label.includes(v);
        }}
        onSearch={handleAutoCompleteInputChange}
        onSelect={(id, option) => handleAutoCompleteInputChange(option.label)}
        {...rest}
      >
        <Input.Search value={value} maxLength={maxLength} onSearch={onSearch} />
      </AutoComplete>
    );
  }
);
