import React, { memo, useEffect } from 'react';
import { useAgentMethods, useAgentReducer } from 'use-agent-reducer';
import classNames from 'classnames';
import { Column, Table } from '@/components/widgets/table';
import { Input } from '@/components/widgets/input';
import { CustomRangePicker } from '@/components/layouts/customRangePicker';
import ConditionUnit from '@/components/layouts/conditionUnit';
import { Select, Option } from '@/components/widgets/select';
import usePageContentScroll from '@/hooks/usePageContentScroll';
import { EnterpriseStaffLoginWebDto } from '@/requests/staff/type';
import { realTimeLoginRef } from './model';
import css from './style.less';
import { Container, Searcher, ShowRecord, OnlineTimes } from './components';
import { buildDateOptions } from '@/services/buildCoustomDateOptions';

const loginStatusMap = {
  OFFLINE: '离线',
  ONLINE: '在线'
};

const StaffAccountListComp = memo(() => {
  const {
    state: {
      staffLoading,
      staffPage,
      staffSearchCondition,
      staffLoginStatus,
      staffTotal,
      dateRange
    },
    changeStaffSearchCondition,
    fetchStaff
  } = useAgentReducer(realTimeLoginRef.current);

  return (
    <Container title="智能跟单助手账号在线明细">
      <Searcher
        value={staffSearchCondition}
        onChange={changeStaffSearchCondition}
        onClick={() => fetchStaff(1)}
      >
        <ConditionUnit
          className={css.formItem}
          label="在线状态"
          name="loginStatus"
        >
          <Select className={css.select}>
            <Option value="ALL">全部</Option>
            <Option value="ONLINE">在线</Option>
            <Option value="OFFLINE">离线</Option>
          </Select>
        </ConditionUnit>
        <ConditionUnit className={css.formItem} label="员工" name="staffName">
          <Input className={css.select} placeholder="请输入搜索员工" />
        </ConditionUnit>
      </Searcher>
      <div className={css.staffTable}>
        <Table
          rowKey="staffId"
          loading={staffLoading}
          dataSource={staffLoginStatus}
          pagination={{
            size: 'default',
            position: ['bottomCenter'],
            current: staffPage,
            onChange: page => fetchStaff(page),
            total: staffTotal,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: true
          }}
          bordered
        >
          <Column title="员工" dataIndex="staffName" />
          <Column title="账号" dataIndex="accountName" />
          <Column
            title="在线时长"
            dataIndex="onlineSeconds"
            render={value => <OnlineTimes times={[value]} />}
          />
          <Column
            title="当前在线状态"
            dataIndex="loginStatus"
            render={value => loginStatusMap[value]}
          />
          <Column
            title="操作"
            key="action"
            render={(value, record: EnterpriseStaffLoginWebDto) => (
              <ShowRecord
                id={record.staffId}
                type="staff"
                from={dateRange[0]}
                to={dateRange[1]}
                name={record.staffName}
              />
            )}
          />
        </Table>
      </div>
    </Container>
  );
});

const DateSelector = memo(() => {
  const {
    state: { dateRange },
    handleDateRangeChange
  } = useAgentReducer(realTimeLoginRef.current);

  const scrollTop = usePageContentScroll();

  return (
    <Container
      className={classNames(css.dateSelector, { [css.scroll]: scrollTop > 0 })}
    >
      <CustomRangePicker
        options={buildDateOptions()}
        value={dateRange}
        onChange={handleDateRangeChange}
        onSubmit={handleDateRangeChange}
      />
    </Container>
  );
});

export default memo(() => {
  const { fetchStaff } = useAgentMethods(realTimeLoginRef.current);

  useEffect(() => {
    fetchStaff();
  }, []);

  return (
    <>
      <DateSelector />
      <StaffAccountListComp />
    </>
  );
});
