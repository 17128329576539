import React, { memo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Button } from '@/components/widgets/button';
import { FormLike } from '@/components/widgets/formLike';
import { useDialogMethods } from '@/components/widgets/modal/dialogs';
import RecordDialog from '../recordDialog';
import { RecordDialogProps } from '../recordDialog/type';
import { ContainerProps, SearcherProps, OnlineTimesProps } from './type';
import css from './style.less';

export const Container = (props: ContainerProps): JSX.Element => {
  const { title, children, className } = props;

  return (
    <div className={classNames(css.container, className)}>
      {title ? <div className={css.title}>{title}</div> : null}
      {children}
    </div>
  );
};

export const Searcher = (props: SearcherProps): JSX.Element => {
  const { children, value, onChange, onClick } = props;

  return (
    <div className={css.searcher}>
      <FormLike value={value} onChange={onChange}>
        {children}
      </FormLike>
      <Button type="primary" onClick={() => onClick()}>
        搜索
      </Button>
    </div>
  );
};

export const ShowRecord = (props: RecordDialogProps): JSX.Element => {
  const [openDialog] = useDialogMethods();

  const openRecordDialog = () => {
    openDialog(RecordDialog, props);
  };

  return (
    <Button type="link" onClick={openRecordDialog}>
      查看在线明细
    </Button>
  );
};

export const OnlineTimes = memo((props: OnlineTimesProps): JSX.Element => {
  const { times } = props;

  const duration = moment.duration(
    times.reduce((prev, curr) => prev + (curr ?? 0), 0),
    'seconds'
  );

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return (
    <span>
      {hours}小时{minutes}分
    </span>
  );
});
