import React, { memo, useState } from 'react';
import { Modal } from '@/components/widgets/modal';
import { AddTextareaInfo } from '@/components/works/components/customTextarea';
import { RemindType } from '@/types/remindType';
import {
  RemindContentsMutipleEditorProps,
  VariableSelectorProps
} from './type';
import css from './style.less';
import { AutoComplete } from '@/components/widgets/autoComplate';
import { Tooltip } from '@/components/widgets/tooltip';
import { MultipleRoundsTextarea } from '@/components/works/components/multipleRoundsTextarea';
import { Unit } from '@/components/works/components/conditionUnit';
import { Value } from '@/components/works/components/multipleRoundsTextarea/type';

// 内容条数限制
const CONTENT_LIMIT = 5;
// 输入框 placeholder
const RemindContentsEditorPlaceholder: Partial<Record<RemindType, string>> = {
  REPLY_TIMEOUT_PUSH: '请输入推送内容'
};
// 变量
const ExtraVariables: Partial<Record<RemindType, string[]>> = {
  REPLY_TIMEOUT_PUSH: ['客服昵称']
};
// 添加按钮文案
const AddTextareaInfoName = {
  REPLY_TIMEOUT_PUSH: '推送轮次'
};

export const VariableSelector = memo((props: VariableSelectorProps) => {
  const { options, onSelect } = props;
  const [value, setValue] = useState('');

  const handleSelect = v => {
    onSelect(v);
    setValue('');
  };

  return (
    <AutoComplete
      value={value}
      options={options}
      style={{ width: 160 }}
      onSelect={handleSelect}
      onChange={setValue}
      placeholder="插入变量"
    />
  );
});

/**
 * 多轮推送内容
 */
export const RemindContentsMutipleEditor = memo(
  (props: RemindContentsMutipleEditorProps) => {
    const {
      value = {
        remindType: '' as RemindType,
        remindContents: [],
        roundDelaySeconds: []
      },
      onChange
    } = props;
    const { remindType, remindContents, roundDelaySeconds = [] } = value || {};
    // 由于跟单没有round，等待超时回复进入如果没有 round 补1
    const newRemindContents = remindContents.map(i =>
      i.round ? i : { ...i, round: 1 }
    );

    const limit =
      roundDelaySeconds.length >= CONTENT_LIMIT ? CONTENT_LIMIT : undefined;

    const handleContentChange = (v: Value) => {
      onChange?.({ ...value, ...v });
    };

    const handleTextareaDelete = (index: number) => async () => {
      const ok = await Modal.confirm({ title: '是否确认删除？', content: '' });
      if (!ok) {
        return;
      }

      const deleteRound = index + 1;

      onChange?.({
        ...value,
        roundDelaySeconds: roundDelaySeconds.filter((_, i) => index !== i),
        remindContents: newRemindContents.flatMap(remindContent => {
          if (remindContent.round !== deleteRound && remindContent.round) {
            return {
              ...remindContent,
              round:
                remindContent?.round > deleteRound
                  ? remindContent?.round - 1
                  : remindContent.round
            };
          }

          return [];
        })
      });
    };

    const handleAddContent = () => {
      onChange?.({
        ...value,
        roundDelaySeconds: roundDelaySeconds.concat(30),
        remindContents: newRemindContents.concat({
          heraCheckStatus: 'WAIT_CHECK',
          contents: [],
          replyOrder: 'TEXT_BEFORE_IMAGE',
          round: roundDelaySeconds.length + 1
        })
      });
    };

    return (
      <>
        <div>
          {roundDelaySeconds.map((delayTime, index) => (
            <MultipleRoundsTextarea
              key={`${index.toString()}`}
              index={index}
              value={{ roundDelaySeconds, remindContents: newRemindContents }}
              onChange={handleContentChange}
              onDelete={handleTextareaDelete(index)}
              placeholder={RemindContentsEditorPlaceholder[remindType]}
              renderVariableSelect={handleSelect => (
                <VariableSelector
                  options={
                    ExtraVariables[remindType]?.map(v => ({
                      value: v,
                      label:
                        v === '客服昵称' ? (
                          <Tooltip title="假设子账号名称为“XX旗舰店:小A”，发送时变量会自动替换成“小A”；店铺主号直接替换为“XX旗舰店”">
                            {v}
                          </Tooltip>
                        ) : (
                          v
                        )
                    })) ?? []
                  }
                  onSelect={v => handleSelect(`{${v}}`)}
                />
              )}
            />
          ))}

          <Unit className={css.unitWrapper}>
            <AddTextareaInfo
              name={AddTextareaInfoName[remindType]}
              limit={limit}
              onChange={handleAddContent}
              info=""
              prefix="+"
            />
          </Unit>
        </div>
      </>
    );
  }
);
