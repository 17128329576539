// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_Yl1dn .ant-typography {\n  color: inherit;\n}\n.style_wrapper_Yl1dn .ant-typography p,\n.style_wrapper_Yl1dn div.ant-typography {\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/widgets/autoEllipsisText/style.less"],"names":[],"mappings":"AAAA;EAGM,cAAA;AADN;AAFA;;EAMM,gBAAA;AAAN","sourcesContent":[".wrapper {\n  :global {\n    .ant-typography {\n      color: inherit;\n    }\n    .ant-typography p, div.ant-typography {\n      margin-bottom: 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_Yl1dn"
};
export default ___CSS_LOADER_EXPORT___;
