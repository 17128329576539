import React, { memo, useEffect, useState } from 'react';
import { Radio, RadioGroup } from '@/components/widgets/radio';
import { Select, Option } from '@/components/widgets/select';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CustomType } from '../type';
import css from './style.less';

export type RefundTypes = {
  type: CustomType;
  types: string[];
};

type RefundReasonProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  cancelFormError?: () => void;
};

export const RefundTypeSelector = memo((props: RefundReasonProps) => {
  const { value = [], onChange, cancelFormError } = props;

  const [refundTypes, setRefundTypes] = useState<RefundTypes>({
    type: 'ALL',
    types: []
  });

  useEffect(() => {
    if (!value.length) {
      return;
    }
    setRefundTypes({ type: value.length ? 'FIXED' : 'ALL', types: value });
  }, [value]);

  const handleTypeChange = (e: RadioChangeEvent) => {
    cancelFormError?.();
    setRefundTypes({ ...refundTypes, type: e.target.value });
    onChange?.(e.target.value === 'ALL' ? [] : refundTypes.types);
  };

  const handleTypesChange = v => {
    cancelFormError?.();
    setRefundTypes({ ...refundTypes, types: v });
    onChange?.(v);
  };

  return (
    <div>
      <RadioGroup value={refundTypes.type} onChange={handleTypeChange}>
        <Radio value="ALL">不限</Radio>
        <Radio value="FIXED">指定退款类型</Radio>
      </RadioGroup>
      {refundTypes.type === 'FIXED' ? (
        <div className={css.item}>
          <Select
            mode="multiple"
            className={css.multipleSelect}
            placeholder="请选择退款类型"
            value={refundTypes.types}
            onChange={handleTypesChange}
          >
            <Option value="HAS_GOODS">退货退款</Option>
            <Option value="CONSIGNED_NO_GOODS">已发货仅退款</Option>
            <Option value="NO_CONSIGN_NO_GOODS">未发货仅退款</Option>
          </Select>
        </div>
      ) : null}
    </div>
  );
});
