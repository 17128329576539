import React from 'react';
import { Select, Option } from '@/components/widgets/select';
import { unitTextMap } from '@/constants/unitTime';
import { Unit, UnitTime } from '@/types/unitTime';

export type UnitTimeProps = {
  unitTime: UnitTime;
  onChange: (value: UnitTime) => void;
  unitRange: Array<Unit>;
  timeRange: Partial<Record<Unit, number[]>>;
};

export const UnitTimeSelector: React.FC<UnitTimeProps> = props => {
  const { unitRange, timeRange, unitTime, onChange } = props;
  const { unit, value } = unitTime;

  const handleValueChange = (value: number) => {
    onChange({ ...unitTime, value });
  };

  const handleUnitChange = (unit: Unit) => {
    onChange({ unit, value: timeRange?.[unit]?.[0] as number });
  };

  return (
    <span style={{ margin: '0 8px' }}>
      <Select
        style={{ width: 70, marginRight: '4px' }}
        value={value}
        onChange={handleValueChange}
      >
        {timeRange?.[unit]?.map(i => (
          <Option key={i}>{i}</Option>
        ))}
      </Select>
      <Select style={{ width: 70 }} value={unit} onChange={handleUnitChange}>
        {unitRange.map(i => (
          <Option key={i}>{unitTextMap[i]}</Option>
        ))}
      </Select>
    </span>
  );
};
