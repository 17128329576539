export const tradeStatusesTBMap = {
  WAIT_ORDER: '未下单',
  WAIT_PAY: '等待买家付款',
  WAIT_SEND: '等待卖家发货',
  SENT: '卖家已发货',
  SELLER_CONSIGNED_PART: '卖家部分发货',
  SUCCESS: '交易成功',
  CLOSE: '交易关闭',
  FRONT_PAID_FINAL_NO_PAID: '待付尾款',
  FRONT_PAID_FINAL_PAY_TIMEOUT: '尾款支付超时',
  CLOSE_NO_PAID: '交易关闭（且未付款）'
};

export const tradeStatusesJDMap = {
  WAIT_ORDER: '未下单',
  WAIT_PAY: '待付款',
  WAIT_SEND: '待出库',
  SENT: '已出库',
  SUCCESS: '已完成',
  CLOSE: '已取消',
  FRONT_PAID_FINAL_NO_PAID: '待付尾款',
  FRONT_PAID_FINAL_PAY_TIMEOUT: '尾款支付超时'
};
