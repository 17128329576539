import { Http, http } from '@/libs/http';
import {
  RecordDetailListParams,
  RecordDetailListResult,
  StatsListParams,
  StatsListResult,
  RemindStrategyStatsWebQuery,
  RemindBuyStrategyStatsWebDto
} from './type';

export class RemindBuyStatsRequest {
  private http: Http = http('/api/shop/remind-buy');

  // 查询明细接口
  recordDetailList(
    params: RecordDetailListParams
  ): Promise<RecordDetailListResult> {
    return this.http
      .path('record-detail-list-v2')
      .setRequestParams(params)
      .get<RecordDetailListResult>();
  }

  // 查询统计数据接口
  statsList(params: StatsListParams): Promise<StatsListResult> {
    return this.http
      .path('stats-list-v3')
      .setRequestBody(params)
      .post<StatsListResult>();
  }

  // 查询统计数据接口
  strategyStatsList(
    params: RemindStrategyStatsWebQuery
  ): Promise<RemindBuyStrategyStatsWebDto> {
    return this.http
      .path('strategy-stats-list-v2')
      .setRequestBody(params)
      .post();
  }
}

export default new RemindBuyStatsRequest();
