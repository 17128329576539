import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { Icon } from '@/components/widgets/icon';
import { Emoji } from '@/components/widgets/emoji';
import { TextArea } from '@/components/widgets/input';
import { Alert } from '@/components/widgets/alert';
import { Tooltip } from '@/components/widgets/tooltip';
import { Divider } from '@/components/widgets/divider';
import { message } from '@/components/widgets/message';
import { SubsectionTextareaProps, Value } from './type';
import css from './style.less';
import UploadPic from '../../uploadPic';
import { CheckStatusTags } from '../customTextarea';

export function SubsectionTextarea<T extends Value = Value>(
  props: SubsectionTextareaProps<T>
): JSX.Element {
  const {
    width,
    index,
    length,
    value,
    onChange,
    onDelete,
    extraVariables,
    maxLength = 500,
    placeholder,
    type = 'WAIT_CHECK',
    checkFailReason = '',
    showUpload = true,
    showInfo = true,
    showSubsection = true,
    renderVariableSelect
  } = props;

  const firstTextAreaRef = useRef<TextAreaRef>(null);
  const secondTextAreaRef = useRef<TextAreaRef>(null);
  const [subsectionIndex, setSubsectionIndex] = useState<0 | 1>(0);

  const hasSubsection = value.contents && value.contents.length >= 2;
  const [firstContent = '', secondContent = ''] = value.contents ?? ['', ''];

  const handleContentChange = (content: string) => {
    if (hasSubsection) {
      onChange({
        ...value,
        contents:
          subsectionIndex === 0
            ? [content, secondContent]
            : [firstContent, content]
      });
      return;
    }
    onChange({ ...value, contents: [content] });
  };

  const handleEmojiOrValSelect = (key: string) => {
    const focusedTextArea =
      subsectionIndex === 0
        ? firstTextAreaRef.current
        : secondTextAreaRef.current;

    if (!focusedTextArea?.resizableTextArea?.textArea) {
      return;
    }

    const { textArea } = focusedTextArea.resizableTextArea;

    const startPos = textArea.selectionStart; // 所选中的字段第一个字符串的index
    const endPos = textArea.selectionEnd; // 所选中的字段最后一个字符串index+1
    if (key.length + textArea.value.length - endPos + startPos > maxLength) {
      return;
    }
    const restoreTop = textArea.scrollTop;
    const start = textArea.value.substring(0, startPos);
    const end = textArea.value.substring(endPos, textArea.value.length);
    textArea.value = start + key + end;
    if (restoreTop > 0) {
      textArea.scrollTop = restoreTop;
    }
    textArea.focus();
    textArea.selectionStart = startPos + key.length;
    textArea.selectionEnd = startPos + key.length;

    const content = textArea.value.slice(0, maxLength);

    handleContentChange(content);
  };

  const handleImageChange = (imageId: number) => {
    onChange({ ...value, imageId });
  };

  const handleImageRemove = () => {
    onChange({
      ...value,
      contents: value.contents,
      imageId: undefined,
      replyOrder: 'TEXT_BEFORE_IMAGE'
    });
  };

  const handleReplyOrderChange = () => {
    onChange({
      ...value,
      replyOrder:
        value.replyOrder === 'IMAGE_BEFORE_TEXT'
          ? 'TEXT_BEFORE_IMAGE'
          : 'IMAGE_BEFORE_TEXT'
    });
  };

  const handleCancelSubsection = () => {
    if (firstContent.length + secondContent.length > maxLength) {
      message.warning(`第一段加第二段小于等于${maxLength}字时可取消分段`);
      return;
    }
    onChange({
      ...value,
      contents: [
        value.contents
          ?.reduce((prev, curr) => prev.trim().concat('\n', curr.trim()))
          .trim()
          .slice(0, maxLength) ?? ''
      ]
    });
  };

  const handleEnableSubsection = () => {
    onChange({ ...value, contents: [firstContent, ''] });
  };

  return (
    <div className={css.textAreaMain} style={{ width }}>
      {length > 1 ? (
        <div className={css.topTextMargin}>
          <span className={css.topText}>{`随机推送${index + 1}`}</span>
          <Icon
            className={css.icon}
            type="shanchu"
            onClick={() => onDelete?.()}
          />
        </div>
      ) : null}
      <div className={css.emojiInfo} style={{ width }}>
        <div className={css.emojiInfoLeft}>
          <div>
            <Emoji onSelect={handleEmojiOrValSelect} disabled={false} />
          </div>
          {extraVariables?.map(v => {
            if (typeof v !== 'string') {
              return (
                <Tooltip title={v.tooltip} key={`extra_${v.variable}`}>
                  <span
                    className={css.variableList}
                    onClick={() => handleEmojiOrValSelect(`{${v.variable}}`)}
                  >{`{${v.variable}}`}</span>
                </Tooltip>
              );
            }
            return (
              <span
                key={`extra_${v}`}
                className={css.variableList}
                onClick={() => handleEmojiOrValSelect(`{${v}}`)}
              >{`{${v}}`}</span>
            );
          })}
          {renderVariableSelect?.(handleEmojiOrValSelect) || null}
        </div>
        <CheckStatusTags type={type} checkFailReason={checkFailReason} />
      </div>
      <div className={css.textAreaBox}>
        <div
          className={classNames(css.textAreaWrap, {
            [css.subection]: hasSubsection
          })}
        >
          <TextArea
            className={classNames(css.textAreaFirst, {
              [css.subection]: hasSubsection
            })}
            maxLength={maxLength}
            ref={firstTextAreaRef}
            value={firstContent}
            onChange={handleContentChange}
            placeholder={hasSubsection ? '请输入第一段推送内容' : placeholder}
            onFocus={() => setSubsectionIndex(0)}
            bordered={false}
            autoSize={{ minRows: 1 }}
          />
          <div
            className={classNames(css.textAreaFooter, {
              [css.subection]: !hasSubsection
            })}
          >
            {showSubsection && !hasSubsection ? (
              <span
                className={css.enableSubsection}
                onClick={handleEnableSubsection}
              >
                分段回复
              </span>
            ) : null}
            <span className={css.wordCount}>
              {firstContent.length}/{maxLength}
            </span>
          </div>
          {hasSubsection ? (
            <>
              <Divider className={css.cancelSubsection} dashed>
                <span className={css.text} onClick={handleCancelSubsection}>
                  取消分段
                </span>
              </Divider>
              <TextArea
                maxLength={maxLength}
                ref={secondTextAreaRef}
                className={css.textAreaSecond}
                value={secondContent}
                onChange={handleContentChange}
                placeholder="请输入第二段推送内容"
                onFocus={() => setSubsectionIndex(1)}
                bordered={false}
                autoSize={{ minRows: 1 }}
              />
              <div className={css.textAreaFooter}>
                <span className={css.wordCount}>
                  {secondContent.length}/{maxLength}
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className={css.imageWrap}>
        {showUpload ? (
          <UploadPic
            imageId={value.imageId}
            changeImage={handleImageChange}
            removeImage={handleImageRemove}
            size="small"
          />
        ) : null}
        {value.imageId ? (
          <Tooltip
            placement="right"
            title={
              <div>
                {value.replyOrder === 'TEXT_BEFORE_IMAGE'
                  ? '先发文字，再发图片，点击交换顺序'
                  : '先发图片，再发文字，点击交换顺序'}
              </div>
            }
          >
            <Icon
              className={css.sendOrder}
              type={
                value.replyOrder === 'TEXT_BEFORE_IMAGE'
                  ? 'xianwenhoutu'
                  : 'xiantuhouwen'
              }
              onClick={handleReplyOrderChange}
            />
          </Tooltip>
        ) : null}
      </div>
      {showInfo ? (
        <Alert
          className={css.textAreaMsg}
          message="请不要输入平台禁止的敏感内容，以免买家拒收或投诉"
          type="info"
          showIcon
        />
      ) : null}
    </div>
  );
}
