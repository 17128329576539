// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_main_xasYf {\n  height: 460px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.style_main_xasYf .ant-table-body {\n  overflow-y: auto !important;\n}\n.style_info_SQmhe {\n  float: left;\n  display: inline-block;\n  line-height: 32px;\n  color: #3d7fff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/templateModal/style.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AADF;AAHA;EAMI,2BAAA;AAAJ;AAIA;EACE,WAAA;EACA,qBAAA;EACA,iBAAA;EACA,cAAA;AAFF","sourcesContent":["@import '~@/css/color.less';\n\n.main {\n  height: 460px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  :global(.ant-table-body) {\n    overflow-y: auto !important;\n  }\n}\n\n.info {\n  float: left;\n  display: inline-block;\n  line-height: 32px;\n  color: @link-color;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "style_main_xasYf",
	"info": "style_info_SQmhe"
};
export default ___CSS_LOADER_EXPORT___;
