import { SelectProps } from '@/components/widgets/select';

export enum NickType {
  PERSONAL = 'PERSONAL',
  SAME = 'SAME'
}

export const nickTypeOptions: SelectProps['options'] = [
  {
    label: '个性昵称',
    value: NickType.PERSONAL
  },
  {
    label: '统一昵称',
    value: NickType.SAME
  }
];
