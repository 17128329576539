// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.5_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_radioWrap_BQf0c {\n  line-height: 30px;\n}\n.style_noLimitedWrap_meW\\+k {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 300px);\n  max-height: 392px;\n  border: 1px solid #f0f0f0;\n}\n.style_treeHeader_6oWIn {\n  height: 40px;\n  width: 100%;\n  padding: 0 12px;\n  font-size: 14px;\n  margin-top: 10px;\n  line-height: 40px;\n  background-color: #f0f2f5;\n}\n.style_treeContent_qslBp {\n  height: calc(100vh - 281px);\n  max-height: 300px;\n  overflow-y: auto;\n  border: 1px solid #e0e0e0;\n  border-top: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/works/remindSubStrategyForm/remindSubStrategyTemplate/components/tradeStatusesSelector/tradeStatusesSelectModal/style.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,2BAAA;EACA,iBAAA;EACA,yBAAA;AAAF;AAGA;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;AADF;AAIA;EACE,2BAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;AAFF","sourcesContent":[".radioWrap {\n  line-height: 30px;\n}\n\n.noLimitedWrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 300px);\n  max-height: 392px;\n  border: 1px solid #f0f0f0;\n}\n\n.treeHeader {\n  height: 40px;\n  width: 100%;\n  padding: 0 12px;\n  font-size: 14px;\n  margin-top: 10px;\n  line-height: 40px;\n  background-color: #f0f2f5;\n}\n\n.treeContent {\n  height: calc(100vh - 281px);\n  max-height: 300px;\n  overflow-y: auto;\n  border: 1px solid #e0e0e0;\n  border-top: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioWrap": "style_radioWrap_BQf0c",
	"noLimitedWrap": "style_noLimitedWrap_meW+k",
	"treeHeader": "style_treeHeader_6oWIn",
	"treeContent": "style_treeContent_qslBp"
};
export default ___CSS_LOADER_EXPORT___;
