import { testString } from '@/utils/regUtils';

export const checkPhoneNumber = (phoneNumber: string) => {
  const res = testString(phoneNumber, 10);

  if (!phoneNumber) {
    return '请输入手机号';
  }

  if (!res) {
    return '手机号不存在，请重新输入';
  }
};

export const verifyFormValue = value => {
  const { phoneNumber, accountName } = value;

  if (!accountName) {
    return '请输入账号';
  }

  const phoneNumberErr = checkPhoneNumber(phoneNumber);

  if (phoneNumber) return phoneNumberErr;

  return '';
};
