/**
 * 获取客服冒号后面的内容
 *
 */
import { message } from '@/components/widgets/message';

export const getColonAfterContent = (str = ''): string => {
  const index = str.indexOf(':');
  return str.substring(index + 1, str.length);
};

export const checkIntegerWithRange = (
  num: string | number,
  range: number[]
): boolean => {
  if (typeof num !== 'string') {
    return Math.abs(num) >= range[0] && Math.abs(num) <= range[1];
  }
  return /^\d+$/.test(num) && +num >= range[0] && +num <= range[1];
};

/**
 * 判断是否是店铺主号
 */
export const isMainNick = (wangwangNick: string): boolean =>
  !wangwangNick.includes(':');

export const copy = (text: string) => {
  const input = document.createElement('textarea');
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);
  message.success('复制成功');
};

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
