import {
  OriginAgent,
  MiddleWarePresets,
  weakSharing,
  middleWare
} from 'agent-reducer';
import { takeLoading } from '@/middleWares/takeUiCache';
import { presaleActivityService } from './service';
import {
  PresaleActivityCheckForDeleteWebDto,
  PresaleActivityModifiedCommand,
  PresaleActivityWebDto
} from '@/requests/presaleActivity/type';
import { orderBy } from '@/polyfill/array';
import { RemindTimesConfigWebDto } from '@/requests/remindTimesConfig/type';

type PresaleActivityState = {
  dataSource: PresaleActivityWebDto[];
  editDataSource: PresaleActivityWebDto;
  deleteMsg: PresaleActivityCheckForDeleteWebDto;
  page: number;
  timeConfigList: RemindTimesConfigWebDto[];
  activityNameSort?: 'ascend' | 'descend';
  activityStatusSort?: 'ascend' | 'descend';
  rangeTimeSort?: 'ascend' | 'descend';
};

const remindType = 'REMIND_FINAL_PAY';

const takeLatest = MiddleWarePresets.takeLatest();

export class PresaleActivityModel implements OriginAgent<PresaleActivityState> {
  state: PresaleActivityState = {
    dataSource: [],
    editDataSource: {
      activityName: '',
      activityStatus: 'VALID',
      finalPayStart: '',
      finalPayEnd: '',
      id: 0
    },
    page: 1,
    timeConfigList: [],
    deleteMsg: {}
  };

  // 预售活动列表
  @middleWare(takeLoading(), takeLatest)
  async getPresaleActivityList() {
    const dataSource = await presaleActivityService.getPresaleActivityList();
    return { ...this.state, dataSource };
  }

  // 预售活动根据id查询
  @middleWare(takeLoading(), takeLatest)
  async findPresaleActivityById(id: number) {
    const editDataSource = await presaleActivityService.findPresaleActivityById(
      {
        id
      }
    );
    return { ...this.state, editDataSource };
  }

  // 预售活动根据id删除
  @middleWare(takeLoading(), takeLatest)
  async deletePresaleActivityById(id: number) {
    await presaleActivityService.deletePresaleActivityById({
      id
    });
    return this.getPresaleActivityList();
  }

  // 预售活动新增或者更新
  @middleWare(takeLoading(), MiddleWarePresets.takeNothing())
  async modifiedPresaleActivity(params: PresaleActivityModifiedCommand) {
    return presaleActivityService.modifiedPresaleActivity(params);
  }

  @middleWare(takeLoading(), takeLatest)
  async handleChange(page: number): Promise<PresaleActivityState> {
    return {
      ...this.state,
      page
    };
  }

  @middleWare(takeLoading(), takeLatest)
  async getTimeConfigList(): Promise<PresaleActivityState> {
    const timeConfigList = await presaleActivityService.timesConfigList(
      remindType
    );
    return {
      ...this.state,
      timeConfigList
    };
  }

  updatePresaleActivity(params: PresaleActivityWebDto): PresaleActivityState {
    const newDataSource = this.state.dataSource.map(i =>
      i.id === params.id ? params : i
    );
    return {
      ...this.state,
      dataSource: newDataSource
    };
  }

  handleTableChange(page, filter, sort) {
    const { field, order } = sort;

    if (!order) {
      return this.initSort();
    }

    const newOrder: Array<'asc' | 'desc'> =
      sort.order === 'descend' ? ['desc'] : ['asc'];
    const newDataSource =
      field === 'rangeTime'
        ? orderBy(this.state.dataSource, ['finalPayStart'], newOrder)
        : orderBy(this.state.dataSource, [field], newOrder);
    return {
      ...this.state,
      dataSource: newDataSource,
      activityNameSort: field === 'activityName' ? order : undefined,
      rangeTimeSort: field === 'rangeTime' ? order : undefined,
      activityStatusSort: field === 'activityStatus' ? order : undefined
    };
  }

  initSort() {
    return {
      ...this.state,
      activityNameSort: undefined,
      rangeTimeSort: undefined,
      activityStatusSort: undefined
    };
  }
}

export const presaleActivityRef = weakSharing(() => PresaleActivityModel);
