import {
  middleWare,
  MiddleWarePresets,
  OriginAgent,
  sharing
} from 'agent-reducer';
import { useAgentSelector } from 'use-agent-reducer';
import service from './service';
import { CategoryDto, CategoryState, TreeData } from './type';

class CategoryModel implements OriginAgent<CategoryState> {
  state: CategoryState = {
    itemCategoryList: [],
    sellerCategoryList: [],
    itemOriginList: [],
    sellerOriginList: []
  };

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async initial(): Promise<CategoryState> {
    return this.fetchCategory();
  }

  @middleWare(MiddleWarePresets.takePromiseResolve())
  async fetchCategory(): Promise<CategoryState> {
    const [itemOriginList, sellerOriginList] = await Promise.all([
      service.fetchItemCatList(),
      service.fetchSellerCatList()
    ]);
    const sellerCategoryList = service.buildItemCategoryTree(
      sellerOriginList
    ) as TreeData[];
    const itemCategoryList = service.buildItemCategoryTree(
      itemOriginList
    ) as TreeData[];
    return {
      ...this.state,
      itemOriginList,
      sellerOriginList,
      itemCategoryList,
      sellerCategoryList
    };
  }
}

export const categoryRef = sharing(() => CategoryModel);

/**
 * @description 只计算叶子节点数
 * @param {string[]} sellerCatCids
 * @returns {number}
 */
export const useCategoryNum = (
  sellerCatCids: (string | number)[] = []
): number => {
  const list = useAgentSelector(categoryRef.current, s => s.sellerOriginList);

  return calcCategoryNum(list, sellerCatCids);
};

export function calcCategoryNum(
  allSellerCatCids: CategoryDto[],
  sellerCatCids: (string | number)[] = []
): number {
  const newSellerCatCids = sellerCatCids.map(i =>
    typeof i === 'string' ? i : i.toString()
  );

  const allParenteCids = Array.from(
    new Set(allSellerCatCids.map(i => i.parentCid))
  );

  const categoryNum = newSellerCatCids?.filter(
    i => !allParenteCids.includes(i)
  ).length;

  return categoryNum;
}
