import React from 'react';
import { Icon } from '@/components/widgets/icon';
import { useDialogMethods } from '@/components/widgets/modal';
import { SubStrategySellerTradeMemoConfig } from '@/requests/remindStrategy/types';
import { SellerTradeDialog } from './sellerTradeDialog';
import { SellerTradeSelectorProps } from './type';
import css from './style.less';
import { SellerTradePop } from '@/components/works/customPop/sellerTradePop';

export const SellerTradeSelector = (props: SellerTradeSelectorProps) => {
  const { value, onChange } = props;
  const [openDialog] = useDialogMethods();

  const { filterFlags, filterKeywords, rejectKeywords } = value || {};
  const flagsCount = filterFlags?.length ?? 0;
  const filterKeywordsCount = filterKeywords?.length ?? 0;
  const rejectKeywordsCount = rejectKeywords?.length ?? 0;

  const openSellerTradeDialog = async () => {
    const result = (await openDialog(SellerTradeDialog, {
      value
    })) as SubStrategySellerTradeMemoConfig;

    if (!result && result !== null) {
      return;
    }

    onChange?.(result);
  };

  return (
    <span>
      {value ? (
        <SellerTradePop value={value}>
          已选择{flagsCount}个旗帜、{filterKeywordsCount}个包含关键词、
          {rejectKeywordsCount}个不包含关键词
        </SellerTradePop>
      ) : (
        '不限'
      )}
      <Icon
        className={css.icon}
        type="bianji"
        onClick={openSellerTradeDialog}
      />
    </span>
  );
};

export const validSellerTradeMemoConfig = (
  value?: SubStrategySellerTradeMemoConfig
): string => {
  const { filterFlags, filterKeywords, rejectKeywords } = value || {};

  if (
    !(filterFlags?.length || filterKeywords?.length || rejectKeywords?.length)
  ) {
    return '旗帜/关键词请至少填写一项';
  }

  if (
    filterKeywords?.some(v => v.length > 20) ||
    rejectKeywords?.some(v => v.length > 20)
  ) {
    return '关键词最多20字';
  }

  const same =
    !!filterKeywords?.length &&
    filterKeywords?.some(i => rejectKeywords?.includes(i));

  if (same) {
    return '包含/不包含关键词不可一致';
  }

  return '';
};
