import { ShopAfterSaleStatusConfigDto } from '@/types/afterSale';
import { TradeStatusRuleWebDto } from '@/types/tradeStatus';

export function configsToRules(
  configs: ShopAfterSaleStatusConfigDto[]
): TradeStatusRuleWebDto[] {
  return configs.map(config => {
    const { tradeStatus, tradeCloseReason } = config;
    if (tradeCloseReason) {
      return {
        tradeStatus,
        tradeStatusCondition: {
          filterType: 'CLOSE_REASON',
          tradeCloseReason
        }
      };
    }
    return { tradeStatus };
  });
}

export function rulesToConfigs(
  rules: TradeStatusRuleWebDto[]
): ShopAfterSaleStatusConfigDto[] {
  return rules.map(rule => ({
    tradeStatus: rule.tradeStatus,
    tradeCloseReason: rule.tradeStatusCondition?.tradeCloseReason
  }));
}
