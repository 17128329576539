import { Model, strict, act, flow, Flows, weakSharing } from 'agent-reducer';
import { staffService } from './service';
import { SearchParams, StaffInfo, StaffState } from './type';
import { uiPrompt } from '@/avatars';

const getDefaultSearchParams = (): SearchParams => {
  return {
    accountName: null,
    staffName: null
  };
};

@strict()
class StaffModel implements Model<StaffState> {
  state: StaffState = {
    loading: false,
    dataSource: [],
    origin: [],
    searchParams: getDefaultSearchParams(),
    staffIds: [],
    hasSyncHelperPermission: false,
    hasTrackOrderPermission: false,
    batchAddStaffDrawerVisible: false,
    page: 1,
    pageSize: 10,
    totalCount: 0
  };

  @act()
  private load(): StaffState {
    return { ...this.state, loading: true };
  }

  @act()
  private unload(): StaffState {
    return { ...this.state, loading: false };
  }

  @act()
  private changeState(state: Partial<StaffState>): StaffState {
    return { ...this.state, ...state };
  }

  @act()
  handleFormChange(value: SearchParams): StaffState {
    return {
      ...this.state,
      searchParams: value
    };
  }

  @flow()
  async handlePageChange(page: number): Promise<void> {
    this.changeState({ page });
    this.getStaffList();
  }

  @flow()
  batchAddStaffDrawerVisibleChange(visible: boolean, refresh?: boolean): void {
    this.changeState({ batchAddStaffDrawerVisible: visible });
    if (refresh) {
      this.getStaffList();
    }
  }

  @act()
  handleStaffIdsChange(staffIds: React.Key[]): StaffState {
    return { ...this.state, staffIds: staffIds as number[] };
  }

  @flow(Flows.latest())
  async initialize(): Promise<void> {
    this.load();
    const { page, pageSize } = this.state;
    try {
      const [result, config] = await Promise.all([
        staffService.staffList({ page, pageSize }),
        staffService.getAuthorizeConfig()
      ]);
      const { hasSyncHelperPermission, hasTrackOrderPermission } = config;
      const { dataList, totalCount } = result;
      const newData = dataList.map(i => ({ ...i, showPhoneNumber: false }));

      this.changeState({
        dataSource: newData,
        origin: newData,
        hasSyncHelperPermission,
        hasTrackOrderPermission,
        totalCount
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unload();
    }
  }

  @flow(Flows.latest())
  async getStaffList(): Promise<void> {
    this.load();
    const {
      searchParams: { staffName, accountName },
      page,
      pageSize
    } = this.state;
    try {
      const params = {
        staffName: staffName || null,
        accountName: accountName || null,
        page,
        pageSize
      };
      const { dataList, totalCount } = await staffService.staffList(params);
      const dataSource = dataList.map(i => ({ ...i, showPhoneNumber: false }));

      this.changeState({ dataSource, totalCount });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.unload();
    }
  }

  @act()
  changePhoneNumberVisible(id: number, value: boolean): StaffState {
    const { origin, dataSource } = this.state;

    return {
      ...this.state,
      dataSource: dataSource.map(i =>
        i.id === id ? { ...i, showPhoneNumber: value } : i
      ),
      origin: origin.map(i =>
        i.id === id ? { ...i, showPhoneNumber: value } : i
      )
    };
  }

  @flow()
  searchList(): void {
    this.changeState({ page: 1 });
    this.getStaffList();
  }

  @flow()
  handleReset() {
    this.changeState({ searchParams: getDefaultSearchParams(), page: 1 });
    this.getStaffList();
  }

  @flow(Flows.latest())
  async deleteStaff(staffId: number) {
    const { totalCount, pageSize, page } = this.state;
    try {
      const confirm = await uiPrompt.current.modalConfirm({
        title: '温馨提示',
        content: '确认删除吗？'
      });

      if (!confirm) {
        return;
      }

      await staffService.deleteStaff({ staffId });
      uiPrompt.current.success('删除成功');
      if (Math.ceil((totalCount - 1) / pageSize) < page && page > 1) {
        this.changeState({ page: page - 1 });
      }
      this.getStaffList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow()
  resetPhoneNumberVisible(): void {
    const { origin, dataSource } = this.state;

    this.changeState({
      dataSource: dataSource.map(i => ({ ...i, showPhoneNumber: false })),
      origin: origin.map(i => ({ ...i, showPhoneNumber: false }))
    });
  }

  @flow()
  async handleBatchDelete(): Promise<void> {
    const { staffIds } = this.state;

    try {
      const confirm = await uiPrompt.current.modalConfirm({
        title: '温馨提示',
        content: '确认删除吗？'
      });

      if (!confirm) {
        return;
      }
      await staffService.batchDeleteStaff({ staffIds });
      uiPrompt.current.success('批量删除成功');
      this.changeState({ page: 1 });
      this.handleStaffIdsChange([]);
      this.getStaffList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }
}

export const staffModelRef = weakSharing(() => StaffModel);
