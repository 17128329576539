import React, {
  memo,
  useState,
  useContext,
  createContext,
  useRef
} from 'react';
import moment from 'moment';
import classNames from 'classnames';
import useResizeObserver from '@/hooks/useResizeObserver';
import { Tooltip } from '@/components/widgets/tooltip';
import { TimeBarContainerProps, TimeBarProps, TimeRulerProps } from './type';
import css from './style.less';

const Context = createContext(0);

export const TimeBarContainer = memo((props: TimeBarContainerProps) => {
  const { children, className, style } = props;

  const [width, setWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useResizeObserver(ref, size => setWidth(size.inlineSize));

  return (
    <div
      ref={ref}
      className={classNames(css.container, className)}
      style={style}
    >
      <Context.Provider value={width}>{children}</Context.Provider>
    </div>
  );
});

export const TimeBar = memo((props: TimeBarProps) => {
  const { className, style, bgColor, start, end, tooltip } = props;

  const width = useContext(Context);

  // 1440: 一天的分钟数
  const offest = width / 1440;

  const base = moment(start).set({ hours: 0, minutes: 0 });

  const startMoment = start
    ? moment(start)
    : moment(base).set({ hours: 0, minutes: 0 });

  const endMoment = end
    ? moment(end)
    : moment(base).set({ hours: 23, minutes: 59 });

  const left = startMoment.diff(base, 'minutes');
  const right = endMoment.diff(base, 'minutes');
  const widthPx = (right - left) * offest;

  return (
    <Tooltip title={tooltip}>
      <div
        className={classNames(css.bar, className)}
        style={{
          left: left * offest,
          width: widthPx,
          backgroundColor: bgColor,
          ...style
        }}
      />
    </Tooltip>
  );
});

export const TimeRuler = memo((props: TimeRulerProps) => {
  const { className } = props;

  const base = moment({ hours: 21, minutes: 0 });

  const times = Array(9)
    .fill('')
    .map((v, i) => base.add(3, 'hours').format(i === 0 ? 'HH:mm' : 'kk:mm'));

  return (
    <div className={classNames(css.ruler, className)}>
      {times.map((val, i) => (
        <div key={val} className={css.rulerItem}>
          <div>{val}</div>
          <div>|</div>
        </div>
      ))}
    </div>
  );
});
