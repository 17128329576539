import { BrowserLoginParams } from '@/requests/webApi/type';

export const check = (params: BrowserLoginParams) => {
  const { accountName, password, captchaCode } = params;

  if (!accountName) {
    return '请输入登录账号';
  }

  if (!password) {
    return '请输入登录密码';
  }

  if (!captchaCode) {
    return '请输入验证码';
  }
};

export const trimObject = (obj, keysToExclude: string[] = []) => {
  const trimmedParams = { ...obj };
  Object.keys(obj).forEach(key => {
    if (!keysToExclude.includes(key)) {
      trimmedParams[key] =
        typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
    }
  });
  return trimmedParams;
};
