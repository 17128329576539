import React, {
  Component,
  Context,
  createElement,
  Fragment,
  PureComponent,
  useContext,
  useEffect,
  useRef,
  ComponentType,
  FunctionComponent,
  ReactNode
} from 'react';
import { ConfigProvider, Modal as AntModal } from 'antd';
import zh_CN from 'antd/es/locale/zh_CN';

import { ButtonProps, ButtonType } from 'antd/lib/button';
import { Button, Submit } from '@/components/widgets/button';
import css from './style.less';

enum DialogStatus {
  open,
  close
}

type FnCallback = (...args: any[]) => any;

interface DialogProps {
  readonly title: string;
  readonly bodyStyle?: Record<string, any>;
  readonly visible?: boolean;
  readonly footer?: ReactNode | boolean;
  readonly onOk?: (e: React.MouseEvent<any>) => void;
  readonly okText?: string;
  readonly cancelText?: string;
  readonly onCancel?: () => void;
  readonly onFootCancel?: () => void;
  readonly children: ReactNode;
  readonly width?: number | string;
  readonly wrapClassName?: string;
  readonly okButtonProps?: ButtonProps;
  readonly cancelButtonProps?: ButtonProps;
  readonly closable?: boolean;
  readonly params?: any;
  readonly okType?: ButtonType;
  readonly dbMs?: number; // useLeadingDebounceFn(fn,dbMs) 传参意味者启动防抖
  readonly getContainer?:
    | string
    | HTMLElement
    | getContainerFunc
    | false
    | null;
  readonly maskStyle?: React.CSSProperties;
}

declare type getContainerFunc = () => HTMLElement;

interface DialogBinder {
  runResolvers: (...args: any[]) => any;
}

declare type FCLike<T = void> = (props: DialogDeployProps<T>) => JSX.Element;

class StatusResolver<T, P> {
  type: FCLike<T> | ComponentType<DialogDeployProps<T>>;

  status: DialogStatus;

  resolve?: FnCallback;

  params?: T | P;

  constructor(
    type: FCLike<T> | ComponentType<DialogDeployProps<T>>,
    status: DialogStatus,
    resolve: FnCallback,
    params?: T | P
  ) {
    this.type = type;
    this.status = status;
    this.resolve = resolve;
    this.params = params;
  }

  changeStatus(status: DialogStatus, params?: T | P, resolve?: FnCallback) {
    this.status = status;
    if (resolve) {
      this.params = params !== undefined ? (params as T) : undefined;
      this.resolve = resolve;
      return;
    }
    if (this.resolve) {
      this.resolve(params);
      this.resolve = undefined;
    }
  }

  destroy() {
    if (this.resolve) {
      this.resolve();
      this.params = undefined;
      this.resolve = undefined;
    }
  }
}

class DialogResolver {
  private statusResolvers: Array<StatusResolver<any, any>> = [];

  put<T, P>(
    newType: FCLike<T> | ComponentType<DialogDeployProps<T>>,
    status: DialogStatus,
    params?: P,
    resolve?: FnCallback
  ): void {
    const sameTypeResolver = this.statusResolvers.find(
      ({ type }) => type === newType
    );
    if (sameTypeResolver) {
      sameTypeResolver.changeStatus(status, params, resolve);
      return;
    }
    if (resolve) {
      this.statusResolvers.push(
        new StatusResolver(newType, status, resolve, params)
      );
    }
  }

  destroy(): void {
    this.statusResolvers.forEach(resolver => {
      resolver.destroy();
    });
    this.statusResolvers = [];
  }

  getStatusResolvers(): Array<StatusResolver<any, any>> {
    return this.statusResolvers;
  }
}

/**
 * dialog操作器，拥有openDialog打开弹窗，closeDialog关闭弹窗方法
 */
export class DialogDeploy {
  private dialogResolver: DialogResolver = new DialogResolver();

  private binder?: DialogBinder;

  register(binder: DialogBinder): void {
    this.binder = binder;
    this.resolve();
  }

  private resolve(): void {
    const { binder } = this;
    if (!binder) {
      return;
    }
    binder.runResolvers();
  }

  /**
   * 打开弹窗,返回Promise
   *
   * @param type  需要打开组件的class或function
   * @param params  窗口入参，将映射到窗口组件的props.params字段上
   */
  openDialog = <T, R>(
    type: FCLike<T> | ComponentType<DialogDeployProps<T>>,
    params?: T
  ): Promise<R> =>
    new Promise<R>(resolve => {
      this.dialogResolver.put(type, DialogStatus.open, params, resolve);
      this.resolve();
    });

  /**
   * 关闭窗口，必须先调用openDialog，该方法是对openDialog返回Promise的resolve
   *
   * @param type  需要关闭组件的class或function
   * @param params  窗口关闭出参，通过resolve同type的Promise（通过openDialog产生的）传出数据
   */
  closeDialog = <P, T>(
    type: FCLike<T> | ComponentType<DialogDeployProps<T>>,
    params?: P
  ): void => {
    this.dialogResolver.put(type, DialogStatus.close, params);
    this.resolve();
  };

  destroy(): void {
    this.dialogResolver.destroy();
    this.resolve();
  }

  getStatusResolvers(): Array<StatusResolver<any, any>> {
    return this.dialogResolver.getStatusResolvers();
  }
}

class ContextDriver {
  visibleChange?: FnCallback;

  closeAfterChange?: FnCallback;

  visible: boolean;

  constructor(visible: boolean) {
    this.visible = visible;
  }

  subscribeVisibleChange = visibleChange => {
    this.visibleChange = visibleChange;
  };

  subscribeCloseAfterChange = closeAfterChange => {
    this.closeAfterChange = closeAfterChange;
  };
}

const DialogsContext: Context<ContextDriver> = React.createContext(
  new ContextDriver(false)
);

interface DialogHookProps<T = any> {
  readonly params: T;
  readonly controller: DialogDeploy;
  readonly visible?: boolean;
  readonly type: ComponentType<DialogDeployProps<T>>;
}

interface DialogHookState {
  readonly isDialogClosed: boolean;
}

/**
 * 为了让使用者可以将Dialog手写到自定义组件中，又不希望通过props透传visible这样的被托管信息数据（增加灵活性和易用度），
 * 还要在弹窗完全关闭后模拟组件销毁功能（注意弹窗Dialog关闭只能销毁Dialog的children，而使用Dialog的class实例不能被销毁），
 * 需要有DialogHook这样采用Context技术的中间垫片层组件，通过Context直接和调用者使用的Dialog进行对话管理visible状态，
 * 并通过监听Dialog的afterClose去检测Dialog是否完全关闭，如果完全关闭则代替用户销毁整个class实例
 */
class DialogHook<T = any> extends Component<
  DialogHookProps<T>,
  DialogHookState
> {
  driver: ContextDriver;

  constructor(props) {
    super(props);
    this.state = { isDialogClosed: !props.visible };
    this.driver = new ContextDriver(props.visible);
    /**
     * @afterClose
     *
     * 针对ant design弹窗组件，因为visible字段仅仅指明了是否需要显示弹窗，
     * 并不能监听到弹窗是否真的已经完全关闭，因为ant design modal组件关闭的动画效果，所以需要使用antd的afterClose回调
     */
    this.driver.subscribeCloseAfterChange(this.closeAfterChange);
  }

  /**
   * 因为Context技术只能保证context resume组件componentDidMount时获取数据的正确性，
   * 所以context只能提供方法和初始数据，后续数据的变化过程需要通过方法来传递
   * @param prevProps
   */
  componentDidUpdate(prevProps: DialogHookProps<T>) {
    const { visible } = this.props;
    const { visibleChange } = this.driver;
    if (prevProps.visible !== visible && visibleChange) {
      visibleChange(visible);
    }
    /**
     * 打开弹窗设置完全关闭状态为false
     */
    if (!prevProps.visible && visible) {
      /* eslint-disable-next-line */
      this.setState({ isDialogClosed: false });
    }
  }

  /**
   * Dialog组件的afterClose方法通过context回传的通知方法
   */
  closeAfterChange = () => {
    this.setState({ isDialogClosed: true });
  };

  /**
   * 为用户组件提供关闭方法
   *
   * @param params  可选出参
   */
  handleCancel = (params?: any) => {
    const { type, controller } = this.props;
    controller.closeDialog(type, params);
  };

  /**
   * 通过组件的visible状态和isDialogClosed（弹窗是否完全关闭状态）来判断是否销毁组件，还是渲染组件
   */
  renderChild() {
    const { isDialogClosed } = this.state;
    const { type, visible, params, controller } = this.props;
    const TargetComponent = type;
    return visible || !isDialogClosed ? (
      <TargetComponent
        params={params}
        controller={controller}
        type={type}
        closeDialog={this.handleCancel}
      />
    ) : null;
  }

  render() {
    const child = this.renderChild();
    return (
      <DialogsContext.Provider value={this.driver}>
        {child}
      </DialogsContext.Provider>
    );
  }
}

export interface DialogsProps {
  readonly dialogDeploy: DialogDeploy;
}

/**
 * dialog管理器高阶组件，加上该高阶组件后，通过高阶组件入参control.openDialog打开的弹窗都会跟随该高阶组件一起销毁，
 * 对整个app的顶层组件加上该高阶组件，并不传入control（相当于使用默认全局control）,这时候便开启了全局模式，
 * 全局模式的弹窗随location的变化自动销毁
 *
 * @param control （可选）不传则开启全局模式；传入new DialogController
 */
function withDialogs<P extends Record<string, unknown>>(
  TargetComponent:
    | FunctionComponent<P & DialogsProps>
    | ComponentType<P & DialogsProps>
): ComponentType<P> {
  return class DialogHookProxy extends Component<P> {
    controller = new DialogDeploy();

    constructor(props: P) {
      super(props);
      this.state = {};
    }

    componentDidMount() {
      this.controller.register({
        runResolvers: this.runResolvers
      });
    }

    componentWillUnmount() {
      this.controller.destroy();
    }

    runResolvers = () => {
      this.setState({});
    };

    renderDialogs = () => {
      const { controller } = this;
      const statusResolvers: Array<StatusResolver<any, any>> =
        controller.getStatusResolvers();
      return statusResolvers.map(({ status, type, params }, index) => (
        <DialogHook
          visible={status === DialogStatus.open}
          type={type}
          params={params}
          key={`${index.toString()}`}
          controller={controller}
        />
      ));
    };

    render() {
      const { props } = this;
      const dialogs = this.renderDialogs();
      return (
        <>
          <TargetComponent {...(props as P)} dialogDeploy={this.controller} />
          {dialogs}
        </>
      );
    }
  };
}

const GlobalContent: Context<DialogDeploy> = React.createContext(
  new DialogDeploy()
);

export function withGlobalDialogs<P extends Record<string, unknown>>(
  TargetComponent:
    | FunctionComponent<P & DialogsProps>
    | ComponentType<P & DialogsProps>
): ComponentType<P> {
  class Global extends PureComponent<P & DialogsProps> {
    dialogDeploy: DialogDeploy;

    constructor(props) {
      super(props);
      this.dialogDeploy = props.dialogDeploy;
    }

    render() {
      const { props } = this;
      return createElement(
        GlobalContent.Provider,
        { value: this.dialogDeploy },
        createElement(TargetComponent, props)
      );
    }
  }

  return withDialogs(Global);
}

export const useDialogMethods = <R, P = any, T = any>(): [
  (
    type: FCLike<T> | ComponentType<DialogDeployProps<T>>,
    params?: T
  ) => Promise<R>,
  (type: FCLike<T> | ComponentType<DialogDeployProps<T>>, params?: P) => void
] => {
  const openings = useRef(
    new Set<FCLike<T> | ComponentType<DialogDeployProps<T>>>()
  );
  const deploy = useContext(GlobalContent);

  useEffect(
    () => () => {
      openings.current.forEach(type => {
        deploy.closeDialog(type);
      });
    },
    []
  );

  async function openDialog(
    type: FCLike<T> | ComponentType<DialogDeployProps<T>>,
    params?: T
  ): Promise<R> {
    openings.current.add(type);
    const result: R = await deploy.openDialog(type, params);
    if (openings.current.has(type)) {
      openings.current.delete(type);
    }
    return result;
  }

  function closeDialog(
    type: FCLike<T> | ComponentType<DialogDeployProps<T>>,
    params?: P
  ) {
    return deploy.closeDialog(type, params);
  }

  return [openDialog, closeDialog];
};

const renderDefaultFooter = (
  onOk?: (e: React.MouseEvent<any>) => void,
  onCancel?: () => any,
  okText = '确定',
  cancelText = '取消',
  okButtonProps?: ButtonProps,
  cancelButtonProps?: ButtonProps,
  dbMs?: number
) => {
  const handleCancel = () => {
    if (!onCancel) {
      return;
    }
    onCancel();
  };

  const okDisabled = okButtonProps && okButtonProps.disabled;
  const cancelDisabled = cancelButtonProps && cancelButtonProps.disabled;

  return (
    <div className={css.footer}>
      {cancelText && (
        <Button
          className={css.button}
          onClick={handleCancel}
          disabled={cancelDisabled}
        >
          {cancelText}
        </Button>
      )}
      {okText && (
        <Submit
          className={css.button}
          dbMs={dbMs}
          onClick={onOk}
          disabled={okDisabled}
        >
          {okText}
        </Submit>
      )}
    </div>
  );
};

/**
 * ant design modal的替代品
 *
 * 1、用于判断dialog是否完全关闭，方便 DialogHook 销毁完全关闭的弹窗组件
 * 2、onCancel绕过了modal的onCancel，因此即便把closeDialog方法赋值给props.onCancel也不会在关闭的时候接到一个htmlEvent对象
 * 3、统一托管visible状态，使用者不需要为其赋props值
 */
export class Dialog extends Component<DialogProps, { visible: boolean }> {
  constructor(props: DialogProps) {
    super(props);
    this.state = { visible: false };
  }

  componentDidMount(): void {
    const { visible, subscribeVisibleChange } = this.context;
    subscribeVisibleChange(this.visibleChange);
    this.visibleChange(visible);
  }

  visibleChange = (visible: boolean): void => {
    this.setState({ visible });
  };

  handleCancel = (): void => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
  };

  render(): ReactNode {
    const { context } = this;
    const { visible } = this.state;
    const {
      onOk,
      onCancel,
      onFootCancel,
      okText,
      cancelText,
      okButtonProps,
      cancelButtonProps,
      footer,
      dbMs,
      ...props
    } = this.props;
    const foo =
      footer !== undefined
        ? footer
        : renderDefaultFooter(
            onOk,
            onFootCancel || onCancel,
            okText,
            cancelText,
            okButtonProps,
            cancelButtonProps,
            dbMs
          );
    return (
      <ConfigProvider locale={zh_CN}>
        <AntModal
          visible={visible}
          {...props}
          footer={foo}
          onCancel={this.handleCancel}
          destroyOnClose
          afterClose={context.closeAfterChange}
          maskClosable={false}
          keyboard={false}
          centered
        />
      </ConfigProvider>
    );
  }
}

Dialog.contextType = DialogsContext;

/**
 * 弹窗组件class的props类型
 */
export interface DialogDeployProps<T = void> {
  // 入参
  readonly params: T;
  // 组件class类型，==当前使用的class，用来避免model引class,class又引model的情况
  readonly type: ComponentType<DialogDeployProps<T>>;
  // 当前的DialogController实例，有openDialog/closeDialog方法，
  // 如果使用的全局模式，那这项就没什么意义了
  readonly controller: DialogDeploy;
  // 关闭弹窗方法
  readonly closeDialog: (e?: any) => void;
}
