import React, { Fragment, memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@/components/widgets/button';
import { Icon } from '@/components/widgets/icon';
import { Input } from '@/components/widgets/input';
import { usePersistFn } from '@/hooks/usePersistFn';
import {
  DisplayStatusProps,
  EditableStatusProps,
  EditableTextProps
} from './type';
import css from './style.less';
import { Popconfirm } from '@/components/widgets/popconfirm';
import useDebounceFn from '@/hooks/useDebounceFn';
import AutoTooltipText from '@/components/widgets/autoTooltipText';

const EditableStatus = memo((props: EditableStatusProps) => {
  const { value, errMsg, inputOptions, onOk, onCancel } = props;
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value || '');
  }, [value]);

  const handleSave = usePersistFn(() => onOk(text));

  return (
    <div className={css.editWrapper}>
      <div className={classNames(css.input, errMsg ? css.errInput : '')}>
        <Input autoFocus value={text} onChange={setText} {...inputOptions} />
        {errMsg ? <span className={css.errMsg}>{errMsg}</span> : null}
      </div>
      <div>
        <Button
          onClick={handleSave}
          type="link"
          icon={<Icon type="queding" />}
        />
        <Button onClick={onCancel} type="link" icon={<Icon type="guanbi" />} />
      </div>
    </div>
  );
});

const DisplayStatus = memo((props: DisplayStatusProps) => {
  const {
    value,
    extraAction,
    deleteConfirmTip,
    hiddenAction,
    onClick,
    onEdit,
    onDelete
  } = props;

  return (
    <div className={css.displayWrapper}>
      <div className={css.text} onClick={onClick}>
        <AutoTooltipText>{value}</AutoTooltipText>
      </div>
      {hiddenAction ? null : (
        <div className={css.displayBtns}>
          {extraAction}
          <Button onClick={onEdit} type="link" icon={<Icon type="bianji" />} />
          <Popconfirm
            title={deleteConfirmTip}
            okText="确定"
            cancelText="取消"
            onConfirm={onDelete}
          >
            <Button type="link" icon={<Icon type="shanchu" />} />
          </Popconfirm>
        </div>
      )}
    </div>
  );
});

export const EditableText = memo((props: EditableTextProps) => {
  const {
    value,
    edit = false,
    deleteConfirmTip = '确定删除吗？',
    className,
    inputOptions,
    extraAction,
    activeStyle,
    hiddenAction = false,
    onOk,
    onCancel,
    onClick,
    onDelete,
    validate
  } = props;

  const [editing, setEditing] = useState(edit);
  const [errMsg, setErrMsg] = useState('');

  const handleOk = useDebounceFn(async (v: string) => {
    const err = await validate?.(v);
    if (err) {
      setErrMsg(err);
      return;
    }

    setEditing(false);
    onOk?.(v);
  });

  const handleCancel = () => {
    setEditing(false);
    setErrMsg('');
    onCancel?.();
  };

  const handleClick = () => {
    onClick?.();
  };

  const handleDelete = () => {
    onDelete?.();
  };

  return (
    <div
      className={classNames(css.editableTextWrapper, className)}
      style={activeStyle}
    >
      {editing ? (
        <EditableStatus
          value={value}
          errMsg={errMsg}
          inputOptions={{
            ...inputOptions,
            onFocus: () => setErrMsg('')
          }}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : (
        <DisplayStatus
          value={value}
          deleteConfirmTip={deleteConfirmTip}
          extraAction={extraAction}
          hiddenAction={hiddenAction}
          onClick={handleClick}
          onEdit={() => setEditing(true)}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
});

export default EditableText;
