import { ItemListMode } from './type';

export const placeholderMap: Record<ItemListMode, string> = {
  SPU: '商品标题/商品货号/商品编码',
  SKU: '商品标题/商家SKU/商品SKU编号'
};

export const tooltipMap: Record<ItemListMode, string> = {
  SPU: '支持批量搜索商品编码、商品货号，最多支持同时搜索50个商品，多个请使用换行分隔',
  SKU: '支持批量搜索商品SKU编号、商家SKU，最多支持同时搜索50个商品，多个请使用换行分隔'
};

export const approveStatusMap: Record<string, string> = {
  onsale: '出售中',
  instock: '仓库中'
};
