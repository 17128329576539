import React, { memo, ReactNode, useCallback, useRef } from 'react';
import { Button as SourceButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { useFormSubmit } from '@/components/widgets/formLike';
import useLeadingDebounceFn from '@/hooks/useLeadingDebounceFn';

export const Button = memo((props: ButtonProps) => {
  const {
    type,
    style = type === 'link'
      ? {
          padding: '4px',
          color: props.disabled ? '#cccccc' : '#4a80f6'
        }
      : { margin: '0 6px ' },
    ...rest
  } = props;
  return <SourceButton {...props} {...rest} style={style} />;
});

export const Submit = memo((props: ButtonProps & { dbMs?: number }) => {
  const {
    type,
    style = type === 'link'
      ? {
          padding: '4px',
          color: props.disabled ? '#cccccc' : '#4a80f6'
        }
      : { margin: '0 6px ' },
    dbMs,
    onClick,
    ...rest
  } = props;

  const debounceClick = useLeadingDebounceFn(onClick!, dbMs);

  return (
    <SourceButton
      type="primary"
      {...rest}
      onClick={dbMs ? debounceClick : onClick}
      style={style}
    />
  );
});

export const FormSubmit = memo(({ children }: { children: ReactNode }) => {
  const submit = useFormSubmit();

  const handleClick = useCallback(() => {
    submit(s => s);
  }, [submit]);

  return <Submit onClick={handleClick}>{children}</Submit>;
});
