import { OriginAgent, weakSharing } from 'agent-reducer';
import { GoodsSelectModalProps, State } from './type';
import { EsItem, SkuWebDto, SpuItemDto } from '@/types/esItem';

const getDefaultState = (props: GoodsSelectModalProps): State => ({
  selectItems: props.items ? props.items : [],
  selectSkus: props.skus ? props.skus : [],
  selectSellerCatList: props.sellerCatCids ? props.sellerCatCids : [],
  sellerCatNames: [],
  selectItemGroupIds: props.itemGroupIds ? props.itemGroupIds : [],
  itemGroupNames: []
});

export class GoodsSelectModalModel implements OriginAgent<State> {
  state: State;

  constructor(props: GoodsSelectModalProps) {
    this.state = getDefaultState(props);
  }

  handleItemChange(props: { items: SpuItemDto[]; skus: SkuWebDto[] }) {
    const { items, skus } = props;
    const { selectItems, selectSkus } = this.state;
    return {
      ...this.state,
      selectItems: items || selectItems,
      selectSkus: skus || selectSkus
    };
  }

  handleSellerCatCidsChange(e, checkInfo?: any) {
    return {
      ...this.state,
      selectSellerCatList: e,
      sellerCatNames: checkInfo?.checkedNodes.map(i => i.title)
    };
  }

  handleItemGroupIdsChange(selectItemGroupIds: number[], checkInfo?: any) {
    return {
      ...this.state,
      selectItemGroupIds,
      itemGroupNames: checkInfo?.checkedNodes.map(i => i.title)
    };
  }
}

export const goodsSelectModalModelRef = weakSharing(
  (props: GoodsSelectModalProps) => new GoodsSelectModalModel(props)
);
