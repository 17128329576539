import {
  KeyGenerator,
  CompareKeyLike,
  CompareValueGenerator,
  Comparator,
  ComparableSerial
} from './type';

export function partition<T>(
  array: Array<T>,
  callback: (e: T, i: number) => boolean
): [Array<T>, Array<T>] {
  const [matches, rest] = [[], []] as [Array<T>, Array<T>];
  array.forEach((e: T, i: number) => {
    if (callback(e, i)) {
      matches.push(e);
    } else {
      rest.push(e);
    }
  });
  return [matches, rest];
}

export function groupBy<T, K>(
  array: Array<T>,
  callback: KeyGenerator<T, K> | keyof T
): Map<K, Array<T>> {
  const newMap = new Map<K, Array<T>>();
  array.forEach((e: T, i: number) => {
    const keyData =
      typeof callback === 'function' ? callback(e, i) : e[callback];
    const key = keyData as K;
    const value = newMap.get(key);
    if (value === undefined) {
      newMap.set(key as K, [e]);
    } else {
      value.push(e);
    }
  });
  return newMap;
}

export function keyBy<T>(
  array: T[],
  keySelector: ((item: T) => string | number) | keyof T
): Record<string, T> {
  return array.reduce((result: Record<string, T>, item: T) => {
    const key =
      typeof keySelector === 'function' ? keySelector(item) : item[keySelector];
    result[String(key)] = item;
    return result;
  }, {});
}

export function chunk<T>(array: Array<T>, size: number): Array<T[]> {
  const totalPages = Math.ceil(array.length / size);
  const struct: Array<undefined> = Array.from({ length: totalPages });
  return array.reduce(
    (result: Array<T[] | undefined>, current: T, index: number) => {
      const resultIndex = Math.floor(index / size);
      return result.map((u, i) => {
        if (i !== resultIndex) {
          return u;
        }
        return u ? [...u, current] : [current];
      });
    },
    struct
  ) as Array<T[]>;
}

function compare(value: any, other: any): 1 | -1 {
  const valIsUndefined = value === undefined;
  const valIsNull = value === null;
  const valIsNaN = Number.isNaN(value);

  const othIsUndefined = other === undefined;
  const othIsNull = other === null;
  const othIsNaN = Number.isNaN(other);

  if (valIsUndefined || valIsNaN || valIsNull) {
    return -1;
  }
  if (othIsUndefined || othIsNaN || othIsNull) {
    return 1;
  }
  return value > other ? 1 : -1;
}

function sort(obj: ComparableSerial, other: ComparableSerial) {
  const index = obj.comparators.findIndex(
    ({ value }, i) => value !== other.comparators[i].value
  );
  if (index < 0) {
    return obj.index - other.index;
  }
  const asc = compare(
    obj.comparators[index].value,
    other.comparators[index].value
  );
  return obj.comparators[index].order === 'desc' ? -asc : asc;
}

function sortIndex<T>(array: Array<T>, comparators: Array<Comparator<T>>) {
  const is = array.map((d, i) => {
    const comparatorArray = comparators.map(({ order, keyLike }) => ({
      value: keyLike(d, i),
      order
    }));
    return { comparators: comparatorArray, index: i };
  }) as Array<ComparableSerial>;
  is.sort((a, b) => sort(a, b));
  return is;
}

export function orderBy<T>(
  array: Array<T>,
  by: Array<CompareKeyLike<T>>,
  orders: Array<'asc' | 'desc'> = []
): Array<T> {
  const comparators = by.map((c, i) => {
    const order = orders[i] || 'asc';
    if (typeof c !== 'function') {
      return {
        order,
        keyLike(e: T, ind: number) {
          return e[c];
        }
      };
    }
    return { keyLike: c, order };
  }) as Array<Comparator<T>>;
  const indexes = sortIndex(array, comparators);
  return indexes.map(({ index }) => array[index]);
}

export function map<T, K>(
  array: T[] | undefined | null,
  callback: KeyGenerator<T, K> | keyof T
): K[] {
  const result: K[] = [];
  if (array && array.length) {
    return array.map((e: T, i: number) =>
      typeof callback === 'function'
        ? callback(e, i)
        : (e[callback] as T[keyof T])
    ) as K[];
  }
  return result;
}

export function arrayMoveImmutable<T = unknown>(
  arr: T[],
  oldIndex: number,
  newIndex: number
): T[] {
  const temp = arr[oldIndex];
  const newArr = arr.slice();
  newArr[oldIndex] = arr[newIndex];
  newArr[newIndex] = temp;
  return newArr;
}

export function intersection<T>(left: T[], right: T[]): T[] {
  const countMap = new Map<T, number>();
  const all = [...left, ...right];

  all.forEach(val => {
    const count = countMap.get(val);
    countMap.set(val, count !== undefined ? count + 1 : 1);
  });

  return [...countMap.entries()]
    .filter(([_, count]) => count > 1)
    .map(([key]) => key);
}

export function replaceAll(
  originStr: string,
  str: string,
  newStr: string
): string {
  // If a regex pattern
  if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
    return originStr.replace(str, newStr);
  }

  // If a string
  return originStr.replace(
    new RegExp(str.replace(/[{}]/g, '\\$&'), 'g'),
    newStr
  );
}
