import { RangePickerProps } from 'antd/lib/date-picker';
import React from 'react';

export type ShortcutOption = {
  label: string;
  value: [number, number];
};

export type OptionProps = {
  value: ShortcutOption;
  selected: ShortcutOption | null;
  onSelect: (value: ShortcutOption) => any;
};

export interface CustomRangePickerProps
  extends Omit<RangePickerProps, 'value' | 'onChange'> {
  value?: [string, string];
  options?: ShortcutOption[];
  version?: number;
  onChange?: (value: [string | undefined, string | undefined]) => any;
  onSubmit?: (value: [string | undefined, string | undefined]) => any;
  maxDay?: number;
  extraFooter?: React.ReactNode;
}

export class DateRange {
  from: string;

  to: string;

  constructor(from: string, to: string) {
    this.from = from;
    this.to = to;
  }
}
