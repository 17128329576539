import React, { memo } from 'react';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { Button } from '@/components/widgets/button';
import css from './style.less';

export const TipDialog = memo(
  (
    props: DialogDeployProps<{
      successCount: number;
      notExistSubAccounts: string[];
    }>
  ) => {
    const {
      closeDialog,
      params: { successCount, notExistSubAccounts }
    } = props;

    return (
      <Dialog
        title="操作结果"
        onCancel={closeDialog}
        width={540}
        onOk={closeDialog}
        footer={
          <Button type="primary" onClick={closeDialog}>
            确定
          </Button>
        }
      >
        <div>
          <div style={{ marginBottom: '12px' }}>
            <span className={css.success}>{successCount}</span>
            个子账号操作成功，以下
            <span className={css.error}> {notExistSubAccounts.length}</span>
            个子账号由于不存在操作失败：
          </div>

          {notExistSubAccounts.length
            ? notExistSubAccounts.map(i => <div>{i}</div>)
            : null}
        </div>
      </Dialog>
    );
  }
);
