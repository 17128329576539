/* eslint-disable no-nested-ternary */
import { SharingModel } from '@/modules/type';
import { connect } from 'agent-reducer';
import { cacheRef } from './cache';
import { accountRef } from './account';
import { addressRef } from './address';
import { categoryRef } from './category';
import { appKeyModelRef } from './appkey';
import { wwGroupListRef } from './wwGroupList';
import { shopModelRef } from './shop';
import { isLogin } from '@/libs/api';

export const initial = (): Promise<any[]> => {
  const modules = [];
  const initialPromises = modules.map(async (sharing): Promise<any> => {
    const model = sharing.current as SharingModel;
    return connect(model).run(agent => {
      const { initial: agentInitial } = agent;
      if (!agentInitial) {
        return null;
      }
      return agentInitial();
    });
  });
  return Promise.all(initialPromises);
};

export function createInitial(params) {
  return async function initial(
    modules?: { current: SharingModel }[]
  ): Promise<any> {
    const { pageAuthority } = params;
    const actual = isLogin(window.location.pathname)
      ? []
      : modules || pageAuthority !== 'mainAccount'
      ? [
          cacheRef,
          accountRef,
          addressRef,
          categoryRef,
          appKeyModelRef,
          wwGroupListRef,
          shopModelRef
        ]
      : [cacheRef];
    const initialPromises = actual.map(async (sharing): Promise<any> => {
      const model = sharing.current as SharingModel;
      return connect(model).run(agent => {
        const { initial: agentInitial } = agent;
        if (!agentInitial) {
          return null;
        }
        return agentInitial(params);
      });
    });
    return Promise.all(initialPromises);
  };
}
