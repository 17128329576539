import { useEffect } from 'react';
import {
  strict,
  act,
  flow,
  Flows,
  Model,
  weakSharing,
  avatar
} from 'agent-reducer';
import { useAgentReducer } from 'use-agent-reducer';
import type { SearchQuery, State } from './type';
import { itemGroupRequest } from '@/requests/itemGroups';
import {
  DeleteGroupItemCmd,
  GroupItemQuery,
  GroupItemUpdateCmd,
  SearchType
} from '@/requests/itemGroups/type';
import { createEmptyKey, isEmptyKey, transformGropList } from './util';
import { uiPrompt } from '@/avatars';
import { reduceParentKey } from '@/utils/tree';
import { editInputClassName } from './components/group';
import { adjustPageAfterDelete } from '@/utils/pagination';
import { noop } from '@/polyfill/func';
import { maxNum } from '@/constants/maxNum';
import { testString } from '@/utils/regUtils';
import { getItem } from '@/utils/storage';
import { Platform } from '@/types/platform';

const defaultSearchQuery = {
  approveStatus: 'ALL',
  title: undefined,
  taobaoIid: undefined
} as SearchQuery;

const defaultTableQuery = {
  page: 1,
  pageSize: 10
};

@strict()
class ItemGroupModel implements Model<State> {
  state: State = {
    itemLoading: false,
    groupLoading: false,
    searchType: 'GROUP_NAME',
    keyword: '',
    originGroupTree: [],
    groupTree: [],
    expandedKeys: [],
    searchQuery: defaultSearchQuery,
    tableQuery: defaultTableQuery,
    itemList: [],
    total: 0
  };

  prompt = avatar({
    warnModel: (actionType, usingFeats) => {
      noop();
    }
  });

  @act()
  groupLoad() {
    return { ...this.state, groupLoading: true };
  }

  @act()
  groupUnLoad() {
    return { ...this.state, groupLoading: false };
  }

  @act()
  itemLoad() {
    return { ...this.state, itemLoading: true };
  }

  @act()
  itemUnLoad() {
    return { ...this.state, itemLoading: false };
  }

  @act()
  changeState(newState: Partial<State>) {
    return { ...this.state, ...newState };
  }

  @act()
  handleChangeSearchType(v: SearchType) {
    return { ...this.state, searchType: v };
  }

  @act()
  handleChangeKeyword(v: string) {
    return { ...this.state, keyword: v };
  }

  @act()
  handleFormChange(formValue) {
    return { ...this.state, searchQuery: { ...formValue } };
  }

  @act()
  hanldeSelectItemGroup(key: React.Key): State {
    return {
      ...this.state,
      currentGroupId: key,
      tableQuery: defaultTableQuery,
      searchQuery: defaultSearchQuery
    };
  }

  @flow(Flows.latest())
  async init() {
    await this.fetchGroup();
    const { groupTree } = this.state;

    this.changeState({ originGroupTree: groupTree });
  }

  // 商品组fetch
  @flow(Flows.latest())
  async fetchGroup() {
    this.groupLoad();
    try {
      const { searchType, keyword } = this.state;

      if (
        searchType === 'TAOBAO_IID' &&
        keyword &&
        (!testString(keyword, 5) || BigInt(keyword) > BigInt(maxNum))
      ) {
        uiPrompt.current.warning('请输入正确的商品ID');
        return;
      }

      const groupList = await itemGroupRequest.searchItemProups({
        searchType,
        keyword: keyword || undefined
      });

      const groupTree = transformGropList(groupList);
      const expandedKeys = groupTree.map(g => g.key);

      this.changeState({
        groupTree,
        expandedKeys,
        currentGroupId: undefined
      });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.groupUnLoad();
    }
  }

  @flow()
  handleAddFirstItemGroup() {
    const { groupTree, expandedKeys } = this.state;

    if (groupTree.some(r => isEmptyKey(r.key))) {
      return;
    }
    if (groupTree.length === 20) {
      uiPrompt.current.warning('最多创建20个一级商品组');
      return;
    }

    const emptyKey = createEmptyKey();
    this.changeState({
      groupTree: [
        { key: emptyKey, title: '', children: [], itemNum: 0 },
        ...groupTree
      ],
      expandedKeys: [...expandedKeys, emptyKey]
    });
  }

  @flow()
  handleAddSecondItemGroup(parentKey: React.Key) {
    const { groupTree, expandedKeys } = this.state;
    const firstItemGroup = groupTree.find(r => r.key === parentKey);

    if (firstItemGroup?.children!.some(f => isEmptyKey(f.key))) {
      return;
    }
    if (firstItemGroup?.children?.length === 20) {
      uiPrompt.current.warning('单个一级商品组下最多创建20个二级商品组');
      return;
    }
    if (!expandedKeys.includes(parentKey)) {
      this.changeState({ expandedKeys: [...expandedKeys, parentKey] });
    }

    const newGroupTree = groupTree.map(node => {
      if (node.key === parentKey) {
        return {
          ...node,
          children: [{ key: createEmptyKey(), title: '' }, ...node.children!]
        };
      }
      return node;
    });

    this.changeState({ groupTree: newGroupTree });
  }

  @flow()
  handleExpand(expandedKeys, arg) {
    const { expanded, node } = arg;
    const inputs = document.getElementsByClassName(editInputClassName);
    const editingGroupKeys = Array.from(inputs).map(i => Number(i.id));

    if (
      !expanded &&
      (editingGroupKeys.includes(node.key) ||
        node.children.some(i => editingGroupKeys.includes(i.key)))
    ) {
      uiPrompt.current.warning('此分类下有正在编辑的商品组，请先完成编辑');
      return;
    }
    this.changeState({ expandedKeys });
  }

  @flow(Flows.latest())
  async actionInterceptor(type: 'edit' | 'del', groupId: number) {
    const actionType = {
      edit: '编辑',
      del: '删除'
    }[type];

    try {
      const { canDelete, deleteRejectReminds } =
        await itemGroupRequest.checkRelation(groupId);

      const interrupt = { del: !canDelete }[type];
      const usingFeats = { del: deleteRejectReminds }[type];

      if (interrupt) {
        this.prompt.current.warnModel(actionType, usingFeats);
      }

      return interrupt;
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow(Flows.latest())
  async handleDeleteGroup(key: React.Key) {
    const { groupTree } = this.state;
    const isFirstGroup = groupTree.some(node => node.key === key);

    const interrupt = await this.actionInterceptor('del', key as number);
    if (interrupt) {
      return;
    }

    if (isFirstGroup) {
      const node = groupTree.find(g => g.key === key)!;
      const inputs = document.getElementsByClassName(editInputClassName);
      const editingGroupKeys = Array.from(inputs).map(i => Number(i.id));
      if (
        node.children?.some(i => editingGroupKeys.includes(i.key as number))
      ) {
        uiPrompt.current.warning('此分类下有正在编辑的商品组，请先完成编辑');
        return;
      }
    }
    try {
      await itemGroupRequest.delete(key as number);
      uiPrompt.current.success('删除成功');
      this.fetchGroup();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow(Flows.latest())
  async handleGroupSearch(v: string) {
    if (document.getElementsByClassName(editInputClassName)?.length) {
      uiPrompt.current.warning('有正在编辑的商品组，请先完成编辑');
      return;
    }

    await this.fetchGroup();

    const { groupTree } = this.state;

    if (v.trim()) {
      const newExpandedKeys = groupTree.map(i => i.key);

      this.changeState({
        expandedKeys: newExpandedKeys
      });
    }
  }

  @flow(Flows.latest())
  async create(params) {
    try {
      const { value, key } = params;
      const { groupTree } = this.state;

      const parentKey = reduceParentKey(key, groupTree) as number;
      const newParams = { groupName: value, parentId: parentKey ?? 0 };

      await itemGroupRequest.create(newParams);
      await this.fetchGroup();

      uiPrompt.current.success('新建成功');
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow(Flows.latest())
  async update(params) {
    try {
      const { value, key } = params;
      const newParams = { groupName: value, id: key };

      await itemGroupRequest.update(newParams);
      await this.fetchGroup();

      uiPrompt.current.success('编辑成功');
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow(Flows.latest())
  async fetchItemList() {
    const { currentGroupId, searchQuery, tableQuery } = this.state;
    const { taobaoIid, approveStatus } = searchQuery;

    if (taobaoIid && BigInt(taobaoIid) > BigInt(maxNum)) {
      uiPrompt.current.warning('请输入正确的商品ID');
      return;
    }

    this.itemLoad();
    try {
      const platform = (getItem('platform') || 'TB') as Platform;

      const params: GroupItemQuery = {
        groupId: currentGroupId as number,
        ...searchQuery,
        ...tableQuery,
        taobaoIid: platform === 'JD' ? undefined : taobaoIid,
        taobaoSkuId: platform === 'JD' ? taobaoIid : undefined,
        approveStatus:
          platform === 'JD' && approveStatus === 'ALL'
            ? undefined
            : approveStatus
      };
      const { items, totalResult } =
        platform === 'TB'
          ? await itemGroupRequest.listItem(params)
          : await itemGroupRequest.listSku(params);

      this.changeState({ itemList: items, total: totalResult });
    } catch (error) {
      uiPrompt.current.error(error);
    } finally {
      this.itemUnLoad();
    }
  }

  @flow(Flows.latest())
  async handleSearch() {
    this.changeState({ tableQuery: defaultTableQuery });
    this.fetchItemList();
  }

  @flow(Flows.latest())
  async hanldeReset() {
    this.changeState({
      searchQuery: defaultSearchQuery,
      tableQuery: defaultTableQuery
    });
    this.fetchItemList();
  }

  @flow()
  async handleAddGroupOk(groupIds, close, taobaoIids) {
    const { tableQuery, itemList } = this.state;

    const platform = (getItem('platform') || 'TB') as Platform;

    const params = {
      groupIds,
      taobaoIids: platform === 'JD' ? undefined : taobaoIids,
      taobaoIidskuids:
        platform === 'JD'
          ? itemList
              .filter(v => taobaoIids.includes(v.taobaoSkuId))
              .map(v => ({
                taobaoIid: v.taobaoIid as unknown as string,
                taobaoSkuId: v.taobaoSkuId
              }))
          : undefined
    };

    try {
      const { success, errorMsg } =
        await itemGroupRequest.addItemsToPointGroups(params);

      if (success) {
        uiPrompt.current.success('添加成功');
        close(true);
        this.changeState({ tableQuery: { ...tableQuery, page: 1 } });
        this.fetchItemList();
      } else {
        uiPrompt.current.modalConfirm({
          title: '温馨提示',
          content: errorMsg
        });
      }
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow(Flows.latest())
  async handleDelete(params: DeleteGroupItemCmd) {
    const {
      tableQuery: { page, pageSize },
      total
    } = this.state;
    try {
      await itemGroupRequest.deleteGroupItem(params);
      uiPrompt.current.success('删除成功');

      const newPage = adjustPageAfterDelete(page, pageSize, total);
      this.changeState({ tableQuery: { page: newPage, pageSize } });
      this.fetchItemList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow(Flows.latest())
  async updateGroupItem(params: GroupItemUpdateCmd) {
    try {
      await itemGroupRequest.updateGroupItem(params);
      uiPrompt.current.success('编辑成功');

      this.fetchItemList();
    } catch (error) {
      uiPrompt.current.error(error);
    }
  }

  @flow(Flows.latest())
  async handlePageChange(page: number, pageSize: number) {
    this.changeState({ tableQuery: { page, pageSize } });

    await this.fetchItemList();
  }

  @flow(Flows.latest())
  async fetchAllitems() {
    const { currentGroupId } = this.state;
    const allItems = await itemGroupRequest.listAllItems(
      currentGroupId as number
    );

    return allItems;
  }
}

export const itemGroupModelRef = weakSharing(() => ItemGroupModel);

export const useItemGroupNum = (
  itemGroupIds: number[] = []
): number | undefined => {
  const {
    state: { originGroupTree },
    init
  } = useAgentReducer(itemGroupModelRef.current);

  useEffect(() => {
    init();
  }, []);

  if (!itemGroupIds.length) {
    return;
  }

  const newItemGroupList = originGroupTree.flatMap(i =>
    i.children?.length ? i : []
  );
  const allParenteCids = Array.from(new Set(newItemGroupList.map(i => i.key)));
  const itemGroupNum = itemGroupIds.filter(
    i => !allParenteCids.includes(i)
  ).length;

  return itemGroupNum;
};
