import React, { memo } from 'react';
import { useDialogMethods } from '@/components/widgets/modal';
import GoodsSelectModal from '@/components/works/goodsSelectModal';
import { GoodsPopInfo } from '@/components/works/components/goodsPopInfo';
import { CategoryPop } from '@/components/works/customPop';
import { Icon } from '@/components/widgets/icon';
import { RelatedType } from '@/requests/remindStrategy/types';
import { EsItem, TaobaoIidskuids } from '@/types/esItem';
import { ItemSelectorProps } from './type';
import css from './style.less';
import { showItemSku } from '../../../constants';
import { ItemGroupPop } from '@/components/works/customPop/itemGroupPop';

/**
 * 咨询商品、订单含商品、退款商品、退货商品、换货商品
 */
export const ItemSelector = memo((props: ItemSelectorProps) => {
  const {
    label = '咨询商品',
    require,
    remindType,
    value = {
      relatedType: 'ALL',
      taobaoIidskuids: [],
      itemGroupIds: [],
      sellerCatCids: []
    },
    onChange
  } = props;

  const showSku = showItemSku.includes(remindType);

  const {
    relatedType,
    taobaoIidskuids = [],
    itemGroupIds = [],
    sellerCatCids = []
  } = value;

  const [openDialog] = useDialogMethods();

  const openGoodsSelectModal = async () => {
    const params = {
      modalAction: 'inspectionItem',
      limit: 5000,
      require,
      taobaoIidskuids,
      itemRelatedType: relatedType,
      itemGroupIds,
      sellerCatCids,
      items: [],
      label,
      showSku
    };

    const confirm = await openDialog(GoodsSelectModal, params);

    if (confirm) {
      const { itemRelatedType: selectType, result } = confirm as {
        itemRelatedType: RelatedType;
        result: EsItem[] | string[] | number[] | TaobaoIidskuids[];
      };

      if (selectType === 'ITEM') {
        onChange?.({
          ...value,
          relatedType: selectType,
          taobaoIidskuids: result as TaobaoIidskuids[]
        });
        return;
      }

      if (selectType === 'ITEM_GROUP') {
        onChange?.({
          ...value,
          relatedType: selectType,
          itemGroupIds: result as number[]
        });
        return;
      }

      if (selectType === 'SELLER_CAT') {
        onChange?.({
          ...value,
          relatedType: selectType,
          sellerCatCids: result as string[]
        });
        return;
      }

      onChange?.({
        ...value,
        relatedType: selectType
      });
    }
  };

  return (
    <>
      {relatedType === 'NONE' ? '请选择' : null}
      {relatedType === 'ALL' ? '不限' : null}
      {relatedType === 'ITEM' ? (
        <GoodsPopInfo taobaoIidskuids={taobaoIidskuids} showSku={showSku} />
      ) : null}
      {relatedType === 'ITEM_GROUP' ? (
        <ItemGroupPop ids={itemGroupIds} />
      ) : null}
      {relatedType === 'SELLER_CAT' ? (
        <CategoryPop ids={sellerCatCids} />
      ) : null}
      <Icon className={css.icon} type="bianji" onClick={openGoodsSelectModal} />
    </>
  );
});
