import {
  ItemFilterConfig,
  TaobaoIidskuids
} from '@/requests/remindStrategy/types';
import { SpuItemProps } from './components/spuItem/type';
import { SkuPropertyWebDto, SkuWebDto, SpuItemDto } from '@/types/esItem';

export const linkToGoodsDetail = (goodsId: string) => {
  window.open(`https://item.taobao.com/item.htm?id=${goodsId}`);
};

export const validateItemConfig = (config?: ItemFilterConfig) => {
  const { relatedType, taobaoIidskuids, itemGroupIds, sellerCatCids } =
    config || {};

  if (!relatedType || relatedType === 'NONE') {
    return '请选择商品';
  }

  if (relatedType === 'ITEM' && !taobaoIidskuids?.length) {
    return '请选择商品';
  }

  if (relatedType === 'ITEM_GROUP' && !itemGroupIds?.length) {
    return '请选择商品组';
  }

  if (relatedType === 'SELLER_CAT' && !sellerCatCids?.length) {
    return '请选择店铺分类';
  }
};

export const computeSkuSelectStatus = (props: SpuItemProps) => {
  const { selected, item, taobaoSkuIdSet } = props;
  const { skuList = [] } = item;

  const selectSkusForSpu = item?.skuList?.filter(v =>
    taobaoSkuIdSet.has(v.taobaoSkuId)
  );

  const allSelected = selected && selectSkusForSpu?.length === skuList?.length;
  const partialSelected =
    selected &&
    selectSkusForSpu?.length > 0 &&
    selectSkusForSpu?.length < skuList?.length;
  const notSelctedAndNoSkus = !selected;
  const selectedAndNoSkus = selected && selectSkusForSpu?.length === 0;

  return {
    selectSkusForSpu,
    allSelected,
    partialSelected,
    notSelctedAndNoSkus,
    selectedAndNoSkus
  };
};

export const mergeIidSkuIds = (
  selectSkuList: SkuWebDto[],
  selectSpuList: SpuItemDto[]
): TaobaoIidskuids[] => {
  const taobaoIidskuids: TaobaoIidskuids[] = [];

  selectSkuList.forEach(v => {
    taobaoIidskuids.push({
      taobaoIid: v.taobaoIid,
      taobaoSkuId: v.taobaoSkuId
    });
  });

  const excludeTaobaoIids = taobaoIidskuids.map(v => v.taobaoIid);
  const taobaoIidSet = new Set(selectSpuList.map(v => v.taobaoIid));

  taobaoIidSet.forEach(v => {
    if (!excludeTaobaoIids.includes(v)) {
      taobaoIidskuids.push({ taobaoIid: v });
    }
  });

  return taobaoIidskuids;
};

export const splitIidSkuIds = (
  taobaoIidskuids: TaobaoIidskuids[]
): { taobaoIids: string[]; taobaoSkuIds: string[] } => {
  return {
    taobaoIids: [...new Set(taobaoIidskuids.map(v => v.taobaoIid))],
    taobaoSkuIds: taobaoIidskuids.reduce(
      (acc, x) => (x.taobaoSkuId ? [...acc, x.taobaoSkuId] : acc),
      [] as string[]
    )
  };
};

export function mergeIidSkuIdsJD(
  selectSkuList: SkuWebDto[],
  selectSpuList: SpuItemDto[]
): TaobaoIidskuids[] {
  const selectedSpuWithAllSkus = selectSpuList.filter(spu => {
    const spuSkuIds = spu.skuList.map(sku => sku.taobaoSkuId);
    const selectedSkuIds = selectSkuList.flatMap(sku =>
      sku.taobaoIid === spu.taobaoIid ? sku.taobaoSkuId : []
    );

    // 判断 selectSku 中的所有 SKU 是否都在 spu.skuList 中
    const allSelected = spuSkuIds.every(skuId =>
      selectedSkuIds.includes(skuId)
    );

    return allSelected;
  });

  const selectedSpuWithAllSkusIds = selectedSpuWithAllSkus.map(
    i => i.taobaoIid
  );
  const newSelectSpuList = selectSpuList.filter(
    i => !selectedSpuWithAllSkusIds.includes(i.taobaoIid)
  );
  const newSelectSkuList = selectSkuList.filter(
    i => !selectedSpuWithAllSkusIds.includes(i.taobaoIid)
  );

  return [
    ...selectedSpuWithAllSkus.map(i => ({ taobaoIid: i.taobaoIid })),
    ...mergeIidSkuIds(newSelectSkuList, newSelectSpuList)
  ];
}

export const combineSkuProperties = (skuProperties: SkuPropertyWebDto[]) => {
  return skuProperties
    .map(property => {
      return `${property.propertyName}: ${property.propertyValue}`;
    })
    .join(';');
};
