import React from 'react';
import { Icon } from '@/components/widgets/icon';
import { Tooltip } from '@/components/widgets/tooltip';
import classNames from 'classnames';
import css from './style.less';

type HelpTooltipProps = {
  tooltip: React.ReactNode;
  iconClassName?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const HelpTooltip = (
  props: React.PropsWithChildren<HelpTooltipProps>
): JSX.Element => {
  const { tooltip, children, iconClassName, className, style } = props;

  return (
    <span className={classNames(css.wrapper, className)} style={style}>
      {children}
      <Tooltip title={tooltip}>
        <Icon
          type="bangzhushuoming"
          className={classNames(css.helpIcon, iconClassName)}
        />
      </Tooltip>
    </span>
  );
};
