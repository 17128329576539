import React from 'react';
import { RedirectTo, SwitchWith404 } from '@/components/layouts/route';
import { Route, Redirect } from 'react-router';
import { shopChildNodes, enterpriseChildNodes } from './childNodes';

export default ({ to }: { to?: RedirectTo }): JSX.Element => {
  const pageAuthority = window.localStorage.getItem('pageAuthority');

  return (
    <SwitchWith404>
      <Route exact path="/">
        <Redirect to={to!} />
      </Route>
      {pageAuthority ? enterpriseChildNodes : shopChildNodes}
    </SwitchWith404>
  );
};
