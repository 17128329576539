import React, { memo, useState, useEffect } from 'react';
import { Table, Column, Summary } from '@/components/widgets/table';
import { DailySummaryTableProps } from './type';
import { sortByKey } from '@/services/sort';
import { TooltipWithIcon } from '@/components/works/tooltipWithIcon';
import { MarketingStatsDailySummaryValueList as SummaryDto } from '@/requests/buyerMembermarketStats/type';
import { useSorter } from '@/hooks/useSorter';

export const DailySummaryTable = memo((props: DailySummaryTableProps) => {
  const { dailySummaryStats, sumMap = {}, avgMap = {} } = props;

  const [page, setPage] = useState(1);

  const { getSortOrder, handleSorterChange, resetSorter } =
    useSorter<SummaryDto>({
      field: 'businessDay',
      order: 'descend'
    });

  useEffect(() => {
    setPage(1);
    resetSorter();
  }, [dailySummaryStats]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const summaryColumns = [
    'sendNum',
    'tradeCreatedNum',
    'createdPayment',
    'tradePaidNum',
    'payment',
    'conversionRate'
  ];

  return (
    <Table
      dataSource={dailySummaryStats}
      rowKey="businessDay"
      bordered
      showSorterTooltip={false}
      size="small"
      summary={_ =>
        dailySummaryStats.length ? (
          <Summary fixed>
            <Summary.Row>
              <Summary.Cell index={0}>汇总</Summary.Cell>
              {summaryColumns?.map((v, i) => (
                <Summary.Cell key={i} index={i + 1}>
                  {sumMap[v]?.data ?? sumMap[v] ?? '-'}
                </Summary.Cell>
              ))}
            </Summary.Row>
            <Summary.Row>
              <Summary.Cell index={0}>均值</Summary.Cell>
              {summaryColumns?.map((v, i) => (
                <Summary.Cell key={i} index={i + 1}>
                  {avgMap[v]?.data ?? avgMap[v] ?? '-'}
                </Summary.Cell>
              ))}
            </Summary.Row>
          </Summary>
        ) : null
      }
      pagination={{
        hideOnSinglePage: true,
        showQuickJumper: true,
        size: 'default',
        position: ['bottomCenter'],
        current: page,
        onChange: handlePageChange
      }}
      onChange={handleSorterChange}
    >
      <Column
        width="100px"
        title="日期"
        dataIndex="businessDay"
        sorter={sortByKey('businessDay')}
        sortOrder={getSortOrder('businessDay')}
      />
      <Column
        width="100px"
        title="发送人次"
        dataIndex="sendNum"
        sorter={sortByKey('sendNum')}
        sortOrder={getSortOrder('sendNum')}
      />
      <Column
        width="100px"
        title="下单人次"
        dataIndex="tradeCreatedNum"
        sorter={sortByKey('tradeCreatedNum')}
        sortOrder={getSortOrder('tradeCreatedNum')}
      />
      <Column
        width="100px"
        title="下单金额"
        dataIndex="createdPayment"
        sorter={sortByKey('createdPayment')}
        sortOrder={getSortOrder('createdPayment')}
      />
      <Column
        width="100px"
        title="付款人次"
        dataIndex="tradePaidNum"
        sorter={sortByKey('tradePaidNum')}
        sortOrder={getSortOrder('tradePaidNum')}
      />
      <Column
        width="100px"
        title="付款金额"
        dataIndex="payment"
        sorter={sortByKey('payment')}
        sortOrder={getSortOrder('payment')}
      />
      <Column
        width="100px"
        title={
          <span>
            总转化率
            <TooltipWithIcon tooltip="下单人次/发送人次" label="" />
          </span>
        }
        dataIndex="conversionRate"
        render={(value: number) => `${(value * 100).toFixed(2)}%`}
      />
    </Table>
  );
});
