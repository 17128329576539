import React, { memo, useState, useEffect, useRef } from 'react';
import { useAgentReducer, useModel, useModelProvider } from 'use-agent-reducer';
import classNames from 'classnames';
import { Tree } from '@/components/widgets/tree';
import { Select } from '@/components/widgets/select';
import { Tooltip } from '@/components/widgets/tooltip';
import { Icon } from '@/components/widgets/icon';
import { useDialogMethods } from '@/components/widgets/modal';
import { Dialog, DialogDeployProps } from '@/components/widgets/modal/dialogs';
import { TradeStatus, TradeStatusRuleWebDto } from '@/types/tradeStatus';
import { warnError } from '@/components/infos';
import { message } from '@/components/widgets/message';
import { Button } from '@/components/widgets/button';
import css from './style.less';
import { tradeStatusMap, closeReasonMap, closeReasonList } from './constants';
import { TradeStatusEditorModel } from './model';
import { joinTimeoutText, stopPropagation } from './util';
import TimeoutEditor from './timeoutEditor';
import { Params, TitleProps, SubMenuItemPorps } from './type';
import { usePlatform } from '@/hooks/usePlatform';

export { TradeStatusList } from './tardeStatusList';

const SubMenuItem = memo((props: SubMenuItemPorps) => {
  const { filterType, rule, closeReason, onSelect, onTimeoutEdit } = props;

  const selected = (() => {
    if (filterType === 'CLOSE_REASON') {
      return closeReason === rule.tradeStatusCondition?.tradeCloseReason;
    }
    return filterType === rule.tradeStatusCondition?.filterType;
  })();

  const handleSelect = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (filterType === 'CLOSE_REASON') {
      onSelect({
        ...rule,
        tradeStatusCondition: {
          filterType: 'CLOSE_REASON',
          tradeCloseReason: closeReason
        }
      });
      return;
    }

    onSelect({
      ...rule,
      tradeStatusCondition: {
        filterType,
        filterTime: rule.tradeStatusCondition?.filterTime || {
          value: 1,
          unit: 'DAYS'
        }
      }
    });
  };

  const handleEditTimeout = (e: React.MouseEvent) => {
    e.stopPropagation();
    onTimeoutEdit?.();
  };

  return (
    <div
      onClick={handleSelect}
      className={classNames(css.tipOption, {
        [css.selected]: selected,
        [css.paddingRight]: filterType === 'CLOSE_REASON'
      })}
    >
      {selected ? <Icon type="queding" className={css.selectedIcon} /> : null}
      {filterType === 'ALL' ? '不限' : null}
      {filterType === 'TIMEOUT' ? (
        <span>
          {joinTimeoutText(rule)}
          <Icon
            type="bianji"
            onClick={handleEditTimeout}
            className={css.editIcon}
          />
        </span>
      ) : null}
      {filterType === 'CLOSE_REASON' && closeReason
        ? closeReasonMap[closeReason]
        : null}
    </div>
  );
});

const Title = memo((props: TitleProps) => {
  const { rule } = props;

  const [tipVisible, setTipVisible] = useState(false);
  const { isTB } = usePlatform();

  const [openDialog] = useDialogMethods<TradeStatusRuleWebDto | undefined>();

  const {
    state: { tradeDay, timeoutTradeStatus },
    changeRule
  } = useAgentReducer(useModel(TradeStatusEditorModel));

  const handleTimeEdit = async () => {
    const res = await openDialog(TimeoutEditor, {
      rule,
      tradeDay
    });
    if (res) {
      changeRule(res);
    }
  };

  const handleSelect = (newRule: TradeStatusRuleWebDto) => {
    changeRule(newRule);
    setTipVisible(false);
  };

  const {
    tradeStatus,
    tradeStatusCondition: { filterType, tradeCloseReason } = {}
  } = rule;

  const title = tradeStatusMap[tradeStatus];

  const showSubMenu =
    timeoutTradeStatus.includes(tradeStatus) ||
    (tradeStatus === 'CLOSE' && isTB);

  if (!showSubMenu) {
    return <span>{title}</span>;
  }

  return (
    <span>
      {title}
      <>
        <Tooltip
          title={
            <div className={css.tips}>
              {tradeStatus === 'CLOSE' ? (
                closeReasonList.map(reason => (
                  <SubMenuItem
                    key={reason}
                    filterType="CLOSE_REASON"
                    rule={rule}
                    onSelect={handleSelect}
                    closeReason={reason}
                  />
                ))
              ) : (
                <SubMenuItem
                  filterType="TIMEOUT"
                  rule={rule}
                  onSelect={handleSelect}
                  onTimeoutEdit={handleTimeEdit}
                />
              )}
              <SubMenuItem
                filterType="ALL"
                rule={rule}
                onSelect={handleSelect}
              />
            </div>
          }
          placement="bottom"
          visible={tipVisible}
          onVisibleChange={setTipVisible}
          trigger="click"
          zIndex={1000}
          overlayClassName={css.tooltipOverlay}
        >
          <Icon
            type="xiala"
            className={classNames(css.downIcon, {
              [css.rotateIcon]: tipVisible
            })}
            onClick={stopPropagation}
          />
        </Tooltip>

        {filterType === 'TIMEOUT' ? (
          <span className={css.text} onClick={stopPropagation}>
            {joinTimeoutText(rule)}
          </span>
        ) : null}
        {filterType === 'CLOSE_REASON' && tradeCloseReason ? (
          <span className={css.text} onClick={stopPropagation}>
            {closeReasonMap[tradeCloseReason]}
          </span>
        ) : null}
      </>
    </span>
  );
});

export const TradeStatusEditor = memo((props: DialogDeployProps<Params>) => {
  const { closeDialog, params } = props;

  const tradeStatusEditorModelRef = useRef(new TradeStatusEditorModel(params));

  const ModelProvider = useModelProvider(tradeStatusEditorModelRef.current);

  const {
    state: {
      saveLoading,
      tradeDay,
      tradeDayList,
      tradeStatusRules,
      checkedTradeStatus
    },
    init,
    changeTradeDay,
    changeCheckedTradeTatus,
    confirm,
    load,
    unload
  } = useAgentReducer(tradeStatusEditorModelRef.current);

  useEffect(() => {
    init();
  }, []);

  const {
    title = '过滤订单状态',
    showCheckAll = true,
    tradeDaySelectText = '',
    onOK
  } = params;

  const treeData = showCheckAll
    ? [
        {
          title: <div>订单状态</div>,
          key: 'ALL' as TradeStatus,
          children: tradeStatusRules.map(rule => ({
            title: <Title key={rule.tradeStatus} rule={rule} />,
            key: rule.tradeStatus
          }))
        }
      ]
    : tradeStatusRules.map(rule => ({
        title: <Title key={rule.tradeStatus} rule={rule} />,
        key: rule.tradeStatus
      }));

  const handleOk = async () => {
    const res = confirm();
    if (onOK && res) {
      if (!res.tradeStatusRules.length) {
        message.warning('请选择售后状态');
        return;
      }
      load();
      try {
        await onOK(res);
        message.success('保存成功');
      } catch (error) {
        warnError(error);
      } finally {
        unload();
      }
    }
    if (res) {
      closeDialog(res);
    }
  };

  return (
    <Dialog
      title={title}
      onCancel={closeDialog}
      width={520}
      bodyStyle={{ padding: '20px 18px' }}
      footer={[
        <Button
          key="ok"
          onClick={handleOk}
          type="primary"
          loading={saveLoading}
        >
          确定
        </Button>,
        <Button key="cancel" onClick={() => closeDialog()}>
          取消
        </Button>
      ]}
    >
      <ModelProvider>
        <span>
          买家近
          <Select
            className={css.daySelect}
            options={tradeDayList.map(val => ({ label: val, value: val }))}
            value={tradeDay}
            onChange={changeTradeDay}
          />
          天的订单中，有选中的订单状态{tradeDaySelectText}
        </span>
        <div className={css.content}>
          <div className={css.header}>全部状态</div>
          <div className={css.body}>
            <Tree
              checkable
              defaultExpandAll
              checkedKeys={checkedTradeStatus}
              onCheck={keys => changeCheckedTradeTatus(keys as TradeStatus[])}
              treeData={treeData}
            />
          </div>
        </div>
      </ModelProvider>
    </Dialog>
  );
});
